import { ChangeEvent, FC } from 'react';
import { FormControlLabel, Radio, RadioProps, styled } from '@mui/material';

interface StyledRadioProps {
  value: string;
  label: string;
  name: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const BpIcon = styled('span')({
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 20,
  height: 20,
  boxShadow: 'none',
  border: '1px solid #CAD3E0',
  transition: 'border-color 0.2s ease',
  backgroundColor: '#ffffff',
  '.Mui-focusVisible &': {
    outline: '3px auto #C6DDFF',
    outlineOffset: 2,
    borderColor: '#075FF7',
  },
  'input:hover ~ &': {
    borderColor: '#075FF7',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    backgroundColor: '#F7F7F7',
  },
  'input:disabled:hover ~ &': {
    borderColor: '#CAD3E0',
  },
});

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#ffffff',
  '&:before': {
    display: 'block',
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: '#075FF7',
    content: '""',
  },
  'input:disabled ~ &': {
    opacity: '0.5',
  },
});

const BpRadio = (props: RadioProps) => {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

const StyledRadio: FC<StyledRadioProps> = ({
  value,
  label,
  name,
  disabled,
  onChange,
  checked,
  ...props
}) => {
  return (
    <FormControlLabel
      value={value}
      name={name}
      checked={checked}
      disabled={disabled}
      control={<BpRadio onChange={onChange} />}
      label={label}
      sx={{ position: 'relative', zIndex: 1 }}
      {...props}
    />
  );
};

export default StyledRadio;

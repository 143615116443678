import { useCallback, useMemo, useState } from 'react';
import { Box, Grid, Typography, Tooltip, IconButton } from '@mui/material';
import Button from '@components/common/Button/Button';
import { ReactComponent as CloseIcon } from '@assets/icons/ico-close.svg';
import OrganizationSelect from '@components/common/Select/Organization';
import EduGroupSelect from '@components/common/Select/EduGroup';
import { ProfileCategory } from '@declarations/enum/profile';
import { profileRoles } from '@mock-data/profile';
import { Region } from '@declarations/region';
import { StructureRole } from '@declarations/enum/structureRole';
import { Entity, isOfType } from '@declarations/common';
import { EduGroup } from '@declarations/eduGroup';
import StyledRadio from '@components/common/StyledRadio/StyledRadio';
import { EntityTypes } from '@mock-data/entities';
import { FormField } from '@components/common/FormField';
import { ReactComponent as QuestionIcon } from '@assets/icons/ico-question.svg';

type Props = {
  onClose: () => void;
  onSave: (data: FormData) => Promise<void>;
};

export type FormData = Partial<{
  resourceType: string;
  role: StructureRole;
  organizationId: string;
  region: Region;
  eduGroup: Entity<EduGroup>;
}>;

const ProfileTeacherAddWork = ({ onClose, onSave }: Props) => {
  const [formData, setFormData] = useState<FormData>({
    resourceType: EntityTypes.EDU_PROVIDER_ROLE,
    role: StructureRole.TEACHER,
  });
  const [loading, setLoading] = useState(false);

  const enableSave = useMemo(
    () =>
      (formData.role &&
        formData.organizationId &&
        ((formData.eduGroup && formData.role === StructureRole.CLASS_TEACHER) ||
          formData.role === StructureRole.TEACHER)) ||
      loading,
    [formData, loading],
  );

  const organizationFilters = useMemo(
    () => ({
      regionCode: formData.region && [formData.region.id],
    }),
    [formData.region],
  );

  const eduGroupFilters = useMemo(
    () => ({
      organizationId: formData.organizationId,
    }),
    [formData.organizationId],
  );

  const handleChangeFormData = useCallback(
    (newData: { [key: string]: any }) =>
      setFormData((prevState: any) => ({
        ...prevState,
        ...newData,
      })),
    [],
  );

  const onSubmit = useCallback(async () => {
    if (enableSave) {
      setLoading(true);
      await onSave(formData);
      setLoading(false);
    }
  }, [formData, enableSave, onSave]);

  return (
    <Grid container flexDirection="column" spacing={3}>
      <Grid item>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">Новая запись</Typography>
          </Grid>
          <Grid item>
            <Button
              sx={{ width: '36px', height: '36px', p: 0, minWidth: 0 }}
              onClick={onClose}
            >
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <FormField label="Должностная роль">
          <Grid container flexDirection="column">
            <Box maxWidth="372px">
              {profileRoles[ProfileCategory.TEACHER_EDUCATOR].map((role) => (
                <Grid item key={role.code}>
                  <StyledRadio
                    name="role"
                    value={role.code}
                    checked={formData.role === role.code}
                    label={role.display}
                    onChange={() =>
                      handleChangeFormData({
                        role: role.code,
                      })
                    }
                  />
                </Grid>
              ))}
            </Box>
          </Grid>
        </FormField>
      </Grid>
      {/* <Grid item container spacing={3} sx={{ alignItems: 'center' }}>
        <Grid item md={3}>
          <Typography
            variant={'additionally' as 'h1'}
            sx={{ color: '#626B7A' }}
          >
            Субъект РФ
          </Typography>
        </Grid>
        <Grid item flexGrow={1}>
          <Box sx={{ maxWidth: '416px' }}>
            <RegionSelect
              name="region"
              placeholder="выбрать"
              id="region"
              onChange={(region: Region) =>
                handleChangeFormData({
                  region,
                  organization: undefined,
                  eduGroup: undefined,
                })
              }
            />
          </Box>
        </Grid>
      </Grid> */}
      {/* <Grid item container spacing={3} sx={{ alignItems: 'center' }}>
        <Grid item md={3}>
          <Typography
            variant={'additionally' as 'h1'}
            sx={{ color: '#626B7A' }}
          >
            Населенный пункт
          </Typography>
        </Grid>
        <Grid item flexGrow={1}>
          <Box sx={{ maxWidth: '416px' }}>
            <SingleSelect
              // TODO: change logic, when api will return reference
              isDisabled
              options={subjectOptions}
              name="subject"
              placeholder={
                formData.region ? 'выбрать' : 'сначала выберите субъект РФ'
              }
              id="subject"
            />
          </Box>
        </Grid>
      </Grid> */}
      <Grid item sx={{ alignItems: 'center' }}>
        <FormField label="Образовательная организация">
          <Box display={'flex'}>
            <Box flexGrow={1} maxWidth="372px">
              <OrganizationSelect
                name="organization"
                id="organization"
                // isDisabled={!formData.region}
                placeholder="выбрать"
                // placeholder={
                //   formData.region
                //     ? 'выбрать'
                //     : 'сначала выберите Населенный пункт'
                // }
                value={formData.organizationId}
                filters={organizationFilters}
                onChange={(organizationId) => {
                  if (isOfType<string>(organizationId, 'toLocaleLowerCase')) {
                    handleChangeFormData({
                      organizationId,
                      eduGroup: undefined,
                    });
                  } else {
                    console.error('Organization cannot be multiple');
                  }
                }}
              />
            </Box>
            <Box>
              <Tooltip title="Свою образовательную организацию вы можете так же найти, указав ИНН">
                <IconButton disableRipple>
                  <QuestionIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </FormField>
      </Grid>
      <Grid item sx={{ alignItems: 'center' }}>
        <FormField label="Класс (или другая группировка обучающихся)">
          <Box maxWidth="372px">
            <EduGroupSelect
              name="eduGroup"
              isDisabled={!formData.organizationId}
              placeholder={
                formData.organizationId
                  ? 'выбрать'
                  : 'сначала выберите Образовательную организацию'
              }
              selected={formData.eduGroup}
              filters={eduGroupFilters}
              onChange={(eduGroup: Entity<EduGroup>) =>
                handleChangeFormData({
                  eduGroup,
                })
              }
            />
          </Box>
        </FormField>
      </Grid>
      <Grid item container spacing={3} sx={{ flexWrap: 'nowrap' }}>
        <Grid item md={3} flexShrink={0} />
        <Grid item container columnSpacing={1}>
          <Grid item>
            <Button variant="fourth" onClick={onClose}>
              Отмена
            </Button>
          </Grid>
          <Grid item md={5}>
            <Button
              fullWidth
              variant="first"
              disabled={!enableSave}
              onClick={onSubmit}
            >
              Сохранить
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProfileTeacherAddWork;

import * as React from 'react';
import { useCallback } from 'react';
import Button from '@components/common/Button/Button';
import { ReactComponent as IconEdit } from '@assets/icons/edit-fill.svg';
import { ReactComponent as IconDown } from '@assets/icons/arrow-down.svg';
import docxImg from '@assets/icons/file-types/docx.png';
import xlsxImg from '@assets/icons/file-types/xlsx.png';
import pptxImg from '@assets/icons/file-types/pptx.png';
import { documentsAPI } from '@api/documents';
import { downloadFile } from '@lib/common';
import { DocumentEntityResponse } from '@api/types/documents';
import { MY_FILES_LINK } from '@constants/config';

type Props = {
  file: DocumentEntityResponse;
};

export const File = ({ file }: Props) => {
  const loadFile = useCallback(async () => {
    try {
      const fileBlob = await documentsAPI.downloadDocument(file.id);
      downloadFile(fileBlob, file.description);
    } catch (error) {
      console.error(error);
    }
  }, [file.id, file.description]);

  const openFileLink = useCallback(async () => {
    try {
      const linkData = await documentsAPI.generateLinkToFile(file.id);
      if (linkData) {
        window.open(`${MY_FILES_LINK}link/${linkData.uid}`, '_blank');
      }
    } catch (error) {
      console.error(error);
    }
  }, [file.id]);

  return (
    <div className="file">
      <div className="file__header">
        <Button onClick={openFileLink} sx={{ p: 0, minWidth: 'auto' }}>
          <IconEdit />
        </Button>
      </div>
      <div className="file__body">
        <img src={getFileImg(file.description)} />
      </div>
      <div className="file__footer">
        <div className="file__footer-file-icon">
          <img src={getFileImg(file.description)} />
        </div>
        <div className="file__footer-label">{file.name}</div>
        <div className="file__footer-down-icon">
          <IconDown />
        </div>
        <button className="file__footer-btn" onClick={loadFile} />
      </div>
    </div>
  );
};

const getFileImg = (description: string) => {
  const extension = description.split('.').pop() ?? '';
  switch (extension) {
    case 'docx':
      return docxImg;
    case 'xlsx':
      return xlsxImg;
    case 'pptx':
      return pptxImg;
    default:
      return '';
  }
};

import { GounnResponse } from '@api/types/gounn';
import dayjs from 'dayjs';
import { Schedule } from '@redux/schedule/types';
import objectFieldsToLowerCase from '@utils/objectFormat';

/**
 * Нам нужно показывать время и игнорировать таймзону (чтобы не было привидения к текущей)
 * Поэтому в явном виде должны передать формат для парсинга даты
 *  */
const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const mapFromGounnServer = (response: GounnResponse): Schedule => {
  const result: Schedule = {};

  const parsedResponse = objectFieldsToLowerCase(response);

  parsedResponse?.school?.forEach((school) => {
    school?.class?.forEach((cl) => {
      cl?.lesson?.forEach((lesson) => {
        const { start, end } = lesson.period;
        const key = `${dayjs(start, DATE_FORMAT).format(
          'HH:mm',
        )}\u00A0—\u00A0${dayjs(end, DATE_FORMAT).format('HH:mm')}`;
        result[key] = result[key] || {};
        result[key][dayjs(start, DATE_FORMAT).format('YYYY-MM-DD')] = {
          lesson: lesson.subject,
          group: cl.class,
          classroom: `каб. ${
            lesson.classroom || lesson.classRoom || 'не указан'
          }`,
          score: lesson.score || [],
        };
      });
    });
  });

  return result;
};

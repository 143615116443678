import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.locale('ru');
dayjs.extend(utc);
dayjs.extend(timezone);

export const setPublishDate = (el: string) => {
  if (el) {
    const date = dayjs(el);
    return date.format('HH:mm, DD MMMM YYYY');
  }
  return '';
};

export const getRemainDate = (el: string) => {
  const date = new Date(Date.parse(el));
  return date.toLocaleDateString('ru-RU', {
    month: 'long',
    day: 'numeric',
  });
};

export const convertUTCtoLocale = (date: Date | string): Date => {
  return dayjs(date).utc(true).local().tz('Europe/Moscow').toDate();
};

export const getPeriodString = (
  startDate?: Date,
  endDate?: Date,
  option?: {
    short?: boolean;
    showYear?: boolean;
  },
) => {
  if (startDate && endDate) {
    const from = new Date(startDate);
    const to = new Date(endDate);
    if (dayjs(from).isSame(to, 'day')) {
      return new Date(startDate).toLocaleDateString('ru', {
        month: option?.short ? 'short' : 'long',
        day: 'numeric',
        year: option?.showYear ? 'numeric' : undefined,
      });
    }
    if (dayjs(from).isSame(to, 'month')) {
      return `${from.getDate()}—${to.toLocaleDateString('ru', {
        month: option?.short ? 'short' : 'long',
        day: 'numeric',
        year: option?.showYear ? 'numeric' : undefined,
      })}`;
    }
    if (dayjs(from).isSame(to, 'year')) {
      return `${from.toLocaleDateString('ru', {
        month: option?.short ? 'short' : 'long',
        day: 'numeric',
      })}—${to.toLocaleDateString('ru', {
        month: option?.short ? 'short' : 'long',
        day: 'numeric',
        year: option?.showYear ? 'numeric' : undefined,
      })}`;
    }
    return `${from.toLocaleDateString('ru', {
      month: option?.short ? 'short' : 'long',
      day: 'numeric',
      year: option?.showYear ? 'numeric' : undefined,
    })}—${to.toLocaleDateString('ru', {
      month: option?.short ? 'short' : 'long',
      day: 'numeric',
      year: option?.showYear ? 'numeric' : undefined,
    })}`;
  }
  return null;
};

export const getWeekStartAndEnd = (date?: Date): [Date, Date] => [
  dayjs(date).startOf('week').toDate(),
  dayjs(date).endOf('week').toDate(),
];

export const getDateRage = (startDate: Date, endDate: Date) => {
  if (endDate < startDate) {
    throw new Error('Дата окончания больше даты начала');
  }
  const arr: Date[] = [];
  for (
    let dt = new Date(startDate);
    dt <= endDate;
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr;
};

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { UserHistory } from '@redux/user/types';
import { setPublishDate } from '@lib/date/date';

type Props = {
  history: UserHistory[];
};

const EntryHistory = ({ history }: Props) => {
  if (!history.length) {
    return null;
  }
  return (
    <Grid container spacing={2} flexDirection="column" sx={{ mt: '48px' }}>
      <Grid item>
        <Typography variant="h4" sx={{ fontWeight: 500 }}>
          История
        </Typography>
      </Grid>
      <Grid item>
        <Table className="notifications__table" sx={{ width: '100%' }}>
          <TableHead className="notifications__table-head">
            <TableRow>
              {history.length && history[0].personName !== undefined && (
                <TableCell>Имя</TableCell>
              )}
              <TableCell>Название</TableCell>
              <TableCell align="left">Статус</TableCell>
              <TableCell align="right">Дата</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history.map(({ id, name, description, date, personName }) => (
              <TableRow
                key={id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                {personName && (
                  <TableCell align="left">
                    <Typography variant="caption">{`${personName.family} ${personName.given}`}</Typography>
                  </TableCell>
                )}
                <TableCell align="left">
                  <Typography variant="caption">{name}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="caption">{description}</Typography>
                </TableCell>
                <TableCell align="right">
                  {!!date && (
                    <Typography variant="caption">
                      {setPublishDate(date)}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default EntryHistory;

import { notifyAxios } from './utils/axios';

const SERVICE_NAME = 'api-ntfc-pref/api/v1';

export type Preference = {
  eventType: number;
  subType: number;
};

export const subscriptionsApi = {
  getSubscriptions: (userId: number | string) =>
    notifyAxios.get<
      { userId: number; preferences: Preference[] },
      { userId: number; preferences: Preference[] }
    >(`${SERVICE_NAME}/preferences/${userId}`, {
      params: {
        isProtected: true,
      },
    }),
  changeSubscriptions: (userId: number, preferences: Preference[]) =>
    notifyAxios.post<unknown, unknown>(
      `${SERVICE_NAME}/preferences`,
      {
        userId,
        preferences,
      },
      {
        params: {
          isProtected: true,
        },
      },
    ),
};

import { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUser } from '@redux/user';
import { loginLink } from '@lib/auth/getAuthLinks';

export const PrivateRoute = (props: RouteProps) => {
  const user = useSelector(getUser);

  useEffect(() => {
    if (!user) {
      window.location.href = loginLink;
    }
  }, [user]);

  return user ? <Route {...props} /> : null;
};

import { useCallback, useEffect, useMemo } from 'react';
import './Links.scss';
import { Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import Title from '@components/common/Title/Title';
import { LinkStatus } from '@declarations/links';
import LinksItem from '@pages/Links/LinksItem/LinksItem';
import linksApi from '@api/links';
import Button from '@components/common/Button/Button';
import { useSnackbar } from '@hooks/useSnackbar';
import { RootState } from '@redux/store';
import CommonFilter from '@components/common/CommonFilter';
import { DEFAULT_PAGINATION } from '@constants/constants';
import { useInfiniteQuery } from 'react-query';
import { Loader } from '@components/common/Loader/Loader';
import { getUser } from '@redux/user';
import { useQueryDelay } from '@hooks/useQueryDelay';

export const LinksPage = () => {
  const { addSnack } = useSnackbar();
  const filterValues = useSelector((state: RootState) => state.filters.values);
  const user = useSelector(getUser);
  const isTargetedSearch = useSelector(
    (state: RootState) => state.filters.isTargetedSearch,
  );
  const { requestAreAllowed } = useQueryDelay();

  const loadData = useCallback(
    async ({ pageParam = DEFAULT_PAGINATION.currentPage }) => {
      const response = await linksApi.searchLinks(
        { currentPage: pageParam, pageSize: 10 },
        {
          status: LinkStatus.PUBLISHED,
          ...filterValues,
        },
        user ? isTargetedSearch : false,
      );
      if (!response) {
        throw Error('Не удалось загрузить данные');
      }
      return response;
    },
    [filterValues, isTargetedSearch, user],
  );

  const {
    data,
    error,
    isSuccess,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['link-list', ...Object.values(filterValues)],
    loadData,
    {
      getNextPageParam: (lastPage, pages) =>
        !lastPage || lastPage.last ? undefined : pages.length + 1,
      enabled: requestAreAllowed,
    },
  );

  const totalCount = useMemo(
    () => data?.pages?.[0]?.totalElements,
    [data?.pages],
  );

  useEffect(() => {
    if (error) {
      addSnack('Ошибка загрузки данных', 'error');
    }
  }, [error, addSnack]);

  return (
    <Grid
      container
      flexDirection="column"
      sx={{ mt: 6, maxWidth: { xl: 952, lg: 894, md: 852, sm: '100%' } }}
    >
      <Grid item>
        <Title>Полезные ссылки</Title>
      </Grid>
      <Grid item sx={{ mt: 3 }} maxWidth={'100%'}>
        <CommonFilter
          showCategory={true}
          showDate={false}
          countItems={totalCount}
        />
      </Grid>
      <Grid item sx={{ mt: 3 }}>
        {isLoading ? (
          <Box mt={3}>
            <Loader />
          </Box>
        ) : (
          totalCount === 0 && (
            <Grid
              container
              flexDirection="column"
              sx={{
                maxWidth: '368px',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                mx: 'auto',
                mt: 5,
              }}
            >
              <Grid item>
                <Typography variant="h4" sx={{ fontWeight: 500 }}>
                  Мы ничего не нашли
                </Typography>
              </Grid>
              <Grid item sx={{ mt: 2 }}>
                <Typography
                  variant={'additionally' as 'h1'}
                  sx={{ color: '#75747B' }}
                >
                  Попробуйте проверить написание или изменить значения фильтров
                </Typography>
              </Grid>
            </Grid>
          )
        )}
        {isSuccess && (
          <>
            <Grid item>
              <Grid container>
                {data?.pages.map((page) => {
                  return page.content.map((link) => (
                    <Grid
                      item
                      key={link.id}
                      width="100%"
                      boxShadow="0px 1px 0px #E8E8ED"
                    >
                      <LinksItem link={link} />
                    </Grid>
                  ));
                })}
              </Grid>
            </Grid>
            {hasNextPage && (
              <Grid item sx={{ mt: 8 }}>
                <Button
                  variant="third"
                  sx={{ mt: 8 }}
                  fullWidth
                  onClick={() => fetchNextPage()}
                  disabled={isFetchingNextPage}
                >
                  {isFetchingNextPage ? 'Загружаем' : 'Показать еще'}
                </Button>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

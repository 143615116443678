import { Filter } from '@declarations/common';
import getSearchGetParams from '@lib/search/getSearchGetParams';
import { searchAxios } from './utils/axios';
import { PagedData } from './types/notify';

const searchApi = {
  search: (
    pagination: { currentPage: number; pageSize: number },
    filters: Partial<Filter>,
  ) =>
    searchAxios.get<PagedData<any>, PagedData<any>>('/', {
      params: getSearchGetParams(pagination, filters),
    }),
};

export default searchApi;

import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import qs from 'query-string';
import { useSelector } from 'react-redux';

import './Search.scss';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import Title from '@components/common/Title/Title';
import SearchItem from '@pages/Search/SearchItem/SearchItem';
// import { ActiveFilters } from '@redux/filters/types';
import searchApi from '@api/search';
import { RootState } from '@redux/store';
import { useHistory } from 'react-router';
import CommonFilter from '@components/common/CommonFilter';
import { ROUTES } from '@constants/routes';
import { SearchSource } from '@declarations/enum/search';
import { Pagination } from '@components/common/Pagination';

export const SearchPage = () => {
  const [searchList, setSearchList] = useState<{
    total: number;
    totalPages: number;
    //TODO add types
    data: any[];
  }>({
    total: 0,
    totalPages: 0,
    data: [],
  });
  const [pagination, setPagination] = useState({
    pageSize: 10,
    currentPage: 1,
  });
  const [loading, setLoading] = useState(true);

  const filters = useSelector((state: RootState) => state.filters.values);

  const { source: searchSource } = qs.parse(location.search);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const items = await searchApi.search(pagination, { ...filters });
      setSearchList({
        total: items?.totalElements || 0,
        totalPages: items?.totalPages || 0,
        data: [...(items?.content || [])],
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [pagination, filters]);

  const pageCount = useMemo(
    () => searchList.totalPages > 1,
    [searchList.totalPages],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    setPagination((prevState) => ({ ...prevState, currentPage: 1 }));
  }, [filters]);

  const history = useHistory();

  useEffect(() => {
    const searchString = filters.search;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { q, ...searchParams } = qs.parse(location.search);
    history.push(
      qs.stringifyUrl({
        url: ROUTES.search,
        query: {
          ...searchParams,
          q: searchString,
        },
      }),
    );
  }, [filters.search, history]);

  const handleChangePage = useCallback(
    (e: ChangeEvent<unknown>, value: number) => {
      setPagination((prevState) => ({
        ...prevState,
        currentPage: value,
      }));
    },
    [],
  );

  return (
    <Grid
      container
      flexDirection="column"
      sx={{ mt: 6, maxWidth: { xl: 952, lg: 894, md: 852, sm: '100%' } }}
    >
      <Grid item>
        <Title>Поиск</Title>
      </Grid>
      <Grid item sx={{ mt: 3 }}>
        <CommonFilter
          disableRegion={searchSource === SearchSource.ALL}
          disableGrade={searchSource === SearchSource.ALL}
          disableOrganization={searchSource === SearchSource.ALL}
          showCategory={false}
          showDate={false}
          showPeriodDate
          showSort
          showContentType
          countItems={searchList.total}
        />
      </Grid>
      {loading ? (
        <Grid
          item
          height={200}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid item sx={{ mt: 3 }}>
          {searchList.data.length === 0 && (
            <Grid
              container
              flexDirection="column"
              sx={{
                maxWidth: '368px',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                mx: 'auto',
                mt: 5,
              }}
            >
              <Grid item>
                <Typography variant="h4" sx={{ fontWeight: 500 }}>
                  Мы ничего не нашли
                </Typography>
              </Grid>
              <Grid item sx={{ mt: 2 }}>
                <Typography
                  variant={'additionally' as 'h1'}
                  sx={{ color: '#75747B' }}
                >
                  Попробуйте проверить написание или изменить значения фильтров
                </Typography>
              </Grid>
            </Grid>
          )}

          {searchList.data.map((item) => (
            <SearchItem key={item.id} item={item} />
          ))}

          {pageCount && (
            <Box mt={6}>
              <Pagination
                count={searchList.totalPages}
                page={pagination.currentPage}
                onChange={handleChangePage}
              />
            </Box>
          )}
        </Grid>
      )}
    </Grid>
  );
};

import { Sidebar } from '@pages/Manager/Sidebar/Sidebar';
import { Grid } from '@mui/material';
import { Redirect, Route, Switch } from 'react-router-dom';
import NewsList from '@pages/Manager/News/NewsList';
import FaqList from '@pages/Manager/Faq/FaqList';
import LinksList from '@pages/Manager/Links/LinksList';
import QuizList from '@pages/Manager/Quiz/QuizList';
import JournalList from '@pages/Manager/Journal/JournalList';
import DiaryList from '@pages/Manager/Diary/DiaryList';
import { FaqForm } from '@pages/Manager/Faq/FaqForm';
import './ManagerContainer.scss';
import { LinksForm } from '@pages/Manager/Links/LinksForm';
import { QuizForm } from '@pages/Manager/Quiz/QuizForm';
import { JournalForm } from '@pages/Manager/Journal/JournalForm';
import { DiaryForm } from '@pages/Manager/Diary/DiaryForm';
import { NewsForm } from '@pages/Manager/News/NewsForm';
import { QuizResult } from '@components/Quiz/Result';
import { useSelector } from 'react-redux';
import AlertForm from '@pages/Manager/Alert/AlertForm';
import { isExternalISAdmin } from '@redux/user';
import { ROUTES } from '@constants/routes';
import AlertList from '@pages/Manager/Alert/AlertList';
import { ReactNode, useMemo } from 'react';
import { RootState } from '@redux/store';
import { useContentCredentials } from '@hooks/useContentCredentials';

export const ManagerContainer: React.FC = () => {
  const isAdminIS = useSelector(isExternalISAdmin);
  const isLoading = useSelector((state: RootState) => state.user.isLoading);

  const credentials = useContentCredentials();

  const isAccessDenied = useMemo(
    () => Object.values(credentials).every((item) => !item),
    [credentials],
  );

  const routes = useMemo(() => getRoutes(credentials), [credentials]);

  if (!isLoading && isAccessDenied) {
    return <Redirect to={ROUTES.notFound} />;
  }

  return (
    <>
      <Grid container>
        <Grid item sx={{ width: '260px' }}>
          <Sidebar />
        </Grid>
        <Grid item xs>
          <div className="manager-container">
            <Switch>{routes}</Switch>
            {isAdminIS && (
              <Switch>
                <Route
                  exact
                  path={ROUTES.managerAlertList}
                  component={AlertList}
                />
                <Route
                  exact
                  path={ROUTES.managerAlertAdd}
                  component={AlertForm}
                />
                <Route
                  exact
                  path={ROUTES.managerAlertItem}
                  component={AlertForm}
                />
              </Switch>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const getRoutes = (credentials: {
  news: boolean;
  faq: boolean;
  links: boolean;
  quiz: boolean;
  journal: boolean;
  diary: boolean;
  administration: boolean;
}) => {
  const routes: ReactNode[] = [];
  if (credentials.news) {
    routes.push(
      ...[
        <Route
          key={ROUTES.managerNewsList}
          exact
          path={ROUTES.managerNewsList}
          component={NewsList}
        />,
        <Route
          key={ROUTES.managerNewsAdd}
          exact
          path={ROUTES.managerNewsAdd}
          component={NewsForm}
        />,
        <Route
          key={ROUTES.managerNewsItem}
          exact
          path={ROUTES.managerNewsItem}
          component={NewsForm}
        />,
      ],
    );
  }
  if (credentials.faq) {
    routes.push(
      ...[
        <Route
          key={ROUTES.managerFaqList}
          exact
          path={ROUTES.managerFaqList}
          component={FaqList}
        />,
        <Route
          key={ROUTES.managerFaqAdd}
          exact
          path={ROUTES.managerFaqAdd}
          component={FaqForm}
        />,
        <Route
          key={ROUTES.managerFaqItem}
          exact
          path={ROUTES.managerFaqItem}
          component={FaqForm}
        />,
      ],
    );
  }
  if (credentials.links) {
    routes.push(
      ...[
        <Route
          key={ROUTES.managerLinkList}
          exact
          path={ROUTES.managerLinkList}
          component={LinksList}
        />,
        <Route
          key={ROUTES.managerLinkAdd}
          exact
          path={ROUTES.managerLinkAdd}
          component={LinksForm}
        />,
        <Route
          key={ROUTES.managerLinkItem}
          exact
          path={ROUTES.managerLinkItem}
          component={LinksForm}
        />,
      ],
    );
  }
  if (credentials.quiz) {
    routes.push(
      ...[
        <Route
          key={ROUTES.managerQuizList}
          exact
          path={ROUTES.managerQuizList}
          component={QuizList}
        />,
        <Route
          key={ROUTES.managerQuizResult}
          exact
          path={ROUTES.managerQuizResult}
          component={QuizResult}
        />,
        <Route
          key={ROUTES.managerQuizAdd}
          exact
          path={ROUTES.managerQuizAdd}
          component={QuizForm}
        />,
        <Route
          key={ROUTES.managerQuizItem}
          exact
          path={ROUTES.managerQuizItem}
          component={QuizForm}
        />,
      ],
    );
  }
  if (credentials.journal) {
    routes.push(
      ...[
        <Route
          key={ROUTES.managerJournalList}
          exact
          path={ROUTES.managerJournalList}
          component={JournalList}
        />,
        <Route
          key={ROUTES.managerJournalAdd}
          exact
          path={ROUTES.managerJournalAdd}
          component={JournalForm}
        />,
        <Route
          key={ROUTES.managerJournalItem}
          exact
          path={ROUTES.managerJournalItem}
          component={JournalForm}
        />,
      ],
    );
  }
  if (credentials.diary) {
    routes.push(
      ...[
        <Route
          key={ROUTES.managerDiaryList}
          exact
          path={ROUTES.managerDiaryList}
          component={DiaryList}
        />,
        <Route
          key={ROUTES.managerDiaryAdd}
          exact
          path={ROUTES.managerDiaryAdd}
          component={DiaryForm}
        />,
        <Route
          key={ROUTES.managerDiaryItem}
          exact
          path={ROUTES.managerDiaryItem}
          component={DiaryForm}
        />,
      ],
    );
  }
  return routes;
};

import { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useOnClickOutside } from 'usehooks-ts';
import { Box } from '@mui/system';
import classNames from 'classnames';

import { useFixBody } from '@hooks/useFixBody';
import Button from '@components/common/Button/Button';

import { MENU_ITEMS } from '../constants';

import './styles.scss';
import { useUserMenu } from '@hooks/useUserMenu';
import { Divider, Grid } from '@mui/material';
import { UserAvatar } from '../UserAvatar/UserAvatar';

export const MobileMenu = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  const menuRef = useRef(null);

  useFixBody(menuOpened);

  useOnClickOutside(menuRef, () => {
    menuOpened && setMenuOpened(false);
  });

  const { isAuth, routes, userInfo, userName } = useUserMenu();

  const handleClose = () => setMenuOpened(false);

  return (
    <div ref={menuRef}>
      <div
        className="menu__trigger"
        onClick={() => setMenuOpened((prev) => !prev)}
      >
        <div
          className={classNames('menu__trigger-line', menuOpened && 'active')}
        />
      </div>
      {menuOpened && (
        <div className="menu-mobile">
          {isAuth ? (
            <>
              {userInfo && (
                <Box p={2} pb={0} pt="4px">
                  <div className="menu-mobile__item">
                    <Grid container alignItems="center">
                      <Grid item>
                        <UserAvatar invert>{userInfo.initials}</UserAvatar>
                      </Grid>
                      <Grid
                        item
                        ml={2}
                        flex={1}
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        fontWeight="500"
                        fontSize="18px"
                        lineHeight="18px"
                      >
                        {userName}
                      </Grid>
                    </Grid>
                  </div>
                </Box>
              )}
              <Divider sx={{ margin: '8px 0' }} />
              <Box p={2} pb={0} pt={0}>
                {routes.authorized.main.map((item) => (
                  <div key={item.url} className="menu-mobile__item">
                    <NavLink
                      to={item.url}
                      activeClassName="active"
                      className="menu-mobile__link"
                      onClick={handleClose}
                    >
                      {item.label}
                      {!!item.badge && (
                        <Box
                          bgcolor="#FA5C41"
                          padding="5px 10px"
                          borderRadius={2}
                          color="#FFFFFF"
                          fontSize="14px"
                        >
                          {item.badge}
                        </Box>
                      )}
                    </NavLink>
                  </div>
                ))}
              </Box>
              {routes.authorized.secondary.length > 0 && (
                <>
                  <Divider sx={{ margin: '8px 0' }} />
                  <Box p={2} pt={0} pb={0}>
                    {routes.authorized.secondary.map((item) => (
                      <div key={item.url} className="menu-mobile__item">
                        <a
                          href={item.url}
                          className="menu-mobile__link"
                          onClick={() => {
                            if (item.onClick) {
                              item.onClick();
                            }
                            handleClose();
                          }}
                        >
                          {item.label}
                        </a>
                      </div>
                    ))}
                  </Box>
                </>
              )}
              <Divider sx={{ margin: '8px 0' }} />
            </>
          ) : (
            routes.unauthorized.map((item) => (
              <Box key={item.url} padding={2} borderBottom="1px solid #DCE2EB">
                <Button variant="third" externalLink={item.url} fullWidth>
                  {item.label}
                </Button>
              </Box>
            ))
          )}
          <Box p={2}>
            {MENU_ITEMS.map(({ label, path }) => (
              <div key={path} className="menu-mobile__item">
                <NavLink
                  to={path}
                  activeClassName="active"
                  className="menu-mobile__link"
                  onClick={handleClose}
                >
                  {label}
                </NavLink>
              </div>
            ))}
          </Box>
        </div>
      )}
    </div>
  );
};

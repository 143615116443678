import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  OrganizationFilters,
  OrganizationShortInfo,
} from '@declarations/organization';
import {
  getOrganizationRequest as loadOrganizations,
  organizationApi,
} from '@api/organization';
import uniqBy from 'lodash/uniqBy';
import { Option } from '@components/common/Select/types';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import debounce from 'lodash/debounce';
import { OrganisationsUtil } from '@utils/organisations.util';
import { EduOrganizationGroup } from '@declarations/eduOrganizationGroup';

export const ALL_ORGANIZATION_KEY = 'all';
export const DYNAMIC_LOADED_ORGANIZATIONS_KEY = 'dynamic';

const useCachedOrganisationsStateManager = (data: {
  filters: OrganizationFilters | undefined;
  value: string | string[] | undefined;
  showFullName: boolean | undefined;
  allowedIds: string[] | undefined;
  hardInstalledOrganizations: EduOrganizationGroup[] | undefined;
}) => {
  const {
    filters,
    value,
    showFullName,
    allowedIds,
    hardInstalledOrganizations,
  } = data;

  const targetInfo = useSelector((state: RootState) => state.user.targetInfo);

  // defaultOptions for AsyncSelect
  const [defaultOptions, setDefaultOptions] = useState<Option[]>([]);

  const [cachedOrganizations, setCachedOrganizations] = useState<Map<
    string,
    OrganizationShortInfo[]
  > | null>(null);

  // список загруженных организаций, соответсвующий выбранным регионам на текущий момент (если учитывается фильтр по региону)
  const [organizationsForSelectedRegions, setOrganizationsForSelectedRegions] =
    useState<OrganizationShortInfo[]>([]);

  // дополнительные организации, которые не входят в organizationsForSelectedRegions, но требуются для отображения
  const [additionalOrganizations, setAdditionalOrganizations] = useState<
    OrganizationShortInfo[]
  >([]);

  // отображаемые в текущий момент организации, после фильтрации
  const [displayedOrganizations, setDisplayedOrganizations] = useState<
    OrganizationShortInfo[]
  >([]);

  // выбранные организации
  const selectedOrganizations = useMemo(() => {
    if (!value) {
      return;
    }
    if (Array.isArray(value)) {
      return displayedOrganizations?.filter(
        (org) => org.id && value.includes(org.id),
      );
    }
    return displayedOrganizations.find((org) => org.id === value);
  }, [value, displayedOrganizations]);

  useEffect(() => {
    if (hardInstalledOrganizations) {
      const options = hardInstalledOrganizations.reduce((acc, group) => {
        const option = OrganisationsUtil.mapEduOrganisationGroupToOption(group);
        return option ? [...acc, option] : acc;
      }, [] as Option[]);

      setDefaultOptions(options);
      setDisplayedOrganizations(
        hardInstalledOrganizations
          .map((eduOrgGroup) =>
            OrganisationsUtil.mapEduOrganizationGroupToOrgShortInfo(
              eduOrgGroup,
            ),
          )
          .filter((org) => !!org) as OrganizationShortInfo[],
      );
    }
  }, [hardInstalledOrganizations]);

  useEffect(() => {
    if (hardInstalledOrganizations) return;
    // Формирование cachedOrganizations

    //TODO: тайм аут необходимо убрать. найти другой способ.
    // причина его здесь: не подхватывает allowedIds при первом рендере

    const timer = setTimeout(() => {
      fetchOrganizations({ inputValue: '' })
        .then((regionsWithOrganizations) => {
          let newCachedOrgs: Map<string, OrganizationShortInfo[]> | null = null;
          if (!cachedOrganizations) {
            setCachedOrganizations(regionsWithOrganizations);
            newCachedOrgs = regionsWithOrganizations;
          } else {
            newCachedOrgs = new Map(cachedOrganizations);
            regionsWithOrganizations.forEach((value, key) => {
              if (newCachedOrgs?.has(key)) return;
              newCachedOrgs?.set(key, value);
            });

            setCachedOrganizations(newCachedOrgs);
          }
          return newCachedOrgs;
        })
        .then((newCachedOrgs) => {
          assembleOrganizationsForSelectedRegions({
            cachedOrgs: newCachedOrgs,
            localValue: value,
          });
        });
    }, 700);

    return () => {
      clearTimeout(timer);
    };

    // Для того, чтобы избежать циклической зависимости от cachedOrganizations
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const assembleOrganizationsForSelectedRegions = useCallback(
    async (data: {
      cachedOrgs: Map<string, OrganizationShortInfo[]> | null;
      localValue: string | string[] | undefined;
      cb?: (options: Option[]) => void;
    }) => {
      // Формирование organizationsForSelectedRegions
      const { cachedOrgs, localValue, cb } = data;

      const updatingPromise = async () => {
        setOrganizationsForSelectedRegions(newOrgsForSelectedRegions);
        const newAdditionalOrgs = await assembleAdditionalOrganizations(
          newOrgsForSelectedRegions,
          localValue,
        );
        await filterOrganizationsToDisplay({
          cb: cb ? cb : (options) => setDefaultOptions(options),
          organizations: newOrgsForSelectedRegions,
          newAdditionalOrgs,
        });
      };

      let newOrgsForSelectedRegions: OrganizationShortInfo[] = [];
      if (filters?.regionCode?.length && cachedOrgs) {
        newOrgsForSelectedRegions = Array.from(cachedOrgs.entries())
          .filter(([key]) => filters?.regionCode?.some((code) => code === +key))
          .reduce(
            (acc, [, value]) => [...acc, ...value],
            [] as OrganizationShortInfo[],
          );
        await updatingPromise();
      } else if (cachedOrgs && cachedOrgs?.has(ALL_ORGANIZATION_KEY)) {
        newOrgsForSelectedRegions = Array.from(cachedOrgs.values()).flat();
        await updatingPromise();
      }
    },

    // Для того, чтобы не было циклической зависимости для assembleAdditionalOrganizations
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filters?.regionCode,
      setDefaultOptions,
      displayedOrganizations,
      cachedOrganizations,
    ],
  );

  const fetchOrganizations = useCallback(
    (data: { isDynamicLoading?: boolean; inputValue: string }) => {
      const { inputValue, isDynamicLoading } = data;

      const notExistRegionsData = isDynamicLoading
        ? filters?.regionCode
        : filters?.regionCode?.filter(
            (code) =>
              !(
                cachedOrganizations &&
                Array.from(cachedOrganizations.keys()).find(
                  (id) => +id === code,
                )
              ),
          );

      const isInnSearchingValue =
        !!Number(inputValue.trim()) &&
        inputValue.trim().length >= 3 &&
        inputValue.trim().length <= 12;

      const createLoadOrganizationsPromise = () => {
        return loadOrganizations({
          ...filters,
          name: isInnSearchingValue ? undefined : inputValue,
          inn: isInnSearchingValue ? inputValue.trim() : undefined,
          regionCode: filters?.regionCode?.length
            ? notExistRegionsData
            : filters?.regionCode,
        });
      };

      if (isDynamicLoading) {
        return createLoadOrganizationsPromise();
      }

      if (
        (filters?.regionCode?.length && !notExistRegionsData?.length) ||
        (!filters?.regionCode?.length &&
          cachedOrganizations?.has(ALL_ORGANIZATION_KEY))
      ) {
        return Promise.resolve(new Map());
      } else {
        return createLoadOrganizationsPromise();
      }
    },
    [cachedOrganizations, filters],
  );

  const assembleAdditionalOrganizations = useCallback(
    async (
      organizations: OrganizationShortInfo[],
      localValue: string | string[] | undefined,
    ) => {
      let newAdditionalOrgsList: OrganizationShortInfo[] = [];
      if (localValue && localValue?.length) {
        const orgIds = organizations.map(({ id }) => id);
        const initialValue = Array.isArray(localValue)
          ? [...localValue]
          : [localValue];
        const notExistOrgIds = initialValue?.filter(
          (id) => !orgIds.includes(id),
        );

        if (notExistOrgIds.length) {
          newAdditionalOrgsList = cachedOrganizations?.values()
            ? uniqBy(
                Array.from(cachedOrganizations?.values()).flat(),
                'id',
              ).filter((cachedOrg) =>
                notExistOrgIds.find(
                  (notExistOrgId) => notExistOrgId === cachedOrg.id,
                ),
              )
            : [];

          if (newAdditionalOrgsList.length === notExistOrgIds.length) {
            setAdditionalOrganizations(newAdditionalOrgsList);
            return newAdditionalOrgsList;
          }

          newAdditionalOrgsList = uniqBy(
            [
              ...newAdditionalOrgsList,
              ...additionalOrganizations.filter((addOrg) =>
                notExistOrgIds.find((id) => id === addOrg.id),
              ),
            ],
            'id',
          );

          if (newAdditionalOrgsList.length === notExistOrgIds.length) {
            setAdditionalOrganizations(newAdditionalOrgsList);
            return newAdditionalOrgsList;
          }

          newAdditionalOrgsList = [
            ...newAdditionalOrgsList,
            ...displayedOrganizations.filter(
              (displayedOrg) =>
                notExistOrgIds.some((id) => id === displayedOrg.id) &&
                !newAdditionalOrgsList.find(
                  (org) => org.id === displayedOrg.id,
                ),
            ),
          ];

          if (newAdditionalOrgsList.length === notExistOrgIds.length) {
            setAdditionalOrganizations(newAdditionalOrgsList);
            return newAdditionalOrgsList;
          }

          const fetchedAdditionalOrgs = await Promise.all(
            notExistOrgIds
              .filter(
                (id) => !newAdditionalOrgsList.find((org) => org.id === id),
              )
              .map(organizationApi.get),
          );

          newAdditionalOrgsList = uniqBy(
            [
              ...newAdditionalOrgsList,
              ...(fetchedAdditionalOrgs
                .map((org) =>
                  OrganisationsUtil.mapOrganizationToOrgShortInfo(org),
                )
                .filter((org) => !!org) as OrganizationShortInfo[]),
            ],
            'id',
          );
          setAdditionalOrganizations(newAdditionalOrgsList);
          return newAdditionalOrgsList;
        } else {
          setAdditionalOrganizations(newAdditionalOrgsList);
          return newAdditionalOrgsList;
        }
      } else if (additionalOrganizations.length) {
        setAdditionalOrganizations(newAdditionalOrgsList);
        return newAdditionalOrgsList;
      }
      return newAdditionalOrgsList;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      value,
      displayedOrganizations,
      cachedOrganizations,
      additionalOrganizations,
    ],
  );

  const mapOrganizationOptions = useCallback(
    (
      organizations: OrganizationShortInfo | OrganizationShortInfo[],
      showFullName?: boolean,
    ): Option[] => {
      if (Array.isArray(organizations)) {
        return organizations.map((org) =>
          OrganisationsUtil.mapOrganisationToOption(org, showFullName),
        );
      }
      return [
        OrganisationsUtil.mapOrganisationToOption(organizations, showFullName),
      ];
    },
    [],
  );

  const filterOrganizationsToDisplay = useCallback(
    async (data: {
      cb: (options: Option[]) => void;
      organizations: OrganizationShortInfo[];
      newAdditionalOrgs: OrganizationShortInfo[];
    }) => {
      const { cb, organizations, newAdditionalOrgs } = data;

      let options: Option[] = [];
      try {
        const addUserOrganizations = (newOrgzList: OrganizationShortInfo[]) => {
          const userOrgs = targetInfo
            ? (Object.values(targetInfo.organizations)
                .map((org) =>
                  OrganisationsUtil.mapOrganizationToOrgShortInfo(org),
                )
                .filter((org) => !!org) as OrganizationShortInfo[])
            : [];
          return newOrgzList.concat(userOrgs);
        };

        const removeDuplicates = (newOrgzList: OrganizationShortInfo[]) => {
          return uniqBy(newOrgzList, 'id');
        };

        const restrictByTargeting = (newOrgzList: OrganizationShortInfo[]) => {
          // Ограничение по таргетированию
          return allowedIds
            ? newOrgzList?.filter((org) => allowedIds.includes(org.id || ''))
            : newOrgzList;
        };

        const updatedOrganizations: OrganizationShortInfo[] =
          restrictByTargeting(
            removeDuplicates(
              addUserOrganizations([
                ...(JSON.parse(
                  JSON.stringify(organizations),
                ) as OrganizationShortInfo[]),
                ...newAdditionalOrgs,
              ]),
            ),
          );

        setDisplayedOrganizations(updatedOrganizations);

        options = mapOrganizationOptions(updatedOrganizations, showFullName);
      } catch (err) {
        console.error('Error on load organizations');
      }

      cb(options);
    },

    [mapOrganizationOptions, showFullName, targetInfo, allowedIds],
  );

  const selectedOptions = useMemo(() => {
    return selectedOrganizations
      ? mapOrganizationOptions(selectedOrganizations, showFullName)
      : null;
  }, [mapOrganizationOptions, selectedOrganizations, showFullName]);

  const loadOptions = debounce(
    async (inputValue: string, cb: (options: Option[]) => void) => {
      const dynamicOrganizations = await fetchOrganizations({
        inputValue,
        isDynamicLoading: true,
      });

      await assembleOrganizationsForSelectedRegions({
        cachedOrgs: dynamicOrganizations,
        localValue: value,
        cb,
      });
    },
    2000,
  );

  const assembleOrganizations = async () => {
    await assembleOrganizationsForSelectedRegions({
      cachedOrgs: cachedOrganizations,
      localValue: value,
    });
  };

  useEffect(() => {
    if (hardInstalledOrganizations) return;
    /* Для обновления списка ОО, в случае когда был снят выбор с additionalOrganization
    (для того чтобы убрать уже не нужную additionalOrganization из списка) */

    assembleOrganizationsForSelectedRegions({
      cachedOrgs: cachedOrganizations,
      localValue: value,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, hardInstalledOrganizations]);

  useEffect(() => {
    if (hardInstalledOrganizations) return;
    // Для записи в кэш организаций, которые находились за все время поиска в additionalOrganizations

    if (additionalOrganizations) {
      setCachedOrganizations((prevState) => {
        if (!prevState) return prevState;
        const newCachedOrgs = new Map(prevState);
        const extraCache = prevState?.get(DYNAMIC_LOADED_ORGANIZATIONS_KEY);
        if (extraCache) {
          newCachedOrgs.set(
            DYNAMIC_LOADED_ORGANIZATIONS_KEY,
            uniqBy(extraCache.concat(additionalOrganizations), 'id'),
          );
        } else {
          newCachedOrgs.set(
            DYNAMIC_LOADED_ORGANIZATIONS_KEY,
            additionalOrganizations,
          );
        }
        return newCachedOrgs;
      });
    }
  }, [additionalOrganizations, hardInstalledOrganizations]);

  return {
    selectedOrganizations,
    displayedOrganizations,
    additionalOrganizations,
    organizationsForSelectedRegions,
    setDisplayedOrganizations,
    selectedOptions,
    loadOptions,
    defaultOptions,
    assembleOrganizations,
  };
};

export { useCachedOrganisationsStateManager };

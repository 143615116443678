import {
  Button,
  Pagination as MuiPagination,
  PaginationProps,
  SxProps,
} from '@mui/material';
import { ReactNode } from 'react';

import { ReactComponent as IconCaretRight } from '@assets/icons/caret-icon-right.svg';

type Props = PaginationProps;

export const Pagination = (props: Props) => (
  <MuiPagination
    size="large"
    color="standard"
    shape="rounded"
    hidePrevButton={props.page === 1}
    hideNextButton={!!props.count && props.page === props.count}
    renderItem={(item) => {
      let buttonContent: ReactNode = item.page;
      if (item.type === 'next') {
        buttonContent = <IconCaretRight />;
      } else if (item.type === 'previous') {
        buttonContent = (
          <IconCaretRight style={{ transform: 'rotate(180deg)' }} />
        );
      } else if (['start-ellipsis', 'end-ellipsis'].includes(item.type)) {
        buttonContent = '...';
      }

      let buttonStyles: SxProps = {
        minWidth: '56px',
      };

      if (item.disabled) {
        buttonStyles = {
          ...buttonStyles,
          background: 'none !important',
          opacity: 0.3,
          color: 'inherit',
        };
      }

      return (
        <Button
          onClick={item.onClick}
          variant={item.selected ? 'first' : undefined}
          disabled={item.disabled}
          sx={buttonStyles}
        >
          {buttonContent}
        </Button>
      );
    }}
    {...props}
  />
);

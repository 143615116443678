import * as React from 'react';
import { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import { CollectionItem } from './CollectionItem/CollectionItem';
import './CollectionWidget.scss';
import Title from '../../../components/common/Title/Title';
import SwiperCore, { Navigation } from 'swiper';
import ArrowRight from '@assets/icons/slider-arrow-right.svg';
import { EUserRole, UserRole } from '@redux/user/types';
import { useSelector } from 'react-redux';
import { getUserRole } from '@redux/user';

interface CollectionItem {
  img: string;
  color: string;
  title: string;
  url: string;
  target: UserRole[];
}

const items: CollectionItem[] = [
  {
    title: 'Интересные вопросы от детей',
    url: 'https://myschool.edu.ru/news/73',
    color: '',
    img: '/img/mainpage/collection/test-news.png',
    target: [EUserRole.NO_AUTH],
  },
  {
    title: 'Тихо! Идет диктант!',
    url: 'https://totaldict.ru/news/news/el-marina-stepnova-stala-avtorom-totalnogo-diktanta-2022/',
    color: '',
    img: '/img/mainpage/collection/collection1-1.png',
    target: [EUserRole.STUDENT, EUserRole.ADMIN, EUserRole.PARENT],
  },
  {
    title: 'Поезд победы в парке «Патриот»',
    url: 'https://patriotp.ru/afisha/poezd-pobedy/',
    color: '',
    img: '/img/mainpage/collection/collection-win.jpg',
    target: [EUserRole.TEACHER],
  },
  {
    title: 'Развивающие программы для детей',
    url: 'https://new.dop.mosreg.ru/directivities/estestvennonauchnoe',
    color: '',
    img: '/img/mainpage/collection/collection-dev.jpg',
    target: [EUserRole.TEACHER],
  },
  {
    title: '3D Экскурсия в музей-заповедник «Бородинское поле»',
    url: 'https://www.borodino.ru/wp-content/uploads/3d_winter/indexdata/',
    color: '',
    img: '/img/mainpage/collection/collection-3d.jpg',
    target: [EUserRole.TEACHER],
  },
  {
    title: 'Всеросс объявляет даты.',
    url: 'https://vos.olimpiada.ru/2021/school',
    color: '',
    img: '/img/mainpage/collection/collection1-5.png',
    target: [EUserRole.PARENT],
  },
  {
    title: 'Гостиный двор принимает абитуриентов',
    url: 'http://www.znanie.info/msk/rus/about/',
    color: '',
    img: '/img/mainpage/collection/collection1-6.png',
    target: [EUserRole.PARENT],
  },
  {
    title: 'Орлята России',
    url: 'https://orlyatarussia.ru/',
    color: '',
    img: '/img/mainpage/collection/collection1-9.png',
    target: [EUserRole.NO_AUTH, EUserRole.STUDENT, EUserRole.ADMIN],
  },
  {
    title: 'Идем в Государственный Эрмитаж',
    url: 'http://academy.hermitagemuseum.org/materials/mastera-lejdenskoj-shkoly-tonkoj-zhivopisi',
    color: '',
    img: '/img/mainpage/collection/collection1-4.png',
    target: [EUserRole.NO_AUTH, EUserRole.STUDENT, EUserRole.ADMIN],
  },
];

SwiperCore.use([Navigation]);
export const CollectionWidget: React.FC = () => {
  const prevRef = useRef<HTMLInputElement>(null);
  const nextRef = useRef<HTMLInputElement>(null);
  const [isEnd, setIsEnd] = useState(false);
  const [isBegin, setIsBegin] = useState(false);

  const userRole = useSelector(getUserRole);
  const filteredItems = items?.filter((item) => item.target.includes(userRole));
  return (
    <div className="collection-widget">
      <Title sx={{ fontSize: '24px', marginBottom: '40px' }}>Подборки</Title>
      <div className="collection-widget__slider">
        <Swiper
          navigation={{
            prevEl: prevRef.current!,
            nextEl: nextRef.current!,
          }}
          onInit={(swiper) => {
            // @ts-ignore
            swiper.params.navigation.prevEl = prevRef.current;
            // @ts-ignore
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.update();
          }}
          spaceBetween={32}
          slidesPerView={1}
          onSlideChange={(swiper) => {
            setIsEnd(swiper.isEnd);
            setIsBegin(swiper.isBeginning);
          }}
          onSwiper={(swiper) => {
            setIsEnd(swiper.isEnd);
            setIsBegin(swiper.isBeginning);
          }}
          breakpoints={{
            758: {
              slidesPerView: 3,
            },
          }}
        >
          {filteredItems.map((collection, index) => (
            <SwiperSlide key={index}>
              <CollectionItem collection={collection} />
            </SwiperSlide>
          ))}
          <div ref={prevRef} />
          <div ref={nextRef} />
        </Swiper>
        <div
          onClick={() => prevRef?.current?.click()}
          className={`collection-widget__arrow collection-widget__arrow--prev ${
            isBegin ? 'collection-widget__arrow--disable' : ''
          }`}
          style={{ backgroundImage: `url(${ArrowRight})` }}
        />
        <div
          onClick={() => nextRef?.current?.click()}
          className={`collection-widget__arrow collection-widget__arrow--next ${
            isEnd ? 'collection-widget__arrow--disable' : ''
          }`}
          style={{ backgroundImage: `url(${ArrowRight})` }}
        />
      </div>
    </div>
  );
};

import axios from 'axios';
import {
  dataResponseInterceptor,
  authResponseErrorInterceptor,
  authRequestInterceptor,
} from '../interceptors';

export const instance = axios.create({
  baseURL: '/etd-backend',
  withCredentials: true,
});

instance.interceptors.request.use(authRequestInterceptor);

instance.interceptors.response.use(
  dataResponseInterceptor,
  authResponseErrorInterceptor(instance),
);

import React, { FC, useCallback } from 'react';
import { Box, Grid } from '@mui/material';
import { ReactComponent as ViewCardIcon } from '@assets/icons/view-card.svg';
import { ReactComponent as ViewListIcon } from '@assets/icons/view-list.svg';

import { NewsViewType } from '@declarations/news';
import CommonFilter from '@components/common/CommonFilter';
import Title from '@components/common/Title/Title';
import { useDispatch } from 'react-redux';
import { addFilterValues } from '@redux/filters';
import { PUBLICATION_DATE } from '@pages/NewsListPage/enum';

export interface NewsListPageHeaderProps {
  view: NewsViewType;
  setView: (view: NewsViewType) => void;
  totalCount?: number;
}

export const NewsListPageHeader: FC<NewsListPageHeaderProps> = ({
  view,
  setView,
  totalCount,
}) => {
  const dispatch = useDispatch();

  const handleSelectTab = useCallback(
    (view: NewsViewType) => () => setView(view),
    [setView],
  );

  React.useEffect(() => {
    dispatch(addFilterValues({ time: PUBLICATION_DATE.WEEK }));
  }, [dispatch]);

  return (
    <>
      <Title>Новости</Title>
      <Box sx={{ mt: 3, mb: 4, position: 'relative', zIndex: 1 }}>
        <CommonFilter
          showCategory={false}
          showDate={true}
          countItems={totalCount}
          extra={
            <Grid
              container
              columnSpacing={0.5}
              borderRadius={1}
              height={44}
              padding={0.5}
              sx={{
                backgroundColor: '#F2F4F7',
              }}
            >
              {[
                { value: 'card', icon: <ViewCardIcon /> },
                { value: 'list', icon: <ViewListIcon /> },
              ].map((item) => (
                <Grid
                  item
                  key={item.value}
                  width={56}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={1}
                  sx={{
                    background: view === item.value ? '#fff' : 'none',
                    cursor: 'pointer',
                  }}
                  onClick={handleSelectTab(item.value as NewsViewType)}
                >
                  {item.icon}
                </Grid>
              ))}
            </Grid>
          }
        />
      </Box>
    </>
  );
};

import * as React from 'react';
import { MultiValue } from 'react-select';
import {
  Box,
  Drawer,
  Grid,
  InputAdornment,
  Paper,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import { ReactComponent as FilterIcon } from '@assets/icons/filter-horizontal.svg';
import { ReactComponent as FilterCloseIcon } from '@assets/icons/ico-close.svg';

import './CommonFilter.scss';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { StyledInput } from '@components/styledComponents/StyledInput';

import { Toggles } from '@components/common/Toggles/Toggles';
import OrganizationSelect from '@components/common/Select/Organization';
import { ButtonGroupToggle } from '@components/common/ButtonGroupToggle/ButtonGroupToggle';
import { fetchFilterOptions } from '@redux/filters';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import useDebounce from '@hooks/useDebounce';
import { Filter } from '@declarations/common';
import { Option } from '@components/common/Select/types';
import RegionSelect from '@components/common/Select/Region';
import EduOrganizationGroupSelect from '@components/common/Select/EduOrganizationGroup';
import { EduOrganizationGroup } from '@declarations/eduOrganizationGroup';
import StyledRadio from '@components/common/StyledRadio/StyledRadio';
import MultiSelect from '@components/common/Select/MultiSelect';
import { DatePicker } from '@components/common/DatePicker/DatePicker';

export const getDefaultFilter = (showPeriod: boolean): Filter => ({
  search: '',
  audience: [],
  educationLevel: [],
  levelOfEducation: [],
  region: [],
  oo: [],
  ooGroup: [],
  parallel: [],
  forWhom: 'all',
  period: showPeriod ? [undefined, undefined] : undefined,
});

export interface FilterProps {
  onChange?: (filters: Filter) => void;
  enableFilterDrawer?: boolean;
  showActivityPeriod?: boolean;
  showActivityPeriodSelector?: boolean;
}

const forWhomItems = [
  {
    label: 'для всех',
    value: 'all',
  },
  {
    label: 'мужчинам',
    value: 'MALE',
  },
  {
    label: 'женщинам',
    value: 'FEMALE',
  },
];

const CommonFilter: React.FC<FilterProps> = ({
  onChange,
  enableFilterDrawer = true,
  showActivityPeriod = true,
  showActivityPeriodSelector,
}) => {
  const dispatch = useDispatch();

  const {
    audience: audienceOptions,
    regions: regionOptions,
    parallels: parallelOptions,
    eduLevels: eduLevelOptions,
  } = useSelector((state: RootState) => state.filters.options);

  const firstUpdate = useRef(true);

  const [activityPeriodOpened, setActivityPeriodOpened] =
    useState(showActivityPeriod);
  const [filterExpanded, setFilterExpanded] = useState(false);
  const [filter, setFilter] = useState(getDefaultFilter(activityPeriodOpened));
  const debouncedFilter = useDebounce<Filter>(filter, 500);

  const [selectedGroups, setSelectedGroups] = useState<{
    [id: string]: EduOrganizationGroup;
  }>({});

  const filterCount = useMemo(
    () =>
      Number(!!filter.audience.length) +
      Number(!!filter.educationLevel.length) +
      Number(!!filter.region.length) +
      Number(!!filter.oo.length) +
      Number(!!filter.ooGroup.length) +
      Number(!!filter.parallel.length) +
      Number(filter.forWhom !== 'all') +
      Number(!!(filter.period?.[0] || filter.period?.[1])),
    [filter],
  );

  const organizationFilters = useMemo(
    () => ({
      regionCode: filter.region,
    }),
    [filter.region],
  );

  const eduOrganizationGroupFilters = useMemo(
    () => ({
      organizations: filter.oo,
    }),
    [filter.oo],
  );

  const selectedRegionOptions = useMemo(
    () => regionOptions?.filter((item) => filter.region.includes(+item.value)),
    [filter.region, regionOptions],
  );

  const selectedParallels = useMemo(
    () =>
      parallelOptions?.filter((item) => filter.parallel.includes(item.value)),
    [filter.parallel, parallelOptions],
  );

  const orgSelectValue = useMemo(
    () => filter.oo.map((item) => item.toString()),
    [filter.oo],
  );

  const clearFilter = useCallback(() => {
    setFilter(getDefaultFilter(activityPeriodOpened));
    setSelectedGroups({});
  }, [activityPeriodOpened]);

  const updateFilter = useCallback((key: keyof Filter, value: any) => {
    if (key === 'region') {
      setFilter((prev) => ({
        ...prev,
        region: value.map((item: { value: number }) => item.value),
        oo: [],
      }));
    } else {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  }, []);

  const onAccept = useCallback(() => {
    onChange && onChange(filter);
    setFilterExpanded(false);
  }, [onChange, filter]);

  useEffect(() => {
    // TODO: Придумать что нибудь по лучше
    // Избавляемся от запуска при первом рендере
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    onChange && onChange(debouncedFilter);
    // автоматичски запускаем callback только при поиске
    // eslint-disable-next-line
  }, [onChange, debouncedFilter.search]);

  useEffect(() => {
    dispatch(fetchFilterOptions());
  }, [dispatch]);

  useEffect(() => {
    if (activityPeriodOpened) {
      updateFilter('period', [undefined, undefined]);
    } else {
      updateFilter('period', undefined);
    }
  }, [activityPeriodOpened, updateFilter]);

  return (
    <Grid container spacing={2}>
      <Grid item xs flexGrow={1}>
        <Paper elevation={0} sx={{ position: 'relative', width: '100%' }}>
          <StyledInput
            className={'news__search'}
            size={'medium'}
            placeholder={'Поиск по разделу'}
            onChange={(e) => updateFilter('search', e.target.value)}
            value={filter.search}
            fullWidth
            InputProps={{
              endAdornment: enableFilterDrawer && (
                <InputAdornment position="end">
                  <Button
                    className={`filter-toggle-btn 
                ${filterExpanded ? 'filter-toggle-btn--active' : ''}
                `}
                    sx={{
                      padding: '8px 24px 8px 24px',
                      fontSize: '14px',
                      lineHeight: '1.3rem',
                    }}
                    onClick={() => setFilterExpanded(!filterExpanded)}
                  >
                    <FilterIcon style={{ marginRight: '5px' }} /> фильтры
                    {filterCount > 0 && (
                      <span className="filter__count">{filterCount}</span>
                    )}
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Paper>
      </Grid>
      <Drawer anchor="right" open={filterExpanded} onClose={onAccept}>
        <Box sx={{ ml: 3, mr: 3, width: 468 }}>
          <Grid item container className="filter__header">
            <Grid item xs={6}>
              <Typography fontSize="18px" fontWeight="500">
                Фильтры
              </Typography>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="flex-end">
              {filterCount > 0 && (
                <button
                  type="reset"
                  className="filter__count-button"
                  onClick={clearFilter}
                >
                  <span className="filter__count reset">{filterCount}</span>
                  Сбросить
                </button>
              )}
              <button
                className="filter__close-btn"
                onClick={() => setFilterExpanded(false)}
              >
                <FilterCloseIcon />
              </button>
            </Grid>
          </Grid>
          <Grid item container sx={{ mb: 3 }}>
            <Grid item xs={12}>
              Период актуальности
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  mt: 1,
                }}
              >
                {showActivityPeriodSelector && (
                  <Box
                    flexDirection="column"
                    display="flex"
                    sx={{
                      mb: activityPeriodOpened ? 1 : 0,
                    }}
                  >
                    <StyledRadio
                      value="1"
                      name="activityPeriod"
                      checked={!activityPeriodOpened}
                      onChange={() => {
                        setActivityPeriodOpened(false);
                      }}
                      label="Актуально всегда"
                    />
                    <StyledRadio
                      value="2"
                      name="activityPeriod"
                      checked={activityPeriodOpened}
                      onChange={() => {
                        setActivityPeriodOpened(true);
                      }}
                      label="Указать месяцы"
                    />
                  </Box>
                )}

                {activityPeriodOpened && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DatePicker
                      value={filter.period?.[0]}
                      onChange={(date) =>
                        updateFilter('period', [date, filter.period?.[1]])
                      }
                    />
                    &nbsp; &#8212; &nbsp;
                    <DatePicker
                      value={filter.period?.[1]}
                      onChange={(date) =>
                        updateFilter('period', [filter.period?.[0], date])
                      }
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>

          <Grid item container sx={{ mb: 3 }}>
            <Grid item xs={12} sx={{ mb: 1 }}>
              Аудитория
            </Grid>
            <Grid item xs={12}>
              <Toggles
                value={filter.audience}
                items={audienceOptions}
                onChange={(audience) => updateFilter('audience', audience)}
              />
            </Grid>
          </Grid>

          <Grid item container sx={{ mb: 3 }}>
            <Grid item xs={12} sx={{ mb: 1 }}>
              Уровень общего образования
            </Grid>
            <Grid item xs={12}>
              <Toggles
                value={filter.educationLevel}
                items={eduLevelOptions}
                onChange={(eduLevel) =>
                  updateFilter('educationLevel', eduLevel)
                }
              />
            </Grid>
          </Grid>

          <Grid item container sx={{ mb: 2 }}>
            <Grid item xs={12} sx={{ mb: 1 }}>
              Субъект РФ
            </Grid>
            <Grid item xs={12}>
              <RegionSelect
                isMulti
                id="subjects"
                placeholder="Выбрать"
                name="Субъект РФ"
                value={selectedRegionOptions?.map((option) => +option.value)}
                onChange={(optionList: MultiValue<Option>) =>
                  updateFilter('region', optionList)
                }
              />
            </Grid>
          </Grid>

          <Grid item container sx={{ mb: 2 }}>
            <Grid item xs={12} sx={{ mb: 1 }}>
              ОО
            </Grid>
            <Grid item xs={12}>
              <OrganizationSelect
                name="organization"
                id="organization"
                placeholder="OO"
                isDisabled={!filter.region.length}
                isMulti
                onChange={(organizationIds) => {
                  if (Array.isArray(organizationIds)) {
                    updateFilter(
                      'oo',
                      organizationIds.map((id) => +id),
                    );
                  } else {
                    organizationIds && updateFilter('oo', [+organizationIds]);
                  }
                }}
                filters={organizationFilters}
                value={orgSelectValue}
              />
            </Grid>
          </Grid>

          <Grid item container sx={{ mb: 2 }}>
            <Grid item xs={12} sx={{ mb: 1 }}>
              Группа ОО
            </Grid>
            <Grid item xs={12}>
              <EduOrganizationGroupSelect
                id={'eduOrganizationGroup'}
                isMulti
                name="eduGroup"
                placeholder="Группа ОО"
                filters={eduOrganizationGroupFilters}
                selected={Object.values(selectedGroups)}
                onChange={(eduOrganizationGroups) => {
                  if (Array.isArray(eduOrganizationGroups)) {
                    updateFilter(
                      'ooGroup',
                      eduOrganizationGroups.map((org) => +(org.id || '0')),
                    );
                    const hashTable = eduOrganizationGroups?.reduce(
                      (hashTable, eduOrganizationGroup) => {
                        if (eduOrganizationGroup.id) {
                          hashTable[eduOrganizationGroup.id] =
                            eduOrganizationGroup;
                        }
                        return hashTable;
                      },
                      {} as { [id: string]: EduOrganizationGroup },
                    );
                    setSelectedGroups(hashTable);
                  } else {
                    eduOrganizationGroups.id &&
                      updateFilter('ooGroup', [+eduOrganizationGroups.id]);

                    setSelectedGroups(
                      eduOrganizationGroups.id
                        ? {
                            [eduOrganizationGroups.id]: eduOrganizationGroups,
                          }
                        : {},
                    );
                  }
                }}
              />
            </Grid>
          </Grid>

          <Grid item container sx={{ mb: 2 }}>
            <Grid item xs={12} sx={{ mb: 1 }}>
              Параллель
            </Grid>
            <Grid item xs={12}>
              <MultiSelect
                options={parallelOptions}
                id="parallel"
                placeholder="Выбрать"
                name="Параллель"
                value={selectedParallels}
                onChange={(options) =>
                  updateFilter(
                    'parallel',
                    options ? options.map(({ value }) => value) : [],
                  )
                }
              />
            </Grid>
          </Grid>

          <Grid item container sx={{ mb: 2 }}>
            <Grid item xs={12} sx={{ mb: 1 }}>
              Для кого
            </Grid>
            <Grid item xs={12}>
              <ButtonGroupToggle
                value={filter.forWhom}
                items={forWhomItems}
                onChange={(forWhom) => updateFilter('forWhom', forWhom)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className="filter__footer">
            <Button variant="first" fullWidth onClick={onAccept}>
              Применить
            </Button>
          </Grid>
        </Box>
      </Drawer>
    </Grid>
  );
};

export default CommonFilter;

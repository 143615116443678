import { useState, useCallback, useEffect, useMemo } from 'react';
import './QuizList.scss';
import {
  Grid,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import Title from '@components/common/Title/Title';
import QuizItem from '@pages/Quiz/QuizItem/QuizItem';
import { quizAPI } from '@api/quiz';
import { Quiz } from '@api/types/quiz';
import { Loader } from '@components/common/Loader/Loader';
import { ScrollListener } from '@components/common/ScrollListerner/ScrollListener';
import { useSelector } from 'react-redux';
import { getUser } from '@redux/user';
import { roleHierarchy } from '@mock-data/entities';

const DEFAULT_PAGINATION = {
  currentPage: 1,
  totalPages: 0,
};

export const QuizListPage = () => {
  const [status, setStatus] = useState('PUBLISHED');
  const [quizList, setQuizList] = useState<Quiz[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [activePage, setActivePage] = useState(DEFAULT_PAGINATION.currentPage);
  const user = useSelector(getUser);

  const hasMore = useMemo(
    () => pagination.currentPage < pagination.totalPages,
    [pagination],
  );

  const isNotLoginOrEmptyRoles: boolean = useMemo(() => {
    const roles = (user as unknown as any)?.realm_access?.roles;
    if (!roles) {
      return true;
    }
    return Object.entries(roleHierarchy).reduce<boolean>((result, entry) => {
      if (result) {
        const [profile, structureRoles] = entry;
        if (roles.includes(profile)) {
          return structureRoles.every((role) => !roles.includes(role));
        }
        return true;
      }
      return false;
    }, true);
  }, [user]);

  const loadData = useCallback(
    async (page = 1) => {
      try {
        setLoading(true);
        const { content, totalPages, number } = await quizAPI.search(
          {
            currentPage: page,
            pageSize: 10,
          },
          {
            status: status,
            period: [undefined, undefined],
          },
          !!user,
        );
        setQuizList((prev) => prev.concat(content));
        setPagination({
          currentPage: number + 1,
          totalPages,
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [status, user],
  );

  useEffect(() => {
    if (!isNotLoginOrEmptyRoles) {
      loadData();
    }
  }, [isNotLoginOrEmptyRoles, loadData]);

  useEffect(() => {
    setQuizList([]);
    setPagination(DEFAULT_PAGINATION);
    setActivePage(DEFAULT_PAGINATION.currentPage);
  }, [status]);

  useEffect(() => {
    if (
      !isNotLoginOrEmptyRoles &&
      hasMore &&
      !loading &&
      activePage !== pagination.currentPage
    ) {
      loadData(activePage);
    }
  }, [
    hasMore,
    loadData,
    loading,
    activePage,
    pagination.currentPage,
    isNotLoginOrEmptyRoles,
  ]);

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    backgroundColor: '#F2F4F7',
    '& .MuiToggleButtonGroup-grouped': {
      margin: '4px',
      border: 0,
      textTransform: 'none',
      backgroundColor: 'transparent',
      padding: '7px 16px',
      '&.Mui-selected': {
        backgroundColor: '#FFF',
      },
      '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
      },
      '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
      },
    },
  }));

  return (
    <Grid
      container
      flexDirection="column"
      sx={{ mt: 6, maxWidth: { xl: 952, lg: 894, md: 852, sm: '100%' } }}
    >
      <Grid item>
        <Title>Опросы</Title>
      </Grid>
      <Grid item sx={{ mt: 3 }}>
        <StyledToggleButtonGroup
          exclusive
          value={status}
          onChange={(ev, val) => setStatus(val)}
        >
          <ToggleButton value="PUBLISHED">Открытые</ToggleButton>
          <ToggleButton value="ARCHIVED">Завершенные</ToggleButton>
        </StyledToggleButtonGroup>
      </Grid>
      <Grid item sx={{ mt: 3 }}>
        {loading ? (
          <Loader />
        ) : !quizList.length || isNotLoginOrEmptyRoles ? (
          <Grid
            container
            flexDirection="column"
            sx={{
              maxWidth: '368px',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              mx: 'auto',
              mt: 5,
            }}
          >
            <Grid item>
              <Typography variant="h4" sx={{ fontWeight: 500 }}>
                {!user || isNotLoginOrEmptyRoles
                  ? 'Опросы недоступны'
                  : 'Мы ничего не нашли'}
              </Typography>
            </Grid>
            <Grid item sx={{ mt: 2 }}>
              <Typography
                variant={'additionally' as 'h1'}
                sx={{ color: '#75747B' }}
              >
                {!user
                  ? 'Необходимо войти в систему'
                  : isNotLoginOrEmptyRoles
                  ? 'Необходимо иметь хотя бы одну роль'
                  : 'Попробуйте проверить написание или изменить значения фильтров'}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <>
            {quizList
              .filter(
                (quiz) =>
                  quiz.status === status &&
                  (status === 'PUBLISHED'
                    ? new Date(quiz.endDate) >= new Date()
                    : true),
              )
              .map((quiz, index) => (
                <QuizItem key={index} quiz={quiz} />
              ))}
            {loading && <Loader />}
          </>
        )}
      </Grid>
      <ScrollListener
        onVisible={() => setActivePage(pagination.currentPage + 1)}
      />
    </Grid>
  );
};

export enum UI_MESSAGE {
  YOU_HAVE_UNCONFIRMED_ROLES = 'имеются неподтвержденные роли',
  TARGET_AUDIENCE_ALL = 'все пользователи ФГИС "Моя школа"',
  QUIZ_NOT_ALLOWED = 'Извините, опрос предназначен для другой целевой аудитории',
  FAQ_NOT_AVAILABLE = 'Не удалось загрузить информацию о вопросе',
  LINK_NOT_AVAILABLE = 'Не удалось загрузить информацию о ссылке',
  NEWS_NOT_AVAILABLE = 'Не удалось загрузить информацию о новости',
  QUIZ_NOT_AVAILABLE = 'Не удалось загрузить информацию об опросе',
  JOURNAL_NOT_AVAILABLE = 'Не удалось загрузить информацию о данных для получения журнала',
  DIARY_NOT_AVAILABLE = 'Не удалось загрузить информацию о данных для получения дневника',
  ALERT_NOT_AVAILABLE = 'Не удалось загрузить информацию об оповещении',
}

export enum ERROR_MESSAGE {
  FAILED_TO_LOAD_TARGET_AUDIENCE = 'Произошла ошибка при загрузке целевой аудитории',
}

import { Container, styled } from '@mui/material';

export const ResponsiveContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  [theme.breakpoints.up('lg')]: {},
  [theme.breakpoints.up('xl')]: {},
}));

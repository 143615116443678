import { deleteCookie, getCookie } from '@lib/cookie';
import {
  COOKIE_NAMES,
  MAIN_DOMAIN,
  REDIRECT_ON_REFRESH_TOKEN_ERROR,
} from '@constants/config';
import { createRefreshTokenPromise } from '@api/utils/interceptors';
import { ROUTES } from '@constants/routes';
import { RESPONSE_STATUS_CODE } from '@api/types/enum/responseStatusCode';

export enum FETCH_METHOD {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
}

enum FETCH_CREDENTIAL_TYPE {
  OMIT = 'omit',
  INCLUDE = 'include',
  SAME_ORIGIN = 'same-origin',
}

const BASE_URL = '/etd-backend';

const etdFetch = async <T>(data: {
  path: string;
  method: FETCH_METHOD;
  isProtected: boolean;
  headers?: { [key: string]: string };
  params?: { [key: string]: string | number | undefined };
  credential?: FETCH_CREDENTIAL_TYPE;
  body?: { [key: string]: any };
}) => {
  const { params, method, isProtected, path, headers, credential, body } = data;

  const fetchConfig: RequestInit = {};
  const requestHeaders = new Headers();
  const requestSearchParams = new URLSearchParams();

  requestHeaders.append('Accept', 'application/json, text/plain, */*');
  requestHeaders.append('Content-type', 'application/json');

  if (headers || isProtected) {
    fetchConfig.headers = requestHeaders;
  }

  if (isProtected) {
    const accessToken = getCookie(COOKIE_NAMES.ACCESS_TOKEN);

    if (fetchConfig.headers && accessToken) {
      requestHeaders.append('Authorization', `Bearer ${accessToken}`);
    }
  }

  if (headers && Object.keys(headers).length) {
    for (const key in headers) {
      requestHeaders.append(key, headers[key]);
    }
  }

  if (params && Object.keys(params).length) {
    for (const key in params) {
      if (params[key] !== undefined) {
        requestSearchParams.append(key, String(params[key])!);
      }
    }
  }

  if ((method === FETCH_METHOD.POST || method === FETCH_METHOD.PUT) && body) {
    fetchConfig.body = JSON.stringify(body);
  }

  fetchConfig.credentials = credential ?? FETCH_CREDENTIAL_TYPE.OMIT;
  fetchConfig.method = method;

  const requestPromise = createRequestPromise();

  function createRequestPromise() {
    return fetch(
      `${BASE_URL}/${path}${params ? `?${requestSearchParams}` : ''}`,
      fetchConfig,
    );
  }

  const response = await requestPromise;
  return checkError(
    response,
    createRequestPromise,
    requestHeaders,
  ) as Promise<T>;
};

export { etdFetch };

const checkError = (
  response: Response,
  createRequestPromise: () => Promise<Response>,
  requestHeaders: Headers,
) => {
  if (!response.ok) {
    if (response.status === RESPONSE_STATUS_CODE.UNAUTHORIZED) {
      const refreshToken = getCookie(COOKIE_NAMES.REFRESH_TOKEN);
      let refreshTokenPromise: Promise<string> | null = null;
      if (refreshToken) {
        refreshTokenPromise = createRefreshTokenPromise(refreshToken, response);
        refreshTokenPromise
          .then((token: string) => {
            requestHeaders.append('Authorization', `Bearer ${token}`);
            return createRequestPromise();
          })
          .then((response) => {
            if (!response.ok) {
              return Promise.reject(response);
            }
          })
          .catch((err) => {
            console.error(err);
            if (REDIRECT_ON_REFRESH_TOKEN_ERROR) {
              deleteCookie(COOKIE_NAMES.ACCESS_TOKEN, MAIN_DOMAIN);
              deleteCookie(COOKIE_NAMES.REFRESH_TOKEN, MAIN_DOMAIN);
              localStorage.setItem('refreshTokenStatus', 'expired');
              // @ts-ignore
              window.location = ROUTES.main;
            }
          });
      }
    } else {
      return Promise.reject(response);
    }
  }

  // response.text().then((text) => (text ? JSON.parse(text) : {})) - т.к. тело ответа для PUT запроса м.б. пустым
  return !response.ok
    ? Promise.reject(response)
    : response.text().then((text) => (text ? JSON.parse(text) : {}));
};

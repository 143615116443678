import { useCallback, useEffect, useRef } from 'react';

type Props = {
  onVisible: () => void;
  offset?: number;
};

export const ScrollListener = ({ onVisible, offset = 0 }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const handleScroll = useCallback(() => {
    if (ref.current) {
      const { offsetTop } = ref.current;
      const { pageYOffset, innerHeight } = window;
      const scrollTop = pageYOffset + innerHeight;
      if (offsetTop - offset <= scrollTop) {
        onVisible();
      }
    }
  }, [onVisible, offset, ref]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return <div ref={ref} />;
};

import { getSearchQueryParams } from '@api/utils';
import { Filter } from '@declarations/common';
import { mainAxios } from './utils/axios';
import { PagedData } from './types/notify';
import { Quiz, QuizResult, QuizStatus } from './types/quiz';

const SERVICE_NAME = 'api-poll/api/v1';

export const quizAPI = {
  create: (quiz: Partial<Quiz>) =>
    mainAxios.post<unknown, unknown>(`${SERVICE_NAME}/`, quiz, {
      params: { isProtected: true },
    }),
  update: (id: number | string, quiz: Partial<Quiz>) =>
    mainAxios.put<unknown, unknown>(`${SERVICE_NAME}/${id}`, quiz, {
      params: { isProtected: true },
    }),
  getList: () =>
    mainAxios.get<Quiz[], Quiz[]>(`${SERVICE_NAME}/list`, {
      params: { isProtected: true },
    }),
  getItem: (id: number | string, isManage = false) =>
    mainAxios.get<Quiz, Quiz>(`${SERVICE_NAME}/${id}`, {
      params: { isProtected: true, isManage },
    }),
  sendAnswers: (quiz: any) =>
    mainAxios.put<unknown, unknown>(`${SERVICE_NAME}/complete`, quiz, {
      params: { isProtected: true },
    }),
  changeStatus: (id: number, status: QuizStatus) =>
    mainAxios.put<unknown, unknown>(
      `${SERVICE_NAME}/status/${id}`,
      { status },
      {
        params: { isProtected: true },
      },
    ),
  deleteQuiz: (id: (number | string) | (number | string)[]) =>
    mainAxios.delete(
      `${SERVICE_NAME}/${Array.isArray(id) ? id.join(',') : id}`,
      {
        params: { isProtected: true },
      },
    ),
  getResult: (id: string) =>
    mainAxios.get<QuizResult, QuizResult>(`${SERVICE_NAME}/stat/${id}`, {
      params: { isProtected: true },
    }),
  search: (
    pagination: { currentPage: number; pageSize: number },
    filters: Partial<Filter>,
    isTargetedSearch?: boolean,
  ) =>
    mainAxios.get<PagedData<Quiz>, PagedData<Quiz>>(`${SERVICE_NAME}/search`, {
      params: {
        ...getSearchQueryParams(pagination, filters),
        isProtected: true,
        targeting: isTargetedSearch ? 'on' : 'off',
      },
    }),
};

import { LinkStatus, Link } from '@declarations/links';
import { FileDto, Filter } from '@declarations/common';
import { getSearchQueryParams } from '@api/utils';
import { LinkCategoryList } from '@constants/links';
import { mainAxios } from './utils/axios';
import { ResponseList } from './types/common';

const SERVICE_NAME = 'api-link/api/v1';

const linksApi = {
  getLinkList: () => mainAxios.get<Link[], Link[]>(`${SERVICE_NAME}/list`),
  getLinkCategories: () =>
    mainAxios.get<LinkCategoryList[], LinkCategoryList[]>(
      `${SERVICE_NAME}/list-categories`,
    ),
  searchLinks: (
    pagination: { currentPage: number; pageSize: number },
    filters: Partial<Filter>,
    isTargetedSearch?: boolean,
  ) =>
    mainAxios.get<ResponseList<Link>, ResponseList<Link>>(
      `${SERVICE_NAME}/search`,
      {
        params: {
          ...getSearchQueryParams(pagination, filters),
          isProtected: true,
          targeting: isTargetedSearch ? 'on' : 'off',
        },
      },
    ),
  createLink: (link: Link) =>
    mainAxios.post(`${SERVICE_NAME}/`, link, {
      params: { isProtected: true },
    }),
  updateLink: (linkData: Link, id: number | string) =>
    mainAxios.put<Link, Link>(`${SERVICE_NAME}/${id}`, linkData, {
      params: { isProtected: true },
    }),
  removeLink: (id: (number | string) | (number | string)[]) =>
    mainAxios.delete(
      `${SERVICE_NAME}/${Array.isArray(id) ? id.join(',') : id}`,
      {
        params: { isProtected: true },
      },
    ),
  getLinkById: (id: number | string, isManage = false) =>
    mainAxios.get<Link, Link>(`${SERVICE_NAME}/${id}`, {
      params: { isManage },
    }),

  changeLinkStatus: (id: string | number, status: LinkStatus) =>
    mainAxios.put(
      `${SERVICE_NAME}/status/${id}`,
      { status },
      {
        params: { isProtected: true },
      },
    ),
  addLinkLogo(files: File[]) {
    const formData = new FormData();
    files?.forEach((fileItem) => {
      formData.append('files', fileItem);
    });

    return mainAxios.post<FileDto[], FileDto[]>(
      `${SERVICE_NAME}/files`,
      formData,
      {
        params: { isProtected: true },
      },
    );
  },
};

export default linksApi;

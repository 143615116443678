import { DateRange, DateRangePicker } from '@mui/lab';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { ReactComponent as CaretDownIcon } from '@assets/icons/ico-caret-down-react-select.svg';

import './style.scss';

type Props = {
  value?: [Date | undefined, Date | undefined];
  placeholder?: string;
  onChange: (value: DateRange<Date | undefined>) => void;
};

export const RangePicker = ({
  value = [undefined, undefined],
  placeholder,
  onChange,
}: Props) => {
  const [inputValue, setInputValue] = useState(getInputValue(value));

  useEffect(() => {
    setInputValue(getInputValue(value));
  }, [value]);
  return (
    <DateRangePicker
      value={value}
      onChange={onChange}
      renderInput={(startProps) => (
        <div className="range-picker">
          <input
            {...startProps.inputProps}
            readOnly
            className="range-picker__input"
            placeholder={placeholder}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <CaretDownIcon className="range-picker__icon" />
        </div>
      )}
    />
  );
};

const getInputValue = (period: [Date | undefined, Date | undefined]) => {
  if (period[0] && period[1]) {
    return `${dayjs(period[0]).format('DD.MM.YYYY')} — ${dayjs(
      period[1],
    ).format('DD.MM.YYYY')}`;
  }
  return '';
};

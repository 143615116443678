import {
  AdminProfileStatus,
  UserProfileStatus,
} from '@declarations/enum/profile';

export const adminProfileStatusLocale = {
  [AdminProfileStatus.CONFIRMED]: 'Подтверждено',
  [AdminProfileStatus.CONFIRMED_PARTIALLY]: 'Частично подтверждено',
  [AdminProfileStatus.RETIRED]: 'Отклонено',
  [AdminProfileStatus.CREATED_BY_ADMIN]: 'Создано администратором',
} as const;

export const userProfileStatusLocale = {
  [UserProfileStatus.CONFIRMED]: 'Подтверждено',
  [UserProfileStatus.CONFIRMED_PARTIALLY]: 'Частично подтверждено',
  [UserProfileStatus.CREATED_BY_ADMIN]: 'Создано администратором',
  [UserProfileStatus.CREATED_BY_SELF]: 'На рассмотрении',
  [UserProfileStatus.RETIRED]: 'Отклонено',
};

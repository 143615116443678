import {
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import cloneDeep from 'lodash/cloneDeep';
import cn from 'classnames';
import { connect, useDispatch, useSelector } from 'react-redux';
import { MultiValue, SingleValue } from 'react-select';
import qs from 'query-string';

import { ReactComponent as FilterIcon } from '@assets/icons/filter-horizontal.svg';
import { ReactComponent as FilterCloseIcon } from '@assets/icons/clear-filter.svg';
import { StyledInput } from '@components/styledComponents/StyledInput';
import SingleSelect from '@components/common/Select/SingleSelect';
import { RequestStatuses } from '@redux/ui/types';
import { RootState } from '@redux/store';

import { getFetchStatus } from '@redux/ui';
import {
  addFilterValues,
  clearFilterValues,
  fetchFilters,
  getActiveFilters,
  getFilters,
  setTargetedSearch,
} from '@redux/filters';
import { Filters } from '@redux/filters/types';
import { Option } from '@components/common/Select/types';
import RegionSelect from '@components/common/Select/Region';
import OrganizationSelect from '@components/common/Select/Organization';
import { Filter } from '@declarations/common';
import { isEmpty } from '@lib/common';
import LinkCategorySelect from '@components/common/Select/LinkCategorySelect';

import MultiSelect from '../Select/MultiSelect';
import './CommonFilter.scss';
import { RangePicker } from '../RangePicker';
import { PUBLICATION_DATE } from '@pages/NewsListPage/enum';
import {
  FILTER_UPDATE_INITIATOR,
  filtersAffectingTargetedSearch,
} from '@components/common/CommonFilter/constants';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '@constants/routes';

export interface FilterProps {
  filters: Filters;
  fetchFilters: () => void;
  fetchingStatus?: RequestStatuses;
  showCategory: boolean;
  showDate: boolean;
  showSort?: boolean;
  showContentType?: boolean;
  showSystem?: boolean;
  countItems?: number;
  showPeriodDate?: boolean;
  extra?: ReactNode;
  disableRegion?: boolean;
  disableGrade?: boolean;
  disableOrganization?: boolean;
}

const mapStateToProps = (state: RootState) => ({
  filters: getFilters(state),
  activeFilterQuery: getActiveFilters(state),
  fetchingStatus: getFetchStatus(state, 'fetchFaqs'),
});

const mapDispatchToProps = {
  fetchFilters,
};

const CommonFilter = ({
  filters,
  fetchFilters,
  showCategory,
  showDate,
  showSort,
  showContentType,
  countItems,
  showPeriodDate,
  showSystem,
  extra,
  disableRegion,
  disableGrade,
  disableOrganization,
}: FilterProps) => {
  const location = useLocation();
  const inputRef = useRef(null);
  const size = !showCategory && !showDate ? 4 : 3;
  const orgSize = showSort ? 6 : size;
  const dispatch = useDispatch();
  const { filterValues, userFilter } = useSelector((state: RootState) => ({
    filterValues: state.filters.values,
    userFilter: state.filters.userFilters,
  }));

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  const [filterExpanded, setFilterExpanded] = useState<boolean>(false);

  const [queryString, setQueryString] = useState('');

  const organizationFilters = useMemo(
    () => ({
      regionCode: filterValues.region ? filterValues.region : [],
    }),
    [filterValues.region],
  );

  const filterCount = useMemo(() => {
    const clone = cloneDeep(filterValues);

    delete clone.search;

    const filtered = Object.values(clone)?.filter((item) =>
      Array.isArray(item)
        ? item.some((i) => i)
        : !isEmpty(item) && item !== undefined,
    );
    return filtered.length;
  }, [filterValues]);

  const checkConditionForUpdateTargetedSearchValue = useCallback(
    (filterUpdateInitiator: FILTER_UPDATE_INITIATOR) => {
      if (location.pathname === ROUTES.newsList) {
        filtersAffectingTargetedSearch.newsPage.some(
          (filter) => filter === filterUpdateInitiator,
        ) && dispatch(setTargetedSearch(false));
      } else if (location.pathname === ROUTES.faqList) {
        filtersAffectingTargetedSearch.faqPage.some(
          (filter) => filter === filterUpdateInitiator,
        ) && dispatch(setTargetedSearch(false));
      } else if (location.pathname === ROUTES.linkList) {
        filtersAffectingTargetedSearch.linksPage.some(
          (filter) => filter === filterUpdateInitiator,
        ) && dispatch(setTargetedSearch(false));
      }
    },
    [dispatch, location.pathname],
  );

  const updateFilterItem = useCallback(
    (
      filter: Partial<Filter>,
      filterUpdateInitiator: FILTER_UPDATE_INITIATOR,
    ) => {
      checkConditionForUpdateTargetedSearchValue(filterUpdateInitiator);
      dispatch(addFilterValues(filter));
    },
    [checkConditionForUpdateTargetedSearchValue, dispatch],
  );

  const clearFilter = useCallback(() => {
    checkConditionForUpdateTargetedSearchValue(
      FILTER_UPDATE_INITIATOR.RESET_ALL_FILTERS,
    );
    dispatch(clearFilterValues({ search: queryString }));
  }, [checkConditionForUpdateTargetedSearchValue, dispatch, queryString]);

  useLayoutEffect(() => {
    return () => {
      dispatch(clearFilterValues({ search: '' }));
      dispatch(setTargetedSearch(true));
    };
  }, [dispatch]);

  const applyQueryString = () =>
    updateFilterItem(
      {
        search: queryString,
      },
      FILTER_UPDATE_INITIATOR.SEARCH_LINE,
    );

  useEffect(() => {
    const queryStr = qs.parseUrl(window.location.href);
    const q = queryStr?.query?.q;
    if (q) {
      updateFilterItem(
        { search: (q as string) || '' },
        FILTER_UPDATE_INITIATOR.URL_PARAMETERS,
      );
      setQueryString((q as string) || '');
    }
  }, [updateFilterItem]);

  useLayoutEffect(() => {
    if (userFilter) {
      const clone = cloneDeep(userFilter);
      if (disableOrganization) {
        delete clone.oo;
      }
      if (disableRegion) {
        delete clone.region;
      }
      delete clone.educationLevel;
      clone.educationLevel = clone.levelOfEducation;

      dispatch(addFilterValues(clone));
    }
  }, [userFilter, dispatch, disableOrganization, disableGrade, disableRegion]);

  return (
    <Grid container spacing={2} id="common-filter">
      <Grid item xs>
        <Grid container spacing={2}>
          <Grid item sx={{ flex: 1 }}>
            <Paper elevation={0} sx={{ position: 'relative', width: '100%' }}>
              <StyledInput
                className="filter-block__search"
                size="medium"
                placeholder="Поиск по разделу"
                onChange={(e) => setQueryString(e.target.value)}
                value={queryString}
                fullWidth
                inputRef={inputRef}
                sx={{
                  pr: '4px',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <>
                        <Button
                          className={cn({
                            'filter-block__show-btn': true,
                            'filter-toggle-btn': true,
                            'filter-toggle-btn--active': filterExpanded,
                          })}
                          startIcon={<FilterIcon />}
                          onClick={() => setFilterExpanded((prev) => !prev)}
                        >
                          <span className="filter-block__show-btn-text">
                            фильтры
                          </span>
                        </Button>
                        <ClearIcon
                          sx={{
                            mr: '6px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setQueryString('');
                            // @ts-ignore
                            if (inputRef?.current?.focus) {
                              // @ts-ignore
                              inputRef?.current?.focus();
                            }
                          }}
                        />
                        <Button
                          variant="first"
                          size="small"
                          onClick={applyQueryString}
                        >
                          Найти
                        </Button>
                      </>
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
          </Grid>
          {extra && (
            <Grid
              item
              sx={{
                flex: 'none',
                zIndex: 100,
              }}
            >
              {extra}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        container
        // sx={{ display: filterVisible }}
        columnSpacing={{
          lg: 1,
        }}
        rowSpacing={{
          xs: 2,
        }}
      >
        {filterExpanded && (
          <>
            {showSort && (
              <Grid item xs={12} lg={3}>
                <SingleSelect
                  options={filters.sort}
                  name="selectSort"
                  placeholder="Сортировка"
                  id="selectSort"
                  onChange={(selectedOption) =>
                    updateFilterItem(
                      {
                        sort: selectedOption?.value,
                      },
                      FILTER_UPDATE_INITIATOR.SORT_FIELD,
                    )
                  }
                  value={filters.sort?.find((option) => {
                    return option.value === filterValues.sort;
                  })}
                />
              </Grid>
            )}
            {showCategory && (
              <Grid item xs={12} lg={size}>
                <LinkCategorySelect
                  name="linkCategory"
                  placeholder="Категория"
                  id="linkCategory"
                  onChange={(selectedOption: SingleValue<Option>) =>
                    updateFilterItem(
                      { category: selectedOption?.value },
                      FILTER_UPDATE_INITIATOR.CATEGORY_FIELD,
                    )
                  }
                  value={filterValues?.category}
                />
              </Grid>
            )}
            {showDate && !showPeriodDate && (
              <Grid item xs={12} lg={size}>
                <SingleSelect
                  options={filters.time}
                  name="time"
                  placeholder="Дата публикации"
                  id="time"
                  hasResetButton={true}
                  onChange={(selectedOption) => {
                    updateFilterItem(
                      {
                        time:
                          (selectedOption?.value as PUBLICATION_DATE) ??
                          undefined,
                      },
                      FILTER_UPDATE_INITIATOR.PUBLICATION_DATE_FIELD,
                    );
                  }}
                  value={filters.time?.find((option: Option) => {
                    return option.value === filterValues.time;
                  })}
                />
              </Grid>
            )}
            {showPeriodDate && (
              <Grid item xs={12} lg={size}>
                <Box
                  sx={{
                    mb: 2,
                  }}
                >
                  <RangePicker
                    placeholder="Дата публикации"
                    value={filterValues?.period}
                    onChange={(period) =>
                      updateFilterItem(
                        {
                          period: [
                            period[0] || undefined,
                            period[1] || undefined,
                          ],
                        },
                        FILTER_UPDATE_INITIATOR.PUBLICATION_DATE_RANGE_FIELD,
                      )
                    }
                  />
                </Box>
              </Grid>
            )}
            {showContentType && (
              <Grid item xs={12} lg={size}>
                <MultiSelect
                  options={filters.sortCategory || []}
                  name="contentTypeSelect"
                  placeholder="Вид контента"
                  id="contentTypeSelect"
                  onChange={(selectedOptions) => {
                    updateFilterItem(
                      {
                        sortCategory: selectedOptions.map(
                          (option) => option.value,
                        ),
                      },
                      FILTER_UPDATE_INITIATOR.CONTENT_TYPE_FIELD,
                    );
                  }}
                  value={filters.sortCategory?.filter((option) => {
                    return filterValues.sortCategory?.includes(option.value);
                  })}
                />
              </Grid>
            )}
            {/* Нужно будет для страницы поиска */}
            {showSystem && (
              <Grid item xs={12} lg={size}>
                <SingleSelect
                  options={filters.system || []}
                  name="contentTypeSelect"
                  placeholder="Система"
                  id="contentTypeSelect"
                  onChange={(selectedOption) => {
                    updateFilterItem(
                      {
                        system: selectedOption?.value,
                      },
                      FILTER_UPDATE_INITIATOR.SYSTEM_FIELD,
                    );
                  }}
                  value={filters.system?.find(
                    (option) => option.value === filterValues.system,
                  )}
                />
              </Grid>
            )}
            {!disableGrade && (
              <Grid item xs={12} lg={size}>
                <MultiSelect
                  options={filters.grade || []}
                  name="selectEdu"
                  placeholder="Уровни образования"
                  id="selectEdu"
                  onChange={(selectedOptions) =>
                    updateFilterItem(
                      {
                        educationLevel: selectedOptions.map(
                          (option) => option.value,
                        ),
                      },
                      FILTER_UPDATE_INITIATOR.LEVELS_OF_EDUCATION_FIELD,
                    )
                  }
                  value={filters.grade?.filter((option) => {
                    return filterValues.educationLevel?.includes(option.value);
                  })}
                />
              </Grid>
            )}
            {!disableRegion && (
              <Grid item xs={12} lg={size}>
                <RegionSelect
                  name="selectReg"
                  id="selectReg"
                  placeholder="Регион РФ"
                  isMulti
                  onChange={(selectedOptions: MultiValue<Option>) =>
                    updateFilterItem(
                      {
                        region: selectedOptions.map((option) => +option.value),
                      },
                      FILTER_UPDATE_INITIATOR.REGION_FIELD,
                    )
                  }
                  value={filterValues.region}
                />
              </Grid>
            )}
            {!disableOrganization && (
              <Grid item xs={12} lg={orgSize}>
                <OrganizationSelect
                  name="organization"
                  placeholder="Образовательная организация"
                  id="organization"
                  filters={organizationFilters}
                  isMulti
                  value={filterValues.oo}
                  onChange={(organizationIds) => {
                    updateFilterItem(
                      {
                        oo: Array.isArray(organizationIds)
                          ? organizationIds
                          : [organizationIds],
                      },
                      FILTER_UPDATE_INITIATOR.EDU_ORGANIZATION_FIELD,
                    );
                  }}
                />
              </Grid>
            )}
          </>
        )}
        {(!!countItems || (filterExpanded && filterCount > 0)) && (
          <Grid item xs={12}>
            <Grid
              container
              columnSpacing={2}
              alignItems="center"
              justifyContent={{
                xs: 'space-between',
                lg: 'flex-start',
              }}
            >
              {!!countItems && (
                <Grid item>
                  <Typography variant="caption" sx={{ color: '#626B7A' }}>
                    Найдено: {countItems}
                  </Typography>
                </Grid>
              )}
              {filterExpanded && filterCount > 0 && (
                <Grid item>
                  <button
                    type="reset"
                    className="filter__count-button"
                    onClick={clearFilter}
                  >
                    <span className="filter__count reset">{filterCount}</span>
                    Сбросить
                    <FilterCloseIcon style={{ marginLeft: '4px' }} />
                  </button>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonFilter);

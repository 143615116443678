import { StructureRole } from '@declarations/enum/structureRole';

export const structureRoleLocale = {
  [StructureRole.ETD_ADMINISTRATOR]: 'Администратор ЕТД',
  [StructureRole.OO_GROUP_ADMINISTRATOR]: 'Администратор группы ОО',
  [StructureRole.OO_ADMINISTRATOR]: 'Администратор ОО',
  [StructureRole.EXTERNAL_IS_ADMINISTRATOR]: 'Администратор внешней ИС',
  [StructureRole.TEACHER]: 'Педагог',
  [StructureRole.CLASS_TEACHER]: 'Классный руководитель',
  [StructureRole.OO_STUDENT]: 'Обучающийся в ОО',
  [StructureRole.FAMILY_STUDENT]: 'Обучающийся в семье',
  [StructureRole.SPO_STUDENT]: 'Обучающийся в СПО',
  [StructureRole.PARENT]: 'Родитель',
  [StructureRole.GUARDIAN]: 'Опекун',
  [StructureRole.TRUSTEE]: 'Попечитель',
} as const;

import { ThunkAction } from 'redux-thunk';
import { RootState } from '../store';
import { AnyAction } from 'redux';
import {
  getUser,
  getUserRole,
  setAuthAccount,
  setContentAccesses,
  setFunctionalRoles,
  setHistory,
  setIsLoading,
  setPupilInfo,
  setTargetInfo,
  setUserLocation,
  setUserRoleList,
} from './index';
import { isFetching, setFetched, setFetching, setFetchingError } from '../ui';
import { userApi } from '@api/user';
import {
  AccountEntry,
  AuthAccountReponse,
  EUserRole,
  UserRole,
  UserRoleStatus,
} from './types';
import { EduProviderStatusReason } from '@declarations/enum/retireRoleStatusReason';
import { getCookie } from '@lib/cookie';
import { COOKIE_NAMES } from '@constants/config';
import { setUserFilter } from '@redux/filters';
import getWhoIsAuth from '@lib/userInfo/getWhoIsAuth';
import getWhoIsAuthRole from '@lib/userInfo/getWhoIsAuthRole';
import { getUserFunctionalRoles } from '@lib/userInfo/getUserFunctionalRoles';
import getUserAllRoleList from '@lib/userInfo/getUserAllRoleList';
import getUserMobile from '@lib/userInfo/getUserMobile';
import getBirthday from '@lib/userInfo/getBirthday';
import { getUserLocations } from '@lib/userInfo/getUserLocations';
import { getPupilInfo } from '@lib/userInfo/getPupilInfo';
import { clearRetiredRoles } from '@lib/userInfo/clearRetiredRoles';
import { getUserTargetInfo } from '@lib/userInfo/getUserTargetInfo';
import { getAccesses } from '@lib/userInfo/getAccesses';
import { getUserDefaultFilter } from '@lib/userInfo/getUserDefaultFilter';
import { EntityTypes } from '@mock-data/entities';
import { parseReference } from '@lib/common';
import {
  getUserHistory,
  mapToProfileCategory,
} from '@lib/userInfo/getUserHistory';
import { AuthAccount, PupilProfile } from '@declarations/profile';
import { NextOfKinRole } from '@declarations/role';
import { PupilUtils } from '@utils/pupilUtils';
import { Person } from '@declarations/person';
import { Entity } from '@declarations/common';
import { getUserRegion } from '@lib/userInfo/getUserRegion';
import { PupilInfo } from '@declarations/pupil';

export type VoidThunk = ThunkAction<void, RootState, undefined, AnyAction>;

export const fetchUserRole = (): VoidThunk => async (dispatch, getState) => {
  const state = getState();
  const requestName = 'fetchUserRole';
  const isFetchingUserRole = isFetching(state, requestName);
  const id = getUser(state)?.etd_id;
  const token = getCookie(COOKIE_NAMES.ACCESS_TOKEN);
  const userRole = getUserRole(state);

  if (!token || isFetchingUserRole) {
    return;
  }
  dispatch(setFetching(requestName));

  try {
    const {
      pupilInfo = null,
      authAccount,
      userHistory,
      userLocations = null,
      targetInfo = null,
      userFilter,
      functionalRoles,
      accesses = null,
      ...userRoles
    } = await getUserRolesRequest(id as number, userRole);
    dispatch(setUserRoleList(userRoles));
    dispatch(setPupilInfo(pupilInfo));
    dispatch(setTargetInfo(targetInfo));
    dispatch(setAuthAccount(authAccount?.resource));
    dispatch(setHistory(userHistory || []));
    dispatch(setFetched(requestName));
    dispatch(setUserLocation(userLocations));
    dispatch(setUserFilter(userFilter || {}));
    dispatch(setFunctionalRoles(functionalRoles || []));
    dispatch(setContentAccesses(accesses));
  } catch (e: any) {
    console.error(e.message || e);
    dispatch(setFetchingError(requestName));
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const updateEduProviderRoleStatus =
  (
    status: UserRoleStatus,
    eduProviderRoleId: string,
    reason?: EduProviderStatusReason,
  ): VoidThunk =>
  async (dispatch) => {
    try {
      const role = await userApi.getEduProviderRole(eduProviderRoleId);
      if (role) {
        role.status = status;
        if (reason) {
          role.reason = reason;
        }
        await userApi.updateEduProviderRoleStatus(role);
        dispatch(fetchUserRole());
      }
    } catch (err) {
      console.error(err);
    }
  };

const getUserRolesRequest = async (id: number, userRole: UserRole) => {
  const userAccountInfoResponse = await userApi.getUserInfo({ id });
  if (userAccountInfoResponse) {
    const authAccount = getWhoIsAuth(userAccountInfoResponse);
    const whoIs = authAccount?.resource.lastLoginRole || '';
    const whoIsRole = getWhoIsAuthRole(whoIs);
    const functionalRoles = getUserFunctionalRoles(
      userAccountInfoResponse.entry,
    );
    const profileRoles = await getUserAllRoleList(
      userAccountInfoResponse,
      whoIs,
      whoIsRole,
    );
    const userMobile = getUserMobile(userAccountInfoResponse);
    const userBirthday = getBirthday(userAccountInfoResponse);
    const userLocations = getUserLocations(userAccountInfoResponse);
    let pupilInfo: PupilInfo | null = getPupilInfo(
      userAccountInfoResponse?.entry || [],
      authAccount?.resource,
    );
    const modifiedReq = clearRetiredRoles(userAccountInfoResponse);
    const targetInfo = getUserTargetInfo(modifiedReq?.entry || []);
    const accesses = getAccesses(userAccountInfoResponse.entry);

    let userHistory = getUserHistory(
      pupilInfo?.person ? Object.values(pupilInfo?.person) : [],
      userAccountInfoResponse?.entry || [],
      mapToProfileCategory(whoIs),
    ).sort((a, b) => {
      if (a.date && b.date) {
        return new Date(b.date) > new Date(a.date) ? 1 : -1;
      }
      return 0;
    });

    const userFilter = await getUserDefaultFilter(
      userAccountInfoResponse.entry,
      profileRoles?.roleListAll ?? [],
      userRole,
    );

    if (userRole === EUserRole.PARENT) {
      const { updatedPupilInfo, pupilRolesHistory } = await clarifyPupilsInfo({
        pupilInfo,
        userAccountInfoResponse,
        authAccount,
        whoIs,
      });

      if (updatedPupilInfo) {
        pupilInfo = updatedPupilInfo;
      }
      if (pupilRolesHistory) {
        userHistory = pupilRolesHistory;
      }
    }

    return {
      profileRoles,
      pupilInfo,
      userMobile,
      userBirthday,
      authAccount,
      userHistory,
      userLocations,
      targetInfo,
      userFilter,
      functionalRoles,
      accesses,
    };
  }
  return {};
};

const clarifyPupilsInfo = async (data: {
  pupilInfo: PupilInfo | null;
  userAccountInfoResponse: AuthAccountReponse;
  authAccount: Entity<AuthAccount> | undefined;
  whoIs: string;
}) => {
  const { pupilInfo, userAccountInfoResponse, authAccount, whoIs } = data;

  if (!pupilInfo) return {};

  // Получаем данные по детям (ученикам), по которым законному представителю пришли PupilProfile
  let pupilAccountsInfoEntries = await getPupilData(pupilInfo.pupilProfile);
  let pupilsEntities = PupilUtils.parsePupilAuthAccountResponses(
    pupilAccountsInfoEntries,
  );

  const foundPupilPersonsIds = pupilsEntities.reduce((acc, pupilEntity) => {
    return pupilEntity.resource.resourceType === EntityTypes.PERSON &&
      !!pupilEntity.resource.id
      ? [...acc, +pupilEntity.resource.id]
      : acc;
  }, [] as number[]);
  const ownPupilPersonsIds = pupilInfo.parentPerson?.related.map(
    (related) => +parseReference(related.related.reference).id,
  );
  const missedOwnPupilPersonsIds =
    ownPupilPersonsIds?.filter(
      (personId) =>
        !foundPupilPersonsIds.some(
          (foundedPersonId) => foundedPersonId === personId,
        ),
    ) ?? [];

  // Если профили своих детей не пришли, то делаем запрос за их данными по Person ID (principal),
  // который взяли из поля related у Person ЗП
  const pupilAccountsInfoEntriesByPrincipalID = await getPupilDataByPrincipalId(
    missedOwnPupilPersonsIds,
  );
  pupilAccountsInfoEntries = pupilAccountsInfoEntries.concat(
    pupilAccountsInfoEntriesByPrincipalID,
  );
  pupilsEntities = pupilsEntities.concat(
    PupilUtils.parsePupilAuthAccountResponses(
      pupilAccountsInfoEntriesByPrincipalID,
    ),
  );

  let updatedPupilInfo: PupilInfo | null = JSON.parse(
    JSON.stringify(pupilInfo),
  );
  if (pupilsEntities.length) {
    // Производим слияние entity полученных для ЗП и по детям (ученикам), удаляя дубликаты (если они есть)
    const parentAndPupilEntities = userAccountInfoResponse?.entry.reduce(
      (entities: AccountEntry[], entity) => {
        return entities.find(
          (savedEntity) => savedEntity.fullUrl === entity.fullUrl,
        )
          ? entities
          : [...entities, entity];
      },
      pupilsEntities,
    );

    // Обновляем pupilInfo на основании новых entities детей (учеников)
    updatedPupilInfo = getPupilInfo(
      parentAndPupilEntities,
      authAccount?.resource,
    );
  }
  if (!updatedPupilInfo) return {};

  const { person, pupilJournalData } = getPupilPerson(pupilAccountsInfoEntries);

  // Если профили неСвоих детей не пришли (причина: если Profile ученика прекращен),
  // однако у родителя есть роль в отношении таких детей
  const extraPupil = await getPupilPersonForMissingPupilProfiles(
    updatedPupilInfo,
    person,
  );

  updatedPupilInfo.person = extraPupil
    ? { ...person, ...extraPupil.pupilPersons }
    : person;
  updatedPupilInfo.pupilJournalData = pupilJournalData;

  if (extraPupil?.pupilAuthAccounts.length) {
    updatedPupilInfo.pupilAuthAccounts = [
      ...updatedPupilInfo.pupilAuthAccounts,
      ...extraPupil.pupilAuthAccounts,
    ];
  }

  const pupilRolesHistory = getUserHistory(
    updatedPupilInfo?.person ? Object.values(updatedPupilInfo?.person) : [],
    pupilsEntities,
    mapToProfileCategory(whoIs),
  ).sort((a, b) => {
    if (a.date && b.date) {
      return new Date(b.date) > new Date(a.date) ? 1 : -1;
    }
    return 0;
  });

  return {
    updatedPupilInfo,
    pupilRolesHistory,
  };
};

const getPupilData = async (hashTable: {
  [reference: string]: PupilProfile;
}): Promise<PromiseSettledResult<AuthAccountReponse | undefined>[]> => {
  const responseList = await Promise.allSettled(
    Object.keys(hashTable)?.reduce((request, reference) => {
      const personReference = hashTable[reference].authAccount?.reference;
      if (personReference) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [resourceType, id] = personReference.split('/');
        request.push(userApi.getUserInfo({ id: +id }));
      }
      return request;
    }, [] as (Promise<AuthAccountReponse> | undefined)[]),
  );
  return responseList.filter(
    (response) => response?.status === 'fulfilled' && response.value?.entry,
  );
};

/* Используем только для поиска учеников (детей), не состоящих в родственных связях.
 Если ребенок состоит в родственных связях в аналогичной ситуации используется getPupilDataByPrincipalId
 Однако если не была найдена Profile ID своего ребенка по getPupilDataByPrincipalId,
 например не создана УЗ, тогда также делается запрос и за своим ребенком
 (т.к. нет данных, чтобы отфильтровать этот профиль, и не делать по нему запрос)
 */
const getPupilPersonForMissingPupilProfiles = async (
  pupilInfo: {
    nextOfKinRole: { [p: string]: NextOfKinRole };
    pupilProfile: { [p: string]: PupilProfile };
  },
  personInfo: { [p: string]: Person },
) => {
  if (!pupilInfo) return null;
  const missingPupilProfiles = Object.values(pupilInfo.nextOfKinRole)
    .filter(
      (nextOfKinRole) =>
        !Object.values(personInfo).some((person) => {
          return nextOfKinRole.pupilProfile?.reference &&
            person.pupilProfile?.reference
            ? parseReference(nextOfKinRole.pupilProfile?.reference)?.id ===
                parseReference(person.pupilProfile?.reference)?.id
            : false;
        }),
    )
    .map(
      (nextOfKinRole) =>
        parseReference(nextOfKinRole.pupilProfile!.reference)?.id,
    );

  if (missingPupilProfiles.length) {
    const pupilProfilesData = await Promise.allSettled(
      missingPupilProfiles.map((id) => userApi.getPupilProfile(+id)),
    );

    const accountIDs = pupilProfilesData.reduce((acc, profile) => {
      if (
        profile.status === 'fulfilled' &&
        profile.value.authAccount?.reference
      ) {
        const id = parseReference(profile.value.authAccount?.reference)?.id;
        return id ? [...acc, +id] : acc;
      } else {
        return acc;
      }
    }, [] as number[]);

    const pupilsAccountsInfo = await Promise.allSettled(
      accountIDs.map((id) => userApi.getUserInfo({ id })),
    );

    const accountsEntries = pupilsAccountsInfo.reduce(
      (entries: AccountEntry[], res) => {
        return res.status === 'fulfilled' && res.value?.entry
          ? entries.reduce(
              (acc, entryItem) =>
                acc.find((item) => item.fullUrl === entryItem.fullUrl)
                  ? acc
                  : [...acc, entryItem],
              res.value?.entry,
            )
          : entries;
      },
      [],
    );

    const pupilAuthAccounts = accountsEntries
      ?.filter(
        (item) => item.resource.resourceType === EntityTypes.AUTH_ACCOUNT,
      )
      .map((acc) => acc.resource as AuthAccount);

    const pupilPersons = pupilsAccountsInfo.reduce(
      (acc, accInfo) => {
        if (accInfo.status === 'fulfilled' && accInfo.value) {
          const { personObject } = PupilUtils.assemblePersonObject(
            accInfo.value,
          );
          return personObject ? { ...acc, ...personObject } : acc;
        } else {
          return acc;
        }
      },
      {} as {
        [reference: string]: Person;
      },
    );

    return {
      pupilAuthAccounts,
      pupilPersons,
    };
  }
};

/* Для случая, когда не приходят данные PupilProfile.
   Используется в случае: если у ЗП отсутствуют роли в отношении Обучающегося
   (у обучающегося прекращен профиль).
   Однако необходимо получить Person и Account данные
*/
const getPupilDataByPrincipalId = async (
  personIds: number[],
): Promise<PromiseSettledResult<AuthAccountReponse | undefined>[]> => {
  const responseList = await Promise.allSettled(
    personIds?.reduce((request, personId) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      request.push(userApi.getPupilByPrincipal(+personId));
      return request;
    }, [] as (Promise<AuthAccountReponse> | undefined)[]),
  );
  return responseList.filter(
    (response) => response?.status === 'fulfilled' && response.value?.entry,
  );
};

const getPupilPerson = (
  pupilsAccountsInfo: PromiseSettledResult<AuthAccountReponse | undefined>[],
) => {
  let personsObject: {
    [reference: string]: Person;
  } = {};
  const pupilJournalObject: {
    [accountId: string]: {
      id: string | undefined;
      code: string | undefined;
    };
  } = {};

  pupilsAccountsInfo?.forEach((response) => {
    if (response.status === 'fulfilled' && response.value) {
      const { personKey, personObject } = PupilUtils.assemblePersonObject(
        response.value,
      );
      personsObject = { ...personsObject, ...personObject };
      if (personKey) {
        const account = response.value?.entry?.find(
          (item: any) =>
            item.resource.resourceType === EntityTypes.AUTH_ACCOUNT,
        ) as Entity<AuthAccount> | undefined;
        const accountId = account?.resource.id;
        if (account && accountId) {
          const regionCode = getUserRegion(getUserLocations(response.value));
          pupilJournalObject[personKey] = {
            id: account.resource.esiaIdentifier,
            code: regionCode,
          };
        }
      }
    }
  });

  return {
    person: personsObject,
    pupilJournalData: pupilJournalObject,
  };
};

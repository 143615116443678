import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import './ScheduleWidget.scss';
import Title from '@components/common/Title/Title';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid, Link, TextField } from '@mui/material';
import Button from '@components/common/Button/Button';
import { ReactComponent as ArrowBack } from '@assets/icons/ico-arrow.svg';
import { ReactComponent as CaretRightIcon } from '@assets/icons/caret-icon-right.svg';
import { DatePicker } from '@components/common/DatePicker/DatePicker';
import { PickersDayProps } from '@mui/lab/PickersDay/PickersDay';
import { getRenderWeekPickerDay } from '@lib/date/date-picker-utils';
import {
  getDateRage,
  getPeriodString,
  getWeekStartAndEnd,
} from '@lib/date/date';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { setPeriod } from '@redux/schedule';
import { RootState } from '@redux/store';
import { getUserRegionSelector } from '@redux/user';

const EMPTY_DAY = { lesson: '', group: '', classroom: '' };

type TabContentProps = {
  date: Date;
};
const TabContent = ({ date }: TabContentProps) => {
  const schedule = useSelector((state: RootState) => state.schedule.schedule);

  const weekDays = useMemo(() => {
    const [weekStart, weekEnd] = getWeekStartAndEnd(date);
    return getDateRage(weekStart, dayjs(weekEnd).subtract(1, 'day').toDate());
  }, [date]);

  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: 'none', marginBottom: '32px' }}
    >
      <Table sx={{ minWidth: 650 }}>
        <TableBody>
          <TableRow>
            <TableCell
              scope="row"
              component="th"
              valign="top"
              sx={{
                minWidth: 16,
                maxWidth: 128,
                verticalAlign: 'top',
                border: '1px solid #F7F7F7',
                borderBottom: '1px solid #DCE2EB',
                padding: '12px 32px 12px 8px',
                background: '#F7F7F7',
                borderRadius: '8px 0 0 0',
              }}
            />
            {weekDays.map((day) => (
              <TableCell
                key={day.getTime()}
                scope="row"
                component="th"
                valign="top"
                sx={{
                  width: '15%',
                  verticalAlign: 'top',
                  border: '1px solid #F7F7F7',
                  borderBottom: '1px solid #DCE2EB',
                  padding: '12px 32px 12px 8px',
                  background: '#F7F7F7',
                  color: '#626B7A',
                }}
              >
                {dayjs(day).format('dd, D MMM')}
              </TableCell>
            ))}
          </TableRow>
          {schedule &&
            Object.keys(schedule)
              .sort()
              .map((time) => (
                <TableRow key={time}>
                  <TableCell
                    scope="row"
                    valign="top"
                    sx={{
                      minWidth: 16,
                      maxWidth: 128,
                      verticalAlign: 'top',
                      border: '1px solid #DCE2EB',
                      padding: '8px 32px 50px 8px',
                    }}
                  >
                    <div className="schedule-item__time">{time}</div>
                  </TableCell>
                  {weekDays.map((day) => {
                    const dayData =
                      schedule[time][dayjs(day).format('YYYY-MM-DD')] ||
                      EMPTY_DAY;
                    return (
                      <TableCell
                        key={dayjs(day).format('YYYY-MM-DD')}
                        scope="row"
                        sx={{
                          verticalAlign: 'top',
                          border: '1px solid #DCE2EB',
                          padding: '8px 8px 50px',
                          width: '15%',
                        }}
                      >
                        {dayData.replace && (
                          <div className="schedule-item__replace">
                            <div className="schedule-item__replace-item">
                              {dayData.replace}...
                            </div>
                            <div className="schedule-item__replace-btn">
                              Замена
                            </div>
                          </div>
                        )}
                        <div className="schedule-item__lesson">
                          {dayData.lesson}
                        </div>
                        <div className="schedule-item__group">
                          {dayData.group}
                        </div>
                        <div className="schedule-item__cabinet">
                          {dayData.classroom}
                        </div>
                        <div className="schedule-item__comment">
                          {dayData.comment}
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const ScheduleWidget: React.FC = () => {
  const dispatch = useDispatch();
  const regionCode = useSelector(getUserRegionSelector);
  const journalLinks = useSelector(
    (state: RootState) => state.filters.options.journalLinks,
  );
  const journalUrl = React.useMemo(() => {
    return journalLinks.find((j) => j.value === regionCode)?.label;
  }, [regionCode, journalLinks]);
  const scheduleLink = journalUrl ? journalUrl : '#';
  const initialDate = useSelector(
    (state: RootState) => state.schedule.startDate,
  );
  const [date, setDate] = useState<Date>(new Date(initialDate));

  const renderWeekPickerDay = useMemo(
    () => getRenderWeekPickerDay(date),
    [date],
  );

  useEffect(() => {
    const [weekStart, weekEnd] = getWeekStartAndEnd(date);
    dispatch(
      setPeriod({
        startDate: weekStart.getTime(),
        endDate: dayjs(weekEnd).subtract(1, 'day').toDate().getTime(),
      }),
    );
  }, [date, dispatch]);

  return (
    <div className="schedule-widget">
      <Grid container>
        <Grid item xs>
          <Title
            className="schedule-widget__title"
            sx={{ fontSize: '24px', marginBottom: '32px' }}
          >
            <Link
              href={scheduleLink}
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                color: 'inherit !important',
              }}
            >
              Расписание
              <CaretRightIcon style={{ marginLeft: 16 }} />
            </Link>
          </Title>
        </Grid>
        <Grid
          display="flex"
          item
          xs="auto"
          sx={{ marginBottom: { xs: '24px', md: '0' } }}
        >
          <Button
            variant="fourth"
            sx={{
              marginLeft: { xs: '0', md: '8px' },
              marginRight: { xs: '8px', md: '0' },
              padding: '10px',
              height: '44px',
              minWidth: '44px',
            }}
            onClick={() =>
              setDate((prev) => dayjs(prev).subtract(1, 'week').toDate())
            }
          >
            <ArrowBack />
          </Button>
          <Button
            variant="fourth"
            sx={{
              marginLeft: { xs: '0', md: '8px' },
              marginRight: { xs: '8px', md: '0' },
              padding: '10px',
              height: '44px',
              minWidth: '44px',
            }}
            onClick={() =>
              setDate((prev) => dayjs(prev).add(1, 'week').toDate())
            }
          >
            <ArrowBack style={{ transform: 'rotate(180deg)' }} />
          </Button>
          <DatePicker
            onChange={(date) => date && setDate(date)}
            value={date}
            renderDay={(day, selectedDays, props) =>
              renderWeekPickerDay(
                day as Date,
                selectedDays as Array<Date | null>,
                props as PickersDayProps<Date>,
              )
            }
            renderInput={(params) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { inputProps, ...rest } = params;
              const [weekStart, weekEnd] = date ? getWeekStartAndEnd(date) : [];
              return (
                <TextField
                  {...rest}
                  size="small"
                  value={
                    weekStart &&
                    weekEnd &&
                    getPeriodString(weekStart, weekEnd, { showYear: true })
                  }
                  sx={{
                    marginLeft: { xs: '0', md: '8px' },
                    marginRight: { xs: '8px', md: '0' },
                    height: '44px',
                    minWidth: '44px',
                    fontWeight: '400',
                    ['& input']: {
                      height: '27px',
                    },
                  }}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <TabContent date={date} />
    </div>
  );
};

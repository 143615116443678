import { ApiResponse, Entity } from '@declarations/common';
import { Group } from '@declarations/group';
import qs from 'query-string';
import { mainAxios } from './utils/axios';
import uniq from 'lodash/uniq';
import { AUDIENCE_ID } from '@declarations/enum/audience';

const SERVICE_NAME = 'api-news/api/v1';

export const groupApi = {
  getTargetUserCount: ({
    audience,
    educationLevel,
    region,
    oo,
    ooGroup,
    gender,
    parallel,
  }: Partial<{
    audience: string[];
    educationLevel: string[];
    region: string[];
    oo: string[];
    ooGroup: string[];
    parallel: string[];
    gender: string[];
  }>) => {
    const assembleRequestParams = (): { [key: string]: string | boolean } => {
      const params: { [key: string]: string | boolean } = {};

      if (audience?.length) {
        params['profile'] = getList(
          1,
          uniq(
            audience
              .map((audience) => hashTableForAudience[audience] ?? '')
              .filter((code) => !!code.length),
          ),
        );
      }

      if (educationLevel?.length) {
        params['eduLevel'] = getList(
          2,
          educationLevel
            .map((eduLevel) => hashTableForEduLevel[eduLevel] ?? '')
            .filter((code) => !!code.length),
        );
      }

      if (region?.length) {
        params['region'] = getList(3, region);
      }

      if (oo?.length) {
        params['organization'] = getList(4, oo);
      }

      if (ooGroup?.length) {
        params['eduOrgGroup'] = getList(5, ooGroup);
      }

      if (parallel?.length && audience?.includes(String(AUDIENCE_ID.STUDENT))) {
        params['parallel'] = getList(7, parallel);
      }

      if (gender?.length && audience?.includes(String(AUDIENCE_ID.STUDENT))) {
        params['sex'] = getList(6, gender);
      }

      return params;
    };

    const requestParams = assembleRequestParams();

    if (Object.keys(requestParams).length == 0) {
      return null;
    }

    requestParams['isProtected'] = true;

    return mainAxios.get<
      ApiResponse<Entity<Group>>,
      ApiResponse<Entity<Group>>
    >(`${SERVICE_NAME}/number-of-users`, {
      params: requestParams,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'none' }),
    });
  },
};

const getList = (codeId: number, list: string[]) =>
  list.map((item) => `${codeId}$${item}`).join(',');

const hashTableForAudience: { [key: string]: string } = {
  1: '3',
  2: '4',
  3: '2',
  4: '1',
  5: '1',
};

const hashTableForEduLevel: { [key: string]: string } = {
  1: '2',
  2: '3',
  3: '4',
};

import { Typography } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export const ErrorMessage = ({ children }: Props) => {
  return (
    <Typography color="#eb5757" fontSize="14px" mt={1}>
      {children}
    </Typography>
  );
};

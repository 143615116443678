import * as React from 'react';
import { Grid } from '@mui/material';
import { StyledInput } from '@components/styledComponents/StyledInput';
import { StyledTextarea } from '@components/styledComponents/StyledTextarea/StyledTextarea';

export const QUIZ_FORM_MAIN_DEFAULT = {
  title: '',
  description: '',
};

export type QuizFormMainType = {
  title: string;
  description: string;
};

type Props = {
  value: QuizFormMainType;
  onChange: (value: QuizFormMainType) => void;
};

export const QuizFormMain: React.FC<Props> = ({ value, onChange }) => {
  return (
    <Grid container>
      <Grid item xs={3} sx={{ marginBottom: 2 }}>
        Название
        <span className="mandatory-field">*</span>
      </Grid>
      <Grid item xs={9} sx={{ marginBottom: 2 }}>
        <StyledInput
          className="news__search"
          size="small"
          fullWidth
          value={value.title}
          onChange={({ target }) => onChange({ ...value, title: target.value })}
        />
      </Grid>

      <Grid item xs={3}>
        Описание
      </Grid>
      <Grid item xs={9}>
        <StyledTextarea
          className="quiz-form__textarea"
          minRows={6}
          value={value.description}
          onChange={({ target }) =>
            onChange({ ...value, description: target.value })
          }
        />
      </Grid>
    </Grid>
  );
};

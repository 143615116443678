import { useCallback, useMemo, useState } from 'react';
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';

import { ReactComponent as ConfirmIcon } from '@assets/icons/confirm.svg';
import Button from '@components/common/Button/Button';
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';
import { UserRoleList, UserRoleStatus } from '@redux/user/types';
import ProfileTeacherAddWork, {
  FormData,
} from '@components/Profile/ProfileTeacherAddWork/ProfileTeacherAddWork';
import { updateEduProviderRoleStatus } from '@redux/user';
import { Organization } from '@declarations/organization';
import { EduGroup } from '@declarations/eduGroup';
import { Entity } from '@declarations/common';
import { EduProviderStatusReason } from '@declarations/enum/retireRoleStatusReason';
import { eduProviderStatusReasonLocale } from '@locales/retireRoleStatusReason';
import { useSnackbar } from '@hooks/useSnackbar';
import { userApi } from '@api/user';
import { EduProviderRole } from '@declarations/role';
import { EntityTypes } from '@mock-data/entities';

type Props = {
  roleList?: UserRoleList;
  onReloadRoleList: () => void;
};

const ProfileTeacherRoleList = ({ roleList, onReloadRoleList }: Props) => {
  const dispatch = useDispatch();
  const { addSnack } = useSnackbar();

  const [openAddWork, setOpenAddWork] = useState<boolean>(false);
  const [retiredRole, setRetiredRole] = useState<{
    label: string;
    reason?: EduProviderStatusReason;
    id?: string;
  } | null>(null);
  const [loadingRolesIds, setLoadingRolesIds] = useState<string[]>([]);

  const organizationHashTable = useMemo(() => {
    const result: { [fullUrl: string]: Entity<Organization> } = {};
    if (roleList?.profileRoles?.userOrgListAll.length) {
      return (
        roleList?.profileRoles?.userOrgListAll as Entity<Organization>[]
      )?.reduce((acc, record) => {
        acc[`${record.resource.resourceType}/${record.resource.id}`] = record;
        return acc;
      }, result);
    }
    return result;
  }, [roleList]);

  const eduGroupHashTable = useMemo(() => {
    const result: { [fullUrl: string]: Entity<EduGroup> } = {};
    if (roleList?.profileRoles?.userEduGroupListAll.length) {
      return (
        roleList?.profileRoles?.userEduGroupListAll as Entity<EduGroup>[]
      )?.reduce((acc, record) => {
        acc[`${record.resource.resourceType}/${record.resource.id}`] = record;
        return acc;
      }, result);
    }
    return result;
  }, [roleList]);

  const teacherRoleList = useMemo(() => {
    let result: Entity<EduProviderRole>[] | null = null;
    if (roleList?.profileRoles?.roleListAll?.length) {
      const grouped = (
        roleList?.profileRoles?.roleListAll as Entity<EduProviderRole>[]
      )?.reduce(
        (acc, value) => {
          switch (value.resource.status) {
            case UserRoleStatus.RETIRED:
              acc.retired.push(value);
              break;
            case UserRoleStatus.CONFIRMED:
              acc.confirmed.push(value);
              break;
            case UserRoleStatus.CREATED_BY_ADMIN:
              acc.adminCreate.push(value);
              break;
            case UserRoleStatus.CREATED_BY_SELF:
              acc.selfCreate.push(value);
              break;
          }
          return acc;
        },
        { retired: [], confirmed: [], adminCreate: [], selfCreate: [] } as {
          retired: Entity<EduProviderRole>[];
          confirmed: Entity<EduProviderRole>[];
          adminCreate: Entity<EduProviderRole>[];
          selfCreate: Entity<EduProviderRole>[];
        },
      );

      result = [
        ...grouped.confirmed,
        ...grouped.selfCreate,
        ...grouped.adminCreate,
      ];
    }
    return result;
  }, [roleList]);

  const eduProviderProfile = useMemo(() => {
    if (roleList?.profileRoles?.roleListAll?.length) {
      const role: Entity<EduProviderRole> =
        roleList?.profileRoles?.roleListAll[0];
      return role.resource.eduProviderProfile;
    }
    return null;
  }, [roleList?.profileRoles?.roleListAll]);

  const addRole = useCallback(() => {
    setOpenAddWork(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpenAddWork(false);
  }, []);

  const handleAddWork = useCallback(
    async (formData: FormData) => {
      try {
        if (!eduProviderProfile) {
          throw new Error('Не загружен проифль пед. работника');
        }
        const data = {
          resourceType: formData.resourceType,
          role: {
            code: formData.role,
          },
          eduProviderProfile,
          period: { start: new Date().toISOString() },
          organization: {
            reference:
              formData.organizationId &&
              `${EntityTypes.ORGANIZATION}/${formData.organizationId}`,
          },
          // TODO: place for bug, but I can't add role without this field
          specialty: { code: '050100' },
          eduGroup: formData.eduGroup
            ? {
                reference: `${formData.eduGroup.resource.resourceType}/${formData.eduGroup.resource.id}`,
              }
            : undefined,
          status: UserRoleStatus.CREATED_BY_SELF,
        };

        const response = await userApi.addEduProviderRole(data);
        if (!response) {
          throw new Error('Роль не добавлена');
        }
        addSnack('Роль добавлена', 'success');
        // TODO: find another way for reload data, maybe set data to redux store
        setTimeout(() => onReloadRoleList(), 3000);
        handleClose();
      } catch (err) {
        console.error(err);
        addSnack('Произошла ошибка при добавлении роли', 'error');
      }
    },
    [handleClose, onReloadRoleList, addSnack, eduProviderProfile],
  );

  const handleConfirmRoleStatus = useCallback(
    async (statusRoleId?: string) => {
      if (statusRoleId) {
        try {
          setLoadingRolesIds((prev) => [...prev, statusRoleId]);
          await dispatch(
            updateEduProviderRoleStatus(UserRoleStatus.CONFIRMED, statusRoleId),
          );
          addSnack('Роль подтверждена', 'success');
          // TODO: find another way for reload data, maybe set data to redux store
          setTimeout(() => onReloadRoleList(), 3000);
        } catch (err) {
          console.error(err);
          addSnack('Произошла ошибка при смене статуса', 'error');
        } finally {
          setLoadingRolesIds((prev) =>
            prev?.filter((id) => id !== statusRoleId),
          );
        }
      } else {
        addSnack('Не найден идентификатор роли', 'error');
      }
    },
    [dispatch, addSnack, onReloadRoleList],
  );

  const handleRejectRoleStatus = useCallback(async () => {
    if (retiredRole && retiredRole.reason && retiredRole.id) {
      try {
        setLoadingRolesIds((prev) =>
          retiredRole.id ? [...prev, retiredRole.id] : prev,
        );
        await dispatch(
          updateEduProviderRoleStatus(
            UserRoleStatus.RETIRED,
            retiredRole.id,
            retiredRole.reason,
          ),
        );
        addSnack(`Роль "${retiredRole.label}" отклонена`, 'error');
        setRetiredRole(null);
        onReloadRoleList();
      } catch (err) {
        console.error(err);
        addSnack('Произошла ошибка', 'error');
      } finally {
        setLoadingRolesIds((prev) =>
          prev?.filter((id) => id !== retiredRole.id),
        );
      }
    }
  }, [dispatch, retiredRole, addSnack, onReloadRoleList]);

  return (
    <>
      <Typography variant="h4" sx={{ fontWeight: 500 }}>
        Роль
      </Typography>
      <ul className="profile__list">
        {!teacherRoleList && (
          <li className="profile__list-item">
            <Typography variant="h5">Пока нет добавленных ролей</Typography>
          </li>
        )}
        {teacherRoleList &&
          teacherRoleList.map((role) => {
            const organization =
              role.resource.organization &&
              organizationHashTable[role.resource.organization.reference];

            const eduGroup =
              role.resource.eduGroup &&
              eduGroupHashTable[role.resource.eduGroup.reference];

            let title = role.resource.role.display;
            if (eduGroup) {
              title += `, ${eduGroup.resource.name}`;
            }
            const isRequestProgress =
              role.resource.id && loadingRolesIds.includes(role.resource.id);
            return (
              <li className="profile__list-item" key={role.resource.id}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography variant="h5">{title}</Typography>
                  </Grid>
                  <Grid item>
                    {role.resource.status === UserRoleStatus.CONFIRMED && (
                      <ConfirmIcon />
                    )}
                  </Grid>
                </Grid>
                {organization && (
                  <div className="profile__text" key={organization.resource.id}>
                    {organization.resource.name}
                  </div>
                )}
                {role.resource.status === UserRoleStatus.CREATED_BY_SELF && (
                  <Box sx={{ mt: 2 }}>
                    <div className="profile__notification">на рассмотрении</div>
                  </Box>
                )}

                {role.resource.status === UserRoleStatus.CREATED_BY_ADMIN && (
                  <Box sx={{ mt: 2 }}>
                    {isRequestProgress ? (
                      <CircularProgress />
                    ) : (
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={3}
                        columnSpacing={1}
                      >
                        <Grid item>
                          <Button
                            size="small"
                            variant="first"
                            onClick={() =>
                              handleConfirmRoleStatus(role.resource.id)
                            }
                          >
                            Подтвердить роль
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            size="small"
                            variant="fourth"
                            onClick={() =>
                              setRetiredRole({
                                label: title,
                                id: role.resource.id,
                              })
                            }
                          >
                            Отклонить
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                )}
              </li>
            );
          })}
      </ul>
      {openAddWork && (
        <Box
          sx={{
            mt: 2,
            border: '1px solid #CAD3E0',
            borderRadius: '8px',
            p: '24px 24px 24px 40px',
          }}
        >
          <ProfileTeacherAddWork onClose={handleClose} onSave={handleAddWork} />
        </Box>
      )}
      <Button
        variant="text"
        startIcon={<PlusIcon />}
        sx={{ mt: 2 }}
        onClick={addRole}
      >
        Добавить
      </Button>
      <Modal
        open={!!retiredRole}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalContentStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Отклонение роли
          </Typography>

          <Box
            sx={{
              mt: 2,
            }}
          >
            Вы отклоняете роль &quot;{retiredRole?.label}&quot;
          </Box>
          <Box
            sx={{
              mt: 2,
            }}
          >
            <Grid item container flexDirection="column">
              {retireReasonOptions.map((option) => (
                <Grid item key={option.value}>
                  {/*TODO add styled radio component*/}
                  <FormControlLabel
                    name="reason"
                    value={option.value}
                    checked={retiredRole?.reason === option.value}
                    label={option.label}
                    control={
                      <Radio
                        color="primary"
                        onChange={() =>
                          setRetiredRole((prev) =>
                            prev
                              ? {
                                  ...prev,
                                  reason: option.value,
                                }
                              : prev,
                          )
                        }
                      />
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Grid
            container
            columnSpacing={2}
            sx={{ mt: 4, justifyContent: 'flex-end' }}
          >
            <Grid item>
              <Button variant="fourth" onClick={() => setRetiredRole(null)}>
                Отмена
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="first"
                disabled={!retiredRole?.reason}
                onClick={handleRejectRoleStatus}
              >
                Отклонить
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default ProfileTeacherRoleList;

const modalContentStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 480,
  bgcolor: '#FFFFFF',
  borderRadius: '8px',
  boxShadow:
    '0px 1px 8px rgba(123, 135, 158, 0.12), 0px 8px 32px rgba(123, 135, 158, 0.25)',
  p: 4,
};

const retireReasonOptions = Object.values(EduProviderStatusReason)
  .filter((x) => typeof x === 'string')
  .map((value) => ({
    label: eduProviderStatusReasonLocale[value],
    value,
  }));

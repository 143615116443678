import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Typography, Grid } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as IconEye } from '@assets/icons/eye.svg';
import { ReactComponent as IconTrash } from '@assets/icons/trash.svg';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

import {
  QUIZ_FORM_MAIN_DEFAULT,
  QuizFormMain,
} from '@pages/Manager/Quiz/QuizFormMain';
import Button from '@components/common/Button/Button';
import EmptyData from '@components/common/EmptyData/EmptyData';
import { Loader } from '@components/common/Loader/Loader';
import {
  SETTINGS_FORM_DEFAULT_VALUE,
  SettingsForm,
} from '@pages/Manager/CommonForms/SettingsForm';
import { DEFAULT_TASK, QuizFormTasks } from '@pages/Manager/Quiz/QuizFormTasks';
import { ReactComponent as IconPlus } from '@assets/icons/plus.svg';
import { QuizStatus } from '@api/types/quiz';
import { useSnackbar } from '@hooks/useSnackbar';
import { quizAPI } from '@api/quiz';
import { QuizForms } from '@pages/Manager/Quiz/types';
import { mapFromServer, mapToServer } from '@pages/Manager/Quiz/utils';
import dayjs from 'dayjs';
import { ROUTES } from '@constants/routes';
import { openPreviewWindow } from '@lib/quiz';
import { useTargetUserCount } from '@hooks/useTargetUserCount';
import { FormErrors, QuizContext } from './context';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import { UI_MESSAGE } from '@declarations/enum/uiMessage';
import { useCheckForAdminStructuredRole } from '@hooks/useCheckForAdminStructuredRole';
import { StructureRole } from '@declarations/enum/structureRole';

const STEPS = {
  FIRST: 1,
  SECOND: 2,
};

export const QuizForm: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const history = useHistory();
  const { addSnack } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [showSetting, setShowSetting] = useState(true);
  const [step, setStep] = useState(STEPS.FIRST);
  const [form, setForm] = useState<QuizForms>({
    main: QUIZ_FORM_MAIN_DEFAULT,
    setting: SETTINGS_FORM_DEFAULT_VALUE,
    tasks: [DEFAULT_TASK],
  });
  const [isLoadQuiz, setIsLoadQuiz] = useState(!!id);
  const [errLoadQuiz, setErrLoadQuiz] = useState(false);
  const { userCount, isAllUsers, isLoadError } = useTargetUserCount(
    form.setting,
  );
  const [errors, setErrors] = useState<FormErrors>({});

  const functionalRoles = useSelector(
    (state: RootState) => state.user.functionalRoles,
  );

  const funcRoleId = useMemo(
    () => functionalRoles.find((item) => item.role === 'PoolsCRUD')?.id,
    [functionalRoles],
  );

  const { isRole: isOOGroupsAdmin } = useCheckForAdminStructuredRole(
    StructureRole.OO_GROUP_ADMINISTRATOR,
  );

  const formIsValid = useMemo(() => {
    if (!form.main.title) {
      return false;
    }

    if (form.tasks.some((item) => !item.title)) {
      return false;
    }

    if (form.setting.period.some((item) => !item)) {
      return false;
    }

    return (
      isEmpty(errors) &&
      form.tasks.every((item) => {
        if (Array.isArray(item.answer)) {
          return item.answer.every((answer) => answer.label);
        }
        return true;
      })
    );
  }, [form, errors]);

  const canPublish = useMemo(() => {
    const [startDate, endDate] = form.setting.period;
    const today = new Date();
    return dayjs(endDate).isAfter(today) && dayjs(startDate).isBefore(today);
  }, [form.setting.period]);

  const disableSave =
    loading ||
    !formIsValid ||
    (!!isOOGroupsAdmin &&
      (!form.setting.region.length || !form.setting.ooGroup.length));

  const disabledPublishBtn = disableSave || !canPublish;

  const createQuiz = useCallback(
    async (status: QuizStatus) => {
      try {
        setLoading(true);
        const dataToSend = mapToServer(form, status);
        if (id) {
          await quizAPI.update(id, dataToSend);
          addSnack(
            status === 'PUBLISHED' ? 'Опрос опубликован' : 'Опрос обновлен',
            'success',
          );
        } else {
          await quizAPI.create(dataToSend);
          addSnack(
            status === 'PUBLISHED' ? 'Опрос опубликован' : 'Опрос создан',
            'success',
          );
        }
        history.push(ROUTES.managerQuizList);
      } catch (error) {
        console.error(error);
        addSnack(
          id ? 'Ошибка при обновлении опроса' : 'Ошибка при создании опроса',
          'error',
        );
      } finally {
        setLoading(false);
      }
    },
    [form, history, id, addSnack],
  );

  const addQuestion = useCallback((id: number) => {
    setForm((prev) => ({
      ...prev,
      tasks: [
        ...prev.tasks,
        {
          ...DEFAULT_TASK,
          id,
          index: prev.tasks.length,
        },
      ],
    }));
  }, []);

  const preview = useCallback(() => {
    openPreviewWindow(mapToServer(form, 'DRAFT'));
  }, [form]);

  const moveItem = useCallback(
    (fromIndex: number, toIndex: number) => {
      const tasks = [...form.tasks];
      const item = tasks?.splice(fromIndex, 1)[0];
      // Чтобы не писать еще одну фукнцию на удаления,
      // будем удалять если toIndex = -1
      if (toIndex !== -1) {
        tasks.splice(toIndex, 0, item);
      }
      setForm((prev) => ({ ...prev, tasks }));
    },
    [form.tasks],
  );

  const copyItem = useCallback(
    (id: number) => {
      const itemIndex = form.tasks.findIndex((item) => item.id === id);
      if (itemIndex !== -1) {
        const clone = cloneDeep(form.tasks[itemIndex]);
        clone.title = `(копия) ${clone.title}`;
        clone.id = Date.now();
        const tasks = [...form.tasks];
        tasks.splice(itemIndex + 1, 0, clone);
        setForm((prev) => ({ ...prev, tasks }));
      }
    },
    [form.tasks],
  );

  const remove = useCallback(async () => {
    try {
      if (id) {
        await quizAPI.deleteQuiz(id);
        addSnack('Опрос удален', 'success');
        history.push(ROUTES.managerQuizList);
      }
    } catch (error) {
      console.error(error);
      addSnack('Ошибка при удалении опроса', 'error');
    }
  }, [id, history, addSnack]);

  useEffect(() => {
    if (step === STEPS.SECOND) {
      setShowSetting(false);
    }
  }, [step]);

  useEffect(() => {
    const loadData = async () => {
      if (id) {
        try {
          const data = await quizAPI.getItem(id, true);
          setForm(mapFromServer(data));
        } catch (error) {
          setErrLoadQuiz(true);
          addSnack(UI_MESSAGE.QUIZ_NOT_AVAILABLE, 'error');
        } finally {
          setIsLoadQuiz(false);
        }
      }
    };
    if (id) {
      loadData();
      setStep(STEPS.SECOND);
      return;
    }
    if (id && id !== 'add') {
      setIsLoadQuiz(false);
      setErrLoadQuiz(true);
      addSnack(UI_MESSAGE.QUIZ_NOT_AVAILABLE, 'error');
    }
  }, [addSnack, id]);

  if (isLoadQuiz) {
    return <Loader />;
  }

  if (errLoadQuiz) {
    return <EmptyData />;
  }

  return (
    <QuizContext.Provider
      value={{
        errors,
        setErrors,
      }}
    >
      <div className="quiz-form">
        <Grid container sx={{ maxWidth: '768px', pt: 3, pl: 3, pr: 3 }}>
          <div className="quiz-form__title">
            {id ? 'Редактирование' : 'Новый опрос'}
          </div>
        </Grid>
        <Grid container sx={{ maxWidth: '768px', pl: 3, pr: 3 }}>
          <QuizFormMain
            value={form.main}
            onChange={(main) => setForm((prev) => ({ ...prev, main }))}
          />
        </Grid>
        <Grid container sx={{ maxWidth: '768px', marginTop: 4, pl: 3, pr: 3 }}>
          {showSetting ? (
            <>
              <Grid item xs={3}>
                <Typography fontSize="18px" fontWeight="500" color="#2C3038">
                  Настройки
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <button
                  className="quiz-form__text-btn"
                  onClick={() => setShowSetting(false)}
                >
                  Свернуть настройки
                </button>
              </Grid>
              <Grid item xs={12}>
                <SettingsForm
                  value={form.setting}
                  funcRoleId={funcRoleId}
                  onChange={(setting) =>
                    setForm((prev) => ({ ...prev, setting }))
                  }
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={3} />
              <Grid item xs={9}>
                <button
                  className="quiz-form__text-btn"
                  onClick={() => setShowSetting(true)}
                >
                  Показать настройки
                </button>
              </Grid>
            </>
          )}
        </Grid>

        {step === STEPS.SECOND && (
          <Grid container>
            <Grid item xs={12}>
              <QuizFormTasks
                value={form.tasks}
                onChange={(tasks: any) =>
                  setForm((prev) => ({ ...prev, tasks }))
                }
                moveItem={moveItem}
                copyItem={copyItem}
              />
            </Grid>

            <>
              <Grid
                item
                xs={3}
                sx={{
                  borderTop: '1px solid #dce2eb',
                  paddingTop: 5,
                }}
              />
              <Grid
                item
                xs={9}
                sx={{
                  borderTop: '1px solid #dce2eb',
                  paddingTop: 5,
                }}
              >
                <Button
                  onClick={() => addQuestion(Date.now())}
                  startIcon={<IconPlus />}
                  variant="text"
                >
                  Добавить вопрос
                </Button>
              </Grid>
            </>
          </Grid>
        )}
        <Grid item mt={2} pl={3} pr={3}>
          Предполагаемое количество опрашиваемых:{' '}
          {isAllUsers
            ? UI_MESSAGE.TARGET_AUDIENCE_ALL
            : isLoadError
            ? '-'
            : userCount.toLocaleString('ru')}
        </Grid>
        <Grid
          container
          sx={{ display: 'block', pb: 3, pl: 3, pr: 3, maxWidth: '768px' }}
        >
          <div className="quiz-form__row quiz-form__row--push-top quiz-form__row--controls">
            <div className="quiz-form__row-left">
              <Button variant="fourth" sx={iconBtnSx} onClick={remove}>
                <IconTrash />
              </Button>
            </div>
            <div className="quiz-form__row-right">
              {step === STEPS.FIRST ? (
                <Button variant="first" onClick={() => setStep(STEPS.SECOND)}>
                  Продолжить
                </Button>
              ) : (
                <>
                  <Button variant="fourth" sx={iconBtnSx} onClick={preview}>
                    <IconEye />
                  </Button>
                  <Button
                    variant="fourth"
                    sx={{ marginRight: 2, backgroundColor: '#fff', height: 44 }}
                    disabled={disableSave}
                    onClick={() => createQuiz('DRAFT')}
                  >
                    Сохранить
                  </Button>
                  <Button
                    sx={{ height: 44 }}
                    disabled={disabledPublishBtn}
                    variant="first"
                    onClick={() => createQuiz('PUBLISHED')}
                  >
                    Опубликовать
                  </Button>
                </>
              )}
            </div>
          </div>
        </Grid>
      </div>
    </QuizContext.Provider>
  );
};

const iconBtnSx = {
  marginRight: 2,
  backgroundColor: '#fff',
  height: 44,
  width: 44,
  padding: 0,
  minWidth: 44,
};

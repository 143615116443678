import { ThunkAction } from 'redux-thunk';
import { RootState } from '../store';
import { AnyAction } from 'redux';
import { setFilterOptions, setOptions } from './index';
import { setFetched, setFetching } from '../ui';
import { newsAPI, Option } from '@api/news';
import { Option as SelectOption } from '@components/common/Select/types';
import linksApi from '@api/links';
import { diaryAPI } from '@api/diary';

export type VoidThunk = ThunkAction<void, RootState, undefined, AnyAction>;

export const fetchFilters = (): VoidThunk => async (dispatch, getState) => {
  let eduLevels = getState().filters.options.eduLevels;
  if (!eduLevels.length) {
    await dispatch(fetchEduLevelFilterOptions());
    // Берем еще раз данные из обновденного стейта
    eduLevels = getState().filters.options.eduLevels;
  }

  dispatch(
    setFilterOptions({
      grade: eduLevels,
    }),
  );
};

export const fetchFilterOptions =
  (): VoidThunk => async (dispatch, getState) => {
    const requestName = 'fetchFilterOptions';
    if (
      ['fetched', 'fetching'].includes(
        getState().ui.requestStatuses[requestName],
      )
    ) {
      return;
    }
    dispatch(setFetching(requestName));
    const [levels, audience, parallels, regions, categories] =
      await Promise.allSettled([
        newsAPI.getEduLevels(),
        newsAPI.getAudienceList(),
        newsAPI.getParallels(),
        newsAPI.getSubjects(),
        linksApi.getLinkCategories(),
      ]);

    dispatch(
      setOptions({
        audience: getMappedValue(audience),
        eduLevels: getMappedValue(levels),
        parallels: getMappedValue(parallels),
        regions: getMappedRegionsValue(regions),
        linkCategories: getMappedValue(categories),
      }),
    );

    dispatch(setFetched(requestName));
  };

export const fetchRegionFilterOptions =
  (): VoidThunk => async (dispatch, getState) => {
    const requestName = 'fetchRegionFilterOptions';
    if (
      ['fetched', 'fetching'].includes(
        getState().ui.requestStatuses[requestName],
      )
    ) {
      return;
    }
    dispatch(setFetching(requestName));
    const regions = await newsAPI.getSubjects();
    const journalLinks = await diaryAPI.getAll();

    await dispatch(
      setOptions({
        regions: regions.map(mapOption),
        journalLinks: journalLinks.map(mapJournalLinks),
      }),
    );

    dispatch(setFetched(requestName));
  };

export const fetchEduLevelFilterOptions =
  (): VoidThunk => async (dispatch, getState) => {
    const requestName = 'fetchEduLevelFilterOptions';
    if (
      ['fetched', 'fetching'].includes(
        getState().ui.requestStatuses[requestName],
      )
    ) {
      return;
    }
    dispatch(setFetching(requestName));
    const eduLevels = await newsAPI.getEduLevels();

    await dispatch(
      setOptions({
        eduLevels: eduLevels.map(mapOption),
      }),
    );

    await dispatch(setFetched(requestName));
  };

export const fetchLinkCategoriesFilterOptions =
  (): VoidThunk => async (dispatch, getState) => {
    const requestName = 'fetchLinkCategoriesFilterOptions';
    if (
      ['fetched', 'fetching'].includes(
        getState().ui.requestStatuses[requestName],
      )
    ) {
      return;
    }
    dispatch(setFetching(requestName));
    const linkCategories = await linksApi.getLinkCategories();
    await dispatch(
      setOptions({
        linkCategories: linkCategories.map(mapOption),
      }),
    );

    await dispatch(setFetched(requestName));
  };

const getMappedValue = (item: PromiseSettledResult<Option[]>) =>
  item.status === 'fulfilled' && item.value ? item.value.map(mapOption) : [];

const mapOption = (option: Option): SelectOption => ({
  label: option.name,
  value: option.id,
});

const mapJournalLinks = (option: {
  url: string;
  regionCode: number | string;
}): SelectOption => ({
  label: option.url,
  value: option.regionCode,
});

const getMappedRegionsValue = (item: PromiseSettledResult<Option[]>) =>
  item.status === 'fulfilled' && item.value
    ? item.value.map(mapRegionOption)
    : [];

const mapRegionOption = (option: Option): SelectOption => ({
  label: option.name,
  value: option.id,
  eduOrgGroupId: option.eduOrgGroupId,
});

import { Question, Quiz, QuizStatus } from '@api/types/quiz';
import { Task, TaskAnswer, TaskType } from '@pages/Manager/Quiz/QuizFormTasks';
import dayjs from 'dayjs';
import { QuizForms } from '@pages/Manager/Quiz/types';
import { AUDIENCE_ID } from '@declarations/enum/audience';

export const mapToServer = (data: QuizForms, status: QuizStatus): Quiz => {
  const questions: Question[] = data.tasks.map((task, i) => {
    const result: Question = {
      id: task.id,
      order: i,
      type: task.type,
      title: task.title,
      answers: Array.isArray(task.answer)
        ? task.answer.map((item: any, index) => ({
            title: item.label,
            isCorrect: item.isTrue,
            order: index,
          }))
        : [],
    };
    if (task.type === TaskType.MULTIPLE_ANSWER) {
      result.maxAnswerCount = task.answersCount?.max || task.answer.length;
      result.minAnswerCount = task.answersCount?.min || 1;
    } else {
      result.maxAnswerCount = 1;
      result.minAnswerCount = 1;
    }

    return result;
  });

  return {
    status,
    startDate: dayjs(data.setting.period[0]).toISOString(),
    endDate: dayjs(data.setting.period[1]).toISOString(),
    title: data.main.title,
    body: data.main.description,
    questions,
    eduLevels: data.setting.educationLevel.map((item) => ({ id: item })),
    targetAudience: data.setting.audience.map((item) => ({ id: item })),
    //@ts-ignore
    genders:
      data.setting.forWhom === 'all'
        ? ['FEMALE', 'MALE']
        : [data.setting.forWhom],
    parallels:
      data.setting.parallel &&
      data.setting.audience.includes(AUDIENCE_ID.STUDENT)
        ? data.setting.parallel.map((id) => ({ id }))
        : [],
    regions: data.setting.region.map(({ value, label }) => ({
      id: value,
      name: label,
    })),
    eduGroupOrganizations: data.setting.ooGroup.map((item) => +item),
    eduOrganizations: data.setting.oo.map((item) => +item),
  };
};

export const mapFromServer = (data: Quiz): QuizForms => {
  const questions = [...data.questions];
  questions.sort(sortFunc);
  const tasks: Task[] = data.questions.map((question, i) => {
    let answer: TaskAnswer = '';

    if (question.answers?.length) {
      const answers = [...question.answers];
      answers.sort(sortFunc);
      answer = answers.map((item) => ({
        label: item.title,
        isTrue: item.isCorrect,
      }));
    }

    const task: Task = {
      id: question.id,
      index: i,
      title: question.title,
      description: question.body,
      type: question.type,
      answer,
      answersCount: {
        max: question.maxAnswerCount,
        min: question.minAnswerCount,
      },
    };

    return task;
  });

  return {
    main: {
      title: data.title,
      description: data.body || '',
    },
    setting: {
      audience: data.targetAudience.map(({ id }) => id),
      educationLevel: data.eduLevels.map(({ id }) => id),
      region: data.regions.map((item) => ({
        label: item.name || '-',
        value: item.id,
      })),
      oo: data.eduOrganizations.map((item) => item.toString()),
      ooGroup: data.eduGroupOrganizations.map((item) => item.toString()),
      parallel: (data.parallels || []).map(({ id }) => id),
      forWhom:
        data.genders.includes('FEMALE') && data.genders.includes('MALE')
          ? 'all'
          : data.genders[0],
      period: [new Date(data.startDate), new Date(data.endDate)],
    },
    tasks,
  };
};

export const sortFunc = (a: { order: number }, b: { order: number }) => {
  if (a.order && b.order) {
    return a.order - b.order;
  }
  if (b.order) {
    return -1;
  }
  if (a.order) {
    return 1;
  }
  return 0;
};

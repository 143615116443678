import { useMemo } from 'react';

import './LinksItem.scss';
import { Grid, Typography } from '@mui/material';
import { Link } from '@declarations/links';
import isValidUrl from '@lib/url/isValidUrl';
import { IMAGE_BASE_URL } from '@constants/config';

type Props = {
  link: Link;
};

const LinksItem = ({ link }: Props) => {
  const imagePath = useMemo(() => {
    if (link.img && link.files.length) {
      const file = link.files.find((item) => item.id === link.img);
      if (file) {
        return `${IMAGE_BASE_URL}${file.path}`;
      }
    }
    return null;
  }, [link]);

  const linkUrl = useMemo(() => {
    const text = link.url.trim();
    if (isValidUrl(text)) {
      return text;
    }
    return `http://${text}`;
  }, [link]);

  return (
    <Grid
      container
      columnSpacing={{
        xs: 0,
        lg: 5,
      }}
      rowSpacing={{
        xs: 1,
        lg: 0,
      }}
      flexDirection={{
        xs: 'column',
        lg: 'row',
      }}
      alignItems={{
        lg: 'center',
      }}
      flexWrap="nowrap"
      p="24px 0 24px 0"
    >
      <Grid item xs={7}>
        <Typography variant="h5">{link.title}</Typography>
      </Grid>
      <Grid item xs={3} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        <a
          href={linkUrl}
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: 'none',
            fontSize: '14px',
          }}
        >
          {link.url}
        </a>
      </Grid>
      <Grid item xs={2} maxHeight={48} flex="none">
        {imagePath && (
          <img
            src={imagePath}
            alt="Логотип"
            style={{
              width: 80,
              height: 48,
              objectFit: 'contain',
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default LinksItem;

import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import Button from '@components/common/Button/Button';

const EmptyData: FC = () => {
  return (
    <Grid
      container
      flexDirection="column"
      sx={{
        maxWidth: '368px',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        mx: 'auto',
        mt: 8,
      }}
    >
      <Grid item>
        <Typography variant="h3" sx={{ fontWeight: 500 }}>
          Страница не найдена
        </Typography>
      </Grid>
      <Grid item sx={{ mt: 4 }}>
        <Button to={'/'} variant="first">
          Перейти на главную
        </Button>
      </Grid>
    </Grid>
  );
};

export default EmptyData;

import { useMemo } from 'react';

import './MainPage.scss';
import { LibraryWidget } from '@pages/MainPage/LibraryWidget/LibraryWidget';
import { items, VideoWidget } from '@pages/MainPage/VideoWidget/VideoWidget';
import { CollectionWidget } from '@pages/MainPage/CollectionsWidget/CollectionWidget';
import { ProfileParentBlock } from '@pages/MainPage/ProfileBlock/ProfileParentBlock';
import { ProfileTeacherBlock } from '@pages/MainPage/ProfileBlock/ProfileTeacherBlock';
import { useSelector } from 'react-redux';
import { getIsAuth, getUserRole } from '@redux/user';
import { EUserRole } from '@redux/user/types';
import { NoAuthBlock } from '@pages/MainPage/ProfileBlock/NoAuthBlock';
import { Diary } from '@components/Profile/Diary';
import { RootState } from '@redux/store';
import { getUserRegion } from '@lib/userInfo/getUserRegion';

export const MainPage: React.FC = () => {
  const userRole = useSelector(getUserRole);
  const isAuth = useSelector(getIsAuth);

  const esiaIdentifier = useSelector(
    // TODO-achernyavets уточнить, что будет принимать бэк etdId или EsiaID
    (state: RootState) =>
      state.user?.user?.esiaIdentifier as string | undefined,
  );
  const locations = useSelector((state: RootState) => state.user?.locations);

  const regionCode = useMemo(() => getUserRegion(locations), [locations]);

  return (
    <>
      {/* TODO-achernyavets раскомментировать, когда будет доработан бэкенд и можно будет получать расписание дневника по каждому ребенку */}
      {isAuth && userRole === EUserRole.PARENT && <ProfileParentBlock />}
      {isAuth && userRole === EUserRole.TEACHER && <ProfileTeacherBlock />}
      {!isAuth && <NoAuthBlock />}
      {isAuth && userRole === EUserRole.STUDENT && (
        <div className="profile-block">
          <Diary esiaId={esiaIdentifier} regionCode={regionCode} />
        </div>
      )}
      <LibraryWidget />
      <VideoWidget items={items} />
      <CollectionWidget />
    </>
  );
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import { useSnackbar } from '@hooks/useSnackbar';
import { Grid, Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import { Journal } from '@declarations/journal';
import { UI_MESSAGE } from '@declarations/enum/uiMessage';
import { ROUTES } from '@constants/routes';
import { journalAPI } from '@api/journal';
import { scheduleAPI } from '@api/schedule';
import { ErrorMessage } from '@pages/FeedbackForm/ErrorMessage';
import { StyledInput } from '@components/styledComponents/StyledInput';
import { StyledTextarea } from '@components/styledComponents/StyledTextarea/StyledTextarea';
import Button from '@components/common/Button/Button';
import { Loader } from '@components/common/Loader/Loader';
import EmptyData from '@components/common/EmptyData/EmptyData';
import SingleSelect from '@components/common/Select/SingleSelect';
import RegionSelect from '@components/common/Select/Region';

type JournalForm = {
  id?: number;
  title?: string;
  regionCode: string;
  dataType: string;
  dataBaseUrl: string;
  dataTokenUrl: string;
  dataApiUrl: string;
  credentialsType: string;
  credentials: string;
  active: boolean;
};

type CheckJournalForm = {
  esiaId: string;
};

type ErrorMessages = {
  [Property in keyof Partial<JournalForm>]: string;
} & {
  [Property in keyof Partial<CheckJournalForm>]: string;
};

const dataTypeOptions = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
];

const credentialsTypeOptions = [
  { value: 'JSON', label: 'JSON' },
  { value: 'ENCODED', label: 'ENCODED' },
  { value: 'BASIC', label: 'BASIC' },
];

export const JournalForm = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const history = useHistory();
  const { addSnack } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<JournalForm>({
    title: '',
    regionCode: '',
    dataType: '',
    dataBaseUrl: '',
    dataTokenUrl: '',
    dataApiUrl: '',
    credentialsType: '',
    credentials: '',
    active: true,
  });
  const [isLoadJournal, setIsLoadJournal] = useState(!!id);
  const [errLoadJournal, setErrLoadJournal] = useState(false);
  const [errors, setErrors] = useState<ErrorMessages>({});
  const [isCheckRequestFromForm, setIsCheckRequestFromForm] = useState(false);
  const [checkFormData, setCheckFormData] = useState<CheckJournalForm>({
    esiaId: '',
  });

  const handleChangeFormData = useCallback(
    (newData: { [key: string]: any }) =>
      setFormData((prevState: any) => ({
        ...prevState,
        ...newData,
      })),
    [],
  );

  const disableCheckBtn =
    !formData.regionCode ||
    !formData.dataType ||
    !formData.dataBaseUrl ||
    !formData.dataTokenUrl ||
    !formData.dataApiUrl ||
    !formData.credentialsType ||
    !formData.credentials;

  const disableSave = useMemo(() => {
    return disableCheckBtn;
  }, [disableCheckBtn]);

  const onSubmit = useCallback(async () => {
    const formErrors = validationForm(formData);
    setErrors(formErrors);
    if (isEmpty(formErrors)) {
      try {
        setLoading(true);
        const journalItem = mapToJournal(formData);
        if (id) {
          await journalAPI.update(journalItem);
        } else {
          await journalAPI.add(journalItem);
        }
        addSnack(
          id
            ? 'Данные для получения журнала обновлены'
            : 'Данные для получения журнала созданы',
          'success',
        );
        history.push(ROUTES.managerJournalList);
      } catch (err: any) {
        console.error(err);
        if (err.response?.data?.message) {
          addSnack(err.response?.data?.message, 'error');
        } else {
          addSnack(
            id
              ? 'Ошибка при обновлении данных для получения журнала'
              : 'Ошибка при создании данных для получения журнала',
            'error',
          );
        }
      } finally {
        setLoading(false);
      }
    }
  }, [formData, id, addSnack, history]);

  const onCheckRequestFromForm = useCallback(async () => {
    if (isCheckRequestFromForm) {
      const formErrors = validationForm(formData);
      if (!checkFormData.esiaId) {
        formErrors.esiaId = 'Обязательно для заполнения';
      }
      setErrors(formErrors);
      if (isEmpty(formErrors) && checkFormData.esiaId.trim().length) {
        const week = [
          dayjs().startOf('week').toDate(),
          dayjs().endOf('week').toDate(),
        ];
        try {
          await scheduleAPI.getJournal(
            week[0],
            week[1],
            checkFormData.esiaId,
            `0${formData.regionCode}`.slice(-2),
          );
          addSnack('ПРОВЕРКА: Журнал получен', 'success');
        } catch (err: any) {
          console.log(err);
          addSnack('ПРОВЕРКА: Ошибка при получении журнала', 'error');
        }
      }
    }
    setIsCheckRequestFromForm(true);
  }, [addSnack, checkFormData, formData, isCheckRequestFromForm]);

  const handleSaveLink = useCallback(() => onSubmit(), [onSubmit]);

  const handleCheckJournal = useCallback(
    () => onCheckRequestFromForm(),
    [onCheckRequestFromForm],
  );

  useEffect(() => {
    const loadData = async (regionCode: string) => {
      try {
        const journalItem = await journalAPI.getByRegionCode(regionCode);
        if (journalItem) {
          setFormData(mapJournalToFormData(journalItem));
        }
      } catch (err) {
        setErrLoadJournal(true);
        addSnack(UI_MESSAGE.JOURNAL_NOT_AVAILABLE, 'error');
      } finally {
        setIsLoadJournal(false);
      }
    };
    if (id && +id) {
      loadData(id);
      return;
    }
    if (id && id !== 'add') {
      setIsLoadJournal(false);
      setErrLoadJournal(true);
      addSnack(UI_MESSAGE.JOURNAL_NOT_AVAILABLE, 'error');
    }
  }, [addSnack, id]);

  if (isLoadJournal) {
    return <Loader />;
  }

  if (errLoadJournal) {
    return <EmptyData />;
  }

  return (
    <div>
      <Grid container sx={{ marginBottom: '32px' }}>
        <Grid item xs alignItems="center">
          <Typography variant="h2" sx={{ fontWeight: '500', fontSize: '18px' }}>
            {!id ? 'Новый журнал' : 'Редактирование журнала'}
          </Typography>
        </Grid>
        <Grid item />
      </Grid>
      <Grid container sx={{ maxWidth: '768px' }}>
        <Grid item xs={3}>
          Название региона
          <span className="mandatory-field">*</span>
        </Grid>
        <Grid item xs={9} pb={2}>
          <RegionSelect
            name="selectRegInJournal"
            id="selectRegInJournal"
            placeholder="Выберите регион РФ"
            isDisabled={!!id}
            showRegionCode
            onChange={(selectedOptions: any) =>
              handleChangeFormData({ regionCode: selectedOptions.value })
            }
            value={formData.regionCode ? +formData.regionCode : 0}
          />
        </Grid>
        <Grid item xs={3}>
          Тип токена
          <span className="mandatory-field">*</span>
        </Grid>
        <Grid item xs={9} pb={2}>
          <SingleSelect
            options={dataTypeOptions}
            name={'dataType'}
            placeholder={'Тип токена'}
            id={'dataType'}
            onChange={(selectedOption) =>
              handleChangeFormData({
                dataType: selectedOption ? selectedOption.value : '',
              })
            }
            value={dataTypeOptions.find(
              (option) => option.value === formData.dataType,
            )}
          />
          {errors.dataType && <ErrorMessage>{errors.dataType}</ErrorMessage>}
        </Grid>
        <Grid item xs={3}>
          Базовая ссылка
          <span className="mandatory-field">*</span>
        </Grid>
        <Grid item xs={9}>
          <div style={{ marginBottom: '16px' }}>
            <StyledInput
              required
              size={'medium'}
              fullWidth
              value={formData.dataBaseUrl}
              onChange={({ target }) =>
                handleChangeFormData({
                  dataBaseUrl: target.value,
                })
              }
            />
            {errors.dataBaseUrl && (
              <ErrorMessage>{errors.dataBaseUrl}</ErrorMessage>
            )}
          </div>
        </Grid>
        <Grid item xs={3}>
          Ссылка для токена
          <span className="mandatory-field">*</span>
        </Grid>
        <Grid item xs={9}>
          <div style={{ marginBottom: '16px' }}>
            <StyledInput
              required
              size={'medium'}
              fullWidth
              value={formData.dataTokenUrl}
              onChange={({ target }) =>
                handleChangeFormData({
                  dataTokenUrl: target.value,
                })
              }
            />
            {errors.dataTokenUrl && (
              <ErrorMessage>{errors.dataTokenUrl}</ErrorMessage>
            )}
          </div>
        </Grid>
        <Grid item xs={3}>
          Ссылка для журнала
          <span className="mandatory-field">*</span>
        </Grid>
        <Grid item xs={9}>
          <div style={{ marginBottom: '16px' }}>
            <StyledInput
              required
              size={'medium'}
              fullWidth
              value={formData.dataApiUrl}
              onChange={({ target }) =>
                handleChangeFormData({
                  dataApiUrl: target.value,
                })
              }
            />
            {errors.dataApiUrl && (
              <ErrorMessage>{errors.dataApiUrl}</ErrorMessage>
            )}
          </div>
        </Grid>
        <Grid item xs={3}>
          Тип для реквизитов
          <span className="mandatory-field">*</span>
        </Grid>
        <Grid item xs={9} pb={2}>
          <SingleSelect
            options={credentialsTypeOptions}
            name={'credentialsType'}
            placeholder={'Выберите тип для реквизитов'}
            id={'credentialsType'}
            onChange={(selectedOption) =>
              handleChangeFormData({
                credentialsType: selectedOption ? selectedOption.value : '',
              })
            }
            value={credentialsTypeOptions.find(
              (option) => option.value === formData.credentialsType,
            )}
          />
          {errors.credentialsType && (
            <ErrorMessage>{errors.credentialsType}</ErrorMessage>
          )}
        </Grid>
        <Grid item xs={3}>
          Реквизиты
          <span className="mandatory-field">*</span>
        </Grid>
        <Grid item xs={9}>
          <div style={{ marginBottom: '16px' }}>
            <StyledTextarea
              minRows={6}
              value={formData.credentials}
              onChange={({ target }) =>
                handleChangeFormData({ credentials: target.value })
              }
            />
            {errors.credentials && (
              <ErrorMessage>{errors.credentials}</ErrorMessage>
            )}
          </div>
        </Grid>
        <Grid item xs={3}>
          Признак активности
        </Grid>
        <Grid item xs={9}>
          <Switch
            checked={formData.active}
            onChange={({ target }) =>
              handleChangeFormData({
                active: target.checked,
              })
            }
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container columnSpacing={2}>
            <Grid item xs />
            <Grid item xs="auto">
              <Button
                variant="third"
                disabled={loading || disableSave}
                onClick={handleSaveLink}
              >
                Сохранить
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                variant="first"
                disabled={loading || disableCheckBtn}
                onClick={handleCheckJournal}
              >
                {isCheckRequestFromForm ? 'Запустить проверку' : 'Проверить'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isCheckRequestFromForm && (
        <Grid container sx={{ maxWidth: '768px', paddingTop: '20px' }}>
          <Grid
            container
            sx={{ marginBottom: '32px', borderTop: '1px solid #e5e5eb' }}
          >
            <Grid item xs alignItems="center">
              <Typography
                variant="h2"
                sx={{ fontWeight: '500', fontSize: '18px' }}
              >
                Проверка данных из формы:
              </Typography>
            </Grid>
            <Grid item />
          </Grid>
          <Grid item xs={3}>
            Введите ЕСИА для проверки
            <span className="mandatory-field">*</span>
          </Grid>
          <Grid item xs={9}>
            <div style={{ marginBottom: '16px' }}>
              <StyledInput
                required
                size={'medium'}
                fullWidth
                value={checkFormData.esiaId}
                onChange={({ target }) => {
                  setCheckFormData({ esiaId: target.value });
                }}
              />
              {errors.esiaId && <ErrorMessage>{errors.esiaId}</ErrorMessage>}
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const mapToJournal = (form: JournalForm): Journal => {
  const {
    id,
    regionCode,
    dataType,
    dataBaseUrl,
    dataTokenUrl,
    dataApiUrl,
    credentialsType,
    credentials,
    active,
  } = form;

  return {
    id,
    regionCode: `0${regionCode}`.slice(-2),
    dataType,
    dataBaseUrl,
    dataTokenUrl,
    dataApiUrl,
    credentialsType,
    credentials: JSON.parse(credentials),
    active,
  };
};

const mapJournalToFormData = (journalItem: Journal): JournalForm => {
  const {
    id,
    regionCode,
    dataType,
    dataBaseUrl,
    dataTokenUrl,
    dataApiUrl,
    credentialsType,
    credentials,
    active,
  } = journalItem;

  return {
    id,
    regionCode: regionCode,
    dataType: `${dataType}`,
    dataBaseUrl,
    dataTokenUrl,
    dataApiUrl,
    credentialsType,
    credentials: JSON.stringify(credentials),
    active,
  };
};

const validationForm = (value: JournalForm) => {
  const isRequiredMessage = 'Обязатально для заполнения';
  const newErrors: {
    [Property in keyof Partial<ErrorMessages>]: string;
  } = {};
  if (!value.regionCode) {
    newErrors.regionCode = isRequiredMessage;
  }
  if (!value.dataType) {
    newErrors.dataType = isRequiredMessage;
  }
  if (!value.dataBaseUrl || value.dataBaseUrl.trim().length === 0) {
    newErrors.dataBaseUrl = isRequiredMessage;
  }
  if (value.dataBaseUrl) {
    const urlRE = /^(https?|ftp):\/\/.+\w$/;
    const validDataBaseUrl = urlRE.test(value.dataBaseUrl.trim().toLowerCase());
    if (!validDataBaseUrl) {
      newErrors.dataBaseUrl = 'Невалидный адрес (URL) для базовой ссылки';
    }
  }
  if (!value.dataTokenUrl || value.dataTokenUrl.trim().length === 0) {
    newErrors.dataTokenUrl = isRequiredMessage;
  }
  if (value.dataTokenUrl) {
    const validDataTokenUrl = /^\/.+$/.test(value.dataTokenUrl.toLowerCase());
    if (!validDataTokenUrl) {
      newErrors.dataTokenUrl =
        'Ссылка для токена должна начинаться с "/" и не быть пустой';
    }
  }
  if (!value.dataApiUrl || value.dataApiUrl.trim().length === 0) {
    newErrors.dataApiUrl = isRequiredMessage;
  }
  if (value.dataApiUrl) {
    const validDataApiUrlTypeOne = /^\/.*\/?%d$/.test(
      value.dataApiUrl.toLowerCase(),
    );
    const validDataApiUrlTypeTwo = /^\/.*\/?%d\/?.*\\?access_token=$/.test(
      value.dataApiUrl.toLowerCase(),
    );
    if (!value.dataType) {
      newErrors.dataApiUrl = 'Выберите тип токена';
    }
    if (value.dataType === '1' && !validDataApiUrlTypeOne) {
      newErrors.dataApiUrl =
        'Ссылка для типа токена "1" должна быть вида "[/path]/%d", где path - часть относительного адреса ссылки';
    }
    if (value.dataType === '2' && !validDataApiUrlTypeTwo) {
      newErrors.dataApiUrl =
        'Ссылка для типа токена "2" должна быть вида "[/path]/%d?access_token=", где path - часть относительного адреса ссылки';
    }
  }
  if (!value.credentialsType) {
    newErrors.credentialsType = isRequiredMessage;
  }
  if (!value.credentials) {
    newErrors.credentials = isRequiredMessage;
  }
  if (value.credentials) {
    try {
      JSON.parse(value.credentials);
    } catch (e) {
      newErrors.credentials = 'Невалидные данные в реквизитах';
    }
  }

  return newErrors;
};

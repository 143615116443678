const getWhoIsAuthRole = (whoIs: string): string => {
  if (whoIs === 'NextOfKinProfile') {
    return 'nextOfKinRole';
  } else if (whoIs === 'EduProviderProfile') {
    return 'eduProviderRole';
  } else if (whoIs === 'AdminProfile') {
    return 'adminRole';
  } else if (whoIs === 'PupilProfile') {
    return 'pupilRole';
  } else if (whoIs === 'ContributorProfile') {
    return 'contributorRole';
  } else {
    return '';
  }
};

export default getWhoIsAuthRole;

import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button as MuiButton, Grid, Typography } from '@mui/material';
import { StyledInput } from '@components/styledComponents/StyledInput';
import Button from '@components/common/Button/Button';
import EmptyData from '@components/common/EmptyData/EmptyData';
import { Loader } from '@components/common/Loader/Loader';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { LinkStatus, Link } from '@declarations/links';
import linksApi from '@api/links';
import { useSnackbar } from '@hooks/useSnackbar';
import { styled } from '@mui/material/styles';
import { ReactComponent as ImageIcon } from '@assets/icons/image-icon.svg';
import StyledRadio from '@components/common/StyledRadio/StyledRadio';
import {
  DEFAULT_SETTINGS,
  SettingsFormType,
} from '@pages/Manager/Links/constants';
import dayjs from 'dayjs';
import { SettingsForm } from '@pages/Manager/CommonForms/SettingsForm';
import { FileDto, isOfType } from '@declarations/common';
import { ReactFilesFile } from '@pages/Manager/News/components/FileUpload/types';
import { LinkCategoryList } from '@constants/links';
import { ROUTES } from '@constants/routes';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import { useCheckForAdminStructuredRole } from '@hooks/useCheckForAdminStructuredRole';
import { StructureRole } from '@declarations/enum/structureRole';
import { UI_MESSAGE } from '@declarations/enum/uiMessage';

type LinksForm = {
  title: string;
  url: string;
  images: (
    | FileDto
    | { id?: number; path?: string; originalFileName?: string }
  )[];
  categoryId: string | number;
  settings: SettingsFormType;
};

const InputFile = styled('input')({
  display: 'none',
});

export const LinksForm: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const history = useHistory();
  // const filesRef = useRef<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const { addSnack } = useSnackbar();
  const [formData, setFormData] = useState<LinksForm>({
    title: '',
    url: '',
    images: [],
    categoryId: '',
    settings: DEFAULT_SETTINGS,
  });

  const [categoryList, setCategoryList] = useState<LinkCategoryList[]>([]);
  const [isLoadLink, setIsLoadLink] = useState(!!id);
  const [errLoadLink, setErrLoadLink] = useState(false);

  const functionalRoles = useSelector(
    (state: RootState) => state.user.functionalRoles,
  );

  const funcRoleId = useMemo(
    () => functionalRoles.find((item) => item.role === 'LinksCRUD')?.id,
    [functionalRoles],
  );

  const { isRole: isOOGroupsAdmin } = useCheckForAdminStructuredRole(
    StructureRole.OO_GROUP_ADMINISTRATOR,
  );

  const handleChangeFormData = useCallback(
    (newData: { [key: string]: any }) =>
      setFormData((prevState: any) => ({
        ...prevState,
        ...newData,
      })),
    [],
  );

  const disableSave =
    formData.url.length === 0 ||
    formData.title.length === 0 ||
    (!!isOOGroupsAdmin &&
      (!formData.settings.region.length || !formData.settings.ooGroup.length));

  const disabledPublishBtn = useMemo(() => {
    if (disableSave) {
      return true;
    }
    if (formData?.settings?.period?.length) {
      return (
        dayjs(formData.settings.period[0]).toISOString() > dayjs().toISOString()
      );
    }
  }, [formData.settings.period, disableSave]);

  const onSubmit = useCallback(
    async (status: LinkStatus) => {
      try {
        setLoading(true);
        const linkItem = await mapToLink(formData, status);
        if (id) {
          await linksApi.updateLink(linkItem, id);
        } else {
          await linksApi.createLink(linkItem);
        }
        addSnack(id ? 'Ссылка обновлена' : 'Ссылка создана', 'success');
        history.push(ROUTES.managerLinkList);
      } catch (err) {
        console.error(err);
        addSnack(
          id ? 'Ошибка при обновлении ссылки' : 'Ошибка при создании ссылки',
          'error',
        );
      } finally {
        setLoading(false);
      }
    },
    [formData, addSnack, id, history],
  );

  const handleSaveLink = useCallback(
    () => onSubmit(LinkStatus.DRAFT),
    [onSubmit],
  );

  const handlePublishLink = useCallback(
    () => onSubmit(LinkStatus.PUBLISHED),
    [onSubmit],
  );

  const imgFileName = useMemo(() => {
    const file = formData.images && formData.images[0];
    if (!file) {
      return;
    }
    if (isOfType<File>(file, 'name')) {
      return file?.name;
    }
    return file?.originalFileName;
  }, [formData.images]);

  useEffect(() => {
    const loadData = async (linkId: number | string) => {
      try {
        const linkItem = await linksApi.getLinkById(linkId, true);
        if (linkItem) {
          setFormData(mapLinkToFormData(linkItem));
        }
      } catch (err) {
        setErrLoadLink(true);
        addSnack(UI_MESSAGE.LINK_NOT_AVAILABLE, 'error');
      } finally {
        setIsLoadLink(false);
      }
    };

    if (id) {
      loadData(id);
      return;
    }
    if (id && id !== 'add') {
      setIsLoadLink(false);
      setErrLoadLink(true);
      addSnack(UI_MESSAGE.LINK_NOT_AVAILABLE, 'error');
    }
  }, [addSnack, id]);

  useEffect(() => {
    const loadCategories = async () => {
      const categories = await linksApi.getLinkCategories();
      if (categories) {
        setCategoryList(categories);
      }
    };
    loadCategories();
  }, []);

  if (isLoadLink) {
    return <Loader />;
  }

  if (errLoadLink) {
    return <EmptyData />;
  }

  return (
    <div>
      <Grid container sx={{ marginBottom: '32px' }}>
        <Grid item xs alignItems="center">
          <Typography variant="h2" sx={{ fontWeight: '500', fontSize: '18px' }}>
            {!id ? 'Новая ссылка' : 'Редактирование'}
          </Typography>
        </Grid>
        <Grid item />
      </Grid>
      <Grid container sx={{ maxWidth: '768px' }}>
        <Grid item xs={3}>
          Название
          <span className="mandatory-field">*</span>
        </Grid>
        <Grid item xs={9}>
          <div style={{ marginBottom: '16px' }}>
            <StyledInput
              required
              className="news__search"
              size="medium"
              fullWidth
              value={formData.title}
              onChange={({ target }) =>
                handleChangeFormData({
                  title: target.value,
                })
              }
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          Адрес сайта
          <span className="mandatory-field">*</span>
        </Grid>
        <Grid item xs={9}>
          <div style={{ marginBottom: '16px' }}>
            <StyledInput
              required
              className={'news__search'}
              size={'medium'}
              fullWidth
              value={formData.url}
              onChange={({ target }) =>
                handleChangeFormData({
                  url: target.value,
                })
              }
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          Логотип сайта
        </Grid>
        <Grid item xs={9}>
          <Box sx={{ mb: 2 }}>
            <label htmlFor="contained-button-file">
              <InputFile
                accept="image/png, image/jpeg, image/jpg"
                id="contained-button-file"
                type="file"
                onChange={(e) =>
                  handleChangeFormData({
                    images: e.target.files ? Array.from(e.target.files) : [],
                  })
                }
              />
              <MuiButton
                variant="outlined"
                size="small"
                startIcon={<ImageIcon />}
                component="span"
              >
                {imgFileName || 'Загрузить'}
              </MuiButton>
            </label>
          </Box>
        </Grid>
        <Grid item xs={3}>
          Категория
        </Grid>
        <Grid item xs={9}>
          <Grid container flexDirection="column">
            {categoryList?.map((category) => (
              <Grid item key={category.id}>
                <StyledRadio
                  name="role"
                  value={String(category.id)}
                  checked={formData.categoryId === category.id}
                  label={category.name}
                  onChange={() =>
                    handleChangeFormData({
                      categoryId: category.id,
                    })
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={3} sx={{ mb: 2 }}>
          <Typography fontSize="18px" fontWeight="500" color="#2C3038">
            Настройки
          </Typography>
        </Grid>
        <SettingsForm
          value={formData.settings}
          onChange={(settings) => handleChangeFormData({ settings })}
          funcRoleId={funcRoleId}
        />
        <Grid item xs={12}>
          <Grid container columnSpacing={2}>
            <Grid item xs />
            <Grid item xs="auto">
              <Button
                variant="third"
                disabled={loading || disableSave}
                onClick={handleSaveLink}
              >
                Сохранить
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                variant="first"
                disabled={loading || disabledPublishBtn}
                onClick={handlePublishLink}
              >
                Опубликовать
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapToLink = async (
  form: LinksForm,
  status: LinkStatus,
): Promise<Link> => {
  let imgId: number | null = null;
  const fileId: { id: number }[] = [];
  if (form.images && form.images.length !== 0) {
    const img = form.images[0];
    const files = form.images?.reduce(
      (acc, file) => {
        if (isOfType(file, 'name')) {
          acc.new.push(file as FileDto);
        } else {
          acc.exists.push(file as FileDto);
        }
        return acc;
      },
      { new: [], exists: [] } as { new: FileDto[]; exists: FileDto[] },
    );

    const imageNotExists = img && isOfType<ReactFilesFile>(img, 'name');
    const totalUpload: (ReactFilesFile | FileDto)[] = imageNotExists
      ? [img, ...files.new]
      : files.new;
    let uploadedFiles: FileDto[] = [];
    if (totalUpload.length > 0) {
      uploadedFiles = await linksApi.addLinkLogo(totalUpload as File[]);
    }
    imgId =
      uploadedFiles && imageNotExists
        ? uploadedFiles[0]?.id
        : (img?.id as number);
    fileId.push({
      id: imgId,
    });
  }

  return {
    status,
    title: form.title,
    url: form.url,
    categoryId: form.categoryId,
    startDate: dayjs(form.settings.period[0]).toISOString(),
    endDate: dayjs(form.settings.period[1]).toISOString(),
    eduLevels: form.settings.educationLevel.map((item) => ({ id: +item })),
    targetAudience: form.settings.audience.map((item) => ({ id: +item })),
    genders:
      form.settings.forWhom === 'all'
        ? ['FEMALE', 'MALE']
        : [form.settings.forWhom],
    parallels: form.settings.parallel
      ? form.settings.parallel.map((value) => ({ id: +value }))
      : [],
    regions: form.settings.region.map(({ value, label }) => ({
      id: +value,
      name: label,
    })),
    files: fileId,
    img: imgId,
    eduOrganizations: form.settings.oo
      ? form.settings.oo.map((item) => +item)
      : [],
    eduGroupOrganizations: form.settings.ooGroup
      ? form.settings.ooGroup.map((item) => +item)
      : [],
  };
};

const mapLinkToFormData = (link: Link): LinksForm => {
  const {
    title,
    url,
    categoryId,
    startDate,
    endDate,
    eduLevels,
    targetAudience,
    genders,
    parallels = [],
    regions,
    files,
    img: imgId,
    eduOrganizations,
    eduGroupOrganizations,
  } = link;

  const linkImgIndex = files.findIndex((file) => file.id === imgId);
  const linkImg = ~linkImgIndex ? files.splice(linkImgIndex, 1) : [];

  // const catId = LINK_CATEGORY_LIST.filter((item) => item.code == categoryId);
  // const catIdCode = catId && catId[0].code;

  return {
    title,
    url,
    images: linkImg,
    categoryId,
    settings: {
      audience: targetAudience.map((item) => item.id),
      educationLevel: eduLevels.map((item) => item.id),
      parallel: parallels.map(({ id }) => id),
      period: [
        startDate ? new Date(startDate) : undefined,
        endDate ? new Date(endDate) : undefined,
      ],
      region: regions.map((item) => ({
        label: item.name || '-',
        value: item.id,
      })),
      oo: eduOrganizations[0]?.toString() || '',
      ooGroup: eduGroupOrganizations[0]?.toString() || '',
      forWhom:
        genders?.length === 2
          ? 'all'
          : (genders?.length && genders[0]) === 'MALE'
          ? 'MALE'
          : 'FEMALE',
    },
  };
};

import * as React from 'react';
import { useMemo } from 'react';

import './QuizItem.scss';
import { Grid, Typography } from '@mui/material';
import { getRemainDate } from '@lib/date/date';
import { Quiz } from '@api/types/quiz';
import Button from '@components/common/Button/Button';
import { Chip } from '@components/common/Chip/Chip';
import { ReactComponent as IconStar } from '@assets/icons/ico-blue-star.svg';
import { generateLink } from '@lib/common';
import { ROUTES } from '@constants/routes';
import { useSelector } from 'react-redux';
import { getUserRole } from '@redux/user';
import { EUserRole } from '@redux/user/types';

type FilterProps = {
  quiz: Quiz;
};

const QuizItem: React.FC<FilterProps> = ({ quiz }) => {
  const userRole = useSelector(getUserRole);

  const isCompleted = useMemo(
    () => new Date(quiz.endDate) < new Date(),
    [quiz.endDate],
  );

  return (
    <div className="quiz-item">
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Typography variant="h2" className="quiz-item-title">
            {quiz.title}
          </Typography>
          <div className="quiz-item-remain">
            {quiz.isCompleted && (
              <Chip
                icon={<IconStar />}
                template="primary"
                label="Вы участвовали"
              />
            )}
            <Chip
              label={`${
                isCompleted ? 'Завершено' : 'Завершится'
              } ${getRemainDate(quiz.endDate)}`}
            />
          </div>
        </Grid>
        <Grid item xs="auto">
          {quiz.isCompleted && quiz.id ? (
            <Button
              to={generateLink(
                userRole === EUserRole.ADMIN
                  ? ROUTES.managerQuizResult
                  : ROUTES.quizResult,
                { id: quiz.id },
              )}
              variant={'fourth'}
            >
              Результаты
            </Button>
          ) : quiz.status === 'PUBLISHED' && !isCompleted && quiz.id ? (
            <Button
              to={generateLink(ROUTES.quizItem, { id: quiz.id })}
              variant={'first'}
              style={{
                display: userRole === EUserRole.ADMIN ? 'none' : '',
              }}
            >
              Пройти
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};

export default QuizItem;

import { createContext } from 'react';

export type FormErrors = Partial<{
  questions: {
    [questionIndex: number]: Partial<{
      [fieldName: string]: string;
    }>;
  };
}>;

type QuizContextType = {
  errors: FormErrors;
  setErrors: (errors: FormErrors) => void;
};

export const QuizContext = createContext<QuizContextType>({
  errors: {},
  setErrors: () => undefined,
});

import { FileDto } from '@declarations/common';

export interface News {
  id?: number;
  status: NewsStatus;
  startDate: string;
  endDate: string;
  title: string;
  body: string;
  isImportant: boolean;
  hasPrivs?: boolean | null;
  files: FileDto[];
  eduLevels: { id: number; name?: string }[];
  targetAudience: { id: number; name?: string }[];
  genders: string[];
  parallels: { id: number; name?: string }[];
  regions: { id: number; name?: string }[];
  eduOrganizations: number[];
  eduGroupOrganizations: number[];
  img?: number;
  statusDate?: string;
}

export enum NewsStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
}

export type NewsViewType = 'card' | 'list';

import {
  AccountEntry,
  AuthAccountReponse,
  UserLocation,
  UserRoleStatus,
} from '@redux/user/types';
import dayjs from 'dayjs';
import { Entity } from '@declarations/common';
import { AuthAccount } from '@declarations/profile';
import { UserRole } from '@declarations/role';
import { EntityTypes } from '@mock-data/entities';

export const getUserLocations = (
  req: AuthAccountReponse | any,
): UserLocation[] | null => {
  if (req) {
    const result: UserLocation[] = [];
    const userResponse: AccountEntry[] = req?.entry || req?.data?.entry;
    if (userResponse?.length) {
      const a = userResponse[0] as Entity<AuthAccount>;
      const lastLoginRole = a.resource.lastLoginRole?.replace(
        'Profile',
        'Role',
      );
      const isRoleActive = (index: number) => {
        const role = userResponse.slice(0, index).reduceRight((r, i) => {
          if (!r && i.resource.resourceType === lastLoginRole) {
            return i;
          }
          return r;
        }, null as unknown as AccountEntry) as Entity<UserRole>;
        return (
          role?.resource.status === UserRoleStatus.CONFIRMED &&
          dayjs().isAfter(role?.resource.period.start) &&
          (!role?.resource.period.end ||
            dayjs().isBefore(role?.resource.period.end))
        );
      };

      userResponse.forEach((item, index) => {
        if (
          item.resource.resourceType === EntityTypes.ORGANIZATION &&
          isRoleActive(index)
        ) {
          item.resource.address?.forEach((address: any) => {
            if (address.state) {
              result.push(address.state);
            }
          });
        }
      });
      return result;
    }
  }

  return null;
};

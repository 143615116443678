import * as React from 'react';
import './CollectionItem.scss';

interface Collection {
  title: string;
  url: string;
  img?: string;
  color?: string;
}

export const CollectionItem: React.FC<{ collection: Collection }> = ({
  collection,
}) => {
  return (
    <a href={collection.url} className="collection-item">
      <div
        className="collection-item__img"
        style={{
          backgroundImage: `url(${collection.img})`,
          backgroundColor: collection.color,
        }}
      />
      <div className="collection-item__info">
        <div className="collection-item__title">{collection.title}</div>
      </div>
    </a>
  );
};

import { GounnResponse } from '@api/types/gounn';

function objectFieldsToLowerCase(data: GounnResponse): GounnResponse {
  const obj = JSON.stringify(data);

  const stringObj = obj.replace(/\b(\w)(?=\w+":)/g, (p1, p2) => {
    return p2.toLowerCase();
  });

  return JSON.parse(stringObj);
}

export default objectFieldsToLowerCase;

import * as React from 'react';
import './ProfileBlock.scss';
import { Grid, Typography } from '@mui/material';
import Button from '@components/common/Button/Button';
import { ServicesWidget } from '@pages/MainPage/ServicesWidget/ServicesWidget';
import { GosuslugiWidget } from '@pages/MainPage/GosuslugiWidget/GosuslugiWidget';
import { loginLink } from '@lib/auth/getAuthLinks';
import { deleteCookie } from '@lib/cookie';
import { COOKIE_NAMES, MAIN_DOMAIN } from '@constants/config';

export const NoAuthBlock: React.FC = () => {
  return (
    <div>
      <div className="first-block">
        <Grid container alignItems="center">
          <Grid item xs={12} md order={{ xs: 2, md: 1 }}>
            <Typography
              variant="h1"
              className="first-block__title"
              sx={{ marginBottom: '24px', wordWrap: 'break-word' }}
            >
              Твой доступ к образовательным электронным ресурсам
            </Typography>
            <div className="first-block__text">
              Единый доступ к образовательным сервисам и цифровым учебным
              материалам для учеников, родителей и учителей
            </div>
            <Button variant="first">
              <a
                className="header__login-link"
                href={loginLink}
                onClick={() =>
                  deleteCookie(COOKIE_NAMES.ACCESS_TOKEN, MAIN_DOMAIN)
                }
              >
                Войти
              </a>
            </Button>
          </Grid>
          <Grid item xs={12} md="auto" order={{ xs: 1, md: 2 }}>
            <div className="first-block__img">
              <img src="/img/mainpage/img-main1.jpg" alt="" />
            </div>
          </Grid>
        </Grid>
      </div>
      <ServicesWidget />
      <GosuslugiWidget />
    </div>
  );
};

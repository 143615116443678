const getFullName = (user: any) => {
  let fullName = '';
  if (user?.family_name && user?.given_name) {
    fullName = `${user.given_name} ${user.family_name}`;
    if (user.middle_name) {
      fullName = `${user.given_name} ${user.middle_name} ${user.family_name}`;
    }
  }
  return fullName;
};

export default getFullName;

import { RootState } from '../store';

export const requestStatus = (state: RootState) => state.ui.requestStatuses;

export const getFetchStatus = (state: RootState, key: string) =>
  requestStatus(state)[key];
export const isFetching = (state: RootState, key: string) =>
  requestStatus(state)[key] === 'fetching';
export const isFetched = (state: RootState, key: string) =>
  requestStatus(state)[key] === 'fetched';
export const isFetchError = (state: RootState, key: string) =>
  requestStatus(state)[key] === 'error';

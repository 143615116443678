import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Title from '../../../common/Title/Title';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@components/common/Button/Button';
import './styles.scss';
import { useQuery } from 'react-query';
import { scheduleAPI } from '@api/schedule';
import { mapResponse } from '../utils/mapResponse';
import { getHomeWorkList } from './utils/getHomeWorkList';
import { Box } from '@mui/system';
import { isOfType } from '@declarations/common';
import { JournalError } from '@api/types/gounn';
import { useSnackbar } from '@hooks/useSnackbar';
import { DIARY_SNACK_ID } from '../constants';

type Props = {
  esiaId: string;
  regionCode: string;
  journalUrl: string | undefined;
};

export const HomeWork = ({ regionCode, esiaId, journalUrl }: Props) => {
  const { addSnack } = useSnackbar();
  const [isShowFull, setIsShowFull] = useState(false);
  const week = useMemo(
    () => [dayjs().add(1, 'day').toDate(), dayjs().add(1, 'week').toDate()],
    [],
  );

  const {
    data: schedule,
    isLoading,
    error,
  } = useQuery(['diary-widget', regionCode, week[0], week[1]], async () => {
    const response = await scheduleAPI.getJournal(
      week[0],
      week[1],
      esiaId,
      regionCode,
    );
    if (response) {
      if (isOfType<JournalError>(response, 'issue')) {
        const errorList = response.issue?.reduce((list, issue) => {
          if (issue.severity === 'error') {
            list.push(issue.details);
          }
          return list;
        }, [] as string[]);
        throw Error(errorList.join(', '));
      }
      return mapResponse(response);
    }
  });

  const homeWork = useMemo(() => getHomeWorkList(schedule), [schedule]);

  const scheduleLink = journalUrl ? journalUrl : '#';

  const homeWorkArray = useMemo(() => {
    if (homeWork.length > 0) {
      return isShowFull ? homeWork : [homeWork[0]];
    }
    return [];
  }, [homeWork, isShowFull]);

  useEffect(() => {
    if (error) {
      const message =
        // @ts-ignore
        (typeof error === 'object' && error.message) ||
        'Ошибка получения данных';
      addSnack(message, 'error', DIARY_SNACK_ID);
    }
  }, [addSnack, error]);

  return (
    <div className="homework-widget">
      <Box
        display="flex"
        alignItems="center"
        mb="32px"
        onClick={() => {
          window.location.href = scheduleLink;
        }}
        sx={{
          cursor: 'pointer',
        }}
      >
        <Title className="grade-widget__title" sx={{ fontSize: '24px' }}>
          Домашние задания
        </Title>
        <Box ml={1} alignItems="center" />
        <ChevronRightIcon />
      </Box>
      {homeWork.length > 0 ? (
        <>
          {homeWorkArray.map((data) => {
            const dateLabel = dayjs(data.date).isSame(
              dayjs().add(1, 'day'),
              'day',
            )
              ? 'На завтра'
              : dayjs(data.date).format('dd, DD.MM');
            return (
              <>
                <div className="homework-widget__label">{dateLabel}</div>
                <TableContainer
                  component={Paper}
                  sx={{ boxShadow: 'none', marginBottom: '32px' }}
                >
                  <Table sx={{ minWidth: 650 }}>
                    <TableBody>
                      {data.work.map((work) => (
                        <TableRow
                          key={work.lesson}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ minWidth: 250, paddingLeft: '0' }}
                          >
                            {work.lesson}
                          </TableCell>
                          <TableCell align="left">{work.work}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            );
          })}
          {homeWork.length > 1 && (
            <Button
              variant="fourth"
              onClick={() => setIsShowFull((prev) => !prev)}
            >
              {isShowFull ? 'Свернуть' : 'Показать на всю неделю'}
            </Button>
          )}
        </>
      ) : isLoading ? null : (
        <Box color="#818999" textAlign="center" width="100%">
          Домашних заданий на эту неделю нет
        </Box>
      )}
    </div>
  );
};

import { ROUTES } from '@constants/routes';

export const MENU_ITEMS = [
  {
    label: 'Новости',
    path: ROUTES.newsList,
  },
  {
    label: 'Вопросы и ответы',
    path: ROUTES.faqList,
  },
  {
    label: 'Полезные ссылки',
    path: ROUTES.linkList,
  },
  {
    label: 'Опросы',
    path: ROUTES.quizList,
  },
];

import { useCallback, useEffect, useMemo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';

import Title from '@components/common/Title/Title';
import Button from '@components/common/Button/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FaqStatus } from '@redux/faq/types';
import { faqApi } from '@api/faq';
import { useSnackbar } from '@hooks/useSnackbar';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import CommonFilter from '@components/common/CommonFilter';

import './FaqListPage.scss';
import { generateLink } from '@lib/common';
import { ROUTES } from '@constants/routes';
import { DEFAULT_PAGINATION } from '@constants/constants';
import { useInfiniteQuery } from 'react-query';
import { Loader } from '@components/common/Loader/Loader';
import { IS_IMPORTANT_CONTENT } from '@constants/constants';
import { getUser } from '@redux/user';
import { useQueryDelay } from '@hooks/useQueryDelay';

export const FaqListPage = () => {
  const { addSnack } = useSnackbar();
  const filterValues = useSelector((state: RootState) => state.filters.values);
  const user = useSelector(getUser);
  const isTargetedSearch = useSelector(
    (state: RootState) => state.filters.isTargetedSearch,
  );
  const { requestAreAllowed } = useQueryDelay();

  const loadData = useCallback(
    async ({ pageParam = DEFAULT_PAGINATION.currentPage }) => {
      const response = await faqApi.searchFaqList(
        { currentPage: pageParam, pageSize: 10 },
        {
          status: FaqStatus.PUBLISHED,
          isImportant: IS_IMPORTANT_CONTENT,
          ...filterValues,
        },
        user ? isTargetedSearch : false,
      );
      if (!response) {
        throw Error('Не удалось загрузить данные');
      }
      return response;
    },
    [filterValues, isTargetedSearch, user],
  );

  const {
    data,
    error,
    isSuccess,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(['faq-list', ...Object.values(filterValues)], loadData, {
    getNextPageParam: (lastPage, pages) =>
      !lastPage || lastPage.last ? undefined : pages.length + 1,
    enabled: requestAreAllowed,
  });

  const totalCount = useMemo(
    () => data?.pages?.[0]?.totalElements,
    [data?.pages],
  );

  useEffect(() => {
    if (error) {
      addSnack('Ошибка загрузки данных', 'error');
    }
  }, [error, addSnack]);

  return (
    <Grid
      container
      flexDirection="column"
      id="faq-list-page"
      sx={{ mt: 6, maxWidth: { xl: 952, lg: 894, md: 852, sm: '100%' } }}
    >
      <Grid item>
        <Title>Вопросы и ответы</Title>
      </Grid>
      <Grid item mt={3} maxWidth={'100%'}>
        <CommonFilter
          showCategory={false}
          showDate={false}
          countItems={totalCount}
        />
        <Grid item container mt={3}>
          {isLoading ? (
            <Box mt={3} width="100%">
              <Loader />
            </Box>
          ) : (
            totalCount === 0 && (
              <Grid
                container
                flexDirection="column"
                sx={{
                  maxWidth: '368px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  mx: 'auto',
                  mt: 5,
                }}
              >
                <Grid item>
                  <Typography variant="h4" sx={{ fontWeight: 500 }}>
                    Мы ничего не нашли
                  </Typography>
                </Grid>
                <Grid item sx={{ mt: 2 }}>
                  <Typography
                    variant={'additionally' as 'h1'}
                    sx={{ color: '#75747B' }}
                  >
                    Попробуйте проверить написание или изменить значения
                    фильтров
                  </Typography>
                </Grid>
              </Grid>
            )
          )}
          {isSuccess && (
            <>
              {data?.pages.map((page) => {
                return page.content.map((item) => (
                  <Accordion key={item.id} sx={{ width: '100%' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      classes={{
                        content: 'faq-item',
                        expanded: 'faq-item--expanded',
                      }}
                      sx={{
                        ':hover': { color: '#007AFF' },
                        ['&.Mui-expanded']: {
                          color: '#007AFF',
                        },
                      }}
                    >
                      <Typography variant="h4" className="faq__title">
                        {item.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container flexDirection="column">
                        <Grid item lg={10} xs={12}>
                          <Typography
                            variant={'body' as 'h1'}
                            sx={{ fontWeight: '400' }}
                          >
                            <span
                              className="faq__response"
                              dangerouslySetInnerHTML={{
                                __html: item.body,
                              }}
                            />
                          </Typography>
                        </Grid>
                        {item.startDate && (
                          <Grid item sx={{ mt: 2 }}>
                            <Typography
                              variant="caption"
                              sx={{ color: '#626B7A' }}
                            >
                              {dayjs(item.startDate).format(
                                'HH:mm, DD MMMM YYYY',
                              )}
                            </Typography>
                          </Grid>
                        )}
                        {item.id && (
                          <Grid item className="faq-item__open-tab">
                            <Button
                              className="faq-item__open-tab-link"
                              variant="fourth"
                              to={generateLink(ROUTES.faqItem, { id: item.id })}
                              target="_blank"
                            >
                              Открыть в новой вкладке
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ));
              })}
              {hasNextPage && (
                <Grid item className="show-more">
                  <Button
                    variant="third"
                    fullWidth
                    onClick={() => fetchNextPage()}
                    disabled={isFetchingNextPage}
                  >
                    {isFetchingNextPage ? 'Загружаем' : 'Показать еще'}
                  </Button>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

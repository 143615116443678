import { connect } from 'react-redux';
import { RootState } from '@redux/store';
import { ProfilePage } from './Profile';
import { getFetchStatus } from '@redux/ui';
import {
  fetchUserRole,
  getUserAuthAccount,
  getUserHistory,
  getUserRoleList,
} from '@redux/user';

const mapStateToProps = (state: RootState) => ({
  userRoleList: getUserRoleList(state),
  authAccount: getUserAuthAccount(state),
  userRoleFetchingStatus: getFetchStatus(state, 'fetchUserRole'),
  userHistory: getUserHistory(state),
});

const mapDispatchToProps = {
  fetchUserRole,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);

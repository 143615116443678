import { EduGroup, EduGroupFilters } from '@declarations/eduGroup';
import { ApiResponse, Entity } from '@declarations/common';
import { etdAxios } from './utils/axios';

export const eduGroupApi = {
  async search(searchParams: EduGroupFilters) {
    const params: {
      [key: string]: string | boolean;
    } = {
      isProtected: true,
    };

    if (searchParams.status) {
      params.status = searchParams.status;
    }
    if (searchParams.organizationId) {
      params.organization = searchParams.organizationId;
    }
    if (searchParams.name) {
      params['name:contains'] = searchParams.name;
    }
    return await etdAxios.get<
      ApiResponse<Entity<EduGroup>>,
      ApiResponse<Entity<EduGroup>>
    >(`/EduGroup`, {
      params,
    });
  },
  get(id: string) {
    return etdAxios.get<EduGroup, EduGroup>(`/EduGroup/${id}`, {
      params: {
        isProtected: true,
      },
    });
  },
};

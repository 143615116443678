import { HeaderTV } from '@pages/SmartTVPages/Header/HeaderTV';
import { Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ResponsiveContainer } from '@components/common/ResponsiveContainer/ResponsiveContainer';
import { useEffect, useState } from 'react';
import educationApi from '@api/smart/education';
import { MY_LIBRARY_LINK } from '@constants/config';
import { EducationPorfolioItem } from '@api/types/smart/education';
import { Loader } from '@components/common/Loader/Loader';

const SERVICE_NAME = `${MY_LIBRARY_LINK}attaches/`;
const MATERIALS_URL = `${MY_LIBRARY_LINK}player/`;

export const SmartTVLibrary = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<EducationPorfolioItem[]>([]);
  const [orgs, setOrgs] = useState<{ [k: number]: string }>({});
  const [classes, setClasses] = useState<{ [k: number]: string }>({});

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const content = await educationApi.getPortfolio();
        setData(content?.filter((item) => item.status === 'ACTIVE'));
        const [classes, orgs] = await Promise.all([
          educationApi.getDictionary('class'),
          educationApi.getOrganizations(),
        ]);
        setOrgs(
          orgs?.content?.reduce(
            (result, item) => ({
              ...result,
              [item.id]: item.shortName || item.fullName,
            }),
            {},
          ),
        );
        setClasses(
          classes?.content?.reduce(
            (result, item) => ({
              ...result,
              [item.id]: +(item.name.match(/\d+/)?.[0] || 0),
            }),
            {},
          ),
        );
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  return (
    <div className="smart">
      <HeaderTV />
      <ResponsiveContainer
        sx={{
          flex: '2 0 auto',
          pb: 4,
        }}
      >
        <Grid container columnSpacing={0.5}>
          <Grid item>
            <Typography
              variant="h1"
              sx={{
                color: '#ffffff',
                fontWeight: 500,
                fontSize: '40px',
                letterSpacing: '-1px',
              }}
            >
              Библиотека
            </Typography>
          </Grid>
        </Grid>
        {loading ? (
          <Loader />
        ) : (
          <Box sx={{ letterSpacing: '-0.4px', color: '#ffffff' }}>
            <Box sx={{ minHeight: '50vh', mt: 4 }}>
              <Typography
                variant="h1"
                sx={{
                  color: '#ffffff',
                  fontWeight: 500,
                  fontSize: '28px',
                  letterSpacing: '-1px',
                }}
              >
                Портфель
              </Typography>
              <div className="smart__portfolio">
                {data.map((item) => {
                  const itemClass = item.classes
                    .map((item) => classes[item])
                    .join(', ');
                  return (
                    <div key={item.id} className="smart__portfolio-item">
                      <Grid
                        container
                        flexDirection="column"
                        rowSpacing={2}
                        tabIndex={1}
                        component="a"
                        href={
                          item.externalContentLink
                            ? item.externalContentLink
                            : `${MATERIALS_URL}${item.id}`
                        }
                        sx={{
                          outline: '0',
                          textDecoration: 'none',
                          color: 'inherit',
                        }}
                      >
                        <div>
                          <div className="smart__item-cover">
                            {item.cover ? (
                              <img
                                src={`${SERVICE_NAME}${item.cover}`}
                                alt={item.name}
                              />
                            ) : (
                              <div
                                className="smart__item-cover-color"
                                style={{
                                  backgroundColor: item.coverColor,
                                }}
                              >
                                <Typography
                                  variant={'caption' as 'h1'}
                                  sx={{
                                    color: '#333333',
                                    fontWeight: '700',
                                    p: 2,
                                    display: 'block',
                                    wordBreak: 'break-word',
                                  }}
                                >
                                  {item.name}
                                </Typography>
                              </div>
                            )}
                          </div>
                        </div>
                        <Grid item>
                          <Typography
                            sx={{
                              color: '#818999',
                              lineHeight: '20px',
                              fontSize: '14px',
                            }}
                          >
                            {item.numberOfViews} просмотров
                          </Typography>
                          <Typography
                            variant="h6"
                            sx={{ wordBreak: 'break-word', mt: '4px' }}
                          >
                            {item.name}
                          </Typography>
                          {orgs[item.publishingHouseId] && (
                            <Typography
                              className="smart__hide-lines"
                              sx={{
                                mt: '4px',
                                color: '#818999',
                                lineHeight: '20px',
                                fontWeight: '400',
                                fontSize: '14px',
                              }}
                            >
                              {orgs[item.publishingHouseId]}
                            </Typography>
                          )}
                          {itemClass && (
                            <Typography
                              className="smart__hide-lines"
                              sx={{
                                mt: '4px',
                                color: '#818999',
                                lineHeight: '20px',
                                fontWeight: '400',
                                fontSize: '14px',
                              }}
                            >
                              {itemClass} класс
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}
              </div>
            </Box>
          </Box>
        )}
      </ResponsiveContainer>
    </div>
  );
};

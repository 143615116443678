import { useEffect, useState } from 'react';

// Создан в первую очередь для использования в компонентах, связанных с получением контента: news, faq, links
/* Используется для предотвращения вызова useInfiniteQuery несколько раз при инициализации.
 Причина этому: изменения filterValues (dispatching addFilterValues action) при инициализации компонента первично
 из-за того, что параметры filterValues меняются в CommonFilters компоненте и в NewsListPageHeader (for News page)
 */
//TODO: необходимо изучить логику приложения, если возможно вынести логику по обновлению filterValues вверх по иерархии компонентов из CommonFilters, не сломав логику
// в остальных частях приложения, то можно будет избавиться от setTimeout и в целом от этого хука

const useQueryDelay = () => {
  const [requestAreAllowed, setRequestAreAllowed] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => setRequestAreAllowed(true), 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return {
    requestAreAllowed,
  };
};

export { useQueryDelay };

import * as React from 'react';
import { Link, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { ReactComponent as CaretRight } from '@assets/icons/caret-icon-right.svg';

interface TitleProps {
  children: string | ReactNode;
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | 'inherit';
  sx?: any;
  className?: string;
  href?: string;
}

const style = {
  textDecoration: 'none',
  color: 'inherit',
  transition: 'all 0.2s ease',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '16px',
  '&:hover': {
    color: '#075FF7',
  },
};

const Title: React.FC<TitleProps> = ({
  children,
  variant = 'h3',
  sx,
  href = '',
  ...props
}) => {
  if (href) {
    return (
      <Typography
        variant={variant}
        fontWeight="500"
        color="#0C1524"
        display="inline-block"
        sx={sx}
        {...props}
      >
        <Link href={href} target="_blank" rel="noreferrer" sx={style}>
          {children}
          <CaretRight />
        </Link>
      </Typography>
    );
  }

  return (
    <Typography
      variant={variant}
      fontWeight="500"
      color="#0C1524"
      sx={sx}
      {...props}
    >
      {children}
    </Typography>
  );
};

export default Title;

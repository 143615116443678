import * as React from 'react';
import 'swiper/swiper.scss';
import './GosuslugiWidget.scss';
import Title from '../../../components/common/Title/Title';
import { Grid } from '@mui/material';

export const GosuslugiWidget: React.FC = () => {
  return (
    <div className="gosuslugi">
      <Title
        className="library-widget__title"
        sx={{ fontSize: '24px', marginBottom: '40px' }}
      >
        Популярные госуслуги
      </Title>
      <Grid container>
        <Grid item xs={12} md>
          <a
            href="https://www.gosuslugi.ru/31341/2/info"
            className="gosuslugi__item"
          >
            Запись в детский сад
          </a>
          <a
            href="https://www.gosuslugi.ru/group/school_enrollment"
            className="gosuslugi__item"
          >
            Запись в школу
          </a>
          <a href="https://www.gosuslugi.ru/355998" className="gosuslugi__item">
            Запись в кружки и секции
          </a>
          <a
            href="https://www.gosuslugi.ru/281395/1/info"
            className="gosuslugi__item"
          >
            Детские путевки с господдержкой
          </a>
        </Grid>
      </Grid>
    </div>
  );
};

import axios from 'axios';
import { COOKIE_NAMES } from '@constants/config';
import { getCookie } from '@lib/cookie';
import {
  DirectoryEntityResponse,
  DocumentEntityResponse,
  DocumentLinkEntityResponse,
} from '@api/types/documents';
import { convertObjectFieldsToLowerCase } from '@utils/convertObjectFieldsToLowerCase';
import {
  LINK_LIFE_TIME_TYPE,
  DOCUMENT_ACCESS_TYPE,
  DOCUMENT_TYPE,
  DOCUMENT_MIME_TYPE,
} from '@declarations/enum/documents';

const instance = axios.create({
  baseURL: '/docsapi',
});

instance.interceptors.request.use((config) => {
  const accessToken = getCookie(COOKIE_NAMES.ACCESS_TOKEN);
  if (config.headers && accessToken) {
    config.headers['Authorization'] = accessToken;
  }
  return config;
});

export const documentsAPI = {
  async getAuthToken() {
    return instance
      .get<{
        Response: {
          Data: { Token: string };
        };
      }>('/api/v1/OAuth20/LoginByToken')
      .then((res) => res.data?.Response?.Data?.Token);
  },

  async geRootDirectories() {
    return instance
      .get<DirectoryEntityResponse[]>('/api/v1/DocumentDirectory/Get')
      .then(
        (res) =>
          convertObjectFieldsToLowerCase(res.data) as DirectoryEntityResponse[],
      );
  },

  async getDirectoryByID(folderId: number) {
    return instance
      .get<DirectoryEntityResponse[]>('/api/v1/DocumentDirectory/Get', {
        params: {
          id: folderId,
        },
      })
      .then(
        (res) =>
          convertObjectFieldsToLowerCase(res.data) as DirectoryEntityResponse[],
      );
  },

  async downloadDocument(id: number): Promise<File> {
    return instance
      .get<File>(`/api/v1/Documents/Download`, {
        params: {
          id,
        },
        responseType: 'blob',
      })
      .then((res) => res.data);
  },

  async generateLinkToFile(fileId: number) {
    return instance
      .get<DocumentLinkEntityResponse>('/api/v1/Link/Generate', {
        params: {
          id: fileId,
          lifetime: LINK_LIFE_TIME_TYPE.SINGLE_CALL,
          accessType: DOCUMENT_ACCESS_TYPE.WRITE,
          type: DOCUMENT_TYPE.DOCUMENT,
        },
      })
      .then(
        (res) =>
          convertObjectFieldsToLowerCase(
            res.data,
          ) as DocumentLinkEntityResponse,
      );
  },

  // Ответ не содержит все поля DirectoryEntityResponse и содержит доп поля
  async createDirectory(data: { parentId: number; directoryName: string }) {
    const { parentId, directoryName } = data;
    return instance
      .post<{ id: number }>('api/v1/DocumentDirectory/AddSubDirectory', {
        Description: 'Новая папка',
        IconId: null,
        Name: directoryName,
        Order: 100,
        ParentId: parentId,
        RoleIds: [],
        Type: 'Default',
        UserIds: [],
      })
      .then(
        (res) => convertObjectFieldsToLowerCase(res.data) as { id: number },
      );
  },

  async createDocument(data: {
    folderId: number;
    documentMimeType: DOCUMENT_MIME_TYPE;
    fileName: string;
  }): Promise<DocumentEntityResponse[]> {
    const { folderId, fileName, documentMimeType } = data;
    return instance
      .post<DocumentEntityResponse[]>(`/api/v1/Documents/Create`, {
        DirectoryId: folderId,
        MimeType: documentMimeType,
        Name: fileName,
      })
      .then(
        (res) =>
          convertObjectFieldsToLowerCase(res.data) as DocumentEntityResponse[],
      );
  },
};

import { AuthAccountReponse, UserRoleStatus } from '@redux/user/types';

import {
  AuthAccount,
  NextOfKinProfile,
  PupilProfile,
} from '@declarations/profile';
import { Person } from '@declarations/person';
import { NextOfKinRole, PupilRole } from '@declarations/role';
import { etdAxios } from './utils/axios';

import {
  NextOfKinStatusReason,
  PupilStatusReason,
} from '@declarations/enum/retireRoleStatusReason';
import { etdFetch, FETCH_METHOD } from '@api/utils/fetch/etd';

export const userApi = {
  getUserInfo: ({
    id,
    principal,
  }: Partial<{ id: number; principal: string }>) =>
    etdFetch<AuthAccountReponse>({
      path: 'AuthAccount',
      method: FETCH_METHOD.GET,
      isProtected: true,
      params: {
        _id: id,
        _include: '*',
        _offset: 0,
        _count: 100,
        principal,
      },
    }),
  getPupilByPrincipal: (principal: number) =>
    etdAxios.get<AuthAccountReponse, AuthAccountReponse>('/AuthAccount', {
      params: {
        isProtected: true,
        principal,
        _include: '*',
      },
    }),
  addAuthAccount: (data: AuthAccount) =>
    etdFetch<AuthAccount>({
      path: 'AuthAccount',
      method: FETCH_METHOD.POST,
      isProtected: true,
      body: data,
      headers: { Prefer: 'return=representation' },
    }),
  getPupilProfile: (id: number) =>
    etdAxios.get<PupilProfile, PupilProfile>(`/PupilProfile/${id}`, {
      params: {
        isProtected: true,
      },
    }),
  getEduProviderRole: (id: string) =>
    etdAxios.get<any, any>(`/EduProviderRole/${id}`, {
      headers: {
        // return created entity
        Prefer: 'return=representation',
      },
      params: {
        isProtected: true,
      },
    }),
  getPupilRole: (id: string) =>
    etdAxios.get<unknown, unknown>(`/PupilRole/${id}`, {
      params: {
        isProtected: true,
      },
    }),
  updateEduProviderRoleStatus: (data: any) =>
    etdFetch({
      path: `EduProviderRole/${data.id}`,
      method: FETCH_METHOD.PUT,
      isProtected: true,
      body: data,
    }),
  updatePupilRoleStatus: (
    roleData: PupilRole,
    newStatus: UserRoleStatus.RETIRED | UserRoleStatus.CONFIRMED,
    reason?: PupilStatusReason,
  ) => {
    const newRoleData: NextOfKinRole = JSON.parse(JSON.stringify(roleData));
    if (reason) {
      newRoleData.reason = reason;
    }
    newRoleData.status = newStatus;
    return etdFetch({
      path: `PupilRole/${newRoleData.id}`,
      method: FETCH_METHOD.PUT,
      isProtected: true,
      body: newRoleData,
    });
  },

  updateNextOfKinRoleStatus: (
    roleData: NextOfKinRole,
    newStatus: UserRoleStatus.RETIRED | UserRoleStatus.CONFIRMED,
    reason?: NextOfKinStatusReason,
  ) => {
    const newRoleData: NextOfKinRole = JSON.parse(JSON.stringify(roleData));
    if (reason) {
      newRoleData.reason = reason;
    }
    newRoleData.status = newStatus;
    return etdFetch<NextOfKinRole>({
      path: `NextOfKinRole/${newRoleData.id}`,
      method: FETCH_METHOD.PUT,
      isProtected: true,
      body: newRoleData,
    });
  },

  addEduProviderRole: (data: any) =>
    etdFetch({
      path: 'EduProviderRole',
      method: FETCH_METHOD.POST,
      isProtected: true,
      body: data,
      headers: { Prefer: 'return=representation' },
    }),
  addNextOfKinProfile: (data: NextOfKinProfile) =>
    etdFetch<NextOfKinProfile>({
      path: 'NextOfKinProfile',
      method: FETCH_METHOD.POST,
      isProtected: true,
      body: data,
      headers: { Prefer: 'return=representation' },
    }),
  addNextOfKinRole: (data: NextOfKinRole) =>
    etdFetch<NextOfKinRole>({
      path: 'NextOfKinRole',
      method: FETCH_METHOD.POST,
      isProtected: true,
      body: data,
      headers: { Prefer: 'return=representation' },
    }),
  getPerson: (id: string) =>
    etdAxios.get<Person, Person>(`/Person/${id}`, {
      params: { isProtected: true },
    }),
  addPupilProfile: (data: PupilProfile) =>
    etdFetch<PupilProfile>({
      path: 'PupilProfile',
      method: FETCH_METHOD.POST,
      isProtected: true,
      body: data,
      headers: { Prefer: 'return=representation' },
    }),
  addPupilRole: (data: PupilRole) =>
    etdFetch<PupilRole>({
      path: 'PupilRole',
      method: FETCH_METHOD.POST,
      isProtected: true,
      body: data,
      headers: { Prefer: 'return=representation' },
    }),
};

export const getPersonList = async (ids: string[]) => {
  const response = await Promise.allSettled(
    ids.map((id) => userApi.getPerson(id)),
  );
  const personList: Person[] = [];
  response.map((responseItem) => {
    if (responseItem.status === 'fulfilled') {
      personList.push(responseItem.value);
    }
  });
  return personList;
};

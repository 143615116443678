import { useRef, useState } from 'react';
import { Swiper } from 'swiper/react';
import ArrowRight from '@assets/icons/slider-arrow-right.svg';
import * as React from 'react';

export const SwapperCard = ({ children }: any) => {
  const prevRef = useRef<HTMLInputElement>(null);
  const nextRef = useRef<HTMLInputElement>(null);
  const [isEnd, setIsEnd] = useState(false);
  const [isBegin, setIsBegin] = useState(false);

  return (
    <div className="library-widget">
      <div className="library-widget__slider">
        <Swiper
          navigation={{
            prevEl: prevRef.current!,
            nextEl: nextRef.current!,
          }}
          onInit={(swiper) => {
            // @ts-ignore
            swiper.params.navigation.prevEl = prevRef.current;
            // @ts-ignore
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.update();
          }}
          onSlideChange={(swiper) => {
            setIsEnd(swiper.isEnd);
            setIsBegin(swiper.isBeginning);
          }}
          onSwiper={(swiper) => {
            setIsEnd(swiper.isEnd);
            setIsBegin(swiper.isBeginning);
          }}
          spaceBetween={20}
          slidesPerView={1}
          breakpoints={{
            758: {
              slidesPerView: 6.5,
            },
          }}
          className="smart__slider"
        >
          {children}
          <div ref={prevRef} />
          <div ref={nextRef} />
        </Swiper>
        <div
          onClick={() => prevRef?.current?.click()}
          className={`library-widget__arrow library-widget__arrow--prev ${
            isBegin ? 'library-widget__arrow--disable' : ''
          }`}
          style={{ backgroundImage: `url(${ArrowRight})` }}
        />
        <div
          onClick={() => nextRef?.current?.click()}
          className={`library-widget__arrow library-widget__arrow--next ${
            isEnd ? 'library-widget__arrow--disable' : ''
          }`}
          style={{ backgroundImage: `url(${ArrowRight})` }}
        />
      </div>
    </div>
  );
};

import * as React from 'react';
import { TextareaAutosize, TextareaAutosizeProps } from '@mui/material';

declare module '@mui/material/TextField' {
  // eslint-disable-next-line no-unused-vars
  interface TextFieldPropsSizeOverrides {
    large: true;
  }
}

type PropsType =
  | TextareaAutosizeProps
  | {
      size?: 'medium' | 'large' | 'small';
      name: string;
      id: string;
      style: any;
      className?: string;
    };

export const StyledTextarea: React.FC<PropsType> = ({
  className,
  style,
  ...rest
}) => {
  return (
    <TextareaAutosize
      className={`styled-textarea ${className || ''}`}
      style={style}
      {...rest}
    />
  );
};

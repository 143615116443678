import { educationAxios } from '../utils/axios';
import {
  EducationContent,
  EducationData,
  EducationDictionaryItem,
  EducationOrg,
  EducationPorfolioItem,
  EducationSections,
  EducationSectionsContent,
} from '@api/types/smart/education';

const educationApi = {
  getAllContent: () =>
    educationAxios.get<
      EducationData<EducationContent>,
      EducationData<EducationContent>
    >(
      `/external/pub/cu/getAll?publishToExternalSubsystem=true&publicationStatusIds=2`,
      {
        params: {
          isProtected: true,
        },
        withCredentials: false,
      },
    ),
  getSectionList: () =>
    educationAxios.get<
      EducationSections<EducationSectionsContent>,
      EducationSections<EducationSectionsContent>
    >(`/dict/external/sections`, { withCredentials: false }),
  getPortfolio: () =>
    educationAxios.get<EducationPorfolioItem[], EducationPorfolioItem[]>(
      '/external/pub/cu/getAll/showcase',
      {
        params: {
          isProtected: true,
        },
        withCredentials: false,
      },
    ),
  getDictionary: (name: string) =>
    educationAxios.get<
      EducationSections<EducationDictionaryItem>,
      EducationSections<EducationDictionaryItem>
    >(`/dict/external/${name}`, { withCredentials: false }),
  getOrganizations: () =>
    educationAxios.get<
      EducationSections<EducationOrg>,
      EducationSections<EducationOrg>
    >('/dict/external/organization/getAll', { withCredentials: false }),
};

export default educationApi;

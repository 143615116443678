import { Definitions, Entity } from '@declarations/common';
import { EduGroup } from '@declarations/eduGroup';
import { Organization } from '@declarations/organization';
import { Person } from '@declarations/person';
import {
  AdminProfile,
  AuthAccount,
  ContributorProfile,
  EduProviderProfile,
  NextOfKinProfile,
  PupilProfile,
} from '@declarations/profile';
import { PupilInfo } from '@declarations/pupil';
import {
  AccessRights,
  AdminRole,
  ContextType,
  ContributorRole,
  EduProviderRole,
  FunctionalRole,
  NextOfKinRole,
  PupilRole,
} from '@declarations/role';
import { TargetInfo } from '@declarations/user';
import { EduOrganizationGroup } from '@declarations/eduOrganizationGroup';

export interface UserState {
  isLoading: boolean;
  user: User | null;
  userRoleList?: UserRoleList | null;
  profiles: Definitions.Coding[];
  pupilInfo: PupilInfo | null;
  authAccount?: AuthAccount;
  history: UserHistory[];
  locations: UserLocation[] | null;
  targetInfo: TargetInfo | null;
  functionalRoles: FunctionalRole[];
  contentAccesses: ContentAccesses | null;
}

export type ContentAccesses = { [key: string]: 'all' | 'onlyOwnArea' };

export type UserLocation = {
  code: string;
  display: string;
};

//TODO change this line
export type UserRoleList = any;

export type AccountEntry = Entity<
  | AuthAccount
  | NextOfKinProfile
  | NextOfKinRole
  | PupilProfile
  | PupilRole
  | AdminRole
  | AdminProfile
  | Organization
  | EduGroup
  | EduOrganizationGroup
  | Person
  | EduProviderRole
  | EduProviderProfile
  | ContributorRole
  | ContributorProfile
  | FunctionalRole
  | AccessRights
  | ContextType
>;

export type AuthAccountReponse = {
  entry: AccountEntry[];
};

export type User = Record<string, number | string | Record<string, any>>;

export enum UserRoleStatus {
  CREATED_BY_ADMIN = 'created-by-admin',
  CREATED_BY_SELF = 'created-by-self',
  CONFIRMED = 'confirmed',
  RETIRED = 'retired',
}

export enum EUserRole {
  ADMIN = 'ADMIN',
  TEACHER = 'TEACHER',
  PARENT = 'PARENT',
  NO_AUTH = 'NO_AUTH',
  STUDENT = 'STUDENT',
  // Участник ЦОС
  PARTICIPANT = 'PARTICIPANT',
}

export type UserRole =
  | EUserRole.ADMIN
  | EUserRole.TEACHER
  | EUserRole.PARENT
  | EUserRole.NO_AUTH
  | EUserRole.STUDENT
  | EUserRole.PARTICIPANT;

export interface UserInfo {
  fullName: string;
  initials: string;
  email: string;
  preferred_username: string;
  etdId: number;
}

export type UserHistory = {
  id: string;
  name: string;
  description: string;
  date?: string;
  personName?: Definitions.HumanName | null;
};

export type UserFunctionalRole =
  | 'AllContent'
  | 'NewsCRUD'
  | 'PoolsCRUD'
  | 'FaqCRUD'
  | 'LinksCRUD'
  | 'JournalCRUD'
  | 'DiaryCRUD';

export enum JuvenileStatus {
  CHILD = 'child', // до-14-лет
  TEENAGER = 'teenager', // от-14-до-18-лет
  ADULT = 'adult', // 18-лет-и-старше
}

export enum AuthAccountStatus {
  PENDING = 'pending',
  ACTIVE = 'active',
  BLOCKED = 'blocked',
  RETIRED = 'retired',
}

// Статус согласия на обработку персональных данных
export enum ConsentStatus {
  PENDING = 'pending', // ожидается
  ACTIVE = 'active', // дано
  WITHDRAWN = 'withdrawn', // отозвано
  CONFIRMED = 'confirmed', // подтверждено
}

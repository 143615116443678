import { Organization, OrganizationFilters } from '@declarations/organization';
import { ApiResponse, Entity } from '@declarations/common';
import { mainAxios } from './utils/axios';
import { ALL_ORGANIZATION_KEY } from '@components/common/Select/hooks/useCachedOrganisationsStateManager';
import { EduLevel } from '@declarations/eduLevel';

const SERVICE_NAME = 'api-news/api/v1';

export const organizationApi = {
  search: async (searchParams: OrganizationRequestFilters) => {
    const params: {
      [key: string]: string | boolean | number;
    } = {
      active: !!searchParams.active,
      _summary: true,
    };
    if (searchParams.name) {
      params['name:contains'] = searchParams.name;
    }
    if (searchParams.regionCode) {
      // TODO-achernyavets: фильтрация должна быть множественная
      params['address-state'] = searchParams.regionCode;
    }
    if (searchParams.inn) {
      params['inn:contains'] = searchParams.inn;
    }
    return await mainAxios.get<
      ApiResponse<Entity<Organization>>,
      ApiResponse<Entity<Organization>>
    >(`${SERVICE_NAME}/organizations/list`, {
      params,
    });
  },
  get: (id: string) =>
    mainAxios.get<Organization, Organization>(
      `${SERVICE_NAME}/organizations/${id}`,
    ),
  getEduLevelByParallelId: (id: number) =>
    mainAxios.get<EduLevel, EduLevel>(
      `${SERVICE_NAME}/getEduLevelByParallelId/${id}`,
    ),

  getEduLevelByParallelName: (name: string) =>
    mainAxios.get<EduLevel, EduLevel>(
      `${SERVICE_NAME}/getEduLevelByParallelName/${name}`,
    ),
};

export const getOrganizationRequest = async (
  filters: OrganizationFilters,
): Promise<Map<string, Organization[]>> => {
  const requestFilters: OrganizationRequestFilters = {
    name: filters.name,
    inn: filters.inn,
    active: true,
  };
  const organizations: Map<string, Organization[]> = new Map();

  // т.к. сейчас нельзя фильтровать ОО по нескольким регионам, то приходится делать несколько запросов
  if (filters.regionCode?.length) {
    const responseList = await Promise.allSettled(
      filters.regionCode.map((region) =>
        organizationApi.search({
          ...requestFilters,
          regionCode: region,
        }),
      ),
    );

    responseList?.forEach((response, index) => {
      if (!filters.regionCode) return;
      const currentRegion = filters.regionCode[index];
      if (response.status === 'fulfilled' && currentRegion) {
        organizations.set(
          String(currentRegion),
          response.value.entry?.map((entity) => entity.resource),
        );
      }
    });

    return organizations;
  }

  const response = await organizationApi.search(requestFilters);
  if (response) {
    organizations.set(
      ALL_ORGANIZATION_KEY,
      response.entry?.map((entity) => entity.resource),
    );
    return organizations;
  }
  return organizations;
};

type OrganizationRequestFilters = Omit<OrganizationFilters, 'regionCode'> & {
  regionCode?: number;
};

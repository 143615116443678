export const selectOptionsEdu = [
  {
    value: '',
    label: 'Все',
  },
  {
    value: 'noEdu',
    label: 'Без образования',
  },
  {
    value: 'midEdu',
    label: 'Среднее',
  },
  {
    value: 'midSpecEdu',
    label: 'Среднее специальное',
  },
  {
    value: 'highEdu',
    label: 'Высшее',
  },
  {
    value: 'moreHighEdu',
    label: 'Два и более высших',
  },
];
export const selectOptionsRegion = [
  {
    value: '',
    label: 'Все',
  },
  {
    value: 'msk',
    label: 'Москва',
  },
  {
    value: 'kzn',
    label: 'Казань',
  },
];
export const selectOptionsOrg = [
  {
    value: '',
    label: 'Все',
  },
  {
    value: 'MPR',
    label: 'Министерство просвещения Российской Федерации',
  },
  {
    value: 'MLR',
    label: 'Министерство образования Российской Федерации',
  },
  {
    value: 'MSR',
    label: 'Министерство спорта Российской Федерации',
  },
];
export const selectOptionsCategory = [
  {
    value: '',
    label: 'Все',
  },
  {
    value: 'org',
    label: 'Образовательные организации',
  },
  {
    value: 'exam',
    label: 'Подготовка к экзаменам',
  },
  {
    value: 'project',
    label: 'Конкурсы, олимпиады, проекты',
  },
  {
    value: 'library',
    label: 'Библиотеки бесплатной литературой',
  },
  {
    value: 'toor',
    label: 'Виртуальные экскурсии',
  },
  {
    value: 'other',
    label: 'Другие образовательные ресурсы',
  },
];

export enum FILTER_UPDATE_INITIATOR {
  SEARCH_LINE = 'SEARCH_LINE',
  URL_PARAMETERS = 'URL_PARAMETERS',
  SORT_FIELD = 'SORT_FIELD',
  CATEGORY_FIELD = 'CATEGORY_FIELD',
  PUBLICATION_DATE_FIELD = 'PUBLICATION_DATE_FIELD',
  PUBLICATION_DATE_RANGE_FIELD = 'PUBLICATION_DATE_RANGE_FIELD',
  CONTENT_TYPE_FIELD = 'CONTENT_TYPE_FIELD',
  SYSTEM_FIELD = 'SYSTEM_FIELD',
  LEVELS_OF_EDUCATION_FIELD = 'LEVELS_OF_EDUCATION_FIELD',
  REGION_FIELD = 'REGION_FIELD',
  EDU_ORGANIZATION_FIELD = 'EDU_ORGANIZATION_FIELD',

  RESET_ALL_FILTERS = 'RESET_ALL_FILTERS',
}

export const filtersAffectingTargetedSearch = {
  newsPage: [
    FILTER_UPDATE_INITIATOR.LEVELS_OF_EDUCATION_FIELD,
    FILTER_UPDATE_INITIATOR.REGION_FIELD,
    FILTER_UPDATE_INITIATOR.EDU_ORGANIZATION_FIELD,
    FILTER_UPDATE_INITIATOR.RESET_ALL_FILTERS,
  ],
  faqPage: [
    FILTER_UPDATE_INITIATOR.LEVELS_OF_EDUCATION_FIELD,
    FILTER_UPDATE_INITIATOR.REGION_FIELD,
    FILTER_UPDATE_INITIATOR.EDU_ORGANIZATION_FIELD,
    FILTER_UPDATE_INITIATOR.RESET_ALL_FILTERS,
  ],
  linksPage: [
    FILTER_UPDATE_INITIATOR.CATEGORY_FIELD,
    FILTER_UPDATE_INITIATOR.LEVELS_OF_EDUCATION_FIELD,
    FILTER_UPDATE_INITIATOR.REGION_FIELD,
    FILTER_UPDATE_INITIATOR.EDU_ORGANIZATION_FIELD,
    FILTER_UPDATE_INITIATOR.RESET_ALL_FILTERS,
  ],
};

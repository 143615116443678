import { VoidThunk } from '@redux/notifications';
import { setFetched, setFetching, setFetchingError } from '@redux/ui';
// import { getUserRegion } from '@lib/userInfo/getUserRegion';
import { setLoading, setSchedule } from '@redux/schedule';
import { mapFromGounnServer } from '@redux/schedule/utils';
import { scheduleAPI } from '@api/schedule';
import { getUserRegion } from '@lib/userInfo/getUserRegion';
import { isOfType } from '@declarations/common';
import { JournalError } from '@api/types/gounn';

export const fetchSchedule = (): VoidThunk => async (dispatch, getState) => {
  const requestName = 'fetchSchedule';
  const state = getState();
  const { user, schedule } = state;

  if (!user?.user) {
    return;
  }

  const esiaIdentifier = user.user.esiaIdentifier as string;
  const regionCode = getUserRegion(state.user?.locations);

  dispatch(setFetching(requestName));
  dispatch(setLoading(true));

  try {
    if (!regionCode) {
      throw Error('Регион не найден');
    }
    const response = await scheduleAPI.getJournal(
      new Date(schedule.startDate),
      new Date(schedule.endDate),
      esiaIdentifier,
      regionCode,
    );

    if (response && isOfType<JournalError>(response, 'issue')) {
      const errorList = response.issue?.reduce((list, issue) => {
        if (issue.severity === 'error') {
          list.push(issue.details);
        }
        return list;
      }, [] as string[]);
      throw Error(errorList.join(', '));
    }
    dispatch(setSchedule(mapFromGounnServer(response)));
    dispatch(setFetched(requestName));
  } catch (error) {
    console.error(error);
    dispatch(setFetchingError(requestName));
  } finally {
    dispatch(setLoading(false));
  }
};

import useMediaQuery from '@mui/material/useMediaQuery';
import { ServicesItem } from '@components/Header/HeaderServiceWidget/HeaderServiceWidget';
import { useMemo } from 'react';

const NUMBER_OF_ITEMS_IN_COLUMN = 6;
const NUMBER_OF_ITEMS_IN_COLUMN_FOR_TABLET = 7;

const useCalculationOfNumberOfColumns = (servicesItems: ServicesItem[]) => {
  const isTabletWidth = useMediaQuery('(max-width: 1024px)');
  const currentNumberOfItemsInColumn = useMemo(
    () =>
      isTabletWidth
        ? NUMBER_OF_ITEMS_IN_COLUMN_FOR_TABLET
        : NUMBER_OF_ITEMS_IN_COLUMN,
    [isTabletWidth],
  );

  const numberOfColumns = Math.ceil(
    servicesItems.length / currentNumberOfItemsInColumn,
  );

  const arrayNumberOfColumns = [];
  for (let i = 0; i < numberOfColumns; i++) {
    arrayNumberOfColumns.push(i);
  }

  return {
    arrayNumberOfColumns,
    currentNumberOfItemsInColumn,
  };
};

export { useCalculationOfNumberOfColumns };

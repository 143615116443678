import { UserFunctionalRole } from '@redux/user/types';

export const getContentCredentials = (
  functionRoleNames: UserFunctionalRole[],
) =>
  functionRoleNames?.reduce(
    (result, role) => {
      switch (role) {
        case 'AllContent':
          result = {
            news: true,
            faq: true,
            links: true,
            quiz: true,
            journal: true,
            diary: true,
            administration: true,
          };
          break;
        case 'FaqCRUD':
          result.faq = true;
          break;
        case 'LinksCRUD':
          result.links = true;
          break;
        case 'NewsCRUD':
          result.news = true;
          break;
        case 'PoolsCRUD':
          result.quiz = true;
          break;
        case 'JournalCRUD':
          result.journal = true;
          break;
        case 'DiaryCRUD':
          result.diary = true;
          break;
      }
      return result;
    },
    {
      news: false,
      faq: false,
      links: false,
      quiz: false,
      journal: false,
      diary: false,
      administration: false,
    },
  );

import {
  CSSProperties,
  MouseEvent,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { Box, Grid, Menu, MenuItem, Typography } from '@mui/material';
import Button from '@components/common/Button/Button';
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';
import {
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { RootState } from '@redux/store';
import { getActiveFilters } from '@redux/filters';
import { connect } from 'react-redux';
import CommonFilter from '@pages/Manager/CommonFilter/CommonFilter';
import { ToggleGroup } from '@components/common/StyledToggleButtonGroup/StyledToggleButtonGroup';
import { News, NewsStatus } from '@declarations/news';
import { newsAPI } from '@api/news';

import './NewsList.scss';
import { FileDto, Filter } from '@declarations/common';
import { IMAGE_BASE_URL } from '@constants/config';
import { generateLink, getNewItemName } from '@lib/common';
import { useSnackbar } from '@hooks/useSnackbar';
import { useHistory } from 'react-router-dom';
import Table from '@components/common/Table/Table';
import { ROUTES } from '@constants/routes';

const PER_PAGE_OPTIONS = [20, 40];

const ITEMS = [
  { value: '', label: 'Все' },
  { value: NewsStatus.PUBLISHED, label: 'Опубликованные' },
  { value: NewsStatus.DRAFT, label: 'Черновики' },
  { value: NewsStatus.ARCHIVED, label: 'Архив' },
];

const mapStateToProps = (state: RootState) => ({
  activeFilterQuery: getActiveFilters(state),
});

const columns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Новость',
    flex: 1,
    minWidth: 300,
    renderCell: (params: GridRenderCellParams<News>) => {
      let imageStyles: CSSProperties | undefined = undefined;
      const image: FileDto | undefined = params.row.files?.find(
        (file: FileDto) => file.id === params.row.img,
      );
      if (image) {
        imageStyles = {
          backgroundImage: `url(${IMAGE_BASE_URL}${image?.path})`,
          backgroundColor: 'inherit',
        };
      }

      return (
        <div className="news-list__title">
          <Box sx={{ mr: 2 }}>
            <div className="news-list__title-object" style={imageStyles} />
          </Box>
          <div>{params.row.title}</div>
        </div>
      );
    },
  },
  { field: 'target', headerName: 'Аудитория', minWidth: 200 },
  {
    field: 'date',
    headerName: 'Дата публикации',
    minWidth: 150,
    renderCell: (params: GridRenderCellParams<News>) => {
      const status = params.row.status;
      if (!params.row.date) {
        return '';
      }
      return status === NewsStatus.PUBLISHED ? (
        dayjs(params.row.date).format('DD.MM.YYYY HH:mm')
      ) : (
        <div className="news-list__status-label">
          {status === NewsStatus.DRAFT
            ? 'черновик'
            : `архив${
                params.row.date
                  ? `, ${new Date(params.row.date).toLocaleDateString('ru')}`
                  : ''
              }`}
        </div>
      );
    },
  },
  {
    field: 'region',
    headerName: 'Субъект РФ',
    minWidth: 150,
  },
  {
    field: 'eduLevels',
    headerName: 'Уровень образования',
  },
  {
    field: 'eduOrganizations',
    headerName: 'ОО',
  },
  {
    field: 'eduGroupOrganizations',
    headerName: 'Группы ОО',
  },
];

const NewsList = () => {
  const history = useHistory();
  const { addSnack } = useSnackbar();

  const [news, setNews] = useState<{ total: number; data: News[] }>({
    total: 0,
    data: [],
  });
  const [pagination, setPagination] = useState({
    pageSize: PER_PAGE_OPTIONS[0],
    currentPage: 1,
  });
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<Partial<Filter>>({
    // Намеренно сделано, т.к. в FAQ period = undefined
    period: [undefined, undefined],
  });
  const [selectedNews, setSelectedNews] = useState<News[]>([]);
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const newsNameList = useMemo(
    () => news.data.map((item) => item.title),
    [news.data],
  );

  const rows = useMemo(
    () =>
      news.data.map((item) => {
        return {
          ...item,
          target: item.targetAudience.map((region) => region.name).join(', '),
          date: item.statusDate,
          region: item.regions.map((region) => region.name).join(', '),
          eduLevels: item.eduLevels.map((level) => level.name).join(', '),
          // TODO-achernyavets, поправить когда с бэка будут приходить данные
          eduOrganizations: '',
          // TODO-achernyavets, поправить когда с бэка будут приходить данные
          eduGroupOrganizations: '',
        };
      }),
    [news.data],
  );

  const handlePageChange = useCallback((page: number) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: page + 1,
    }));
  }, []);

  const handlePageSizeChange = useCallback((pageSize: number) => {
    setPagination((prev) => ({
      ...prev,
      pageSize,
    }));
  }, []);

  const handleContextMenu = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX - 2,
              mouseY: event.clientY - 4,
            }
          : null,
      );
    },
    [contextMenu],
  );

  const onContextMenu = useCallback(
    (e: any) => {
      if (e.type === 'contextmenu') {
        e.preventDefault();
        const closestParent = e?.target?.closest('div[data-id]');
        if (closestParent && selectedNews.length === 0) {
          const id = closestParent.dataset?.id;
          if (id) {
            const newsItem = news.data.find((item) => item.id == id);
            newsItem && setSelectedNews([newsItem]);
          }
        }
        handleContextMenu(e);
      }
    },
    [news.data, handleContextMenu, selectedNews],
  );

  const handleClose = useCallback(() => {
    setSelectedNews([]);
    setContextMenu(null);
  }, []);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const news = await newsAPI.search(pagination, filters);
      setNews({
        total: news?.totalElements || 0,
        data: news?.content || [],
      });
    } catch (err) {
      console.error(err);
      addSnack('Ошибка загрузки данных', 'error');
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [pagination, filters]);

  const handleUnpublish = useCallback(async () => {
    if (selectedNews.length) {
      try {
        const response = await Promise.allSettled(
          selectedNews.map((newsItem) =>
            newsAPI.changeStatus(newsItem.id || 0, NewsStatus.ARCHIVED),
          ),
        );
        if (response.some(({ status }) => status === 'rejected')) {
          if (response.every(({ status }) => status === 'rejected')) {
            throw new Error('Some news not updated');
          }
          addSnack('Новости сняты с публикации частично', 'error');
        } else {
          let message = 'Новость снята с публикации';
          if (selectedNews.length > 1) {
            message = 'Новости сняты с публикации';
          }
          addSnack(message, 'success');
        }
        await loadData();
      } catch (error) {
        console.error(error);
        let message = 'Не удалось снять новость с публикации';
        if (selectedNews.length > 1) {
          message = 'Не удалось снять новости с публикации';
        }
        addSnack(message, 'error');
      }
    }
    handleClose();
  }, [selectedNews, handleClose, loadData, addSnack]);

  const handleView = useCallback(() => {
    if (selectedNews.length) {
      selectedNews.forEach((newsItem) => {
        if (newsItem.id) {
          const link = generateLink(ROUTES.newsItem, { id: newsItem.id });
          window.open(link);
        }
      });
    }
    handleClose();
  }, [handleClose, selectedNews]);

  const handleCopy = useCallback(async () => {
    if (selectedNews.length) {
      try {
        const response = await Promise.allSettled(
          selectedNews.map((newsItem) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { id, ...newNewsItem } = newsItem;
            newNewsItem.title = getNewItemName(newsNameList, newNewsItem.title);
            newNewsItem.status = NewsStatus.DRAFT;
            return newsAPI.add(newNewsItem);
          }),
        );
        if (response.some(({ status }) => status === 'rejected')) {
          if (response.every(({ status }) => status === 'rejected')) {
            throw new Error('Some news not copied');
          }
          addSnack('Новости скопированы частично', 'error');
        } else {
          let message = 'Новость скопирована';
          if (selectedNews.length > 1) {
            message = 'Новости скопированы';
          }
          addSnack(message, 'success');
        }
        await loadData();
      } catch (error) {
        console.error(error);
        let message = 'Ошибка при копировании новости';
        if (selectedNews.length > 1) {
          message = 'Ошибка при копировании новостей';
        }
        addSnack(message, 'error');
      }
      await loadData();
    }
    handleClose();
  }, [selectedNews, handleClose, newsNameList, loadData, addSnack]);

  const handleRemove = useCallback(async () => {
    if (selectedNews) {
      try {
        await newsAPI.remove(selectedNews.map((newsItem) => newsItem.id || 0));

        let message = 'Новость удалена';
        if (selectedNews.length > 1) {
          message = 'Новости удалены';
        }
        addSnack(message, 'success');

        await loadData();
      } catch (error) {
        console.error(error);
        let message = 'Ошибка удаления новости';
        if (selectedNews.length > 1) {
          message = 'Ошибка удаления новостей';
        }
        addSnack(message, 'error');
      }
    }
    handleClose();
  }, [selectedNews, handleClose, loadData, addSnack]);

  const handleEdit = useCallback(() => {
    if (selectedNews.length) {
      if (selectedNews.length > 1) {
        return addSnack('Массовое редактирование не поддерживается', 'error');
      }
      const selectedNewsItem = selectedNews[0];
      if (selectedNewsItem.id) {
        const link = generateLink(ROUTES.managerNewsItem, {
          id: selectedNewsItem.id,
        });
        history.push(link);
      }
    } else {
      addSnack('Необходимо выбрать новость для редактирования', 'error');
    }
  }, [history, selectedNews, addSnack]);

  const contextMenuItems = useMemo(() => {
    const groups = selectedNews?.reduce(
      (acc, newsItem) => {
        switch (newsItem.status) {
          case NewsStatus.DRAFT:
            acc.draft++;
            break;
          case NewsStatus.PUBLISHED:
            acc.published++;
            break;
          case NewsStatus.ARCHIVED:
            acc.archived++;
            break;
        }
        if (newsItem.hasPrivs) {
          acc.hasCredentials++;
        }
        return acc;
      },
      {
        draft: 0,
        published: 0,
        archived: 0,
        hasCredentials: 0,
      },
    );

    const hasCredentials = selectedNews.length === groups.hasCredentials;

    if (!groups.archived && !groups.draft && !groups.published) {
      return [];
    }

    const menuItems: JSX.Element[] = [];
    const isPublished = groups.archived === 0 && groups.draft === 0;
    if (isPublished) {
      menuItems.push(
        <MenuItem key="view" onClick={handleView}>
          Просмотр
        </MenuItem>,
      );
    }
    menuItems.push(
      <MenuItem key="copy" onClick={handleCopy}>
        Скопировать
      </MenuItem>,
    );

    if (groups.archived === 0 && groups.published === 0 && hasCredentials) {
      if (selectedNews.length === 1) {
        menuItems.push(
          <MenuItem key="edit" onClick={handleEdit}>
            Изменить
          </MenuItem>,
        );
      }
      menuItems.push(
        <MenuItem key="delete" onClick={handleRemove}>
          Удалить
        </MenuItem>,
      );
    }

    if (isPublished && hasCredentials) {
      menuItems.push(
        <MenuItem key="unpublish" onClick={handleUnpublish}>
          Снять с публикации
        </MenuItem>,
      );
    }

    return menuItems;
  }, [
    selectedNews,
    handleCopy,
    handleEdit,
    handleRemove,
    handleUnpublish,
    handleView,
  ]);

  const handleRowSelect = useCallback(
    (selectionModel: GridSelectionModel) => {
      setSelectedNews(
        news.data?.filter((newsItem) =>
          selectionModel.includes(newsItem.id || 0),
        ),
      );
    },
    [news.data],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {contextMenuItems}
      </Menu>
      <Grid container>
        <Grid item xs alignItems="center">
          <Typography variant="h2" sx={{ fontWeight: '500', fontSize: '18px' }}>
            Новости
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="first"
            to={ROUTES.managerNewsAdd}
            startIcon={<PlusIcon />}
          >
            Добавить
          </Button>
        </Grid>
      </Grid>
      <div style={{ margin: '20px 0' }}>
        <Grid container columnSpacing={2}>
          <Grid item xs="auto">
            <ToggleGroup
              items={ITEMS}
              value={filters.status || ''}
              handleChange={(event: any, value: string) => {
                setFilters((prev) => ({
                  ...prev,
                  status: value || undefined,
                }));
              }}
            />
          </Grid>
          <Grid item xs flexGrow={1}>
            <CommonFilter onChange={setFilters} />
          </Grid>
        </Grid>
      </div>
      <div
        style={{ height: 'auto', width: '100%' }}
        onContextMenu={onContextMenu}
      >
        <Table
          rows={rows}
          columns={columns}
          handleRowSelect={handleRowSelect}
          rowCount={news.total}
          page={pagination.currentPage - 1}
          pageSize={pagination.pageSize}
          loading={loading}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(NewsList);

import { Option } from '@components/common/Select/types';

export const DEFAULT_SETTINGS: SettingsFormType = {
  audience: [],
  educationLevel: [],
  region: [],
  oo: [],
  ooGroup: [],
  parallel: [],
  forWhom: 'all',
  period: [new Date(), new Date()],
};

type GENDERS = 'all' | 'FEMALE' | 'MALE';

export type SettingsFormType = {
  audience: (string | number)[];
  educationLevel: (number | string)[];
  region: Option[];
  oo: string[];
  ooGroup: string[];
  parallel: (string | number)[];
  forWhom: GENDERS;
  period: [Date | undefined, Date | undefined];
};

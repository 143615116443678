import { Journal } from '@declarations/journal';
import { mainAxios } from './utils/axios';

const SERVICE_NAME = 'api-news/api/v1/journal';

export const journalAPI = {
  getAll: () =>
    mainAxios.get<Journal[], Journal[]>(SERVICE_NAME, {
      params: { isProtected: true },
    }),
  add: (data: Journal) =>
    mainAxios.post<Journal, Journal>(SERVICE_NAME, data, {
      params: { isProtected: true },
    }),
  update: (data: Journal) =>
    mainAxios.put<Journal, Journal>(
      `${SERVICE_NAME}/${data.id}`,
      {
        ...data,
      },
      {
        params: { isProtected: true },
      },
    ),
  getByRegionCode: (id: string) =>
    mainAxios.get<Journal, Journal>(`${SERVICE_NAME}/${id}`, {
      params: { isProtected: true },
    }),
};

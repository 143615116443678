import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import PickersDay, { PickersDayProps } from '@mui/lab/PickersDay';
import { DatePicker } from '@mui/lab';

import './styles.scss';
import { ReactComponent as CalendarIcon } from '@assets/icons/ico-calendar.svg';
import Button from '../Button/Button';
import { getWeekStartAndEnd } from '@lib/date/date';
import { CustomPickersDay } from './components/CustomPickersDay';
import { getWeekCaption } from './utils/getWeekCaption';

dayjs.extend(isBetween);

type Props = {
  value: [Date, Date];
  onChange: (value: [Date, Date]) => void;
};

export const WeekPicker = ({ value, onChange }: Props) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [week, setWeek] = useState<[Date, Date] | null>([
    dayjs().startOf('week').toDate(),
    dayjs().endOf('week').toDate(),
  ]);

  const pickerRef = useRef(null);
  const inputAdopmentRef = useRef<HTMLDivElement>(null);

  const handleSelectDate: PickersDayProps<Date>['onDaySelect'] = (day) => {
    onChange(getWeekStartAndEnd(day));
  };

  const renderWeekPickerDay = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>,
  ) => {
    if (!week) {
      return <PickersDay {...pickersDayProps} />;
    }

    const startWeek = week[0];
    const endWeek = week[1];

    const isFirstDay = dayjs(date).isSame(startWeek, 'day');
    const isLastDay = dayjs(date).isSame(endWeek, 'day');
    const dayIsBetween =
      isFirstDay || isLastDay || dayjs(date).isBetween(startWeek, endWeek);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
        onDaySelect={handleSelectDate}
      />
    );
  };

  useEffect(() => {
    if (value) {
      setSelectedDate(value[0]);
      setWeek(value);
    } else {
      setSelectedDate(null);
      setWeek(null);
    }
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div ref={pickerRef}>
        <DatePicker
          value={selectedDate}
          onChange={(newValue) => {
            setSelectedDate(newValue);
          }}
          showDaysOutsideCurrentMonth
          renderDay={renderWeekPickerDay}
          InputAdornmentProps={{
            ref: inputAdopmentRef,
          }}
          renderInput={(params) => (
            <div ref={params.inputRef}>
              <Button
                variant="fourth"
                sx={{
                  marginLeft: { xs: 0, md: 4 },
                  marginRight: { xs: 1, md: 0 },
                  padding: '10px',
                  height: '44px',
                  minWidth: '44px',
                  fontWeight: '400',
                  width: 230,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                onClick={() => {
                  /**
                   * Метод кривой, но другого способа не нашлось как обратиться
                   * к inputAdopment для открытия календаря
                   * */
                  inputAdopmentRef?.current?.querySelector('button')?.click();
                }}
              >
                {getWeekCaption(week)}
                <div
                  style={{
                    display: 'none',
                  }}
                >
                  {params.InputProps?.endAdornment}
                </div>
                <CalendarIcon />
              </Button>
            </div>
          )}
          disableCloseOnSelect={false}
        />
      </div>
    </LocalizationProvider>
  );
};

import { connect } from 'react-redux';
import { RootState } from '@redux/store';
import { FaqListPage } from './FaqListPage';
import { getFaqs } from '@redux/faq';
import { getFetchStatus } from '@redux/ui';
import { getActiveFilters } from '@redux/filters';

const mapStateToProps = (state: RootState) => ({
  activeFilterQuery: getActiveFilters(state),
  faqs: getFaqs(state),
  faqFetchingStatus: getFetchStatus(state, 'fetchFaqs'),
});

export default connect(mapStateToProps)(FaqListPage);

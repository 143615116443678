import { Avatar, AvatarProps } from '@mui/material';
import { forwardRef, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  onClick?: AvatarProps['onClick'];
  invert?: boolean;
};

export const UserAvatar = forwardRef(
  ({ children, onClick, invert }: Props, ref: any) => {
    return (
      <Avatar
        ref={ref}
        sx={{
          bgcolor: invert ? '#0C1524' : '#F2F4F7',
          color: invert ? '#FFFFFF' : '#2C3038',
          fontSize: 15,
          fontWeight: 500,
        }}
        variant="circular"
        onClick={onClick}
      >
        {children}
      </Avatar>
    );
  },
);

UserAvatar.displayName = 'UserAvatar';

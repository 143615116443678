import { forwardRef } from 'react';
// @ts-ignore definitions for react-files not exists
import Files from 'react-files';
import cn from 'classnames';

import { ReactComponent as ImageIcon } from '@assets/icons/ico-image.svg';
import { ReactFilesError, ReactFilesFile } from './types';
import './style.scss';
import { Box, Button, Stack } from '@mui/material';

import { ReactComponent as IconTrash } from '@assets/icons/trash.svg';
import { FileDto, isOfType } from '@declarations/common';
import { IMAGE_BASE_URL } from '@constants/config';
import { useSnackbar } from '@hooks/useSnackbar';

type Props = {
  maxFileSize: number;
  maxFilesCount: number;
  onChange: (files?: ReactFilesFile[]) => void;
  accepts?: string[];
  value?: (ReactFilesFile | FileDto)[];
};

const FileUpload = forwardRef<any, Props>(
  (
    { maxFileSize, maxFilesCount, onChange, accepts, value }: Props,
    filesRef,
  ) => {
    const { addSnack } = useSnackbar();

    const filesOnError = (
      error: ReactFilesError,
      maxSize: number,
      maxCount: number,
    ) => {
      const message = getErrorText(error, maxSize, maxCount);
      if (message) {
        addSnack(message, 'error');
      }
    };
    return (
      <>
        {value &&
          value.length > 0 &&
          value.map((item) => {
            const fileUrl = item
              ? isOfType<FileDto>(item, 'originalFileName')
                ? `${IMAGE_BASE_URL}${item.path}`
                : item.preview.url
              : '';
            return (
              <div key={item.id}>
                <img src={fileUrl} style={{ width: '100%' }} />
                <Stack
                  spacing={2}
                  direction="row"
                  className="file-upload__btn-group"
                >
                  <Button
                    variant="first"
                    onClick={() => {
                      // @ts-ignore
                      filesRef?.current?.removeFile(item);
                      onChange(undefined);
                      // @ts-ignore
                      filesRef?.current?.openFileChooser();
                    }}
                  >
                    Загрузить новое
                  </Button>
                  <Button
                    variant="outlined"
                    className="file-upload__trash-btn"
                    startIcon={
                      <IconTrash className="file-upload__trash-icon" />
                    }
                    onClick={() => {
                      // @ts-ignore
                      filesRef?.current?.removeFile(item);
                      onChange(undefined);
                    }}
                  >
                    Удалить
                  </Button>
                </Stack>
              </div>
            );
          })}
        <Files
          ref={filesRef}
          onChange={(files: ReactFilesFile[]) => {
            // setFiles(files);
            onChange(files);
          }}
          onError={(error: ReactFilesError) =>
            filesOnError(error, maxFileSize, maxFilesCount)
          }
          accepts={accepts}
          multiple={maxFilesCount > 1}
          maxFiles={maxFilesCount}
          maxFileSize={convertToBytes(maxFileSize)}
          minFileSize={1}
          className={cn({
            'files-dropzone': true,
            'files-dropzone--hidden': !!value && value.length > 0,
          })}
        >
          <div className="file-upload">
            <div className="file-upload__title">
              Перенесите изображение в эту область или нажмите
            </div>
            <Box sx={{ mt: 3 }}>
              <div className="file-upload__btn">
                <ImageIcon /> Загрузить изображение
              </div>
            </Box>
            <Box sx={{ mt: 2 }}>
              <div className="file-upload__description">
                JPG, PNG не более 1600х1200, {maxFileSize} МБ
              </div>
            </Box>
          </div>
        </Files>
      </>
    );
  },
);

FileUpload.displayName = 'FileUpload';

export default FileUpload;

export function convertToBytes(megabytes: number): number {
  return megabytes * 1048576;
}

export const getErrorText = (
  error: ReactFilesError,
  maxFileSize: number,
  maxFileCount: number,
) => {
  let message = undefined;

  // Код ошибки взят из документации библиотеки https://github.com/mother/react-files#props
  if (error.code === 2) {
    message = `Размер файла не должен превышать ${maxFileSize}мб`;
  } else if (error.code === 3) {
    message = 'Файл не должен быть пустой';
  } else if (error.code === 4) {
    message = `Не больше ${maxFileCount} файлов`;
  }

  return message;
};

import { RootState } from '../store';
import { Notification } from './types';

const notifications = (state: RootState) => state.notifications;

export const getNotificationList = (state: RootState) =>
  notifications(state).notifications;

export const getUnreadNotifications = (state: RootState) =>
  notifications(state).unreadNotifications.data;

export const getTotalUnreadNotifications = (state: RootState) =>
  notifications(state).unreadNotifications.total;

export const getNotificationById = (state: RootState, id: string) => {
  const notificationList = getNotificationList(state).data;
  const notificationItem = notificationList?.filter(
    (notification: Notification) => notification.id === Number(id),
  );
  return notificationItem[0];
};

import { isOfType } from '@declarations/common';
import dayjs from 'dayjs';
import { GounnResponse, JournalError } from './types/gounn';
import { journalAxios } from './utils/axios';

const SERVICE_NAME = 'api-news/api/v1';

export const scheduleAPI = {
  getJournal: async (
    startDate: Date,
    endDate: Date,
    esiaId: string,
    regionCode: string,
  ) => {
    try {
      const response = await journalAxios.get<
        GounnResponse | JournalError,
        GounnResponse | JournalError
      >(`${SERVICE_NAME}/journal/esia/${esiaId}/${regionCode}`, {
        params: {
          isProtected: true,
          start: dayjs(startDate).format('YYYY-MM-DD'),
          end: dayjs(endDate).format('YYYY-MM-DD'),
        },
      });

      if (isOfType<JournalError>(response, 'issue')) {
        const errorList = getJournalError(response);
        throw Error(errorList);
      }
      return response;
    } catch (err) {
      const errorList = getJournalError(err);
      throw Error(errorList);
    }
  },
};

const getJournalError = (err: any) => {
  if (err.response?.data) {
    const error = err.response?.data;
    if (isOfType<JournalError>(error, 'issue')) {
      const errorList = error.issue?.reduce((list, issue) => {
        if (issue.severity === 'error') {
          list.push(issue.details);
        }
        return list;
      }, [] as string[]);
      return errorList.join(', ');
    } else if (isOfType<string>(error, 'toLowerCase')) {
      return error;
    }
  }

  return 'Произошла ошибка';
};

import { useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { News, NewsViewType } from '@declarations/news';
import { FileDto, isOfType } from '@declarations/common';
import { IMAGE_BASE_URL } from '@constants/config';
import { generateLink } from '@lib/common';
import { ROUTES } from '@constants/routes';

type Props = {
  data: News;
  view: NewsViewType;
};

export const NewsCard = ({ data, view }: Props) => {
  const imagePath = useMemo(() => {
    const file = data.files.find((file) => file.id === data.img);
    if (file && isOfType<FileDto>(file, 'originalFileName')) {
      return `${IMAGE_BASE_URL}${file.path}`;
    }
    return '';
  }, [data.files, data.img]);

  const link = useMemo(
    () => (data.id ? generateLink(ROUTES.newsItem, { id: data.id }) : ''),
    [data.id],
  );

  if (view === 'list') {
    return (
      <Grid
        container
        xs={12}
        sx={{
          boxShadow: '0px -1px 0px #E5E5E5',
          pt: 2,
          position: 'relative',
        }}
      >
        <Link to={link} className="news-item__link" />
        <Grid item sx={{ flex: 1 }}>
          <Grid
            item
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant={'additionally' as 'h1'}
              sx={{
                fontWeight: 500,
                fontSize: {
                  sx: 16,
                  lg: 18,
                },
                mb: 1,
              }}
            >
              {data.title}
            </Typography>
            {data.statusDate && (
              <Typography variant="caption" sx={{ color: '#626B7A', mb: 1 }}>
                {dayjs(data.statusDate).format('H:mm, D MMMM YYYY')}
              </Typography>
            )}
            {data.regions?.length > 0 && (
              <div className="news-item__region">
                {data.regions.map((region) => region.name).join(', ')}
              </div>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            width: {
              xs: 64,
              md: 100,
            },
            height: {
              xs: 64,
              md: 100,
            },
            ml: 2,
            backgroundColor: '#F2F4F7',
            backgroundImage: `url(${imagePath})`,
            borderRadius: '4px',
            backgroundSize: 'contain',
          }}
        ></Grid>
      </Grid>
    );
  }

  return (
    <div className="news-item">
      <Link to={link} className="news-item__link" />
      <div className="news-item__object">
        <div
          className="news-item__image"
          style={{ backgroundImage: `url(${imagePath})` }}
        />
      </div>
      <div className="news-item__body">
        <div className="news-item__text">
          <Typography variant={'additionally' as 'h1'}>{data.title}</Typography>
        </div>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          {data.statusDate && (
            <Typography variant="caption" sx={{ color: '#626B7A' }}>
              {dayjs(data.statusDate).format('H:mm, D MMMM YYYY')}
            </Typography>
          )}
          {data.regions?.length > 0 && (
            <Box className="news-item__region" sx={{ mt: 1 }}>
              {data.regions.map((region) => region.name).join(', ')}
            </Box>
          )}
        </Box>
      </div>
    </div>
  );
};

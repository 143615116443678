import { UserRoleList } from '@redux/user/types';

export const getBirthday = (req: UserRoleList) => {
  if (req) {
    const userResponse = req?.entry;
    const userPerson = userResponse && userResponse[1];
    return userPerson?.resource.birthDate;
  } else {
    return '-';
  }
};

export default getBirthday;

import { useEffect } from 'react';

export const FIXED_CLASSNAME = 'fixed';

export const useFixBody = (enabled = true) => {
  useEffect(() => {
    if (enabled) {
      const { classList } = document.body;
      if (classList.contains(FIXED_CLASSNAME)) {
        return;
      }
      classList.add(FIXED_CLASSNAME);

      return () => {
        classList.remove(FIXED_CLASSNAME);
      };
    }
  }, [enabled]);
};

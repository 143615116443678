import { styled, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FC } from 'react';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: '#F2F4F7',
  '& .MuiToggleButtonGroup-grouped': {
    margin: '4px',
    border: 0,
    textTransform: 'none',
    backgroundColor: 'transparent',
    padding: '7px 16px',
    '&.Mui-selected': {
      backgroundColor: '#FFF',
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

interface ToggleProps {
  items: Array<{ value: string | number; label: string }>;
  handleChange: (event: any, value: any) => void;
  value: string | number;
}

export const ToggleGroup: FC<ToggleProps> = ({
  items,
  handleChange,
  value,
}) => {
  return (
    <>
      <StyledToggleButtonGroup exclusive value={value} onChange={handleChange}>
        {items.map((item, index) => {
          return (
            <ToggleButton key={index} value={item.value}>
              {item.label}
            </ToggleButton>
          );
        })}
      </StyledToggleButtonGroup>
    </>
  );
};

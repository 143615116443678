import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
// import { RequestStatuses } from '@redux/ui/types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Title from '@components/common/Title/Title';
import Button from '@components/common/Button/Button';
import ScrollToTop from '@components/common/ScrollToTop/ScrollToTop';
import { Faq } from '@redux/faq/types';
import { ReactComponent as BackIcon } from '@assets/icons/back-icon.svg';
import { ReactComponent as ConfirmIcon } from '@assets/icons/confirm.svg';
import { Link } from 'react-router-dom';
import { setPublishDate } from '@lib/date/date';
import { faqApi } from '@api/faq';
import EmptyData from '@components/common/EmptyData/EmptyData';
import { ROUTES } from '@constants/routes';
import { useSnackbar } from '@hooks/useSnackbar';
import { Loader } from '@components/common/Loader/Loader';
import { UI_MESSAGE } from '@declarations/enum/uiMessage';

const FaqItemPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [faqDetail, setFaqDetail] = useState<Faq>();
  const [copied, setCopied] = useState<boolean>(false);
  const [isLoadFaq, setIsLoadFaq] = useState(!!id);
  const [errLoadFaq, setErrLoadFaq] = useState(false);
  const { addSnack } = useSnackbar();

  useEffect(() => {
    const loadData = async () => {
      try {
        if (id) {
          const data = await faqApi.getFaqById(id);
          setFaqDetail(data);
        }
      } catch (err) {
        setErrLoadFaq(true);
        addSnack(UI_MESSAGE.FAQ_NOT_AVAILABLE, 'error');
      } finally {
        setIsLoadFaq(false);
      }
    };
    loadData();
  }, [addSnack, id]);

  if (!faqDetail) {
    return <EmptyData />;
  }
  if (isLoadFaq) {
    return <Loader />;
  }

  if (errLoadFaq) {
    return <EmptyData />;
  }

  return (
    <>
      <ScrollToTop />
      <Grid
        container
        flexDirection="column"
        sx={{
          maxWidth: { xl: 952, lg: 894, md: 852, sm: '100%' },
          mt: { md: 4, lg: 6 },
        }}
      >
        <Grid item>
          <Link to={ROUTES.faqList} style={{ textDecoration: 'none' }}>
            <Typography
              variant="caption"
              sx={{
                fontWeight: 500,
                fontSize: '16px',
                color: '#007AFF',
                display: 'flex',
                alignItems: 'center',
                '& svg': {
                  marginRight: 1,
                },
              }}
            >
              <BackIcon />
              Вопросы и ответы
            </Typography>
          </Link>
        </Grid>
        <Grid
          item
          sx={{
            mt: { xs: 2.5, lg: 3 },
            p: { lg: 6, xs: 0 },
            pr: { lg: 12, xs: 0 },
            pb: { lg: 12, xs: 0 },
            border: { lg: '1px solid #DCE2EB', md: '0' },
            borderRadius: '8px',
          }}
          container
          flexDirection="column"
          spacing={0}
        >
          <Grid item>
            <Title>{faqDetail?.title}</Title>
          </Grid>
          <Grid item sx={{ mt: { lg: 3, xs: 2 } }}>
            <Typography
              variant={'body' as 'h1'}
              sx={{
                fontSize: { lg: 18, xs: 16 },
                lineHeight: 1.6,
                '& p': {
                  m: 0,
                },
              }}
            >
              {faqDetail?.body && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: faqDetail.body,
                  }}
                />
              )}
            </Typography>
          </Grid>
          {faqDetail?.startDate && (
            <Grid
              item
              sx={{
                mt: { md: 2 },
                display: {
                  xs: 'none',
                  md: 'block',
                },
              }}
            >
              <Typography variant="caption" sx={{ color: '#626B7A' }}>
                {setPublishDate(faqDetail.startDate)}
              </Typography>
            </Grid>
          )}
          <Grid
            item
            container
            sx={{
              mt: { md: 4, sm: 3 },
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: { xs: 'stretch', md: 'center' },
            }}
          >
            <Grid item xs={12} md="auto">
              <CopyToClipboard
                text={window.location.href}
                onCopy={() => setCopied(true)}
              >
                <Button variant="fourth" sx={{ width: 1 }}>
                  Поделиться
                </Button>
              </CopyToClipboard>
            </Grid>
            <Grid item>
              {copied && (
                <Typography
                  variant="caption"
                  sx={{
                    color: '#626B7A',
                    fontWeight: 500,
                    ml: { xs: 0, md: 1, lg: 2 },
                    mt: { xs: 2, md: 0 },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ConfirmIcon style={{ marginRight: '4px' }} />
                  Скопировано
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FaqItemPage;

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Option } from '@components/common/Select/types';
import { Person } from '@declarations/person';
import SingleSelect from '@components/common/Select/SingleSelect';
import { SingleValue } from 'react-select';
import { getPersonList } from '@api/user';
import { useSelector } from 'react-redux';
import { getUserPupilInfo } from '@redux/user';
import { parseReference } from '@lib/common';

type Props = {
  name: string;
  placeholder: string;
  id: string;
  onChange?: (person: Person) => void;
  personIds: string[];
  selected?: Person;
};

const PersonSelect = ({
  onChange,
  personIds,
  selected,
  ...otherProps
}: Props) => {
  const pupilInfo = useSelector(getUserPupilInfo);
  const [personList, setPersonList] = useState<Person[]>([]);
  const [options, setOptions] = useState<Option[]>([]);

  const value = useMemo(
    () => selected && mapPersonOption(selected),
    [selected],
  );

  const loadData = useCallback(async () => {
    let options: Option[] = [];
    try {
      let data: Person[] = [];

      const relatedPersonIds = personIds.filter((id) =>
        pupilInfo?.parentPerson?.related.find(
          (person) => parseReference(person.related.reference).id === id,
        ),
      );
      const unrelatedPerson = personIds.filter(
        (id) => !relatedPersonIds.find((relatedId) => relatedId === id),
      );

      if (relatedPersonIds.length) {
        data = await getPersonList(personIds);
      }

      if (unrelatedPerson.length && pupilInfo) {
        data = [
          ...data,
          ...Object.values(pupilInfo.person).filter((person) =>
            unrelatedPerson.some((id) => id === person.id),
          ),
        ];
      }

      setPersonList(data);
      options = data.map(mapPersonOption);
    } catch (err) {
      console.error('Error on load person list');
    }
    setOptions(options);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personIds]);

  const handleChange = useCallback(
    (option: SingleValue<Option>) => {
      const exists =
        option && personList.find((item) => item.id === option.value);
      if (exists && onChange) {
        onChange(exists);
      }
    },
    [onChange, personList],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <SingleSelect
      {...otherProps}
      options={options}
      onChange={handleChange}
      value={value}
    />
  );
};

export default PersonSelect;

const mapPersonOption = (person: Person): Option => {
  const label =
    [person.name.family, person.name.given, person.name.middle]
      .filter((item) => !!item)
      .join(' ') || '-';

  return {
    label,
    value: person.id || '0',
  };
};

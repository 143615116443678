import dayjs from 'dayjs';
import { Schedule, ScheduleState } from '@redux/schedule/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getWeekStartAndEnd } from '@lib/date/date';

const [weekStart, weekEnd] = getWeekStartAndEnd();

const initialState: ScheduleState = {
  schedule: null,
  startDate: weekStart.getTime(),
  endDate: dayjs(weekEnd).subtract(1, 'day').toDate().getTime(),
  loading: false,
};

const slice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setSchedule: (
      state: ScheduleState,
      action: PayloadAction<Schedule | null>,
    ) => ({
      ...state,
      schedule: action.payload,
    }),
    setPeriod: (
      state: ScheduleState,
      action: PayloadAction<{ startDate: number; endDate: number }>,
    ) => ({
      ...state,
      ...action.payload,
    }),
    setLoading: (state: ScheduleState, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
  },
});

export const { setSchedule, setPeriod, setLoading } = slice.actions;

export default slice.reducer;

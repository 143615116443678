import * as React from 'react';
import { Grid } from '@mui/material';
import './ServiceItem.scss';

interface Service {
  title: string;
  text: string;
  url: string;
  ico: string;
  color: string;
}

export const ServiceItem: React.FC<{ service: Service }> = ({ service }) => {
  return (
    <a href={service.url} className="service-item">
      <Grid container columnSpacing={3}>
        <Grid item xs="auto">
          <div
            className="service-item__ico"
            style={{
              backgroundImage: `url(${service.ico})`,
            }}
          />
        </Grid>
        <Grid item xs>
          <div className="service-item__title">{service.title}</div>
          <div className="service-item__text">{service.text}</div>
        </Grid>
      </Grid>
    </a>
  );
};

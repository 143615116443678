import { FC, useRef } from 'react';
import AsyncSelect, { AsyncProps } from 'react-select/async';
import { components } from 'react-select';

import './Select.scss';
import { FontStyles } from '../../../muiStyles/FontStyles';
import variables from '../../../muiStyles/variables';
import { Option } from './types';
import Button from '../Button/Button';

interface MultiAsyncSelectProps extends AsyncProps<Option, true, any> {
  placeholder: string;
  styleExtra?: any;
}

const MultiAsyncSelect: FC<MultiAsyncSelectProps> = ({
  placeholder,
  value,
  ...rest
}) => {
  const selectRef: any = useRef();

  const clearValue = (): void => {
    //@ts-ignore
    selectRef.current.clearValue();
  };

  const Menu: FC<any> = (props) => {
    return (
      <components.Menu {...props}>
        <div>{props.children}</div>
        {props.hasValue && (
          <div className="react-select__reset">
            <Button
              fullWidth
              onClick={() => {
                clearValue();
              }}
              sx={{
                justifyContent: 'flex-start',
                color: '#007AFF',
                pl: '10px',
              }}
            >
              Сбросить
            </Button>
          </div>
        )}
      </components.Menu>
    );
  };

  return (
    <AsyncSelect
      ref={selectRef}
      components={{ Menu }}
      isClearable={false}
      placeholder={placeholder}
      className="react-select-container"
      classNamePrefix="react-select"
      noOptionsMessage={() => 'Нет совпадений'}
      styles={style}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      value={value}
      loadingMessage={() => 'Загружаем данные...'}
      {...rest}
      isMulti
    />
  );
};

export default MultiAsyncSelect;

const boxShadowInFocus =
  '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 8px 16px rgba(0, 0, 0, 0.1)';

const style = {
  container: (base: any) => ({
    ...base,
    fontSize: 16,
    width: '100%',
  }),
  placeholder: (base: any) => ({
    ...base,
    ...FontStyles['text/p_small'],
    color: variables['$color-minor-text'],
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '95%',
  }),
  option: (base: any, state: any) => ({
    ...base,
    borderRadius: 4,
    backgroundColor: state.isSelected ? '#FFFFFF' : 'none',
    color: state.isSelected ? '#222222' : 'none',
    padding: '6px 12px 6px 10px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ':hover': {
      backgroundColor: '#F3F3F4',
    },
  }),
  control: (base: any, state: any) => ({
    ...base,
    borderRadius: 6,
    height: 44,
    borderColor: state.isSelected ? '#BBDEFD' : '#D8D8DD',
    transition: 'all .2s ease',
    ':hover': {
      borderColor: '#80ADFF',
    },
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: 8,
    paddingTop: 8,
    paddingBottom: 8,
    marginTop: 2,
    marginBottom: 0,
    zIndex: 2,
    width: '100%',
    border: '1px solid #D8D8DD',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.2s ease',
    ':hover': {
      boxShadow: boxShadowInFocus,
    },
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    backgroundColor: 'white',
  }),
  valueContainer: (base: any) => ({
    ...base,
    height: 44,
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'no-wrap',
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none',
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    borderRadius: 6,
  }),
  clearIndicator: (base: any) => ({
    ...base,
    paddingRight: 0,
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    transition: 'all .2s ease',
    color: '#222222',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none',
  }),
  multiValue: (base: any) => ({
    ...base,
    backgroundColor: 'transparent',
    padding: 0,
  }),
  multiValueRemove: (base: any) => ({
    ...base,
    display: 'none',
  }),
  multiValueLabel: (base: any) => ({
    ...base,
    backgroundColor: 'transparent',
    padding: 0,
    fontSize: '16px',
  }),
};

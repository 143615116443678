export const FontStyles = {
  h1: {
    fontStyle: 'normal',
    fontSize: '40px',
    lineHeight: '48px',
  },
  h2: {
    fontStyle: 'normal',
    fontSize: '30px',
    lineHeight: '42px',
  },
  h3: {
    fontStyle: 'normal',
    fontSize: '24px',
    lineHeight: '30px',
    fontWeight: 500,
  },
  h4: {
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '24px',
  },
  h5: {
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '24px',
  },
  h6: {
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
  },
  body: {
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '28px',
  },
  additionally: {
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
  },
  caption: {
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
  },
  titleCaption: {
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
  },
  button: {
    fontStyle: 'normal',
    fontSize: '15px',
    lineHeight: '24px',
  },
  'text/p_small': {
    textTransform: 'none',
    fontFamily: 'Golos, Roboto, Helvetica, Arial, sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
  },
};

import * as React from 'react';
import { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import './VideoWidget.scss';
import Title from '../../../components/common/Title/Title';
import SwiperCore, { Navigation } from 'swiper';
import ArrowRight from '../../../assets/icons/slider-arrow-right.svg';
import VideoIcon from '../../../assets/icons/ico-youtube.svg';
import { EUserRole, UserRole } from '@redux/user/types';
import { useSelector } from 'react-redux';
import { getUserRole } from '@redux/user';

export interface VideoItem {
  lesson: string;
  image?: string;
  color: string;
  title: string;
  url: string;
  tags: string[];
  target: UserRole[];
}

export const items: Array<VideoItem> = [
  {
    lesson: 'Урок 27',
    color: '#E9595B',
    title: 'Прыжки через короткую скакалку — техника выполнения',
    url: 'http://resh.edu.ru/subject/lesson/4771/start/172437/',
    tags: ['10 кл', 'Физическая культура'],
    target: [
      EUserRole.NO_AUTH,
      EUserRole.STUDENT,
      EUserRole.ADMIN,
      EUserRole.PARENT,
    ],
  },
  {
    lesson: 'Урок 12',
    color: '#22AC62',
    title: 'Строение и многообразие грибов',
    url: 'http://resh.edu.ru/subject/lesson/7853/start/268585/',
    tags: ['5 кл', 'Биология'],
    target: [
      EUserRole.NO_AUTH,
      EUserRole.STUDENT,
      EUserRole.ADMIN,
      EUserRole.PARENT,
    ],
  },
  {
    lesson: 'Урок 57',
    color: '#6D55CC',
    title: 'Значение и употребление глаголов в речи',
    url: 'http://resh.edu.ru/subject/lesson/4553/start/225425/',
    tags: ['4 кл', 'Русский язык'],
    target: [
      EUserRole.NO_AUTH,
      EUserRole.STUDENT,
      EUserRole.ADMIN,
      EUserRole.PARENT,
    ],
  },
  {
    lesson: 'Урок 11',
    color: '#299EF2',
    title: 'Растровая графика',
    url: 'http://resh.edu.ru/subject/lesson/7328/main/250649/',
    tags: ['7 кл', 'Информатика'],
    target: [
      EUserRole.NO_AUTH,
      EUserRole.STUDENT,
      EUserRole.ADMIN,
      EUserRole.PARENT,
    ],
  },
  {
    lesson: 'Урок 24',
    color: '#299EF2',
    title: 'Площадь круга. Площадь кругового сектора',
    url: 'http://resh.edu.ru/subject/lesson/2514/start/',
    tags: ['9 кл', 'Геометрия'],
    target: [
      EUserRole.NO_AUTH,
      EUserRole.STUDENT,
      EUserRole.ADMIN,
      EUserRole.PARENT,
    ],
  },
  {
    lesson: 'Доп. образование',
    color: '#F95F42',
    title: 'Перечень ресурсов доступных детям',
    url: 'https://resh.edu.ru/summer-education',
    tags: [],
    target: [EUserRole.TEACHER],
  },
  {
    lesson: 'Урок 23',
    color: '#22AC62',
    title:
      'Вес воздуха. Атмосферное давление. Измерение атмосферного давления. Опыт Торричелли',
    url: 'https://resh.edu.ru/subject/lesson/1535/start/',
    tags: ['7 кл', 'Физика'],
    target: [EUserRole.TEACHER],
  },
  {
    lesson: 'Урок 24',
    color: '#22AC62',
    title:
      'Приборы для измерения давления. Решение задач по теме «Атмосфера и атмосферное давление». Обобщение темы «Атмосфера и атмосферное давление»',
    url: 'https://resh.edu.ru/subject/lesson/2969/start/',
    tags: ['7 кл', 'Физика'],
    target: [EUserRole.TEACHER],
  },
  {
    lesson: 'Урок 26',
    color: '#22AC62',
    title: 'Закон Архимеда. Плавание тел. Воздухоплавание',
    url: 'https://resh.edu.ru/subject/lesson/2967/start/',
    tags: ['7 кл', 'Физика'],
    target: [EUserRole.TEACHER],
  },
  {
    lesson: 'Урок 27',
    color: '#22AC62',
    title:
      'Решение задач по теме «Закон Архимеда. Плавание тел». Обобщение по теме «Закон Архимеда. Плавание тел»',
    url: 'https://resh.edu.ru/subject/lesson/2966/start/',
    tags: ['7 кл', 'Физика'],
    target: [EUserRole.TEACHER],
  },
  {
    lesson: 'Урок 28',
    color: '#22AC62',
    title: 'Механическая работа. Мощность.',
    url: 'https://resh.edu.ru/subject/lesson/2965/start/',
    tags: ['7 кл', 'Физика'],
    target: [EUserRole.TEACHER],
  },
];

SwiperCore.use([Navigation]);

export const VideoWidget: React.FC<{ items: Array<VideoItem> }> = ({
  items,
}) => {
  const prevRef = useRef<HTMLInputElement>(null);
  const nextRef = useRef<HTMLInputElement>(null);
  const [isEnd, setIsEnd] = useState(false);
  const [isBegin, setIsBegin] = useState(false);

  const userRole = useSelector(getUserRole);
  const filteredItems = items?.filter((item) => item.target.includes(userRole));
  return (
    <div className="video-widget">
      <Title
        sx={{ fontSize: '24px', marginBottom: '40px' }}
        href="https://lib.myschool.edu.ru/market?filters=%22publishingHouseIds%22%3A%5B%224%22%5D"
      >
        Видеоуроки РЭШ
      </Title>
      <div className="video-widget__slider">
        <Swiper
          navigation={{
            prevEl: prevRef.current!,
            nextEl: nextRef.current!,
          }}
          onInit={(swiper) => {
            // @ts-ignore
            swiper.params.navigation.prevEl = prevRef.current;
            // @ts-ignore
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.update();
          }}
          spaceBetween={32}
          slidesPerView={1}
          onSlideChange={(swiper) => {
            setIsEnd(swiper.isEnd);
            setIsBegin(swiper.isBeginning);
          }}
          onSwiper={(swiper) => {
            setIsEnd(swiper.isEnd);
            setIsBegin(swiper.isBeginning);
          }}
          breakpoints={{
            758: {
              slidesPerView: 5,
            },
          }}
        >
          {filteredItems.map((item, index: number) => (
            <SwiperSlide key={index}>
              <a tabIndex={1} href={item.url} className="video-widget__item">
                <div
                  className="video-widget__item-img"
                  style={{
                    backgroundColor: item.color,
                  }}
                >
                  <div className="video-widget__item-lesson">{item.lesson}</div>
                  <div className="video-widget__item-title">{item.title}</div>
                  <div
                    className="video-widget__item-ico"
                    style={{ backgroundImage: `url(${VideoIcon})` }}
                  />
                </div>
                <div>
                  {item.tags.map((tag, index: number) => (
                    <div key={index} className="video-widget__item-tag">
                      {tag}
                    </div>
                  ))}
                </div>
              </a>
            </SwiperSlide>
          ))}
          <SwiperSlide>
            <a
              tabIndex={1}
              href="https://lib.myschool.edu.ru/market"
              className="video-widget__item"
            >
              <span className="video-widget__item-img video-widget__item-img--more">
                <span className="video-widget__item-text">
                  Посмотреть все материалы
                </span>
              </span>
            </a>
          </SwiperSlide>
          <div ref={prevRef} />
          <div ref={nextRef} />
        </Swiper>
        <div
          onClick={() => prevRef?.current?.click()}
          className={`video-widget__arrow video-widget__arrow--prev ${
            isBegin ? 'video-widget__arrow--disable' : ''
          }`}
          style={{ backgroundImage: `url(${ArrowRight})` }}
        />
        <div
          onClick={() => nextRef?.current?.click()}
          className={`video-widget__arrow video-widget__arrow--next ${
            isEnd ? 'video-widget__arrow--disable' : ''
          }`}
          style={{ backgroundImage: `url(${ArrowRight})` }}
        />
      </div>
    </div>
  );
};

import { Entity } from '@declarations/common';
import { EduGroup } from '@declarations/eduGroup';
import {
  AdminProfileStatus,
  ProfileCategory,
  UserProfileStatus,
} from '@declarations/enum/profile';
import {
  EduProviderStatusReason,
  NextOfKinStatusReason,
} from '@declarations/enum/retireRoleStatusReason';
import {
  AdminProfile,
  EduProviderProfile,
  NextOfKinProfile,
} from '@declarations/profile';
import {
  AdminRole,
  ContributorRole,
  EduProviderRole,
  NextOfKinRole,
  PupilRole,
} from '@declarations/role';
import {
  adminProfileStatusLocale,
  userProfileStatusLocale,
} from '@locales/profileStatus';
import {
  eduProviderStatusReasonLocale,
  nextOfKinStatusReasonLocale,
} from '@locales/retireRoleStatusReason';
import { roleStatusLocale } from '@locales/roleStatus';
import { EntityTypes } from '@mock-data/entities';
import { AccountEntry, UserHistory, UserRoleStatus } from '@redux/user/types';
import { nanoid } from 'nanoid';
import { PupilUtils } from '@utils/pupilUtils';
import { Person } from '@declarations/person';

export const mapToProfileCategory = (whoIs: string) => {
  switch (whoIs) {
    case 'AdminProfile':
      return ProfileCategory.ADMINISTRATOR;
    case 'NextOfKinProfile':
      return ProfileCategory.LEGAL_REPRESENTATIVE;
    case 'EduProviderProfile':
      return ProfileCategory.TEACHER_EDUCATOR;
    default:
      return ProfileCategory.STUDENT;
  }
};

export const getUserHistory = (
  pupilPersons: Person[],
  entries: AccountEntry[],
  profileCategory: ProfileCategory,
) => {
  const historyList: UserHistory[] = [];

  entries?.forEach((entry) => {
    let history: UserHistory | null = null;

    switch (entry.resource.resourceType) {
      case EntityTypes.ADMIN_PROFILE:
        history = getAdminProfileHistory(entry.resource, profileCategory);
        break;
      case EntityTypes.ADMIN_ROLE:
        history = getAdminRoleHistory(entry.resource, profileCategory);
        break;
      case EntityTypes.CONTRIBUTOR_ROLE:
        history = getContributorRoleHistory(entry.resource);
        break;
      case EntityTypes.EDU_PROVIDER_PROFILE:
        history = getEduProviderProfileHistory(entry.resource, profileCategory);
        break;
      case EntityTypes.EDU_PROVIDER_ROLE:
        history = getEduProviderRoleHistory(
          entry.resource,
          entries,
          profileCategory,
        );
        break;
      case EntityTypes.NEXT_OF_KIN_PROFILE:
        history = getNextOfKinProfileHistory(entry.resource, profileCategory);
        break;
      case EntityTypes.NEXT_OF_KIN_ROLE:
        history = getNextOfKinRoleHistory(entry.resource, profileCategory);
        break;
      case EntityTypes.PUPIL_ROLE:
        history = getPupilRoleHistory(
          entry.resource,
          profileCategory,
          pupilPersons,
        );
        break;
    }

    if (history) {
      historyList.push(history);
    }
  });

  return historyList;
};

const getAdminProfileHistory = (
  { status, meta, id }: AdminProfile,
  profileCategory: ProfileCategory,
): UserHistory | null => {
  const date = meta?.lastUpdated;
  const statusName = adminProfileStatusLocale[status] || 'Неизвестный статус';
  const name = 'Администратор';
  const history = {
    id: id || nanoid(),
    name,
    description: statusName,
    date,
  };

  if (status === AdminProfileStatus.RETIRED) {
    return history;
  } else if (profileCategory !== ProfileCategory.ADMINISTRATOR) {
    return history;
  }
  return null;
};

const getAdminRoleHistory = (
  { meta, role, status, id }: AdminRole,
  profileCategory: ProfileCategory,
) => {
  const date = meta?.lastUpdated;
  const statusName = userProfileStatusLocale[status] || 'Неизвестный статус';
  const name = role.display;
  const history = {
    id: id || nanoid(),
    name,
    description: statusName,
    date,
  };
  if (status !== UserRoleStatus.CONFIRMED) {
    return history;
  } else if (profileCategory !== ProfileCategory.ADMINISTRATOR) {
    return history;
  }
  return null;
};

const getContributorRoleHistory = ({
  meta,
  role,
  status,
  id,
}: ContributorRole) => {
  const date = meta?.lastUpdated;
  const statusName = userProfileStatusLocale[status] || 'Неизвестный статус';
  const name = role.display;
  const history = {
    id: id || nanoid(),
    name,
    description: statusName,
    date,
  };
  if (status !== UserRoleStatus.CONFIRMED) {
    return history;
  }
  return null;
};

const getPupilRoleHistory = (
  pupilRole: PupilRole,
  profileCategory: ProfileCategory,
  pupilPersons: Person[],
) => {
  const { meta, role, status, id } = pupilRole;
  const person = PupilUtils.findPupilPersonByPupilRole({
    role: pupilRole,
    persons: pupilPersons,
  });

  const date = meta?.lastUpdated;
  const statusName = userProfileStatusLocale[status] || 'Неизвестный статус';
  const name = role.display;
  const history = {
    id: id || nanoid(),
    name,
    description: statusName,
    date,
    personName: person?.name ?? null,
  };
  if (status === UserRoleStatus.RETIRED) {
    return history;
  } else if (profileCategory !== ProfileCategory.ADMINISTRATOR) {
    return history;
  }
  return null;
};

const getEduProviderProfileHistory = (
  { status, meta, id }: EduProviderProfile,
  profileCategory: ProfileCategory,
): UserHistory | null => {
  const date = meta?.lastUpdated;
  const statusName = userProfileStatusLocale[status] || 'Неизвестный статус';
  const name = 'Администратор';
  const history = {
    id: id || nanoid(),
    name,
    description: statusName,
    date,
  };

  if (status === UserProfileStatus.RETIRED) {
    return history;
  } else if (profileCategory !== ProfileCategory.TEACHER_EDUCATOR) {
    return history;
  }
  return null;
};

export const getEduProviderRoleHistory = (
  { meta, role, status, id, reason, eduGroup }: EduProviderRole,
  entries: AccountEntry[],
  profileCategory: ProfileCategory,
) => {
  const date = meta?.lastUpdated;
  const statusName = roleStatusLocale[status] || 'Неизвестный статус';
  const reasonName =
    reason && eduProviderStatusReasonLocale[reason as EduProviderStatusReason];

  let name = role.display;

  const eduGroupEntry =
    eduGroup &&
    (entries?.find(
      (entry) =>
        `${entry.resource.resourceType}/${entry.resource.id}` ===
        eduGroup.reference,
    ) as Entity<EduGroup> | undefined);
  if (eduGroupEntry) {
    name += `, ${eduGroupEntry.resource.name}`;
  }

  let description = statusName;
  if (reasonName) {
    description += `: ${reasonName}`;
  }
  const history = {
    id: id || nanoid(),
    name,
    description,
    date,
  };
  if (status === UserRoleStatus.RETIRED) {
    return history;
  } else if (profileCategory !== ProfileCategory.TEACHER_EDUCATOR) {
    return history;
  }
  return null;
};

const getNextOfKinProfileHistory = (
  { status, meta, id }: NextOfKinProfile,
  profileCategory: ProfileCategory,
): UserHistory | null => {
  const date = meta?.lastUpdated;
  const statusName = userProfileStatusLocale[status] || 'Неизвестный статус';
  const name = 'Законный представитель';
  const history = {
    id: id || nanoid(),
    name,
    description: statusName,
    date,
  };

  if (status === UserProfileStatus.RETIRED) {
    return history;
  } else if (profileCategory !== ProfileCategory.LEGAL_REPRESENTATIVE) {
    return history;
  }
  return null;
};

export const getNextOfKinRoleHistory = (
  { meta, role, status, id, reason }: NextOfKinRole,
  profileCategory: ProfileCategory,
) => {
  const date = meta?.lastUpdated;
  const statusName = roleStatusLocale[status] || 'Неизвестный статус';
  const reasonName =
    reason && nextOfKinStatusReasonLocale[reason as NextOfKinStatusReason];

  const name = role.display;
  let description = statusName;
  if (reasonName) {
    description += `: ${reasonName}`;
  }
  const history = {
    id: id || nanoid(),
    name,
    description,
    date,
  };
  if (status === UserRoleStatus.RETIRED) {
    return history;
  } else if (profileCategory !== ProfileCategory.LEGAL_REPRESENTATIVE) {
    return history;
  }
  return null;
};

import 'swiper/swiper.scss';
import { SyntheticEvent, useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { useQuery } from 'react-query';

import Title from '../../../common/Title/Title';

import Button from '@components/common/Button/Button';
import { ReactComponent as ArrowBack } from '@assets/icons/ico-arrow.svg';
import { ReactComponent as RunningDogImage } from '@assets/img/run-dog-run.svg';
import { ReactComponent as ElectricDogImage } from '@assets/img/electric-dog.svg';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import dayjs from 'dayjs';
import { TabPanel } from './TabPanel';
import { TabContent } from './TabContent';
import { WeekPicker } from '@components/common/WeekPicker/WeekPicker';
import { getWeekStartAndEnd } from '@lib/date/date';
import { isOfType } from '@declarations/common';
import './styles.scss';
import { mapResponse } from '../utils/mapResponse';
import { scheduleAPI } from '@api/schedule';
import { useSnackbar } from '@hooks/useSnackbar';
import { JournalError } from '@api/types/gounn';
import { DIARY_SNACK_ID } from '../constants';

type Props = {
  esiaId: string;
  regionCode: string;
  journalUrl: string | undefined;
};

const getDateKey = (value?: string | Date) => {
  if (!value) {
    return '';
  }
  let result = '';
  const date = isOfType<Date>(value, 'getDate')
    ? dayjs(value)
    : dayjs(value, 'DD.MM.YYYY');
  if (date.isValid()) {
    result = date.format('dd, DD.MM');
  }
  return result;
};

export const Widget = ({ esiaId, regionCode, journalUrl }: Props) => {
  const { addSnack } = useSnackbar();
  const [period, setPeriod] = useState(getWeekStartAndEnd(new Date()));
  const [selectedDay, setSelectedDay] = useState<string>(getDateKey(period[0]));

  const {
    data: schedule,
    isError,
    error,
    isLoading,
  } = useQuery(['diary-widget', esiaId, period[0], period[1]], async () => {
    const response: any = await scheduleAPI.getJournal(
      period[0],
      period[1],
      esiaId,
      regionCode,
    );
    if (response) {
      if (isOfType<JournalError>(response, 'issue')) {
        const errorList = response.issue?.reduce((list, issue) => {
          if (issue.severity === 'error') {
            list.push(issue.details);
          }
          return list;
        }, [] as string[]);
        throw Error(errorList.join(', '));
      }
      return mapResponse(response);
    }
  });

  const scheduleLink = journalUrl ? journalUrl : '#';

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setSelectedDay(newValue);
  };

  const handleClickForward = () =>
    setPeriod(getWeekStartAndEnd(dayjs(period[0]).add(1, 'week').toDate()));

  const handleClickBackward = () =>
    setPeriod(
      getWeekStartAndEnd(dayjs(period[0]).subtract(1, 'week').toDate()),
    );

  useEffect(() => {
    schedule && setSelectedDay(getDateKey(Object.keys(schedule)?.[0]));
  }, [schedule]);

  useEffect(() => {
    if (error) {
      const message =
        // @ts-ignore
        (typeof error === 'object' && error.message) ||
        'Ошибка получения данных';
      addSnack(message, 'error', DIARY_SNACK_ID);
    }
  }, [addSnack, error]);

  if (isLoading) {
    return (
      <Box
        mb={{
          md: 5,
          lg: 4,
        }}
        height={404}
        bgcolor="white"
        borderRadius={12}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box fontSize={18} lineHeight="28px" color="#0C1524" mb="16px">
          Загрузка данных
        </Box>
        <Box fontSize={16} lineHeight="24px" color="#818999" mb="28px">
          Уважаемый, пользователь, мы уже загружаем ваши данные
        </Box>
        <RunningDogImage />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        mb={{
          md: 5,
          lg: 4,
        }}
        height={404}
        bgcolor="white"
        borderRadius={12}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box fontSize={18} lineHeight="28px" color="#0C1524" mb="16px">
          Ошибка загрузки
        </Box>
        <Box
          fontSize={16}
          lineHeight="24px"
          color="#818999"
          mb="28px"
          maxWidth="490px"
          textAlign="center"
        >
          Мы уже работаем над её устранением.{' '}
          {scheduleLink && (
            <>
              Необходимую информацию вы можете найти в{' '}
              <a href={scheduleLink}>вашем дневнике</a>
            </>
          )}
        </Box>
        <ElectricDogImage />
      </Box>
    );
  }

  if (!schedule) {
    return null;
  }

  return (
    <Grid
      container
      position="relative"
      mb={{
        md: 5,
        lg: 4,
      }}
      bgcolor="#fff"
      borderRadius="12px"
      padding="32px 48px"
    >
      <Grid item xs={12} mb={2}>
        <Grid
          container
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid
            item
            alignItems="center"
            display="flex"
            onClick={() => {
              window.location.href = scheduleLink;
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            <Title className="diary-widget__title" sx={{ fontSize: '24px' }}>
              Дневник
            </Title>
            <Box ml={1} alignItems="center" />
            <ChevronRightIcon />
          </Grid>
          <Grid item display="flex">
            <WeekPicker value={period} onChange={setPeriod} />

            <Button
              variant="fourth"
              sx={{
                marginLeft: { xs: '0', md: '8px' },
                marginRight: { xs: '8px', md: '0' },
                padding: '10px',
                height: '44px',
                minWidth: '44px',
              }}
              onClick={handleClickBackward}
            >
              <ArrowBack />
            </Button>
            <Button
              variant="fourth"
              sx={{
                marginLeft: { xs: '0', md: '8px' },
                marginRight: { xs: '8px', md: '0' },
                padding: '10px',
                height: '44px',
                minWidth: '44px',
              }}
              onClick={handleClickForward}
            >
              <ArrowBack style={{ transform: 'rotate(180deg)' }} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item width="100%">
        <Box borderBottom={1} mb={1} borderColor="#DCE2EB" width="fit-content">
          <Tabs
            value={selectedDay}
            onChange={handleChange}
            sx={{
              '& .MuiTab-root': {
                textTransform: 'none',
              },
              '& .Mui-selected': {
                color: '#2C3038',
              },
              '& .MuiTabs-indicator': {
                backgroundColor: '#075ff7',
              },
            }}
            data-tabs="true" //https://github.com/reactjs/react-tabs/issues/345
          >
            {Object.keys(schedule).map((key) => {
              const day = getDateKey(key);
              return <Tab key={day} label={day} value={day} />;
            })}
          </Tabs>
        </Box>
        {Object.keys(schedule).map((key) => {
          const day = getDateKey(key);
          return (
            <TabPanel key={day} value={day} isActive={day === selectedDay}>
              <TabContent shedule={schedule[key]} />
            </TabPanel>
          );
        })}
      </Grid>
    </Grid>
  );
};

Widget.displayName = 'DialyWidget';

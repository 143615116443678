import { useEffect, useMemo, useState } from 'react';
import { Grid, Typography, Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import dayjs from 'dayjs';
import { useHistory, useParams } from 'react-router-dom';

import { ReactComponent as IconSuccess } from '@assets/icons/alert/success-green-bg.svg';
import { ReactComponent as IconClose } from '@assets/icons/ico-close.svg';
import Button from '@components/common/Button/Button';
import {
  Question,
  Quiz,
  QuizResult as QuizResultType,
  QuizResultAnswer,
  QuizResultQuestion,
} from '@api/types/quiz';
import { quizAPI } from '@api/quiz';
import { useSnackbar } from '@hooks/useSnackbar';
import { sortFunc } from '@pages/Manager/Quiz/utils';
import { percentRound } from '@lib/common';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 2,
  borderRadius: 6,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#F7F7F7',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#075FF7',
  },
}));

export const QuizResult = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { addSnack } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<{
    result: QuizResultType;
    item: Quiz;
  } | null>(null);

  const questionsMap = useMemo(
    () =>
      data
        ? data.result.questions?.reduce(
            (acc, item) => ({
              ...acc,
              [item.id]: item,
            }),
            {},
          )
        : {},
    [data],
  );

  const answersMap = useMemo(
    () =>
      data
        ? data.result.questions?.reduce((acc, item) => {
            if (!item.id || !item.answers?.length) {
              return acc;
            }
            const newAcc = { ...acc };

            item.answers?.forEach((answer) => {
              const answerId = answer.id;
              if (answerId) {
                // @ts-ignore
                newAcc[answerId] = answer;
              }
            });

            return newAcc;
          }, {})
        : {},
    [data],
  );

  const questions = useMemo(() => {
    if (data?.item.questions) {
      const items = [...data.item.questions];
      items.sort(sortFunc);
      return items;
    }
    return [];
  }, [data]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [result, item] = await Promise.all([
          quizAPI.getResult(id),
          quizAPI.getItem(id),
        ]);
        setData({
          result,
          item,
        });
      } catch (error) {
        console.error(error);
        addSnack('Ошибка загрузки данных', 'error');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [id, addSnack]);

  if (loading) {
    return (
      <div className="quiz-result">
        <Grid container>
          <Grid item xs>
            <Typography variant="h3">Загрузка...</Typography>
          </Grid>
        </Grid>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="quiz-result">
        <Grid container>
          <Grid item xs>
            <Typography variant="h3">Опрос не найден</Typography>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div className="quiz-result">
      <Grid container>
        <Grid item xs>
          <Typography variant="h3">Результаты опроса</Typography>
        </Grid>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <span className="quiz-result__title-date">
            {dayjs(data.item.endDate).isAfter(new Date())
              ? 'Завершится'
              : 'Завершен'}{' '}
            {dayjs(data.item.endDate).format('DD MMMM')}
          </span>
          <span
            className={
              data.item.status === 'PUBLISHED'
                ? 'quiz-result__title-status'
                : 'quiz-result__title-status quiz-result__title-status--draft'
            }
          >
            {data.item.status === 'PUBLISHED' ? (
              <>
                <IconSuccess />
                Опубликовано{' '}
              </>
            ) : data.item.status === 'DRAFT' ? (
              'Черновик'
            ) : (
              'Архив'
            )}
          </span>
          <button onClick={history.goBack} className="quiz-result__title-close">
            <IconClose />
          </button>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ marginTop: 3, maxWidth: 752 }}
        flexDirection="column"
        rowSpacing={2}
      >
        {data?.result?.factCount && (
          <Grid item>
            <Typography>Участники: {data.result.factCount}</Typography>
          </Grid>
        )}
        <Grid item>
          <Typography variant="h1">{data.item.title}</Typography>
        </Grid>
        {data.item.body && (
          <Grid item>
            <Typography variant="caption" color="#626B7A">
              {data.item.body}
            </Typography>
          </Grid>
        )}
      </Grid>
      {questions.map((item, i) =>
        item.type === 'CUSTOM_ANSWER' ? (
          <CustomAnswer
            key={i}
            item={item}
            //@ts-ignore
            result={questionsMap[item.id]}
            total={data.result.factCount}
          />
        ) : (
          <SelectAnswer key={i} item={item} answersMap={answersMap} />
        ),
      )}
    </div>
  );
};

const SelectAnswer = ({
  item,
  answersMap,
}: {
  item: Question;
  answersMap: { [key: number | string]: QuizResultAnswer };
}) => {
  const answers = useMemo(() => {
    if (item.answers) {
      const data = [...item.answers];
      data.sort(sortFunc);
      return data;
    }
    return [];
  }, [item.answers]);

  const percents = useMemo(
    () =>
      answers
        ? percentRound(
            answers.map(({ id }) => (id ? answersMap[id]?.count || 0 : 0)),
          )
        : [],
    [answers, answersMap],
  );

  return (
    <Grid container sx={{ maxWidth: 664, marginTop: 11 }}>
      <Grid item xs={12}>
        <Typography variant="h4">{item.title}</Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 3 }}>
        {answers?.map(({ id }, i) => {
          // @ts-ignore
          const answer = answersMap[id];

          if (!answer) {
            return null;
          }

          const percentage = percents[i] || 0;
          return (
            <div key={i} className="quiz-result__answer">
              <div className="quiz-result__answer-label">
                {answer.title || <>&nbsp;</>}
                <BorderLinearProgress
                  variant="determinate"
                  value={percentage}
                />
              </div>
              <div className="quiz-result__answer-percentage">
                {percentage}%
              </div>
              <div className="quiz-result__answer-count">{answer.count}</div>
            </div>
          );
        })}
      </Grid>
    </Grid>
  );
};

const CustomAnswer = ({
  item,
  result,
}: {
  item: Question;
  result: QuizResultQuestion;
  total: number;
}) => {
  const [showDrawer, setShowDrawer] = useState(false);
  return (
    <Grid container sx={{ maxWidth: 664, marginTop: 11 }}>
      <Grid item xs={12}>
        <Typography variant="h4">{item.title}</Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 3 }}>
        <Button variant="fourth" onClick={() => setShowDrawer(true)}>
          Посмотреть
        </Button>
        <Drawer
          anchor="right"
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
        >
          <div className="quiz-result__drawer">
            <Grid container>
              <Grid item xs={12}>
                <div className="quiz-result__drawer-title">
                  <Typography variant="h3">Ответы на вопрос</Typography>
                  <button
                    onClick={() => setShowDrawer(false)}
                    className="quiz-result__title-close"
                  >
                    <IconClose />
                  </button>
                </div>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: 4 }}>
                <Typography fontSize="16px" fontWeight="500" variant="h4">
                  {item.title}
                </Typography>
              </Grid>
              {/*<Grid item xs={12} sx={{ marginTop: 2 }}>*/}
              {/*  <Button variant="third">Скачать результаты</Button>*/}
              {/*</Grid>*/}
              <Grid item xs={12} sx={{ marginTop: 2, marginBottom: 2 }}>
                <Typography variant="caption" color="#626B7A">
                  Всего записей: {result.answers.length}
                </Typography>
              </Grid>
              {result.answers.map((item) => (
                <Grid key={item.id} item xs={12} sx={{ marginTop: 1 }}>
                  <Typography variant="body1">{item.body}</Typography>
                </Grid>
              ))}
            </Grid>
          </div>
        </Drawer>
      </Grid>
    </Grid>
  );
};

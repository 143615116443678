import * as React from 'react';
import './SmartTVMain.scss';
import { HeaderTV } from '@pages/SmartTVPages/Header/HeaderTV';
import { LibraryWidget } from '@pages/MainPage/LibraryWidget/LibraryWidget';
import { ResponsiveContainer } from '@components/common/ResponsiveContainer/ResponsiveContainer';
import {
  VideoItem,
  VideoWidget,
} from '@pages/MainPage/VideoWidget/VideoWidget';
import { useEffect } from 'react';
import { EUserRole } from '@redux/user/types';

export const itemsTV: Array<VideoItem> = [
  {
    lesson: 'Урок 23',
    color: '#22AC62',
    title:
      'Вес воздуха. Атмосферное давление. Измерение атмосферного давления. Опыт Торричелли',
    url: 'https://resh.edu.ru/subject/lesson/1535/start/',
    tags: ['7 кл', 'Физика'],
    target: [EUserRole.NO_AUTH, EUserRole.PARENT, EUserRole.TEACHER],
  },
  {
    lesson: 'Урок 24',
    color: '#22AC62',
    title:
      ' Приборы для измерения давления. Решение задач по теме «Атмосфера и атмосферное давление». Обобщение темы «Атмосфера и атмосферное давление»',
    url: 'https://resh.edu.ru/subject/lesson/2969/start/',
    tags: ['7 кл', 'Физика'],
    target: [EUserRole.NO_AUTH, EUserRole.PARENT, EUserRole.TEACHER],
  },
  {
    lesson: 'Урок 26',
    color: '#22AC62',
    title: 'Закон Архимеда. Плавание тел. Воздухоплавание',
    url: 'https://resh.edu.ru/subject/lesson/2967/start/',
    tags: ['7 кл', 'Физика'],
    target: [EUserRole.NO_AUTH, EUserRole.PARENT, EUserRole.TEACHER],
  },
  {
    lesson: 'Урок 27',
    color: '#22AC62',
    title:
      'Решение задач по теме «Закон Архимеда. Плавание тел». Обобщение по теме «Закон Архимеда. Плавание тел»',
    url: 'https://resh.edu.ru/subject/lesson/2966/start/',
    tags: ['7 кл', 'Физика'],
    target: [EUserRole.NO_AUTH, EUserRole.PARENT, EUserRole.TEACHER],
  },
  {
    lesson: 'Урок 28',
    color: '#22AC62',
    title: 'Механическая работа. Мощность',
    url: 'https://resh.edu.ru/subject/lesson/2965/start/',
    tags: ['7 кл', 'Физика'],
    target: [EUserRole.NO_AUTH, EUserRole.PARENT, EUserRole.TEACHER],
  },
];

export const SmartTVMain: React.FC = () => {
  useEffect(() => {
    const libraryWidget = document.querySelector('.library-widget');
    const firstLibraryEl = libraryWidget?.querySelector(
      'a.library-widget__item',
    );
    const libraryElements = libraryWidget?.querySelectorAll(
      'a.library-widget__item',
    );
    libraryElements?.forEach((item: any) => {
      if (item.href.includes('backUrl')) {
        item.href = item.href.replace(
          /myschool.edu.ru/,
          'myschool.edu.ru/smart/',
        );
      }
    });
    // @ts-ignore
    firstLibraryEl.focus();

    const videoWidget = document.querySelector('.video-widget');
    const firstVideoEl = videoWidget?.querySelector('a.video-widget__item');

    const materialsHeader = libraryWidget?.querySelector('h3');
    const libA = document.createElement('a');
    libA.href = 'https://lib.myschool.edu.ru/market';
    libA.className = 'smart__widget-title';
    libA.tabIndex = 1;
    libA.textContent = materialsHeader?.textContent as string;
    // @ts-ignore
    materialsHeader.textContent = '';
    materialsHeader?.append(libA);

    const videoHeader = videoWidget?.querySelector('h3');
    const libV = document.createElement('a');
    libV.href =
      'https://lib.myschool.edu.ru/market?filters=%22publishingHouseIds%22%3A%5B%224%22%5D';
    libV.className = 'smart__widget-title';
    libV.tabIndex = 1;
    libV.textContent = videoHeader?.textContent as string;
    // @ts-ignore
    videoHeader.textContent = '';
    videoHeader?.append(libV);

    const focusLayers = [
      materialsHeader,
      libraryWidget,
      videoHeader,
      videoWidget,
    ];

    const getFocusLayerIndex = (el: any) => {
      return focusLayers.findIndex((item: any) => {
        if (item.contains(el)) {
          return true;
        }
      });
    };

    const setFocus = (index: number) => {
      switch (focusLayers[index]) {
        case materialsHeader:
          libA?.focus();
          break;
        case libraryWidget:
          // @ts-ignore
          firstLibraryEl.focus();
          break;
        case videoHeader:
          libV?.focus();
          break;
        case videoWidget:
          // @ts-ignore
          firstVideoEl.focus();
          break;
      }
    };

    const func = (e: any) => {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setFocus(getFocusLayerIndex(document.activeElement) + 1);
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setFocus(getFocusLayerIndex(document.activeElement) - 1);
      } else if (e.key === 'ArrowRight') {
        const closest = (e.target as HTMLElement).closest('.swiper-slide');
        (closest?.nextSibling as HTMLElement)?.querySelector('a')?.focus();
      } else if (e.key === 'ArrowLeft') {
        const closest = (e.target as HTMLElement).closest('.swiper-slide');
        (closest?.previousSibling as HTMLElement)?.querySelector('a')?.focus();
      }

      if (e.keyCode == 9) {
        //tab pressed
        e.preventDefault(); // stops its action
      }
    };
    document.addEventListener('keydown', func);

    return () => document.removeEventListener('keydown', func);
  }, []);
  return (
    <div className={'smart'}>
      <HeaderTV />
      <ResponsiveContainer
        sx={{
          flex: '2 0 auto',
        }}
      >
        <LibraryWidget />
        <VideoWidget items={itemsTV} />
      </ResponsiveContainer>
      <template id="materials">
        <a
          tabIndex={1}
          href="https://lib.myschool.edu.ru/market?filters=%22publishingHouseIds%22%3A%5B%225%22%5D%2C%22age%22%3A%5B1%2C18%5D"
        >
          Материалы библиотеки
        </a>
      </template>
    </div>
  );
};

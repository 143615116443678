import { useCallback, useContext, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Box, ButtonGroup, Grid, Typography } from '@mui/material';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import unset from 'lodash/unset';

import { StyledInput } from '@components/styledComponents/StyledInput';
import { StyledTextarea } from '@components/styledComponents/StyledTextarea/StyledTextarea';
import { ButtonGroupToggle } from '@components/common/ButtonGroupToggle/ButtonGroupToggle';

import Button from '@components/common/Button/Button';

import { ReactComponent as IconCopy } from '@assets/icons/icon-copy.svg';
import { ReactComponent as IconUp } from '@assets/icons/icon-up.svg';
import { ReactComponent as IconDown } from '@assets/icons/icon-down.svg';
import { ReactComponent as IconTrash } from '@assets/icons/trash.svg';
import { ReactComponent as IconClose } from '@assets/icons/ico-close.svg';

import { QuizContext } from './context';

export enum TaskType {
  SINGLE_ANSWER = 'SINGLE_ANSWER',
  MULTIPLE_ANSWER = 'MULTIPLE_ANSWER',
  CUSTOM_ANSWER = 'CUSTOM_ANSWER',
}

const taskTypes = [
  {
    label: 'один ответ',
    value: TaskType.SINGLE_ANSWER,
  },
  {
    label: 'несколько',
    value: TaskType.MULTIPLE_ANSWER,
  },
  {
    label: 'открытый ответ',
    value: TaskType.CUSTOM_ANSWER,
  },
];

export const DEFAULT_TASK = {
  id: Date.now(),
  index: 0,
  title: '',
  type: TaskType.SINGLE_ANSWER,
  answer: [
    {
      label: '',
      isTrue: false,
    },
  ],
};

export type TaskAnswer =
  | string
  | {
      label: string;
      isTrue: boolean;
    }[];

export type Task = {
  id: number;
  index: number;
  title: string;
  description?: string;
  type: TaskType;
  answer: TaskAnswer;
  answersCount?: {
    max?: number;
    min?: number;
  };
};

type Props = {
  value: Task[];
  onChange: (tasks: Task[]) => void;
  moveItem: (fromIndex: number, toIndex: number) => void;
  copyItem: (id: number) => void;
};

export const QuizFormTasks = ({
  value,
  onChange,
  moveItem,
  copyItem,
}: Props) => {
  const { errors, setErrors } = useContext(QuizContext);

  const onChangeTask = useCallback(
    (task: Task) => {
      const taskIndex = value.findIndex(({ id }) => task.id === id);
      if (~taskIndex) {
        const clone = cloneDeep(value);
        clone[taskIndex] = task;
        onChange(clone);
      }
    },
    [value, onChange],
  );

  const handleClearQuestionErrors = (index: number) => {
    // TODO-achernyavets отрефакторить, чтобы не было дублирования кода
    const newErrors = cloneDeep(errors);
    unset(newErrors, `questions[${index}]`);
    if (isEmpty(newErrors.questions)) {
      unset(newErrors, `questions`);
    }
    setErrors(newErrors);
  };

  const handleMoveQuestionErrors = (fromIndex: number, toIndex: number) => {
    const newErrors = cloneDeep(errors);
    const fromPath = `questions.[${fromIndex}]`;
    const toPath = `questions.[${toIndex}]`;
    let fromValue: { [fieldName: string]: string | undefined } | undefined;
    if (newErrors.questions?.[fromIndex]) {
      fromValue = cloneDeep(newErrors.questions?.[fromIndex]);
      unset(newErrors, fromPath);
    }
    let toValue: { [fieldName: string]: string | undefined } | undefined;
    if (newErrors.questions?.[toIndex]) {
      toValue = cloneDeep(newErrors.questions?.[toIndex]);
      unset(newErrors, toPath);
    }
    if (fromValue) {
      set(newErrors, toPath, fromValue);
    }
    if (toValue) {
      set(newErrors, fromPath, toValue);
    }
    setErrors(newErrors);
  };

  return (
    <>
      {value.map((item, i) => (
        <TaskItem
          key={item.id}
          index={i}
          moveUp={() => {
            handleMoveQuestionErrors(i, i - 1);
            moveItem(i, i - 1);
          }}
          moveDown={() => {
            handleMoveQuestionErrors(i, i + 1);
            moveItem(i, i + 1);
          }}
          task={item}
          onChange={onChangeTask}
          removeItem={() => {
            handleClearQuestionErrors(i);
            moveItem(i, -1);
          }}
          copyItem={() => copyItem(item.id)}
          disabled={{
            upBtn: i === 0,
            downBtn: i === value.length - 1,
            deleteBtn: value.length === 1,
          }}
        />
      ))}
    </>
  );
};

type TaskProps = {
  task: Task;
  index: number;
  onChange: (task: Task) => void;
  moveUp: () => void;
  moveDown: () => void;
  removeItem: () => void;
  copyItem: () => void;
  disabled: {
    upBtn: boolean;
    downBtn: boolean;
    deleteBtn: boolean;
  };
};

const TaskItem = ({
  task,
  onChange,
  moveDown,
  moveUp,
  disabled,
  index,
  removeItem,
  copyItem,
}: TaskProps) => {
  const [showDescription, setShowDescription] = useState(!!task.description);
  const { errors, setErrors } = useContext(QuizContext);

  const changeType = useCallback(
    (type: TaskType) => {
      if (type === TaskType.CUSTOM_ANSWER) {
        onChange({ ...task, answer: '', type });
      } else if (type === TaskType.MULTIPLE_ANSWER) {
        onChange({
          ...task,
          answer: [
            {
              label: '',
              isTrue: true,
            },
          ],
          answersCount: {
            min: 1,
            max: 1,
          },
          type,
        });
      } else {
        onChange({
          ...task,
          answer: [
            {
              label: '',
              isTrue: true,
            },
          ],
          type,
        });
      }
    },
    [onChange, task],
  );

  const onChangeAnswer = useCallback(
    (value, index) => {
      if (task.type === TaskType.CUSTOM_ANSWER) {
        return;
      }
      const clone = cloneDeep(task);
      if (Array.isArray(clone.answer)) {
        clone.answer[index].label = value;
      }

      onChange(clone);
    },
    [task, onChange],
  );

  const addAnswer = useCallback(() => {
    if (task.type === TaskType.CUSTOM_ANSWER) {
      return;
    }
    const clone = cloneDeep(task);
    if (Array.isArray(clone.answer)) {
      clone.answer = [
        ...clone.answer,
        {
          label: '',
          isTrue: false,
        },
      ];
    }
    onChange(clone);
  }, [onChange, task]);

  const removeLastAnswer = () => {
    const clone = cloneDeep(task);
    Array.isArray(clone.answer) && clone.answer.pop();
    onChange(clone);
  };

  const clearQuestionErrorMessage = (index: number, field: string) => {
    const newErrors = cloneDeep(errors);
    unset(newErrors, `questions[${index}].${field}`);
    if (isEmpty(newErrors.questions?.[index])) {
      unset(newErrors, `questions[${index}]`);
    }
    if (isEmpty(newErrors.questions)) {
      unset(newErrors, `questions`);
    }
    setErrors(newErrors);
  };

  const checkIsNumber = (value: any) => {
    if (!value) {
      return false;
    }
    const numericValue = +value;
    return isFinite(numericValue);
  };

  return (
    <Grid container className="quiz-task">
      <ButtonGroup size="small" className="quiz-task__controls">
        <Button size="small" variant="fourth" onClick={copyItem}>
          <IconCopy />
        </Button>
        <Button
          disabled={disabled.downBtn}
          onClick={moveDown}
          size="small"
          variant="fourth"
        >
          <IconDown />
        </Button>
        <Button
          disabled={disabled.upBtn}
          onClick={moveUp}
          size="small"
          variant="fourth"
        >
          <IconUp />
        </Button>
        <Button
          disabled={disabled.deleteBtn}
          onClick={removeItem}
          size="small"
          variant="fourth"
        >
          <IconTrash />
        </Button>
      </ButtonGroup>
      <Grid item xs={3}>
        <Typography fontSize="18px" fontWeight="500" color="#2C3038">
          Вопрос {index + 1}
          <span className="mandatory-field">*</span>
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <StyledInput
          className="news__search"
          size="small"
          fullWidth
          value={task.title}
          onChange={({ target }) => onChange({ ...task, title: target.value })}
          sx={{ backgroundColor: '#fff' }}
        />
      </Grid>
      {showDescription && (
        <>
          <Grid item xs={3} sx={{ marginTop: 2 }}>
            Описание
          </Grid>
          <Grid item xs={9} sx={{ marginTop: 2 }}>
            <StyledTextarea
              className="quiz-form__textarea"
              minRows={6}
              value={task.description}
              onChange={({ target }) =>
                onChange({ ...task, description: target.value })
              }
            />
          </Grid>
        </>
      )}

      <>
        <Grid item xs={3} sx={{ mt: 2 }} />
        <Grid item xs={9} sx={{ mt: 2 }}>
          <button
            className="quiz-form__text-btn"
            onClick={() => {
              onChange({ ...task, description: '' });
              setShowDescription((prev) => !prev);
            }}
          >
            {showDescription ? 'Убрать описание' : 'Добавить описание'}
          </button>
        </Grid>
      </>

      <>
        <Grid item xs={3} sx={{ marginTop: 2 }}>
          Вариант ответа
          <span className="mandatory-field">*</span>
        </Grid>
        <Grid item xs={9} sx={{ marginTop: 2 }}>
          <ButtonGroupToggle
            value={task.type}
            items={taskTypes}
            onChange={(type) => changeType(type as TaskType)}
          />
        </Grid>
      </>

      <>
        <Grid item xs={3} />
        <Grid item xs={9}>
          {Array.isArray(task.answer) && (
            <>
              {task.answer.map((item, index) => (
                <div key={index} className="quiz-form__answer">
                  <StyledInput
                    size="small"
                    className="quiz-form__answer-input"
                    sx={{
                      mt: 1,
                      backgroundColor: '#fff',
                      ['& input']: { paddingLeft: '12px!important' },
                    }}
                    fullWidth
                    value={item.label}
                    onChange={({ target }) =>
                      onChangeAnswer(target.value, index)
                    }
                    placeholder="Ответ"
                  />
                  <span className="mandatory-field">*</span>
                  <div className="quiz-form__answer-btn">
                    {task.answer.length > 1 &&
                      task.answer.length - 1 === index && (
                        <Button
                          sx={{
                            mt: 1,
                            p: 0,
                            minWidth: 'min-content',
                            border: 'none',
                          }}
                          variant="fourth"
                          size="small"
                          onClick={removeLastAnswer}
                        >
                          <IconClose />
                        </Button>
                      )}
                  </div>
                </div>
              ))}
              <button
                style={{ marginTop: 16 }}
                className="quiz-form__text-btn"
                onClick={addAnswer}
              >
                Добавить ответ
              </button>
              {task.type === TaskType.MULTIPLE_ANSWER && (
                <Grid container sx={{ marginTop: 3 }}>
                  <Typography color="#626B7A" fontSize={16}>
                    Кол-во ответов, которое можно выбрать, от — до
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mt: 1,
                    }}
                  >
                    <StyledInput
                      type="number"
                      size="small"
                      inputProps={{
                        min: 1,
                        max: task.answersCount?.max,
                      }}
                      sx={{
                        width: 80,
                        backgroundColor: '#fff',
                        ['& input']: { paddingLeft: '12px!important' },
                      }}
                      value={task.answersCount?.min ?? ''}
                      onBlur={({ target }) => {
                        let value = checkIsNumber(target.value)
                          ? +target.value
                          : undefined;

                        if (!value || value < 1) {
                          value = 1;
                          onChange({
                            ...task,
                            answersCount: {
                              ...task.answersCount,
                              min: 1,
                            },
                          });
                        }
                        let errorMessage: string | undefined;
                        if (
                          value &&
                          task.answersCount?.max &&
                          value > task.answersCount.max
                        ) {
                          errorMessage =
                            'Указанное минимальное количество не может быть больше максимального';
                        }

                        if (errorMessage) {
                          setErrors({
                            questions: {
                              ...errors.questions,
                              [index]: {
                                answersCountMin: errorMessage,
                              },
                            },
                          });
                        } else if (errors.questions?.[index].answersCountMin) {
                          clearQuestionErrorMessage(index, 'answersCountMin');
                        }
                      }}
                      onChange={({ target }) => {
                        onChange({
                          ...task,
                          answersCount: {
                            ...task.answersCount,
                            min: checkIsNumber(target.value)
                              ? +target.value
                              : undefined,
                          },
                        });
                      }}
                    />
                    &nbsp; &#8212; &nbsp;
                    <StyledInput
                      size="small"
                      type="number"
                      inputProps={{
                        min: 1,
                      }}
                      sx={{
                        width: 80,
                        backgroundColor: '#fff',
                        ['& input']: { paddingLeft: '12px!important' },
                      }}
                      value={task.answersCount?.max ?? ''}
                      onBlur={({ target }) => {
                        let value = checkIsNumber(target.value)
                          ? +target.value
                          : undefined;

                        if (!isNil(value) && value < 1) {
                          value = 1;
                          onChange({
                            ...task,
                            answersCount: {
                              ...task.answersCount,
                              max: value,
                            },
                          });
                        }

                        let errorMessage: string | undefined;
                        if (value && value > task.answer.length) {
                          errorMessage =
                            'Указанное максимальное количество больше, чем количество ответов';
                        } else if (
                          value &&
                          (!task.answersCount?.min ||
                            value < task.answersCount?.min)
                        ) {
                          errorMessage =
                            'Указанное максимальное количество не может быть меньше минимального';
                        }

                        if (errorMessage) {
                          setErrors({
                            questions: {
                              ...errors.questions,
                              [index]: {
                                ...errors.questions?.[index],
                                answersCountMax: errorMessage,
                              },
                            },
                          });
                        } else if (errors.questions?.[index].answersCountMax) {
                          clearQuestionErrorMessage(index, 'answersCountMax');
                        }
                      }}
                      onChange={({ target }) =>
                        onChange({
                          ...task,
                          answersCount: {
                            ...task.answersCount,
                            max: checkIsNumber(target.value)
                              ? +target.value
                              : undefined,
                          },
                        })
                      }
                    />
                  </Box>
                  {!!errors.questions?.[index]?.answersCountMin && (
                    <Typography mt={2} color="red">
                      {errors.questions?.[index].answersCountMin}
                    </Typography>
                  )}
                  {!!errors.questions?.[index]?.answersCountMax && (
                    <Typography mt={2} color="red">
                      {errors.questions?.[index].answersCountMax}
                    </Typography>
                  )}
                </Grid>
              )}
            </>
          )}
        </Grid>
      </>
    </Grid>
  );
};

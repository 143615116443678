import { Diary } from '@declarations/diary';
import { mainAxios } from './utils/axios';

const SERVICE_NAME = 'api-news/api/v1/journalLink';

export const diaryAPI = {
  getAll: () =>
    mainAxios.get<Diary[], Diary[]>(SERVICE_NAME, {
      params: { isProtected: true },
    }),
  add: (data: Diary) =>
    mainAxios.post<Diary, Diary>(SERVICE_NAME, data, {
      params: { isProtected: true },
    }),
  update: (data: Diary) =>
    mainAxios.put<Diary, Diary>(
      `${SERVICE_NAME}/${data.id}`,
      { ...data },
      { params: { isProtected: true } },
    ),
  getByRegionCode: (id: number | string) =>
    mainAxios.get<Diary, Diary>(`${SERVICE_NAME}/${id}`, {
      params: { isProtected: true },
    }),
};

import * as React from 'react';
import { Grid } from '@mui/material';
import { ServiceItem } from './ServiceItem/ServiceItem';
import './ServicesWidget.scss';

const services = [
  {
    title: 'Электронный дневник',
    text: 'Учебный план, расписание, успеваемость, аналитика, информация о событиях и мероприятиях',
    url: `https://login.myschool.edu.ru/auth/realms/etd-realm/protocol/openid-connect/auth?response_type=code&client_id=${process.env.REACT_APP_LOGIN_CLIENT_ID}&scope=openid&redirect_uri=https://myschool.edu.ru`,
    ico: '/img/mainpage/ico/ico-diary-bg.svg',
    color: '#198EFA',
  },
  {
    title: 'Библиотека материалов',
    text: 'Электронные книги, курсы, обучающие видео, интерактивный контент и тренажеры',
    url: 'https://lib.myschool.edu.ru/market',
    ico: '/img/mainpage/ico/ico-library-bg.svg',
    color: '#FD9C2E',
  },
  {
    title: 'Российская электронная школа',
    text: 'Интерактивные уроки с 1 по 11 класс от лучших учителей страны',
    url: 'https://resh.edu.ru/',
    ico: '/img/mainpage/ico/ico-video-bg.svg',
    color: '#E94B4D',
  },
  {
    title: 'Чаты, видеозвонки, конференции ',
    text: 'Платформа для учёбы и общения',
    url: 'https://sferum.ru/',
    ico: '/img/mainpage/ico/ico-sphere-bg.svg',
    color: '#4C4BB0',
  },
];

export const ServicesWidget: React.FC = () => {
  return (
    <Grid container columnSpacing={4} sx={{ marginBottom: '80px' }}>
      {services.map((service, index) => (
        <Grid item xs={12} md={6} key={index}>
          <ServiceItem service={service} />
        </Grid>
      ))}
    </Grid>
  );
};

import * as React from 'react';
import { Avatar, Grid, Typography } from '@mui/material';
import { NextOfKinRole, PupilRole } from '@declarations/role';
import { Person } from '@declarations/person';
import { EduGroup } from '@declarations/eduGroup';
import { Organization } from '@declarations/organization';

type Props = {
  pupilRole: PupilRole;
  person?: Person;
  eduGroup?: EduGroup;
  organization?: Organization;
  nextOfKinRole?: NextOfKinRole;
};

const ProfileParentPupil: React.FC<Props> = ({
  person,
  eduGroup,
  organization,
}) => {
  return (
    <Grid
      container
      spacing={3}
      sx={{ alignItems: 'center', flexWrap: 'nowrap' }}
    >
      <Grid item xs="auto" flexShrink={0}>
        <Avatar
          sx={{
            bgcolor: '#F2F4F7',
            color: '#2C3038',
            fontSize: 24,
            fontWeight: 500,
            width: 80,
            height: 80,
            overflow: 'visible',
            transition: 'all 0.2s ease',
            [`&:before`]: {
              width: 20,
              height: 20,
              content: '""',
              display: 'block',
              borderRadius: '50%',
              position: 'absolute',
              backgroundColor: '#38b048',
              border: '3px solid #ffffff',
              right: 0,
              top: 0,
            },
          }}
          variant="circular"
          className="student__avatar"
        >
          {person?.name?.given.substring(0, 1)}
          {person?.name?.family.substring(0, 1)}
        </Avatar>
      </Grid>
      <Grid item xs zeroMinWidth>
        <Typography
          variant="h4"
          noWrap
          sx={{
            fontWeight: 500,
          }}
          className="student__name"
        >
          {person?.name?.given} {person?.name?.family}
        </Typography>
        <Typography
          component="div"
          variant={'additionally' as 'h1'}
          sx={{ mt: 1 }}
        >
          {eduGroup?.name || '-'}, {organization?.name || '-'}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ProfileParentPupil;

import { Filter } from '@declarations/common';
import dayjs from 'dayjs';

const getSearchGetParams = (
  pagination: { currentPage: number; pageSize: number },
  filters: Partial<Filter>,
) => {
  const queryParams: { [key: string]: any } = {
    page: pagination.currentPage - 1,
    size: pagination.pageSize,
  };

  if (filters.search) {
    queryParams['q'] = filters.search;
  }

  const [startDate, endDate] = filters.period || [null, null];

  if (startDate) {
    queryParams['start'] = dayjs(startDate).format('YYYY-MM-DD');
  }

  if (endDate) {
    queryParams['end'] = dayjs(endDate).format('YYYY-MM-DD');
  }

  if (filters.region?.length) {
    queryParams['region'] = filters.region;
  }

  if (filters.educationLevel?.length) {
    queryParams['edu_lvl'] = filters.educationLevel;
  }

  if (filters.oo?.length) {
    queryParams['org'] = filters.oo;
  }

  if (filters.sortCategory?.length) {
    queryParams['cat'] = filters.sortCategory.join(',');
  }

  if (filters.sort) {
    queryParams['date_ord'] = filters.sort;
  }

  // if (filters.time) {
  //   const today = new Date().toISOString();
  //   switch (filters.time) {
  //     case 'DAY':
  //       queryParams['start'] = today;
  //       queryParams['end'] = dayjs(today).subtract(1, 'day').toISOString();
  //       break;
  //     case 'WEEK':
  //       queryParams['start'] = today;
  //       queryParams['end'] = dayjs(today).subtract(1, 'week').toISOString();
  //       break;
  //   }
  // }
  return queryParams;
};

export default getSearchGetParams;

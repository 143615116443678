import './CircleCounter.scss';

function CircleCounter({ count = 0 }: { count: number }) {
  return (
    <div className="notifications-counter">
      <span>{count}</span>
    </div>
  );
}

export default CircleCounter;

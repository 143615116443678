import { ReactElement } from 'react';

type Props = {
  template?: 'primary' | 'secondary';
  icon?: ReactElement;
  label: string;
};

export const Chip = ({ template, icon, label }: Props) => {
  return (
    <div className={`chip ${template ? `chip--${template}` : ''}`}>
      {icon} {label}
    </div>
  );
};

export enum StructureRole {
  ETD_ADMINISTRATOR = '1.1',
  OO_GROUP_ADMINISTRATOR = '1.2',
  OO_ADMINISTRATOR = '1.3',
  EXTERNAL_IS_ADMINISTRATOR = '1.4',
  TEACHER = '2.1',
  CLASS_TEACHER = '2.2',
  OO_STUDENT = '3.1',
  FAMILY_STUDENT = '3.2',
  SPO_STUDENT = '3.3',
  PARENT = '4.1',
  GUARDIAN = '4.2',
  TRUSTEE = '4.3',
  CONTRIBUTOR_COK = '5.1',
  CONTRIBUTOR_EXPERT = '5.2',
}

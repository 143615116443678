export default {
  '$color-title': '#0C1524',
  '$color-text': '#2C3038',
  '$color-base': '#171725',
  '$color-primary': '#0278ff',
  '$color-primary-text': '#0260cc',
  '$color-minor-text': '#626B7A',
  '$color-stroke-first': '#4d94ff',
  '$color-text-selected': '#bbdefd',
  '$color-stroke-selected': '#DEE8FA',
};

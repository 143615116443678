import { ProfileCategory } from '@declarations/enum/profile';
import { StructureRole } from '@declarations/enum/structureRole';
import { structureRoleLocale } from '@locales/structureRole';

export const profileRoles = {
  [ProfileCategory.ADMINISTRATOR]: [
    {
      code: StructureRole.ETD_ADMINISTRATOR,
      display: structureRoleLocale[StructureRole.ETD_ADMINISTRATOR],
    },
    {
      code: StructureRole.OO_GROUP_ADMINISTRATOR,
      display: structureRoleLocale[StructureRole.OO_GROUP_ADMINISTRATOR],
    },
    {
      code: StructureRole.OO_ADMINISTRATOR,
      display: structureRoleLocale[StructureRole.OO_ADMINISTRATOR],
    },
    {
      code: StructureRole.EXTERNAL_IS_ADMINISTRATOR,
      display: structureRoleLocale[StructureRole.EXTERNAL_IS_ADMINISTRATOR],
    },
  ],
  [ProfileCategory.TEACHER_EDUCATOR]: [
    {
      code: StructureRole.TEACHER,
      display: structureRoleLocale[StructureRole.TEACHER],
    },
    {
      code: StructureRole.CLASS_TEACHER,
      display: structureRoleLocale[StructureRole.CLASS_TEACHER],
    },
  ],
  [ProfileCategory.STUDENT]: [
    {
      code: StructureRole.OO_STUDENT,
      display: structureRoleLocale[StructureRole.OO_STUDENT],
    },
    {
      code: StructureRole.FAMILY_STUDENT,
      display: structureRoleLocale[StructureRole.FAMILY_STUDENT],
    },
    {
      code: StructureRole.SPO_STUDENT,
      display: structureRoleLocale[StructureRole.SPO_STUDENT],
    },
  ],
  [ProfileCategory.LEGAL_REPRESENTATIVE]: [
    {
      code: StructureRole.PARENT,
      display: structureRoleLocale[StructureRole.PARENT],
    },
    {
      code: StructureRole.GUARDIAN,
      display: structureRoleLocale[StructureRole.GUARDIAN],
    },
    {
      code: StructureRole.TRUSTEE,
      display: structureRoleLocale[StructureRole.TRUSTEE],
    },
  ],
} as const;

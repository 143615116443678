import { Grid, Box, Typography } from '@mui/material';
import gerb from '@assets/img/gerb.png';
import edu from '@assets/img/logo-edu.png';
import { ResponsiveContainer } from '@components/common/ResponsiveContainer/ResponsiveContainer';
import { MENU_ITEMS } from '@components/Header/constants';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <Box
        sx={{
          borderTop: '1px solid #DCE2EB',
          paddingTop: '24px',
          paddingBottom: '24px',
        }}
      >
        <ResponsiveContainer>
          <Grid
            container
            columnSpacing={4}
            flexDirection={{
              xs: 'column',
              lg: 'row',
            }}
            justifyContent="space-between"
          >
            <Grid item>
              <Grid container rowSpacing={4} flexDirection="column">
                <Grid
                  item
                  borderBottom={{
                    xs: '1px solid #DCE2EB',
                    lg: 'none',
                  }}
                  pb={{
                    xs: 4,
                    lg: 0,
                  }}
                >
                  <nav className="footer__menu">
                    {MENU_ITEMS.map((item) => (
                      <Link
                        key={item.label}
                        to={item.path}
                        className="footer__menu-item"
                      >
                        {item.label}
                      </Link>
                    ))}
                  </nav>
                </Grid>
                <Grid item xs="auto">
                  <Grid
                    container
                    columnSpacing={{
                      xs: 0,
                      md: 5,
                    }}
                    rowSpacing={{
                      xs: 3,
                      md: 0,
                    }}
                    flexDirection={{
                      xs: 'column',
                      md: 'row',
                    }}
                  >
                    <Grid item>
                      <Grid container columnSpacing={2} alignItems="center">
                        <Grid item>
                          <img
                            src={gerb}
                            alt="Министерство просвещения Российской Федерации"
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            variant={'caption' as 'h1'}
                            sx={{ maxWidth: '203px', display: 'inline-block' }}
                          >
                            Министерство просвещения Российской Федерации
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container columnSpacing={2} alignItems="center">
                        <Grid item>
                          <img
                            src={edu}
                            alt="Национальный проект «Образование»"
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            variant={'caption' as 'h1'}
                            sx={{ maxWidth: '161px', display: 'inline-block' }}
                          >
                            Национальный проект «Образование»
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                flexDirection={{
                  xs: 'column',
                  md: 'row',
                  lg: 'column',
                }}
                borderTop={{
                  xs: '1px solid #DCE2EB',
                  lg: 'none',
                }}
                mt={{
                  xs: 4,
                  lg: 0,
                }}
                pt={{
                  xs: 4,
                  lg: 0,
                }}
                alignItems={{
                  sx: 'flex-start',
                  md: 'center',
                  lg: 'flex-end',
                }}
                justifyContent={{
                  sx: 'center',
                  md: 'space-between',
                  lg: 'center',
                }}
              >
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  alignItems={{
                    sx: 'flex-start',
                    lg: 'flex-end',
                  }}
                >
                  <a href="tel:88001007010" className="footer__phone">
                    8 800 100 70 10
                  </a>
                  <a
                    href="mailto:myschool@gosuslugi.ru"
                    className="footer__email"
                  >
                    myschool@gosuslugi.ru
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ResponsiveContainer>
      </Box>
    </footer>
  );
};

export default Footer;

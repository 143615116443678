import { ReactNode } from 'react';

import './style.scss';

type Props = {
  icon: ReactNode;
  message: string;
};

export const SnackContent = ({ icon, message }: Props) => (
  <div className="snack-content">
    <div className="snack-content__icon">{icon}</div>
    <div className="snack-content__body">{message}</div>
  </div>
);

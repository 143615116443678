import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Option } from '@components/common/Select/types';
import { RootState } from '@redux/store';
import MultiSelect, { MultiSelectProps } from './MultiSelect';
import SingleSelect, { SingleSelectProps } from './SingleSelect';
import { MultiValue, SingleValue } from 'react-select';

type Props = Omit<SingleSelectProps | MultiSelectProps, 'options' | 'value'> & {
  isMulti?: boolean;
  isDisabled?: boolean;
  value?: number | number[];
  allowIds?: number[];
  showRegionCode?: boolean;
};

const RegionSelect = ({
  isMulti,
  onChange,
  value,
  allowIds,
  showRegionCode,
  ...otherProps
}: Props) => {
  const regionOptions = useSelector(
    (state: RootState) => state.filters.options.regions,
  );

  const options: Option[] = useMemo(() => {
    let result: Option[] = [];
    if (regionOptions.length) {
      if (allowIds?.length) {
        result = regionOptions?.filter((subject) =>
          allowIds.includes(+subject.value),
        );
      } else {
        result = regionOptions;
      }
    }
    return showRegionCode
      ? result.map((e) => ({
          value: e.value,
          label: `${e.label} (${`0${e.value}`.slice(-2)})`,
        }))
      : result;
  }, [allowIds, regionOptions, showRegionCode]);

  const selectedValue = useMemo(() => {
    if (!value) {
      return undefined;
    } else if (Array.isArray(value)) {
      return value?.reduce((list, regionId) => {
        const option = options?.find((option) => option.value === regionId);
        if (option) {
          list.push(option);
        }
        return list;
      }, [] as Option[]);
    }
    return options.find((option) => option.value === value);
  }, [value, options]);

  if (isMulti) {
    return (
      <MultiSelect
        {...otherProps}
        options={options}
        value={selectedValue as Option[]}
        onChange={onChange as (option: MultiValue<Option> | undefined) => void}
      />
    );
  } else {
    return (
      <SingleSelect
        {...otherProps}
        value={selectedValue as Option}
        onChange={onChange as (option: SingleValue<Option> | undefined) => void}
        options={options}
      />
    );
  }
  return null;
};

export default RegionSelect;

import { RootState } from '../store';
import getRole from '@lib/userInfo/getRole';
import { getProfileList } from '@lib/userInfo/getProfile';
import { EUserRole, UserInfo, UserRole } from '@redux/user/types';
import getInitials from '@lib/userInfo/getInitials';
import getFullName from '@lib/userInfo/getFullName';
import { Definitions } from '@declarations/common';
import getAdminType from '@lib/userInfo/getAdminType';
import { StructureRole } from '@declarations/enum/structureRole';
import { getUserRegion } from '@lib/userInfo/getUserRegion';

const user = (state: RootState) => state.user;

export const getUser = (state: RootState) => user(state).user;
export const getUserRoleList = (state: RootState) => user(state).userRoleList;
export const getUserAuthAccount = (state: RootState) => user(state).authAccount;
export const getUserHistory = (state: RootState) => user(state).history;

export const getUserProfileList = (state: RootState): Definitions.Coding[] => {
  const user = getUser(state);
  return getProfileList(user);
};

export const getUserRoleLabel = (state: RootState): string => {
  const user = getUser(state);
  return getRole(user);
};

export const getUserRole = (state: RootState): UserRole => {
  const user = getUser(state);
  const role = getRole(user);
  if (role === 'Педагогический работник') {
    return EUserRole.TEACHER;
  } else if (role === 'Законный представитель обучающегося') {
    return EUserRole.PARENT;
  } else if (role === 'Администратор') {
    return EUserRole.ADMIN;
  } else if (role === 'Обучающийся') {
    return EUserRole.STUDENT;
  } else if (role === 'Участник ЦОС') {
    return EUserRole.PARTICIPANT;
  } else {
    return EUserRole.NO_AUTH;
  }
};

export const isExternalISAdmin = (state: RootState) => {
  const user = getUser(state);
  const adminType = getAdminType(user);
  return adminType && adminType === StructureRole.EXTERNAL_IS_ADMINISTRATOR;
};

export const isAdminOO = (state: RootState) => {
  return state.user.userRoleList?.profileRoles?.roleListAll.some(
    (item: any) =>
      item.resource?.role?.code === StructureRole.OO_ADMINISTRATOR &&
      item.resource?.status === 'confirmed',
  );
};

export const isAdminOOGroup = (state: RootState) => {
  return state.user.userRoleList?.profileRoles?.roleListAll.some(
    (item: any) =>
      item.resource?.role?.code === StructureRole.OO_GROUP_ADMINISTRATOR &&
      item.resource?.status === 'confirmed',
  );
};

export const isAdminETD = (state: RootState) => {
  return state.user.userRoleList?.profileRoles?.roleListAll.some(
    (item: any) =>
      item.resource?.role?.code === StructureRole.ETD_ADMINISTRATOR &&
      item.resource?.status === 'confirmed',
  );
};

export const getUserPupilInfo = (state: RootState) => user(state).pupilInfo;

export const getUserInfo = (state: RootState): UserInfo => {
  const user = getUser(state);
  return {
    fullName: getFullName(user) as string,
    initials: getInitials(user) as string,
    email: user?.email as string,
    preferred_username: user?.preferred_username as string,
    etdId: user?.etd_id as number,
  };
};

export const getIsAuth = (state: RootState): boolean => {
  return !!getUser(state);
};

export const getFunctionalRoleNames = (state: RootState) =>
  state.user.functionalRoles.map((item) => item.role);

export const getUserRegionSelector = (state: RootState) =>
  state.user?.locations ? getUserRegion(state.user?.locations) : null;

export enum EduProviderStatusReason {
  MISTAKE = '1',
  INVALID_DATA = '2',
  DUTY_TERMINATION = '3',
  PROFILE_DELETED = '5',
}

export enum PupilStatusReason {
  MISTAKE = '1',
  INVALID_DATA = '2',
  EARLY_EDUCATION_TERMINATION = '3',
  EDUCATION_COMPLETED = '4',
  PROFILE_DELETED = '5',
}

export enum NextOfKinStatusReason {
  MISTAKE = '1',
  INVALID_DATA = '2',
  ADULTHOOD = '3',
  RELATIONSHIP_BREAKDOWN = '4',
  PROFILE_DELETED = '5',
}

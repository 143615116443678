import qs from 'query-string';
import axios from 'axios';

import { BASE_API_URL } from '@constants/config';

import {
  dataResponseInterceptor,
  authResponseErrorInterceptor,
  authRequestInterceptor,
  mapToLocaleDateInterceptor,
} from '../interceptors';

export const instance = axios.create({
  baseURL: BASE_API_URL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'none' }),
});

instance.interceptors.request.use(authRequestInterceptor);

instance.interceptors.response.use((response) => {
  const data = dataResponseInterceptor(response);
  return mapToLocaleDateInterceptor(data);
}, authResponseErrorInterceptor(instance));

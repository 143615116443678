import { connect } from 'react-redux';
import { RootState } from '@redux/store';
import { getFaqById } from '@redux/faq';
import FaqItemPage from './FaqItemPage';
import { RouteComponentProps } from 'react-router-dom';
import { getFetchStatus } from '@redux/ui';

type OwnProps = RouteComponentProps<{ id: string }>;

const mapStateToProps = (
  state: RootState,
  {
    match: {
      params: { id },
    },
  }: OwnProps,
) => ({
  faq: getFaqById(state, id),
  faqFetchingStatus: getFetchStatus(state, 'fetchFaqs'),
});

export default connect(mapStateToProps)(FaqItemPage);

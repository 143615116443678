const getInitials = (user: any) => {
  const userName: string = user?.name;
  if (userName) {
    const names = userName.split(' ');
    let initials = names[0].substring(0, 1);
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1);
    }
    return initials;
  }
};

export default getInitials;

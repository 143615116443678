import { Entity, isOfType } from '@declarations/common';
import { EduGroup } from '@declarations/eduGroup';
import { Organization } from '@declarations/organization';
import { Person } from '@declarations/person';
import {
  AuthAccount,
  NextOfKinProfile,
  PupilProfile,
} from '@declarations/profile';
import { NextOfKinRole, PupilRole } from '@declarations/role';
import { EntityTypes } from '@mock-data/entities';
import { AccountEntry } from '@redux/user/types';

export const getPupilInfo = (
  entries: AccountEntry[],
  authAccount?: AuthAccount,
) => {
  const nextOfKinProfile = entries?.find(
    (item) => item.resource.resourceType === EntityTypes.NEXT_OF_KIN_PROFILE,
  ) as Entity<NextOfKinProfile> | undefined;

  if (!nextOfKinProfile) {
    return null;
  }

  const nextOfKinRoleObject: { [reference: string]: NextOfKinRole } = {};
  nextOfKinProfile.resource.nextOfKinRole?.forEach((item) => {
    const role = entries?.find(
      (entry) =>
        entry.resource.resourceType === EntityTypes.NEXT_OF_KIN_ROLE &&
        `${entry.resource.resourceType}/${entry.resource.id}` ===
          item.reference,
    ) as Entity<NextOfKinRole> | undefined;

    if (role) {
      nextOfKinRoleObject[`${role.resource.resourceType}/${role.resource.id}`] =
        role.resource;
    }
  });

  const pupilProfileObject: { [reference: string]: PupilProfile } = {};
  entries?.forEach((entry) => {
    if (entry.resource.resourceType === EntityTypes.PUPIL_PROFILE) {
      pupilProfileObject[
        `${entry.resource.resourceType}/${entry.resource.id}`
      ] = entry.resource;
    }
  });

  const pupilRoleObject: { [reference: string]: PupilRole } = {};
  Object.keys(pupilProfileObject)?.forEach((reference) => {
    const pupilRoles =
      entries &&
      (entries?.filter(
        (entry) =>
          entry.resource.resourceType === EntityTypes.PUPIL_ROLE &&
          isOfType<PupilRole>(entry.resource, 'pupilProfile') &&
          entry.resource.pupilProfile?.reference === reference,
      ) as Entity<PupilRole>[] | undefined);
    if (pupilRoles?.length) {
      pupilRoles?.forEach((role) => {
        pupilRoleObject[`${role.resource.resourceType}/${role.resource.id}`] =
          role.resource;
      });
    }
  });

  const pupilAuthAccounts = entries
    ?.filter((item) => {
      if (item.resource.resourceType !== EntityTypes.AUTH_ACCOUNT) return false;
      const pupilProfileReference = item.resource.pupilProfile?.reference;
      if (!pupilProfileReference) return false;
      return !!pupilProfileObject[pupilProfileReference];
    })
    .map((acc) => acc.resource as AuthAccount);

  const organizationObject: { [reference: string]: Organization } = {};
  Object.keys(pupilRoleObject)?.forEach((reference) => {
    const key = pupilRoleObject[reference]?.organization?.reference;
    if (key && !organizationObject[key]) {
      const organization = entries?.find(
        (entry) =>
          entry.resource.resourceType === EntityTypes.ORGANIZATION &&
          `${entry.resource.resourceType}/${entry.resource.id}` ===
            pupilRoleObject[reference]?.organization?.reference,
      ) as Entity<Organization> | undefined;
      if (organization) {
        organizationObject[
          `${organization.resource.resourceType}/${organization.resource.id}`
        ] = organization.resource;
      }
    }
  });

  const eduGroupObject: { [reference: string]: EduGroup } = {};
  Object.keys(pupilRoleObject)?.forEach((reference) => {
    const key = pupilRoleObject[reference]?.eduGroup?.reference;
    if (key && !eduGroupObject[key]) {
      const eduGroup = entries?.find(
        (entry) =>
          entry.resource.resourceType === EntityTypes.EDU_GROUP &&
          `${entry.resource.resourceType}/${entry.resource.id}` ===
            pupilRoleObject[reference]?.eduGroup?.reference,
      ) as Entity<EduGroup> | undefined;
      if (eduGroup) {
        eduGroupObject[
          `${eduGroup.resource.resourceType}/${eduGroup.resource.id}`
        ] = eduGroup.resource;
      }
    }
  });

  const parentPerson =
    authAccount &&
    (entries?.find(
      (entry) =>
        entry.resource.resourceType === EntityTypes.PERSON &&
        `${entry.resource.resourceType}/${entry.resource.id}` ===
          authAccount.principal.reference,
    ) as Entity<Person> | undefined);

  return {
    nextOfKinProfile: nextOfKinProfile.resource,
    nextOfKinRole: nextOfKinRoleObject,
    pupilProfile: pupilProfileObject,
    pupilAuthAccounts,
    organization: organizationObject,
    pupilRole: pupilRoleObject,
    eduGroup: eduGroupObject,
    person: {} as { [reference: string]: Person },
    parentPerson: parentPerson?.resource,
    pupilJournalData: {},
  };
};

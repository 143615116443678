import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import Button from '@components/common/Button/Button';
import { ReactComponent as IconRight } from '@assets/icons/caret-icon-right.svg';
import { COOKIE_NAMES, MAIN_DOMAIN, MY_FILES_LINK } from '@constants/config';
// import { ReactComponent as IconUpload } from '@assets/icons/upload.svg';
import { ReactComponent as IconPlus } from '@assets/icons/plus-white.svg';
import { ReactComponent as IconDocs } from '@assets/icons/docs.svg';
import { ReactComponent as IconTable } from '@assets/icons/table.svg';
import { ReactComponent as IconPresentation } from '@assets/icons/presentation.svg';
import { ReactComponent as IconFolder } from '@assets/icons/folder.svg';
import { documentsAPI } from '@api/documents';
import { File } from './FileItem';
import { Loader } from '@components/common/Loader/Loader';
import { Folder } from './FolderItem';
import DropDown from '@components/common/Dropdown/Dropdown';
import { getCookie, setCookie } from '@lib/cookie';
import { DIRECTORY_NAME, FILE_TYPE } from '@declarations/enum/documents';
import {
  DirectoryEntityResponse,
  DocumentEntityResponse,
} from '@api/types/documents';
import { CreateNewDocumentModal } from '@components/modalWindows/CreateNewDocumentModal';

export const FilesBlock = () => {
  const [mainFolderId, setMainFolderId] = useState<number | null>(null);
  const [files, setFiles] = useState<DocumentEntityResponse[]>([]);
  const [folders, setFolders] = useState<DirectoryEntityResponse[]>([]);
  const [loading, setLoading] = useState(true);
  const [documentTypeForNewDocument, setDocumentTypeForNewDocument] = useState<
    FILE_TYPE | 'directory' | null
  >(null);

  const loadMyDirectoriesAndFiles = async () => {
    setLoading(true);
    try {
      let authToken = getCookie(COOKIE_NAMES.AUTH_TOKEN);
      if (!authToken) {
        authToken = await documentsAPI.getAuthToken();
        if (!authToken) return;
        setCookie(COOKIE_NAMES.AUTH_TOKEN, authToken, null, MAIN_DOMAIN);
      }

      const rootDirectories = await documentsAPI.geRootDirectories();
      const myFoldersPartialData = rootDirectories.find(
        (directory) =>
          directory.name.toLowerCase() ===
          DIRECTORY_NAME.MY_DOCUMENTS.toLowerCase(),
      );
      if (!myFoldersPartialData) return;

      const myFoldersFullData = await documentsAPI
        .getDirectoryByID(myFoldersPartialData.id)
        .then((arr) => arr[0]);
      if (!myFoldersFullData) return;
      setMainFolderId(myFoldersFullData.id);
      setFolders(myFoldersFullData.children ?? []);
      setFiles(myFoldersFullData.documents ?? []);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const dropDownItems = useMemo(
    () => [
      {
        label: 'Документ',
        action: () => setDocumentTypeForNewDocument(FILE_TYPE.DOCX),
      },
      {
        label: 'Таблица',
        action: () => setDocumentTypeForNewDocument(FILE_TYPE.XLSX),
      },
      {
        label: 'Презентация',
        action: () => setDocumentTypeForNewDocument(FILE_TYPE.PPTX),
      },
      {
        label: 'Папка',
        action: () => setDocumentTypeForNewDocument('directory'),
      },
    ],
    [],
  );

  useEffect(() => {
    loadMyDirectoriesAndFiles();
  }, []);

  return (
    <>
      <div className="profile-block__my-files">
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button
              size="small"
              sx={{ fontSize: 24 }}
              endIcon={<IconRight />}
              externalLink={MY_FILES_LINK}
            >
              Мои файлы
            </Button>
          </Grid>
          {!!files.length && (
            <Grid item>
              {/*<Button startIcon={<IconUpload />} variant="fourth" sx={{ mr: 2 }}>*/}
              {/*  Загрузить*/}
              {/*</Button>*/}
              <DropDown
                MyButton={() => (
                  <Button startIcon={<IconPlus />} variant="first">
                    Создать
                  </Button>
                )}
              >
                {(setShow) => (
                  <DropDownInner items={dropDownItems} setShow={setShow} />
                )}
              </DropDown>
            </Grid>
          )}
        </Grid>

        {loading ? (
          <Loader />
        ) : files.length || folders.length ? (
          <div className="files-wrap">
            {folders.map((folder) => (
              <Folder key={folder.id} folder={folder} />
            ))}
            {files.map((file) => (
              <File key={file.id} file={file} />
            ))}
          </div>
        ) : (
          <>
            <Grid container justifyContent="center" sx={{ marginTop: 3 }}>
              <div className="profile-block__description">
                Для удобной работы с вашими документами перейдите в раздел «Мои
                файлы» или начните работу здесь
              </div>
            </Grid>
            <Grid container justifyContent="center" sx={{ marginTop: 3 }}>
              <Button
                onClick={() => setDocumentTypeForNewDocument(FILE_TYPE.DOCX)}
                variant="fourth"
                sx={{ mr: 1 }}
                startIcon={<IconDocs />}
              >
                Новый документ
              </Button>
              <Button
                onClick={() => setDocumentTypeForNewDocument(FILE_TYPE.XLSX)}
                variant="fourth"
                sx={{ mr: 1 }}
                startIcon={<IconTable />}
              >
                Taблица
              </Button>
              <Button
                onClick={() => setDocumentTypeForNewDocument(FILE_TYPE.PPTX)}
                variant="fourth"
                sx={{ mr: 1 }}
                startIcon={<IconPresentation />}
              >
                Презентация
              </Button>
              <Button
                onClick={() => setDocumentTypeForNewDocument('directory')}
                variant="fourth"
                startIcon={<IconFolder />}
              >
                Папка
              </Button>
            </Grid>{' '}
          </>
        )}
      </div>
      {documentTypeForNewDocument && (
        <CreateNewDocumentModal
          mainFolderId={mainFolderId}
          documentTypeForNewDocument={documentTypeForNewDocument}
          handleClose={() => setDocumentTypeForNewDocument(null)}
          successCallback={loadMyDirectoriesAndFiles}
        />
      )}
    </>
  );
};

type DropDownInnerProps = {
  items: {
    label: string;
    action: () => void;
  }[];
  setShow: (show: boolean) => void;
};

const DropDownInner = ({ items, setShow }: DropDownInnerProps) => {
  return (
    <div className="notifications__dropdown">
      {items.map((item) => (
        <button
          key={item.label}
          className="notifications__dropdown-item"
          onClick={() => {
            item.action();
            setShow(false);
          }}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};

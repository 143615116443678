import { LOCALSTORAGE_KEY } from '@constants/config';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchState } from './types';

const searchHistoryString = localStorage.getItem(
  LOCALSTORAGE_KEY.SEARCH_HISTORY,
);

const initialState: SearchState = {
  history: (searchHistoryString
    ? JSON.parse(searchHistoryString)
    : []) as string[],
};

const HISTORY_LIMIT = 8;

const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    addHistoryItem: (state: SearchState, action: PayloadAction<string>) => {
      const searchString = action.payload;
      if (state.history.every((item) => item !== searchString)) {
        const newHistory = [searchString, ...state.history].slice(
          0,
          HISTORY_LIMIT,
        );
        state.history = newHistory;
        localStorage.setItem(
          LOCALSTORAGE_KEY.SEARCH_HISTORY,
          JSON.stringify(newHistory),
        );
      }
    },
    clearHistory: (state: SearchState) => {
      localStorage.setItem(LOCALSTORAGE_KEY.SEARCH_HISTORY, JSON.stringify([]));
      state.history = [];
    },
  },
});

export const { addHistoryItem, clearHistory } = slice.actions;

export default slice.reducer;

import { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { AsyncProps } from 'react-select/async';
import { MultiValue, SingleValue } from 'react-select';

import SingleAsyncSelect from '@components/common/Select/SingleAsyncSelect';
import { Option } from '@components/common/Select/types';
import {
  EduOrganizationGroup,
  EduOrganizationGroupFilters,
} from '@declarations/eduOrganizationGroup';
import MultiAsyncSelect from '@components/common/Select/MultiAsyncSelect';
import { getEduOrganizationGroupRequest } from '@api/eduOrganizationGroup';
import MultiSelect from '@components/common/Select/MultiSelect';

type Props = Omit<AsyncProps<Option, any, any>, 'onChange'> & {
  name: string;
  placeholder: string;
  id: string;
  onChange?: (
    eduOrganizationGroup: EduOrganizationGroup | EduOrganizationGroup[],
  ) => void;
  filters?: EduOrganizationGroupFilters;
  isDisabled?: boolean;
  selected?: EduOrganizationGroup | EduOrganizationGroup[];
  allowedIds?: string[];
  hardInstalledGroups?: EduOrganizationGroup[];
};

const EduOrganizationGroupSelect = ({
  onChange,
  filters,
  selected,
  isMulti,
  allowedIds,
  hardInstalledGroups,
  ...otherProps
}: Props) => {
  const [eduOrganizationGroups, setEduOrganizationGroups] = useState<
    EduOrganizationGroup[]
  >([]);
  const [defaultOptions, setDefaultOptions] = useState<Option[]>([]);

  const value = useMemo(
    () => (selected ? mapEduOrganizationGroupsOptions(selected) : null),
    [selected],
  );

  const loadData = useCallback(
    async (inputValue: string, cb: (options: Option[]) => void) => {
      let options: Option[] = [];
      try {
        let data = [];
        if (hardInstalledGroups) {
          data = hardInstalledGroups;
        } else {
          data = (
            await getEduOrganizationGroupRequest({
              ...filters,
              name: inputValue,
            })
          ).map((entity) => entity.resource);
        }
        // Ограничение по таргетированию
        if (allowedIds) {
          data = data?.filter((group) => allowedIds.includes(group.id || ''));
        }
        setEduOrganizationGroups(data);
        options = mapEduOrganizationGroupsOptions(data);
      } catch (err) {
        console.error('Error on load organizations');
      }
      cb(options);
    },
    [filters, allowedIds, hardInstalledGroups],
  );

  const handleChange = useCallback(
    (option: SingleValue<Option>) => {
      const exists = eduOrganizationGroups?.find(
        (item) => option && item.id === option.value,
      );
      if (exists && onChange) {
        onChange(exists);
      }
    },
    [onChange, eduOrganizationGroups],
  );

  const handleMultiChange = useCallback(
    (optionList: MultiValue<Option>) => {
      const ids = optionList.map((option) => option.value);
      const exists = eduOrganizationGroups?.filter(
        (item) => item.id && ids.includes(item.id),
      );
      if (exists && onChange) {
        onChange(exists.map((item) => item));
      }
    },
    [onChange, eduOrganizationGroups],
  );

  const loadOptions = debounce(loadData, 1000);

  useEffect(() => {
    let mounted = true;
    if (mounted && !otherProps.isDisabled) {
      loadData('', (items) => mounted && setDefaultOptions(items));
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, [loadData]);

  if (hardInstalledGroups) {
    return (
      <MultiSelect
        {...otherProps}
        options={defaultOptions}
        value={value as Option[]}
        onChange={handleMultiChange}
      />
    );
  }

  if (isMulti) {
    return (
      <MultiAsyncSelect
        {...otherProps}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        onChange={handleMultiChange}
        value={value}
      />
    );
  }
  return (
    <SingleAsyncSelect
      {...otherProps}
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      onChange={handleChange}
      value={value}
    />
  );
};

export default EduOrganizationGroupSelect;

const mapEduOrganizationGroupsOptions = (
  eduOrganizationGroups: EduOrganizationGroup | EduOrganizationGroup[],
): Option[] => {
  if (Array.isArray(eduOrganizationGroups)) {
    return eduOrganizationGroups.map(mapToOption);
  }
  return [mapToOption(eduOrganizationGroups)];
};

function mapToOption(eduOrganizationGroup: EduOrganizationGroup) {
  return {
    label: eduOrganizationGroup.name,
    value: eduOrganizationGroup.id || '0',
  };
}

import { NextOfKinRole, PupilRole } from '@declarations/role';
import {
  AccountEntry,
  AuthAccountReponse,
  UserRoleStatus,
} from '@redux/user/types';
import { Definitions, Entity } from '@declarations/common';
import { Person } from '@declarations/person';
import { EntityTypes } from '@mock-data/entities';
import { AuthAccount } from '@declarations/profile';

const PUPIL_ENTITY_TYPE = [
  EntityTypes.AUTH_ACCOUNT,
  EntityTypes.PUPIL_PROFILE,
  EntityTypes.PUPIL_ROLE,
  EntityTypes.PERSON,
  EntityTypes.ORGANIZATION,
  EntityTypes.EDU_GROUP,
  EntityTypes.GROUP,
  EntityTypes.EDU_ORGANIZATION_GROUP,
];

class PupilUtils {
  public static findAllNextOfKinRolesForPupil(
    hashTableForNextOfKinRole: {
      [reference: string]: NextOfKinRole;
    },
    pupilProfile: Definitions.Reference,
  ) {
    return Object.values(hashTableForNextOfKinRole)?.filter(
      (nextOfKinRole: NextOfKinRole) =>
        nextOfKinRole.pupilProfile?.reference === pupilProfile?.reference,
    ) as NextOfKinRole[] | undefined;
  }

  public static findPriorityNextOfKinRole(roles: NextOfKinRole[]) {
    return roles.reduce((res: NextOfKinRole | undefined, nextOfKinRole) => {
      if (res?.status === UserRoleStatus.CONFIRMED) return res;
      switch (nextOfKinRole.status) {
        case UserRoleStatus.CONFIRMED:
          return nextOfKinRole;
        case UserRoleStatus.CREATED_BY_ADMIN:
          return nextOfKinRole;
        case UserRoleStatus.CREATED_BY_SELF:
          return res?.status !== UserRoleStatus.CREATED_BY_ADMIN
            ? nextOfKinRole
            : res;
        default:
          return !(
            res?.status === UserRoleStatus.CREATED_BY_SELF ||
            res?.status === UserRoleStatus.CREATED_BY_ADMIN
          )
            ? nextOfKinRole
            : res;
      }
    }, undefined);
  }

  public static assemblePersonObject(pupilAccountInfo: AuthAccountReponse) {
    let personKey: string | null = null;
    const personObject: {
      [reference: string]: Person;
    } = {};

    const person = pupilAccountInfo.entry?.find(
      (item: any) => item.resource.resourceType === EntityTypes.PERSON,
    ) as Entity<Person> | undefined;

    const authAccount = pupilAccountInfo.entry?.find(
      (item: any) => item.resource.resourceType === EntityTypes.AUTH_ACCOUNT,
    ) as Entity<AuthAccount> | undefined;

    if (person) {
      personKey = `${person.resource.resourceType}/${person.resource.id}`;
      personObject[personKey] = {
        ...person.resource,
        pupilProfile: authAccount?.resource.pupilProfile,
      };
    }
    return { personKey, personObject };
  }

  public static findPupilPersonByPupilRole(data: {
    persons: Person[];
    role: PupilRole;
  }) {
    const { persons, role } = data;
    const profileReference = role.pupilProfile?.reference;

    return persons.find(
      (person) => person.pupilProfile?.reference === profileReference,
    );
  }

  public static parsePupilAuthAccountResponses = (
    pupilAccountsEntries: PromiseSettledResult<
      AuthAccountReponse | undefined
    >[],
  ) => {
    return pupilAccountsEntries
      .reduce((acc, entryRes) => {
        return entryRes.status === 'fulfilled' && entryRes.value?.entry
          ? [...acc, entryRes.value?.entry]
          : acc;
      }, [] as AccountEntry[][])
      .map((accEntries) =>
        PupilUtils.getPupilEntitiesFromAccountEntry(accEntries),
      )
      .flat();
  };

  public static getPupilEntitiesFromAccountEntry = (
    accountEntities: AccountEntry[],
  ) => {
    return accountEntities.filter((entry) =>
      PUPIL_ENTITY_TYPE.some(
        (pupilType) => pupilType === entry.resource.resourceType,
      ),
    );
  };
}

export { PupilUtils };

import {
  Organization,
  OrganizationShortInfo,
} from '@declarations/organization';
import { EduOrganizationGroup } from '@declarations/eduOrganizationGroup';
import { parseReference } from '@lib/common';

class OrganisationsUtil {
  public static mapOrganisationToOption(
    organization: OrganizationShortInfo,
    showFullName?: boolean,
  ) {
    const innIdentifier = organization.identifier?.find(
      (item) => item.type === 'ИНН',
    );
    let label =
      (showFullName ? organization.name : organization.alias) ||
      organization.name ||
      '-';
    if (innIdentifier) {
      label += ` (${innIdentifier.value})`;
    }
    return {
      label,
      value: organization.id || '0',
    };
  }

  public static mapEduOrganisationGroupToOption(
    eduOrganizationGroup: EduOrganizationGroup,
  ) {
    if (
      !eduOrganizationGroup.organization?.reference ||
      eduOrganizationGroup.type !== 'O'
    )
      return;
    const organizationId = parseReference(
      eduOrganizationGroup.organization.reference,
    ).id;

    const organisationName = eduOrganizationGroup.name;

    return {
      label: organisationName,
      value: organizationId,
    };
  }

  public static mapOrganizationToOrgShortInfo(
    organization: Organization,
  ): OrganizationShortInfo | undefined {
    if (!organization.id) return;

    return {
      id: organization.id,
      name: organization.name,
      type: organization.type,
      alias: organization.alias,
      identifier: organization.identifier,
    };
  }

  public static mapEduOrganizationGroupToOrgShortInfo(
    eduOrganizationGroup: EduOrganizationGroup,
  ): OrganizationShortInfo | undefined {
    if (
      !eduOrganizationGroup.organization?.reference ||
      eduOrganizationGroup.type !== 'O'
    )
      return;

    return {
      id: parseReference(eduOrganizationGroup.organization.reference).id,
      name: eduOrganizationGroup.name,
    };
  }
}

export { OrganisationsUtil };

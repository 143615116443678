import * as React from 'react';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router';

import './QuizDetail.scss';

import Title from '@components/common/Title/Title';
import { Quiz, Question, Variant } from '@declarations/quiz';
import { getRemainDate } from '@lib/date/date';
import Button from '@components/common/Button/Button';
import SuccessIcon from '@assets/icons/ico-success.svg';

import { quizAPI } from '@api/quiz';
import { Quiz as QuizEntity } from '@api/types/quiz';
import { mapData } from '@pages/Quiz/utils';
import { ROUTES } from '@constants/routes';
import { DEMO_QUIZ_STORAGE } from '@constants/config';
import { AxiosError } from 'axios';
import { RESPONSE_STATUS_CODE } from '@api/types/enum/responseStatusCode';
import { useSnackbar } from '@hooks/useSnackbar';
import { UI_MESSAGE } from '@declarations/enum/uiMessage';
import { Loader } from '@components/common/Loader/Loader';
import EmptyData from '@components/common/EmptyData/EmptyData';

export interface QuizProps {
  quizDetail?: Quiz;
  isPreview?: boolean;
}

type Answer = {
  id?: number;
  isChecked?: boolean;
  body?: string;
};

type Answers = { [id: number]: Answer[] };

export const QuizDetailPage: React.FC<QuizProps> = ({
  quizDetail: defaultQuiz,
  isPreview = false,
}) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { addSnack } = useSnackbar();

  const [quizDetail, setData] = useState<Quiz | undefined>(defaultQuiz);

  const [answerTable, setAnswerTable] = useState<Answers>({});
  const [sendAnswer, setSendAnswer] = useState<boolean>(false);
  const [isLoadQuiz, setIsLoadQuiz] = useState(!!id);
  const [errLoadQuiz, setErrLoadQuiz] = useState(false);

  const canSubmit = useMemo(() => {
    if (quizDetail) {
      for (const question of quizDetail.questions) {
        if (question.type === 'CUSTOM_ANSWER') {
          if (!answerTable[question.id]?.some((item) => item.body)) {
            return false;
          }
        }
        if (question.type === 'SINGLE_ANSWER') {
          if (!answerTable[question.id]?.length) {
            return false;
          }
        }
        if (question.type === 'MULTIPLE_ANSWER') {
          const answersCount = answerTable[question.id]?.length || 0;
          if (
            answersCount < question.minAnswers ||
            answersCount > question.maxAnswers
          ) {
            return false;
          }
        }
      }
    }

    return true;
  }, [answerTable, quizDetail]);

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>,
      id: number,
      type: 'text' | 'radio' | 'checkbox' = 'radio',
      answerId?: number,
    ): void => {
      const clone = { ...answerTable };
      let answers = clone[id] || [];

      if (type === 'radio') {
        answers = [{ id: answerId, isChecked: true }];
      }
      if (type === 'checkbox') {
        if (event.target.checked) {
          answers.push({ id: answerId, isChecked: true });
        } else {
          answers = answers?.filter(({ id }) => id !== answerId);
        }
      }
      if (type === 'text') {
        answers = [{ body: event.target.value }];
      }
      clone[id] = answers;
      setAnswerTable(clone);
    },
    [answerTable],
  );

  const handleSubmit = useCallback(async () => {
    if (quizDetail?.id) {
      try {
        await quizAPI.sendAnswers({
          id: quizDetail.id,
          questions: Object.keys(answerTable).map((id: string) => ({
            id,
            answers: answerTable[+id],
          })),
        });
        setSendAnswer(true);
        history.push(ROUTES.quizList);
      } catch (error) {
        console.error(error);
      }
    }
  }, [answerTable, history, quizDetail]);

  useEffect(() => {
    const loadData = async () => {
      try {
        if (id) {
          const data = await quizAPI.getItem(id);
          if (data) {
            setData(mapData(data));
          }
        } else {
          const data = localStorage.getItem(DEMO_QUIZ_STORAGE);
          if (data) {
            setData(mapData(JSON.parse(data) as QuizEntity));
          }
        }
      } catch (error) {
        console.error(error);

        if (
          error instanceof AxiosError &&
          error.response?.status === RESPONSE_STATUS_CODE.FORBIDDEN
        ) {
          addSnack(UI_MESSAGE.QUIZ_NOT_ALLOWED, 'error');
          history.push(ROUTES.main);
        } else {
          setErrLoadQuiz(true);
          addSnack(UI_MESSAGE.QUIZ_NOT_AVAILABLE, 'error');
        }
      } finally {
        setIsLoadQuiz(false);
      }
    };
    loadData();
    window.scrollTo(0, 0);
    return () => {
      localStorage.removeItem(DEMO_QUIZ_STORAGE);
    };
  }, [addSnack, history, id]);
  if (isLoadQuiz) {
    return <Loader />;
  }

  if (errLoadQuiz) {
    return <EmptyData />;
  }
  return (
    <>
      {!!quizDetail && (
        <Grid
          container
          flexDirection="column"
          sx={{ mt: 6, maxWidth: { xl: 952, lg: 894, md: 852, sm: '100%' } }}
        >
          <Title
            variant="h1"
            className="quiz-detail__title"
            sx={{ marginBottom: '24px' }}
          >
            {quizDetail.title}
          </Title>
          {quizDetail.status === 'ARCHIVED' ? (
            <Typography>Опрос завершен</Typography>
          ) : (
            <>
              <div className="quiz-detail__text">{quizDetail.fullBody}</div>
              <div className="quiz-detail__info">
                <Grid container columnSpacing={2}>
                  {quizDetail.complete && (
                    <Grid item>
                      <div
                        className="quiz-detail__completed"
                        style={{ backgroundImage: `url(${SuccessIcon})` }}
                      >
                        Пройдено
                      </div>
                    </Grid>
                  )}
                  <Grid item>
                    <div className="quiz-detail__remain">
                      Завершится {getRemainDate(quizDetail.endDate)}
                    </div>
                  </Grid>
                </Grid>
              </div>
              {!sendAnswer && (
                <>
                  <div className="quiz-detail__questions">
                    {quizDetail.questions.map((question, index: number) => (
                      <div key={index} className="quiz-detail__question">
                        <div className="quiz-detail__question-number">{`Вопрос ${
                          index + 1
                        } из ${quizDetail.questions.length}`}</div>
                        <div className="quiz-detail__question-title">
                          {question.fullBody}
                        </div>
                        {!question.variants.length && (
                          <div>
                            <TextField
                              disabled={quizDetail.complete}
                              onChange={(e) =>
                                handleChange(e, question.id, 'text')
                              }
                              multiline
                              rows={3}
                              sx={{
                                width: '100%',
                                backgroundColor: '#FFF',
                              }}
                            />
                          </div>
                        )}
                        {question.variants &&
                          question.type === 'MULTIPLE_ANSWER' && (
                            <div>
                              <FormGroup>
                                {question.variants &&
                                  question.variants.map((variant: Variant) => (
                                    <div
                                      className="quiz-detail__option"
                                      key={variant.id}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            onChange={(e) =>
                                              handleChange(
                                                e,
                                                question.id,
                                                'checkbox',
                                                variant.id,
                                              )
                                            }
                                            value={variant.value}
                                            disabled={
                                              quizDetail.complete ||
                                              isDisabled(
                                                question,
                                                variant,
                                                answerTable,
                                              )
                                            }
                                            sx={{
                                              color: '#CAD3E0',
                                              '&.Mui-checked': {
                                                color: '#007AFF',
                                              },
                                            }}
                                          />
                                        }
                                        label={''}
                                      />
                                      {variant?.fullBody || variant.value}
                                    </div>
                                  ))}
                              </FormGroup>
                            </div>
                          )}
                        {question.variants &&
                          question.type === 'SINGLE_ANSWER' && (
                            <div>
                              <RadioGroup
                                aria-label="gender"
                                name="radio-buttons-group"
                                onChange={(e, value) =>
                                  handleChange(e, question.id, 'radio', +value)
                                }
                              >
                                {question.variants &&
                                  question.variants.map(
                                    (variant, index: number) => (
                                      <div
                                        className="quiz-detail__option"
                                        key={index}
                                      >
                                        <FormControlLabel
                                          value={variant.id}
                                          control={
                                            <Radio
                                              disabled={quizDetail.complete}
                                              sx={{
                                                color: '#CAD3E0',
                                                '&.Mui-checked': {
                                                  color: '#007AFF',
                                                },
                                              }}
                                            />
                                          }
                                          label={''}
                                          color="primary"
                                        />
                                        {variant?.fullBody || variant.value}
                                      </div>
                                    ),
                                  )}
                              </RadioGroup>
                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                  {!isPreview && (
                    <Button
                      variant="first"
                      onClick={handleSubmit}
                      disabled={quizDetail.complete || !id || !canSubmit}
                    >
                      Отправить
                    </Button>
                  )}
                </>
              )}
              {sendAnswer && <div>Спасибо за ваши ответы!</div>}
            </>
          )}
        </Grid>
      )}
    </>
  );
};

const isDisabled = (question: Question, answer: Variant, answers: Answers) => {
  const questionAnswers = answers[question.id];
  if (!questionAnswers) {
    return false;
  }
  if (questionAnswers.some(({ id }) => id === answer.id)) {
    return false;
  }

  if (question.maxAnswers) {
    return questionAnswers.length >= question.maxAnswers;
  }

  return false;
};

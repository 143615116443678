import { Pagination } from '@declarations/common';
import { SearchSource } from '@declarations/enum/search';

export const DEFAULT_PAGINATION: Pagination = {
  pageSize: 12,
  currentPage: 1,
};

export const IS_IMPORTANT_CONTENT: string[] = [
  'isImportant,desc',
  'statusDate,desc',
];

export const SEARCH_SOURCE_OPTIONS = [
  {
    label: 'Везде',
    value: SearchSource.ALL,
  },
  {
    label: 'Базовая платформа',
    value: SearchSource.BASE,
  },
  {
    label: 'Библиотека',
    value: SearchSource.LIBRARY,
  },
];

export const NOTIFICATION_COUNT_TO_OUTPUT = 5;

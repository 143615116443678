import { HeaderTV } from '@pages/SmartTVPages/Header/HeaderTV';
import { ResponsiveContainer } from '@components/common/ResponsiveContainer/ResponsiveContainer';
import Typography from '@mui/material/Typography';
import { Grid, Box } from '@mui/material';
import myEduLogo from '@assets/smartTV/play_perspective.png';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  EducationContent,
  EducationData,
  EducationSections,
  EducationSectionsContent,
  EducationSectionData,
} from '@api/types/smart/education';
import { MY_LIBRARY_LINK } from '@constants/config';
import './SmartEducation.scss';
import { isEmpty } from 'lodash';
import educationApi from '@api/smart/education';
import { SwiperSlide } from 'swiper/react';
import { Loader } from '@components/common/Loader/Loader';
import { Link } from 'react-router-dom';
import * as React from 'react';
import { SwapperCard } from '@components/common/Swipper/SwapperCard';
const SERVICE_NAME = `${MY_LIBRARY_LINK}attaches/`;
const MATERIALS_URL = `${MY_LIBRARY_LINK}player/`;
const SECTION_DESCRIPTION = 'Описание';

const SmartEducation = () => {
  const [data, setData] = useState<EducationData<EducationContent>>({
    content: [],
    total: 0,
  });
  const [sections, setSections] = useState<
    EducationSections<EducationSectionsContent>
  >({ content: [], totalPages: 0 });
  const [loading, setLoading] = useState<boolean>(false);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const sections = await educationApi.getSectionList();
      if (sections) {
        setSections(sections);
      }
      const data = await educationApi.getAllContent();
      if (data) {
        setData(data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const sortData = useMemo(() => {
    if (data?.content?.length) {
      const res: EducationSectionData[] = [];
      data.content.map((item, id) => {
        return item.sections.forEach((section: number) => {
          const sectionItem: EducationSectionsContent | undefined =
            sections.content.find((el) => el.id === section);
          if (sectionItem) {
            const { value, property } = sectionItem;
            const sectionDescription = property?.find(
              (sectionElement) => sectionElement.code === SECTION_DESCRIPTION,
            )?.value;
            const isSectionAdded = res?.find(
              (sectionElement) => sectionElement.section === value,
            );
            if (isSectionAdded) {
              const index = res.findIndex((i) => i.section === value);
              res[index].content = [...res[index].content, item];
            } else {
              const arr: EducationSectionData = {
                section: '',
                description: '',
                content: [],
                id: id,
              };
              arr.id = Number(id);
              arr.section = value;
              arr.content = [item];
              arr.description = sectionDescription || '';
              res.push(arr);
            }
          }
        });
      });
      return res;
    }
    return [];
  }, [data?.content, sections?.content]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="smart">
      <HeaderTV />
      <ResponsiveContainer
        sx={{
          flex: '2 0 auto',
          pb: 4,
        }}
      >
        <Grid container columnSpacing={0.5}>
          <Grid item>
            <Typography
              variant="h1"
              sx={{
                color: '#ffffff',
                fontWeight: 400,
                fontSize: '48px',
                letterSpacing: '-1px',
              }}
            >
              Моё просвещение
            </Typography>
          </Grid>
          <Grid item>
            <img src={myEduLogo} alt="Логотип" />
          </Grid>
        </Grid>
        {loading ? (
          <Loader />
        ) : (
          <Box sx={{ letterSpacing: '-0.4px', color: '#ffffff' }}>
            {sortData &&
              !isEmpty(sortData) &&
              sortData.map((section) => {
                return (
                  <Box key={section.section} sx={{ minHeight: '50vh', mt: 12 }}>
                    <Typography
                      variant="h1"
                      sx={{
                        color: '#ffffff',
                        fontWeight: 400,
                        fontSize: '48px',
                        letterSpacing: '-1px',
                      }}
                    >
                      <Link
                        key={section.section}
                        to={`/smart2/${section.id}`}
                        style={{ color: '#fff', textDecoration: 'none' }}
                      >
                        {section.section}
                      </Link>
                    </Typography>
                    {section.description && (
                      <Typography
                        variant="h6"
                        sx={{
                          letterSpacing: '-0.4px',
                          color: '#ABABAD',
                          display: 'inline-block',
                          mt: 1,
                          fontWeight: '400',
                          fontSize: '14px',
                        }}
                      >
                        {section.description}
                      </Typography>
                    )}
                    <div className="library-widget">
                      <div className="library-widget__slider">
                        <SwapperCard>
                          {/*{sortData[section].content.map(*/}
                          {section.content
                            .slice(0, 6)
                            .map((content: EducationContent) => (
                              <SwiperSlide
                                key={content.id}
                                className="smart__slider-slide"
                              >
                                <Grid
                                  container
                                  flexDirection="column"
                                  rowSpacing={2}
                                  tabIndex={1}
                                  component="a"
                                  // TODO check
                                  href={`${MATERIALS_URL}${content.id}#init`}
                                  sx={{
                                    outline: '0',
                                    textDecoration: 'none',
                                    color: 'inherit',
                                  }}
                                >
                                  <Grid item>
                                    <div className="smart__item-cover">
                                      {content.cover ? (
                                        <img
                                          src={`${SERVICE_NAME}${content.cover}`}
                                          alt={content.name}
                                        />
                                      ) : (
                                        <div
                                          className="smart__item-cover-color"
                                          style={{
                                            backgroundColor: `${content.coverColor}`,
                                          }}
                                        >
                                          <Typography
                                            variant={'caption' as 'h1'}
                                            sx={{
                                              color: '#333333',
                                              fontWeight: '700',
                                              p: 2,
                                              display: 'block',
                                              wordBreak: 'break-word',
                                            }}
                                          >
                                            {content.name}
                                          </Typography>
                                        </div>
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      variant="h6"
                                      sx={{ wordBreak: 'break-word' }}
                                    >
                                      {content.name}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </SwiperSlide>
                            ))}
                          <SwiperSlide className="smart__slider-slide">
                            <Link
                              key={section.section}
                              to={`/smart2/${section.id}`}
                              tabIndex={1}
                              style={{ color: '#fff', textDecoration: 'none' }}
                            >
                              <span className="smart__item-cover">
                                Показать всё
                              </span>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <span></span>
                          </SwiperSlide>
                        </SwapperCard>
                      </div>
                    </div>
                  </Box>
                );
              })}
          </Box>
        )}
      </ResponsiveContainer>
    </div>
  );
};

export default SmartEducation;

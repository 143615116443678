import { notifyAxios } from './utils/axios';

const SERVICE_NAME = 'api-ntfc-sender/api/v1/message';

export enum AlertMessageStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export type AlertMessage = {
  id?: number;
  content: string;
  status: AlertMessageStatus;
  startDate: string | null;
  endDate?: string | null;
};

export const alertApi = {
  getActualMessages: () =>
    notifyAxios.get<AlertMessage[], AlertMessage[]>(`${SERVICE_NAME}/actual`),
  getAllMessages: () =>
    notifyAxios.get<AlertMessage[], AlertMessage[]>(`${SERVICE_NAME}`, {
      params: { isProtected: true },
    }),
  createMessage: (message: AlertMessage) =>
    notifyAxios.post<AlertMessage>(`${SERVICE_NAME}`, message, {
      params: { isProtected: true },
    }),
  getMessageById: (id: number) =>
    notifyAxios.get<AlertMessage, AlertMessage>(`${SERVICE_NAME}/${id}`),
  removeMessage: (id: number) =>
    notifyAxios.delete<unknown, unknown>(`${SERVICE_NAME}/${id}`, {
      params: { isProtected: true },
    }),
  updateMessage: (message: AlertMessage, id: number) =>
    notifyAxios.put<unknown, unknown>(`${SERVICE_NAME}/${id}`, message, {
      params: { isProtected: true },
    }),
};

//stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url

const isValidUrl = (checkUrl: string) => {
  let url;

  try {
    url = new URL(checkUrl);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export default isValidUrl;

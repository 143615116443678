import { StructureRole } from '@declarations/enum/structureRole';

export const EntityTypes = {
  AUTH_ACCOUNT: 'AuthAccount',
  NEXT_OF_KIN_PROFILE: 'NextOfKinProfile',
  NEXT_OF_KIN_ROLE: 'NextOfKinRole',
  PUPIL_PROFILE: 'PupilProfile',
  PUPIL_ROLE: 'PupilRole',
  ORGANIZATION: 'Organization',
  EDU_GROUP: 'EduGroup',
  GROUP: 'Group',
  EDU_ORGANIZATION_GROUP: 'EduOrganizationGroup',
  PERSON: 'Person',
  EDU_PROVIDER_PROFILE: 'EduProviderProfile',
  EDU_PROVIDER_ROLE: 'EduProviderRole',
  ADMIN_PROFILE: 'AdminProfile',
  ADMIN_ROLE: 'AdminRole',
  CONTRIBUTOR_ROLE: 'ContributorRole',
  CONTRIBUTOR_PROFILE: 'ContributorProfile',
  FUNCTIONAL_ROLE_TYPE: 'FunctionalRoleType',
  ACCESS_RIGHTS: 'AccessRights',
  CONTEXT_TYPE: 'ContextType',
} as const;

export const roleHierarchy = {
  [EntityTypes.ADMIN_PROFILE]: [
    StructureRole.ETD_ADMINISTRATOR,
    StructureRole.OO_GROUP_ADMINISTRATOR,
    StructureRole.OO_ADMINISTRATOR,
    StructureRole.EXTERNAL_IS_ADMINISTRATOR,
  ],
  [EntityTypes.EDU_PROVIDER_PROFILE]: [
    StructureRole.TEACHER,
    StructureRole.CLASS_TEACHER,
  ],
  [EntityTypes.PUPIL_PROFILE]: [
    StructureRole.OO_STUDENT,
    StructureRole.FAMILY_STUDENT,
    StructureRole.SPO_STUDENT,
  ],
  [EntityTypes.NEXT_OF_KIN_PROFILE]: [
    StructureRole.PARENT,
    StructureRole.GUARDIAN,
    StructureRole.TRUSTEE,
  ],
  [EntityTypes.CONTRIBUTOR_PROFILE]: [
    StructureRole.CONTRIBUTOR_COK,
    StructureRole.CONTRIBUTOR_EXPERT,
  ],
};

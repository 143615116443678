import { FC, FocusEventHandler } from 'react';
import Select, { components, MultiValue } from 'react-select';
import './Select.scss';
import Button from '../Button/Button';
import { useRef } from 'react';
import { Option } from './types';

export type MultiSelectProps = {
  options: Option[];
  name: string;
  id: string;
  placeholder: string;
  isDisabled?: boolean;
  value?: Option[];
  onChange?: (options: MultiValue<Option>) => void;
  onBlur?: FocusEventHandler<HTMLInputElement> | undefined;
};

const MultiSelect: FC<MultiSelectProps> = ({
  name,
  options,
  placeholder,
  id,
  value,
  ...rest
}) => {
  const selectRef: any = useRef();

  const clearValue = (): void => {
    //@ts-ignore
    selectRef.current.clearValue();
  };

  const Menu = (props: any) => {
    return (
      <components.Menu {...props}>
        <div>{props.children}</div>
        {props.hasValue && (
          <div className="react-select__reset">
            <Button
              fullWidth
              onClick={() => {
                clearValue();
              }}
              sx={{
                justifyContent: 'flex-start',
                color: '#007AFF',
                pl: '10px',
              }}
            >
              Сбросить
            </Button>
          </div>
        )}
      </components.Menu>
    );
  };

  const boxShadowInFocus =
    '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 8px 16px rgba(0, 0, 0, 0.1)';

  const style = {
    container: (base: any) => ({
      ...base,
      fontSize: 16,
      width: '100%',
    }),
    placeholder: (base: any) => ({
      ...base,
      color: '#808080',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: '95%',
    }),
    option: (base: any, state: any) => ({
      ...base,
      borderRadius: 4,
      backgroundColor: state.isSelected ? '#FFFFFF' : 'none',
      color: state.isSelected ? '#222222' : 'none',
      padding: '6px 12px 6px 10px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      ':hover': {
        backgroundColor: '#F3F3F4',
      },
    }),
    control: (base: any, state: any) => ({
      ...base,
      borderRadius: 6,
      height: 44,
      borderColor: state.isSelected ? '#BBDEFD' : '#D8D8DD',
      transition: 'all .2s ease',
      ':hover': {
        borderColor: '#80ADFF',
      },
    }),
    menu: (base: any) => ({
      ...base,
      borderRadius: 6,
      paddingTop: 8,
      paddingBottom: 8,
      marginTop: 2,
      marginBottom: 0,
      width: '100%',
      border: '1px solid #D8D8DD',
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
      transition: 'all 0.2s ease',
      ':hover': {
        boxShadow: boxShadowInFocus,
      },
    }),
    menuList: (base: any) => ({
      ...base,
      padding: 0,
      backgroundColor: 'white',
    }),
    valueContainer: (base: any) => ({
      ...base,
      height: 44,
      borderRadius: 6,
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'no-wrap',
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: 'none',
    }),
    indicatorsContainer: (base: any) => ({
      ...base,
      borderRadius: 8,
    }),
    clearIndicator: (base: any) => ({
      ...base,
      paddingRight: 0,
    }),
    dropdownIndicator: (base: any, state: any) => ({
      ...base,
      transition: 'all .2s ease',
      color: '#222222',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none',
    }),
    multiValue: (base: any) => ({
      ...base,
      backgroundColor: 'transparent',
      padding: 0,
    }),
    multiValueRemove: (base: any) => ({
      ...base,
      display: 'none',
    }),
    multiValueLabel: (base: any) => ({
      ...base,
      backgroundColor: 'transparent',
      padding: 0,
      fontSize: '16px',
    }),
  };

  return (
    <Select
      ref={selectRef}
      name={name}
      options={options}
      components={{ Menu }}
      isClearable={false}
      placeholder={placeholder}
      className="react-select-container"
      classNamePrefix="react-select"
      noOptionsMessage={() => 'Нет совпадений'}
      styles={style}
      isMulti
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      id={id}
      value={value || null}
      {...rest}
    />
  );
};

export default MultiSelect;

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { Link, NavLink } from 'react-router-dom';
import { ClickAwayListener, Grid, Tooltip } from '@mui/material';
import { RootState } from '@redux/store';

import { ReactComponent as LogoIcon } from '@assets/img/logo.svg';
import { ReactComponent as LogoShort } from '@assets/img/logo-short.svg';
import { ReactComponent as BetaIcon } from '@assets/img/beta.svg';
import { HeaderServiceWidget } from '@components/Header/HeaderServiceWidget/HeaderServiceWidget';
import { HeaderNotificationWidget } from '@components/Header/HeaderNotificationWidget/HeaderNotificationWidget';
import { HeaderSearchWidget } from '@components/Header/HeaderSearchWidget/HeaderSearchWidget';
import { HeaderProfileWidget } from '@components/Header/HeaderProfileWidget/HeaderProfileWidget';
import Alert from '@components/common/Alert/Alert';

import { ResponsiveContainer } from '../common/ResponsiveContainer/ResponsiveContainer';
import { MENU_ITEMS } from './constants';
import { NavButton } from './NavButton';
import { MobileMenu } from './MobileMenu/MobileMenu';

export const Header = () => {
  const [open, setOpen] = useState(false);

  const user = useSelector((state: RootState) => state.user);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <header className="header container">
        <ResponsiveContainer>
          <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs="auto" display={{ md: 'block', lg: 'none' }}>
              <MobileMenu />
            </Grid>
            <Grid item xs="auto">
              <Grid
                container
                columnSpacing={1}
                alignItems="center"
                position="relative"
              >
                <Link
                  className="header__logo"
                  to="/"
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}
                />
                <Grid item>
                  <Box
                    display={{
                      xs: 'none',
                      md: 'block',
                    }}
                  >
                    <LogoIcon className="header__icon" />
                  </Box>
                  <Box
                    display={{
                      xs: 'block',
                      md: 'none',
                    }}
                  >
                    <LogoShort className="header__icon" />
                  </Box>
                </Grid>
                <Grid item>
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                    <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: '#FFF',
                            color: '#2C3038',
                            width: {
                              xs: 'auto',
                              md: '480px',
                            },
                            maxWidth: 'none',
                            padding: '12px 16px',
                            fontSize: 16,
                            fontWeight: 400,
                            boxShadow:
                              '0 1px 8px rgba(123, 135, 158, 0.12), 0 8px 32px rgba(123, 135, 158, 0.25)',
                          },
                        },
                      }}
                      PopperProps={{
                        placement: 'right',
                        disablePortal: true,
                      }}
                      onClose={handleTooltipClose}
                      open={open}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title="Сервис в стадии разработки. Новые разделы и возможности будут появляться по мере их готовности."
                    >
                      <BetaIcon
                        className="header__icon"
                        onClick={handleTooltipOpen}
                      />
                    </Tooltip>
                  </ClickAwayListener>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs="auto" display={{ xs: 'none', lg: 'block' }}>
              <nav className="header__navigation nav">
                {MENU_ITEMS.map(({ label, path }) => (
                  <NavButton key={path}>
                    <NavLink
                      to={path}
                      activeClassName="active"
                      className="nav__link"
                    >
                      {label}
                    </NavLink>
                  </NavButton>
                ))}
              </nav>
            </Grid>
            <Grid item xs />
            <Grid item xs="auto">
              <div className="header__toolbar toolbar">
                {user?.user && (
                  <Box mr="4px">
                    <HeaderNotificationWidget />
                  </Box>
                )}
                <Box mr="4px">
                  <HeaderServiceWidget />
                </Box>
                <HeaderSearchWidget />
              </div>
            </Grid>
            <Grid item xs="auto" display={{ xs: 'none', md: 'block' }}>
              <HeaderProfileWidget />
            </Grid>
          </Grid>
        </ResponsiveContainer>
      </header>
      <Alert />
    </>
  );
};

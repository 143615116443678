import { styled } from '@mui/material';
import Button from '@mui/material/Button';

export const NavButton = styled(Button)({
  fontFamily: 'inherit',
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '& a': {
    textTransform: 'none',
    textDecoration: 'none',
    transition: 'all 0.3s ease',
    [`&:hover, &.active`]: {
      color: '#0278FF',
    },
  },
});

import { StructureRole } from '@declarations/enum/structureRole';

const getAdminType = (user: any) => {
  const access = user?.realm_access;
  const role = access?.roles;

  return role?.find(
    (item: string) => item === StructureRole.EXTERNAL_IS_ADMINISTRATOR,
  );
};

export default getAdminType;

import dayjs from 'dayjs';
import { Schedule } from '../../types';

export const getHomeWorkList = (schedule?: Schedule) => {
  const list: {
    date: Date;
    work: {
      lesson: string;
      work: string;
    }[];
  }[] = [];

  if (schedule) {
    Object.keys(schedule)?.forEach((key) => {
      const date = dayjs(key, 'DD.MM.YYYY');
      if (date.isValid() && date.isAfter(dayjs())) {
        const work: {
          lesson: string;
          work: string;
        }[] = [];
        Object.values(schedule[key]).forEach((lesson) => {
          work.push({
            lesson: lesson.lesson,
            work: lesson.homework || '',
          });
        });
        if (work.length > 0) {
          list.push({
            date: date.toDate(),
            work,
          });
        }
      }
    });
  }

  return list;
};

export interface FaqFilters {
  education: string | undefined;
  region: string | undefined;
  organization: string | undefined;
  query: string;
}

export interface FaqState {
  faqs: FaqList;
  filter: FaqFilters;
}

export interface FaqList {
  data: Faq[];
  total: number;
}

export enum FaqStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
}

type Gender = 'MALE' | 'FEMALE';

export type Faq = {
  id?: number;
  title: string;
  body: string;
  status: FaqStatus;
  startDate: string;
  endDate: string | null;
  eduLevels: { id: string | number; name?: string }[];
  targetAudience: { id: string | number; name?: string }[];
  genders: Gender[];
  parallels: { id: string | number; name?: string }[];
  regions: { id: string | number; name?: string }[];
  eduOrganizations: number[];
  eduGroupOrganizations: number[];
  isImportant: boolean;
  hasPrivs?: boolean | null;
};

import { groupApi } from '@api/group';
import { SettingsFormType } from '@pages/Manager/CommonForms/SettingsForm';
import { useEffect, useState } from 'react';
import { useSnackbar } from '@hooks/useSnackbar';
import { ERROR_MESSAGE } from '@declarations/enum/uiMessage';

export const useTargetUserCount = (settings: SettingsFormType) => {
  const [userCount, setUserCount] = useState(0);
  const [isLoadError, setIsLoadError] = useState<boolean>(false);
  const [isAllUsers, setIsAllUsers] = useState<boolean>(true);
  const { addSnack } = useSnackbar();

  useEffect(() => {
    const load = async () => {
      let genderIds: string[] = [];
      switch (settings.forWhom) {
        case 'MALE':
          genderIds = ['1'];
          break;
        case 'FEMALE':
          genderIds = ['2'];
          break;
        default:
          genderIds = ['1', '2'];
      }

      try {
        const response = await groupApi.getTargetUserCount({
          audience: settings.audience.map((item) => item.toString()),
          educationLevel: settings.educationLevel.map((item) =>
            item.toString(),
          ),
          region: settings.region.map((item) =>
            item.value.toString().length === 1
              ? `0${item.value.toString()}`
              : item.value.toString(),
          ),
          oo: settings.oo.map((item) => item.toString()),
          ooGroup: settings.ooGroup.map((item) => item.toString()),
          parallel: settings.parallel.map((item) => item.toString()),
          gender: genderIds,
        });

        setIsLoadError(false);
        setIsAllUsers(!response);

        let quantity = 0;
        if (response && response.entry.length > 0) {
          quantity = response.entry[0].resource.quantity;
        }
        setUserCount(quantity);
      } catch (e) {
        console.log(e);
        setIsAllUsers(false);
        setIsLoadError(true);
        addSnack(ERROR_MESSAGE.FAILED_TO_LOAD_TARGET_AUDIENCE, 'error');
      }
    };
    load();
  }, [addSnack, settings]);

  return { userCount, isAllUsers, isLoadError };
};

import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Option } from '@components/common/Select/types';
import { RootState } from '@redux/store';
import { fetchLinkCategoriesFilterOptions } from '@redux/filters';
import MultiSelect, { MultiSelectProps } from './MultiSelect';
import SingleSelect, { SingleSelectProps } from './SingleSelect';
import { MultiValue, SingleValue } from 'react-select';

type Props = Omit<SingleSelectProps | MultiSelectProps, 'options' | 'value'> & {
  isMulti?: boolean;
  value?: number | string;
};

const LinkCategorySelect = ({
  isMulti,
  onChange,
  value,
  ...otherProps
}: Props) => {
  const dispatch = useDispatch();
  const linkCategoryOptions = useSelector(
    (state: RootState) => state.filters.options.linkCategories,
  );

  useEffect(() => {
    if (linkCategoryOptions.length === 0) {
      dispatch(fetchLinkCategoriesFilterOptions());
    }
  }, [dispatch, linkCategoryOptions.length]);

  const selectedValue = useMemo(() => {
    if (!value) {
      return undefined;
    } else if (Array.isArray(value)) {
      return value?.reduce((list, id) => {
        const option = linkCategoryOptions?.find(
          (option) => option.value === id,
        );
        if (option) {
          list.push(option);
        }
        return list;
      }, [] as Option[]);
    }
    return linkCategoryOptions.find((option) => option.value === value);
  }, [value, linkCategoryOptions]);

  if (isMulti) {
    return (
      <MultiSelect
        {...otherProps}
        options={linkCategoryOptions}
        value={selectedValue as Option[]}
        onChange={onChange as (option: MultiValue<Option> | undefined) => void}
      />
    );
  }

  return (
    <SingleSelect
      {...otherProps}
      value={selectedValue as Option}
      hasResetButton={true}
      onChange={onChange as (option: SingleValue<Option> | undefined) => void}
      options={linkCategoryOptions}
    />
  );
};

export default LinkCategorySelect;

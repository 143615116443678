import dayjs from 'dayjs';

/**
 * Получение дат в читаемом виде
 * @param week массив с началом и концом недели
 */
export const getWeekCaption = (week: [Date, Date] | null) => {
  if (!week) {
    return '';
  }
  const start = dayjs(week[0]);
  const end = dayjs(week[1]);
  if (start.isSame(end, 'month')) {
    return `${start.format('D')}—${end.format('D MMM YYYY')}`;
  }
  if (start.isSame(end, 'year')) {
    return `${start.format('D MMM')}—${end.format('D MMM YYYY')}`;
  }
  return `${start.format('D MMM YYYY')}—${end.format('D MMM YYYY')}`;
};

import isEmpty from 'lodash/isEmpty';
import { ApiResponse, Entity } from '@declarations/common';
import {
  EduOrganizationGroup,
  EduOrganizationGroupFilters,
} from '@declarations/eduOrganizationGroup';
import { EduOrganizationGroupType } from '@declarations/enum/eduOrganizationGroup';
import { etdAxios } from './utils/axios';

export const eduOrganizationGroupApi = {
  async search(searchParams: EduOrganizationGroupRequestFilters) {
    const params: {
      [key: string]: string | boolean | number;
    } = {
      isProtected: true,
    };

    if (isEmpty(searchParams)) {
      params['type'] = EduOrganizationGroupType.GROUP;
    } else if (searchParams.type) {
      params['type'] = searchParams.type;
    }

    if (searchParams.name) {
      params['name:contains'] = searchParams.name;
    }
    if (searchParams.organization) {
      params['organization'] = searchParams.organization;
    }

    if (searchParams.id) {
      params['partof'] = searchParams.id;
    }

    const response = await etdAxios.get<
      ApiResponse<Entity<EduOrganizationGroup>>,
      ApiResponse<Entity<EduOrganizationGroup>>
    >('/EduOrganizationGroup', {
      params,
    });

    return response;
  },
  get(id: string) {
    return etdAxios.get<EduOrganizationGroup, EduOrganizationGroup>(
      `/EduOrganizationGroup/${id}`,
      {
        params: {
          isProtected: true,
        },
      },
    );
  },
};

export const getEduOrganizationGroupRequest = async (
  filters: EduOrganizationGroupFilters,
): Promise<Entity<EduOrganizationGroup>[]> => {
  const requestFilters: EduOrganizationGroupRequestFilters = {};

  if (filters.name) {
    requestFilters.name = filters.name;
  }

  // т.к. сейчас нельзя фильтровать ОО по нескольким регионам, то приходится делать несколько запросов
  if (filters.organizations?.length) {
    const responseList = await Promise.allSettled(
      filters.organizations.map((organization) =>
        eduOrganizationGroupApi.search({
          ...requestFilters,
          organization,
        }),
      ),
    );

    const organizations: { [fullUrl: string]: Entity<EduOrganizationGroup> } =
      {};
    responseList?.forEach((response) => {
      if (response.status === 'fulfilled') {
        response.value.entry?.forEach((entry) => {
          organizations[entry.fullUrl] = entry;
        });
      }
    });

    return Object.values(organizations);
  }

  const response = await eduOrganizationGroupApi.search(requestFilters);
  if (response) {
    return response.entry;
  }
  return [];
};

type EduOrganizationGroupRequestFilters = Omit<
  EduOrganizationGroupFilters,
  'organizations'
> & {
  organization?: string;
};

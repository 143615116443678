import Button from '@components/common/Button/Button';
import { Option } from '@components/common/Select/types';

type TogglesProps = {
  value: (string | number)[];
  items: {
    label: string;
    value: string | number;
  }[];
  onChange?: (value: (string | number)[]) => void;
  customHandleClick?: (item: Option, selected: boolean) => void;
};

export const Toggles = ({
  items,
  value,
  onChange,
  customHandleClick,
}: TogglesProps) => {
  const handleClickItem = (item: Option, selected: boolean) => {
    if (!onChange) return;
    if (selected) {
      onChange(value?.filter((val) => val !== item.value));
    } else {
      onChange([...value, item.value]);
    }
  };

  return (
    <>
      {items.map((item) => {
        const selected = value.includes(item.value);
        return (
          <Button
            sx={{ margin: '0 4px 8px 0' }}
            key={`${item.value}${item.label}`}
            size="small"
            variant={selected ? 'first' : 'third'}
            onClick={() =>
              customHandleClick
                ? customHandleClick(item, selected)
                : handleClickItem(item, selected)
            }
          >
            {item.label}
          </Button>
        );
      })}
    </>
  );
};

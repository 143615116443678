import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';
import { Grid, Menu, MenuItem, Typography } from '@mui/material';
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { useSnackbar } from '@hooks/useSnackbar';
import { journalAPI } from '@api/journal';
import { generateLink } from '@lib/common';
import { Journal } from '@declarations/journal';
import Button from '@components/common/Button/Button';
import Table from '@components/common/Table/Table';
import { ROUTES } from '@constants/routes';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';

const columns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Название региона',
    flex: 2,
    minWidth: 150,
  },
  { field: 'regionCode', headerName: 'Код региона', minWidth: 120 },
  { field: 'dataType', headerName: 'Тип токена', minWidth: 110 },
  {
    field: 'dataBaseUrl',
    headerName: 'Базовая ссылка',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'dataTokenUrl',
    headerName: 'Ссылка для токена',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'dataApiUrl',
    headerName: 'Ссылка для журнала',
    flex: 1,
    minWidth: 100,
  },
];

const PER_PAGE_OPTIONS = [20, 40];

const JournalList = () => {
  const history = useHistory();
  const { addSnack } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState<Journal[]>([]);
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [journals, setJournals] = useState<{ total: number; data: Journal[] }>({
    total: 0,
    data: [],
  });
  const regionOptions = useSelector(
    (state: RootState) => state.filters.options.regions,
  );

  const rows = useMemo(() => {
    if (regionOptions?.length && journals.data.length) {
      return journals.data.map((item) => {
        return {
          id: item.id,
          title: regionOptions.reduce(
            (r, e) => r || (e.value === +item.regionCode ? e.label : ''),
            '',
          ),
          regionCode: item.regionCode,
          dataType: item.dataType,
          dataBaseUrl: item.dataBaseUrl,
          dataTokenUrl: item.dataTokenUrl,
          dataApiUrl: item.dataApiUrl,
        };
      });
    }
    return [];
  }, [journals.data, regionOptions]);

  const [pagination, setPagination] = useState({
    pageSize: PER_PAGE_OPTIONS[0],
    currentPage: 1,
  });

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await journalAPI.getAll();
      setJournals({
        data: data || [],
        total: data.length || 0,
      });
    } catch (err) {
      console.error(err);
      addSnack('Ошибка загрузки данных', 'error');
    } finally {
      setLoading(false);
    }
  }, [addSnack]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleRowSelect = useCallback(
    (selectionModel: GridSelectionModel) => {
      setSelectedRegion(
        journals.data?.filter((newsItem) =>
          selectionModel.includes(newsItem.id || 0),
        ),
      );
    },
    [journals.data],
  );

  const handlePageChange = useCallback((page: number) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: page + 1,
    }));
  }, []);

  const handlePageSizeChange = useCallback((pageSize: number) => {
    setPagination((prev) => ({
      ...prev,
      pageSize,
    }));
  }, []);

  const handleContextMenu = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX - 2,
              mouseY: event.clientY - 4,
            }
          : null,
      );
    },
    [contextMenu],
  );

  const handleEdit = useCallback(() => {
    if (selectedRegion.length) {
      if (selectedRegion.length > 1) {
        return addSnack('Массовое редактирование не поддерживается', 'error');
      }
      const selectedRegionItem = selectedRegion[0];
      if (selectedRegionItem.regionCode) {
        const link = generateLink(ROUTES.managerJournalItem, {
          id: selectedRegionItem.regionCode,
        });
        history.push(link);
      }
    } else {
      addSnack('Необходимо выбрать регион для редактирования', 'error');
    }
  }, [history, selectedRegion, addSnack]);

  const contextMenuItems = useMemo(() => {
    const menuItems: JSX.Element[] = [];
    menuItems.push(
      <MenuItem key="view" onClick={handleEdit}>
        Изменить
      </MenuItem>,
    );

    return menuItems;
  }, [handleEdit]);

  const onContextMenu = useCallback(
    (e: any) => {
      if (e.type === 'contextmenu') {
        e.preventDefault();
        const closestParent = e?.target?.closest('div[data-id]');
        if (closestParent && selectedRegion.length === 0) {
          const id = closestParent.dataset?.id;
          if (id) {
            const newsItem = journals.data.find((item) => item.id == id);
            newsItem && setSelectedRegion([newsItem]);
          }
          handleContextMenu(e);
        }
      }
    },
    [handleContextMenu, journals.data, selectedRegion.length],
  );

  const handleCloseContextMenu = useCallback(() => {
    setSelectedRegion([]);
    setContextMenu(null);
  }, []);

  return (
    <div>
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {contextMenuItems}
      </Menu>
      <Grid container>
        <Grid item xs alignItems="center">
          <Typography variant="h2" sx={{ fontWeight: '500', fontSize: '18px' }}>
            Управление журналами
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="first"
            to={ROUTES.managerJournalAdd}
            startIcon={<PlusIcon />}
          >
            Добавить
          </Button>
        </Grid>
      </Grid>
      <div
        style={{ height: 'auto', width: '100%' }}
        onContextMenu={onContextMenu}
      >
        <Table
          columns={columns}
          rows={rows}
          rowCount={journals.total}
          page={pagination.currentPage - 1}
          pageSize={pagination.pageSize}
          loading={loading}
          handleRowSelect={handleRowSelect}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>
    </div>
  );
};

export default JournalList;

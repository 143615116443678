import { FunctionalRole } from '@declarations/role';
import { EntityTypes } from '@mock-data/entities';
import { AccountEntry } from '@redux/user/types';

export const getUserFunctionalRoles = (entries: AccountEntry[]) =>
  entries?.reduce((total, entity) => {
    if (
      entity.resource.resourceType === EntityTypes.FUNCTIONAL_ROLE_TYPE &&
      entity.resource.status === 'active'
    ) {
      total.push(entity.resource);
    }
    return total;
  }, [] as FunctionalRole[]);

import { getContentCredentials } from '@lib/getContentCredentials';
import {
  getFunctionalRoleNames,
  getUserRole,
  isAdminETD,
  isAdminOO,
  isAdminOOGroup,
  isExternalISAdmin,
} from '@redux/user';
import { EUserRole } from '@redux/user/types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useContentCredentials = () => {
  const functionRoleNames = useSelector(getFunctionalRoleNames);
  const isAdminIS = useSelector(isExternalISAdmin);
  const userRole = useSelector(getUserRole);
  const isAdministratorOO = useSelector(isAdminOO);
  const isAdministratorOOGroup = useSelector(isAdminOOGroup);
  const isAdminEtd = useSelector(isAdminETD);
  const adminIS = useMemo(() => {
    return isAdminIS && userRole === EUserRole.ADMIN;
  }, [isAdminIS, userRole]);

  if (isAdminEtd || isAdministratorOO || isAdministratorOOGroup || adminIS) {
    return {
      news: isAdministratorOO || isAdministratorOOGroup || adminIS,
      faq: isAdministratorOO || isAdministratorOOGroup || adminIS,
      links: isAdministratorOO || isAdministratorOOGroup || adminIS,
      quiz: isAdministratorOO || isAdministratorOOGroup || adminIS,
      journal: isAdminEtd,
      diary: isAdminEtd,
      administration: adminIS,
    };
  }

  return getContentCredentials(functionRoleNames);
};

export enum SearchSource {
  ALL = 'all',
  BASE = 'base',
  LIBRARY = 'library',
}

export enum SYSTEM_TYPE {
  BASE_SYSTEM = 'BASE_SYSTEM',
  LIBRARY = 'LIBRARY',
}

import { Button as MuiButton, ButtonBaseProps } from '@mui/material';
import { Link } from 'react-router-dom';

interface ButtonProps extends ButtonBaseProps {
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'first' | 'second' | 'third' | 'fourth' | 'delete';
  color?:
    | 'inherit'
    | 'error'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning';
  startIcon?: any;
  endIcon?: any;
  fullWidth?: boolean;
  to?: string;
  target?: string;
  externalLink?: string;
}

const Button = ({
  children,
  size = 'medium',
  variant = 'text',
  startIcon,
  endIcon,
  fullWidth = false,
  to,
  target,
  externalLink,
  ...rest
}: ButtonProps) => {
  if (to) {
    return (
      <Link to={to} style={{ textDecoration: 'none' }} target={target}>
        <MuiButton
          size={size}
          variant={variant}
          startIcon={startIcon}
          endIcon={endIcon}
          fullWidth={fullWidth}
          {...rest}
        >
          {children}
        </MuiButton>
      </Link>
    );
  }
  if (externalLink) {
    return (
      <a href={externalLink} style={{ textDecoration: 'none' }} target={target}>
        <MuiButton
          size={size}
          variant={variant}
          startIcon={startIcon}
          endIcon={endIcon}
          fullWidth={fullWidth}
          {...rest}
        >
          {children}
        </MuiButton>
      </a>
    );
  }
  return (
    <MuiButton
      size={size}
      variant={variant}
      startIcon={startIcon}
      endIcon={endIcon}
      fullWidth={fullWidth}
      {...rest}
    >
      {children}
    </MuiButton>
  );
};

export default Button;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FaqFilters, FaqState, FaqList } from './types';

const initialState: FaqState = {
  faqs: {
    data: [],
    total: 0,
  },
  filter: {
    education: undefined,
    region: undefined,
    organization: undefined,
    query: '',
  },
};

const slice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    setFaqs: (state: FaqState, action: PayloadAction<FaqList>) => ({
      ...state,
      faqs: {
        data: action.payload.data ? [...action.payload.data] : [],
        total: action.payload.total,
      },
    }),
    addFaqs: (state: FaqState, action: PayloadAction<FaqList>) => ({
      ...state,
      faqs: {
        data: [
          ...state.faqs.data,
          ...(action.payload.data ? [...action.payload.data] : []),
        ],
        total: action.payload.total,
      },
    }),
    setFilters: (state: FaqState, action: PayloadAction<FaqFilters>) => ({
      ...state,
      filter: action.payload,
    }),
  },
});

export const { setFaqs, addFaqs } = slice.actions;

export * from './selectors';

export default slice.reducer;

import { ReactNode } from 'react';

import './style.scss';

type Props = {
  children: ReactNode;
  label: ReactNode;
  required?: boolean;
};

export const FormField = ({ children, label, required }: Props) => (
  <div className="form-field">
    <div className="form-field__label">
      {label}{' '}
      {required && <span className="form-field__label-required">*</span>}
    </div>
    <div className="form-field__body">{children}</div>
  </div>
);

import { Grid } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  label: string;
  children: ReactNode;
};

export const ValueField = ({ label, children }: Props) => {
  return (
    <Grid
      container
      flexWrap="nowrap"
      columnSpacing={{
        xs: 0,
        md: 2,
      }}
      rowSpacing={{
        xs: 1,
        md: 0,
      }}
      flexDirection={{
        xs: 'column',
        md: 'row',
      }}
    >
      <Grid
        item
        maxWidth={{
          xs: '100%',
          md: '150px',
        }}
        minWidth="150px"
        fontSize={{
          xs: '14px',
          md: '16px',
        }}
      >
        {label}
      </Grid>
      <Grid
        item
        fontSize={{
          xs: '18px',
          md: '16px',
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
};

import { FileDto } from '@declarations/common';

export type Link = {
  id?: number | string;
  title: string;
  url: string;
  status: LinkStatus;
  categoryId: number | string;
  eduLevels: { id: string | number; name?: string }[];
  targetAudience: { id: string | number; name?: string }[];
  genders: Gender[];
  parallels: { id: string | number; name?: string }[];
  regions: { id: string | number; name?: string }[];
  eduOrganizations?: any;
  eduGroupOrganizations?: any;
  startDate: string;
  endDate: string;
  files: (
    | {
        id?: number;
        path?: string;
      }
    | FileDto
  )[];
  img?: number | null;
  hasPrivs?: boolean | null;
};

type Gender = 'MALE' | 'FEMALE' | null;

export enum LinkStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
}

export type LinkList = Link[];

export enum LinkCategoryId {
  STATE_EDUCATIONAL_ORG = '1',
  COMPETITIONS_OLYMPIADS_PROJECTS = '2',
  FREE_LITERATURE_LIBRARIES = '3',
  VIRTUAL_TOURS = '4',
  OTHER_EDUCATIONAL_RESOURCES = '5',
}

export enum LinkCategoryIdDisplay {
  STATE_EDUCATIONAL_ORG = 'Подготовка к экзаменам, образовательные организации',
  COMPETITIONS_OLYMPIADS_PROJECTS = 'Конкурсы, олимпиады, проекты',
  FREE_LITERATURE_LIBRARIES = 'Библиотеки бесплатной литературой',
  VIRTUAL_TOURS = 'Виртуальные экскурсии',
  OTHER_EDUCATIONAL_RESOURCES = 'Другие образовательные ресурсы',
}

import React from 'react';
import Button from '@mui/material/Button';
import './HeaderProfileWidget.scss';
import { Link } from 'react-router-dom';
import { Popper, ClickAwayListener, MenuItem, Divider } from '@mui/material';
import { useUserMenu } from '@hooks/useUserMenu';
import { UserAvatar } from '../UserAvatar/UserAvatar';

export const HeaderProfileWidget: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleAwayClick = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'profile-popper' : undefined;

  const { isAuth, routes, userInfo, userName } = useUserMenu();

  return (
    <>
      {isAuth ? (
        <ClickAwayListener onClickAway={handleAwayClick}>
          <UserAvatar onClick={handleClick}>
            {userInfo.initials}
            <Popper
              id={id}
              open={open}
              anchorEl={anchorEl}
              placement="bottom-end"
              className="profile-popper"
            >
              <div className="profile-menu">
                <MenuItem
                  disabled
                  className="profile-menu__item profile-menu__item--header"
                  sx={{
                    opacity: '1!important',
                    fontWeight: '500',
                    marginBottom: '5px',
                    whiteSpace: 'break-spaces',
                  }}
                >
                  {userName}
                </MenuItem>
                {routes.authorized.main.map((item) => (
                  <Link
                    key={item.url}
                    to={item.url}
                    style={{ textDecoration: 'none' }}
                  >
                    <MenuItem
                      className="profile-menu__item"
                      sx={{ borderRadius: '6px' }}
                    >
                      {item.label}
                    </MenuItem>
                  </Link>
                ))}
                {routes.authorized.secondary.length > 0 && (
                  <>
                    <Divider sx={{ margin: '8px 0' }} />
                    {routes.authorized.secondary.map((item) => (
                      <a
                        key={item.url}
                        href={item.url}
                        onClick={item.onClick}
                        style={{ textDecoration: 'none' }}
                      >
                        <MenuItem
                          className="profile-menu__item profile-menu__item--logout"
                          sx={{ borderRadius: '6px' }}
                        >
                          {item.label}
                        </MenuItem>
                      </a>
                    ))}
                  </>
                )}
              </div>
            </Popper>
          </UserAvatar>
        </ClickAwayListener>
      ) : (
        routes.unauthorized.map((item) => (
          <a
            className="header__login-link"
            key={item.url}
            href={item.url}
            onClick={item.onClick}
          >
            <Button variant="first">{item.label}</Button>
          </a>
        ))
      )}
    </>
  );
};

import { Filter } from '@declarations/common';
import dayjs from 'dayjs';

export const getSearchQueryParams = (
  pagination: { currentPage: number; pageSize: number },
  filters: Partial<Filter>,
) => {
  const queryParams: { [key: string]: any } = {
    page: pagination.currentPage - 1,
    size: pagination.pageSize,
  };

  if (filters.status) {
    queryParams['status.equals'] = filters.status;
  }

  if (filters.isImportant?.length) {
    queryParams['sort'] = filters.isImportant;
  }

  if (filters.search) {
    queryParams['title.contains'] = filters.search;
  }

  if (filters.period) {
    const [startDate, endDate] = filters.period;

    if (startDate) {
      startDate.setHours(0, 0, 0, 0);
      queryParams['startDate.greaterThanOrEqual'] = startDate.toISOString();
    }

    if (endDate) {
      endDate.setHours(23, 59, 59, 0);
      queryParams['endDate.lessThanOrEqual'] = endDate.toISOString();
    }
  } else {
    queryParams['endDate.specified'] = false;
  }

  if (filters.region?.length) {
    queryParams['target.regionId.in'] = filters.region;
  }

  if (filters.audience?.length) {
    queryParams['target.targetAudienceId.in'] = filters.audience;
  }

  if (filters.educationLevel?.length) {
    queryParams['target.EduLevelId.in'] = filters.educationLevel;
  }

  if (filters.oo?.length) {
    queryParams['target.eduOrganizationId.in'] = filters.oo;
  }

  if (filters.ooGroup?.length) {
    queryParams['target.eduGroupOrganizationId.in'] = filters.ooGroup;
  }

  if (filters.parallel) {
    queryParams['target.parallelId.in'] = filters.parallel;
  }

  if (filters.category) {
    queryParams['categoryId.equals'] = filters.category;
  }

  if (filters.forWhom && filters.forWhom !== 'all') {
    queryParams['target.gender.equals'] = filters.forWhom;
  }

  if (filters.time) {
    const today = dayjs().hour(23).minute(59).second(59).toISOString();
    switch (filters.time) {
      case 'DAY':
        queryParams['statusDate.lessThanOrEqual'] = today;
        queryParams['statusDate.greaterThanOrEqual'] = dayjs(today)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .subtract(1, 'day')
          .toISOString();
        break;
      case 'WEEK':
        queryParams['statusDate.lessThanOrEqual'] = today;
        queryParams['statusDate.greaterThanOrEqual'] = dayjs(today)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .subtract(1, 'week')
          .toISOString();
        break;
    }
  }
  return queryParams;
};

import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import dayjs from 'dayjs';
// import { NewsDetailImageSlider } from '@components/pages/NewsDetailPage/NewsDetailImageSlider/NewsDetailImageSlider';
import Button from '@components/common/Button/Button';
import { ReactComponent as SuccessIcon } from '@assets/icons/alert/success.svg';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import { NewsDetailPageNewsSlider } from '@components/pages/NewsDetailPage/NewsDetailPageNewsSlider/NewsDetailPageNewsSlider';
import { News } from '@declarations/news';
import { newsAPI } from '@api/news';
import { Link, useParams } from 'react-router-dom';
import { FileDto } from '@declarations/common';
import { IMAGE_BASE_URL } from '@constants/config';
import { useSnackbar } from '@hooks/useSnackbar';
import EmptyData from '@components/common/EmptyData/EmptyData';
import { PREVIEW_ID_VALUE, PREVIEW_LS_KEY } from '@constants/news';
import { UI_MESSAGE } from '@declarations/enum/uiMessage';

type RouteParams = { id: string };

export const NewsDetailPage = () => {
  const { id } = useParams<RouteParams>();
  const { addSnack } = useSnackbar();
  const [newsItem, setNewsItem] = useState<News>();
  const [loading, setLoading] = useState(true);

  const { imagePath, files } = useMemo(() => {
    const result: { imagePath: string | null; files: FileDto[] } = {
      imagePath: null,
      files: [],
    };
    if (newsItem) {
      const files = [...newsItem.files] as FileDto[];
      const imgFileIndex = files.findIndex((file) => file.id === newsItem.img);
      if (~imgFileIndex) {
        const imageFiles = files.splice(imgFileIndex, 1);
        if (imageFiles.length) {
          result.imagePath = `${IMAGE_BASE_URL}${imageFiles[0].path}`;
        }
      }
      const pdfFile = files?.filter((file) => file.format === 'pdf')[0];
      // TODO: Костылек, чтобы показывать только pdf файлы, убрать, когда починят бэкенд
      result.files = pdfFile ? [pdfFile] : [];
    }
    return result;
  }, [newsItem]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const subject = newsItem?.tags.find(
  //   (item: { name: string }) => item.name === 'area',
  // )?.values[0];
  const [copied, setCopied] = useState(false);

  useLayoutEffect(() => {
    const loadData = async () => {
      try {
        let data: News | undefined = undefined;
        setLoading(true);

        if (id === PREVIEW_ID_VALUE) {
          const json = localStorage.getItem(PREVIEW_LS_KEY);
          if (json) {
            try {
              data = JSON.parse(json) as News;
            } catch (err) {
              console.log('cannot parse preview data');
            }
          }
        } else {
          data = await newsAPI.get(+id);
        }

        if (data) {
          setNewsItem(data);
        } else {
          throw new Error('data is undefined');
        }
      } catch (err) {
        console.log(err);
        addSnack(UI_MESSAGE.NEWS_NOT_AVAILABLE, 'error');
      } finally {
        setLoading(false);
      }
    };
    loadData();

    return () => {
      // Очищаем данные превью при размонтировании компонента
      id === PREVIEW_ID_VALUE && localStorage.removeItem(PREVIEW_LS_KEY);
    };

    // не хватает addSnack, но не указываю специально
    // eslint-disable-next-line
  }, [id]);

  // TODO add loading
  if (loading) {
    return <div>Loading</div>;
  }

  // TODO handle no data
  if (newsItem === undefined) {
    return <EmptyData />;
  }

  return (
    <>
      <div className="news-page">
        {/* <div className={'news-page__left'}> */}
        <Grid
          container
          alignItems="center"
          columnSpacing={0.5}
          mt={{
            xs: 3,
            md: 4,
            lg: 6,
          }}
        >
          <Grid
            item
            sx={{
              fontSize: 14,
              fontWeight: 500,
              display: 'flex',
              alignItems: 'center',
              color: '#007AFF',
              position: 'relative',
            }}
          >
            <Link
              to="."
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
            />
            <ChevronLeftIcon
              style={{
                height: 16,
              }}
              color="primary"
            />{' '}
            <span>Новости</span>
          </Grid>
          {newsItem.statusDate && (
            <Grid item>
              <Typography
                variant="caption"
                sx={{
                  fontSize: 14,
                  color: '#626B7A',
                }}
              >
                {' '}
                · {dayjs(newsItem.statusDate).format('HH:mm, D MMMM YYYY')}
              </Typography>
            </Grid>
          )}
        </Grid>

        <Typography
          className={'news-page__title'}
          variant={'h1'}
          fontWeight={500}
          fontSize={{
            xs: 24,
            lg: 40,
          }}
          mt={{
            xs: 2,
            md: 3,
            xl: 2,
          }}
          mb={0}
        >
          {newsItem.title}
        </Typography>
        {imagePath && (
          <Box
            mt={{
              xs: 3,
              lg: 4,
              xl: 5,
            }}
            mb={{
              xs: 3,
              lg: 4,
            }}
            maxWidth={870}
          >
            <img className={'news-page__img'} src={imagePath} />
          </Box>
        )}
        <div className={'news-page__body-wrapper'}>
          <div dangerouslySetInnerHTML={{ __html: newsItem.body }} />
        </div>
        {/* {newsItem.attachments?.length > 1 && (
            <NewsDetailImageSlider
              slides={newsItem.attachments.filter(
                (item: any, index: number) => index !== 0,
              )}
            />
          )} */}
        {files.length > 0 && (
          <>
            <div>Прикрепленные файлы:</div>
            <div>
              {files.map((file) => (
                <a
                  key={`${IMAGE_BASE_URL}${file.path}`}
                  href={`${IMAGE_BASE_URL}${file.path}`}
                  download={file.originalFileName}
                  className="news-page__file-item"
                >
                  {file.originalFileName}
                </a>
              ))}
            </div>
          </>
        )}

        <div className={'news-page__line'} />
        <div className={'news-page__exta-info'}>
          {/* <p className={'news-page__sources-item'}>
                <span className={'news-page__sources-item-title'}>
                  Источник:{' '}
                </span>
                <span className={'news-page__sources-item-name'}>
                  Министерство просвещения Российской Федерации
                </span>
              </p> */}
          <Grid
            container
            flexDirection={{
              xs: 'column',
              lg: 'row',
            }}
            justifyContent={{
              lg: 'space-between',
            }}
          >
            {newsItem.regions.length > 0 && (
              <Grid item>
                <Grid
                  container
                  columnSpacing={{ xs: 1 }}
                  flexDirection={{ sx: 'column', lg: 'row' }}
                >
                  <Grid item color="#626B7A" fontSize={14}>
                    Субъект{newsItem.regions.length > 1 && 'ы'} РФ:{' '}
                  </Grid>
                  <Grid item fontSize={14}>
                    {newsItem.regions.map((region) => region.name).join(', ')}
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid
              item
              mt={{
                xs: 3,
                lg: 0,
              }}
            >
              <div className={'news-page__share'}>
                {copied && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: '#626B7A',
                      fontWeight: 500,
                      mr: 1,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    mb={{
                      xs: 1,
                      lg: 0,
                    }}
                    mr={{
                      xs: 0,
                      lg: 1,
                    }}
                  >
                    <SuccessIcon style={{ marginRight: '4px' }} />
                    Скопировано
                  </Typography>
                )}
                <CopyToClipboard
                  text={window.location.href}
                  onCopy={() => setCopied(true)}
                >
                  <Button fullWidth variant="third">
                    Поделиться
                  </Button>
                </CopyToClipboard>
              </div>
            </Grid>
          </Grid>
        </div>
        {/* </div> */}
        {/* <div className={'news-page__right'}>
          {news.data
            .filter((item: any) => item.id !== newsItem.id)
            .map((item) => {
              return (
                <NewsItemReg
                  key={item.title}
                  text={item.title}
                  reg={item.tags?.find((i: any) => i.name === 'area')?.values}
                  date={item.publishDate}
                  id={item.id}
                />
              );
            })}
        </div> */}
      </div>

      {/* <NewsDetailPageNewsSlider
        slides={news.data.filter((item: any) => item.id !== newsItem.id)}
      /> */}
    </>
  );
};

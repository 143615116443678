import * as React from 'react';
import Button from '@mui/material/Button';
import { Link, NavLink } from 'react-router-dom';
import { Avatar, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { getUserInfo, getIsAuth } from '@redux/user';
import { ResponsiveContainer } from '@components/common/ResponsiveContainer/ResponsiveContainer';
import './HeaderTV.scss';
import { ReactComponent as Logo } from '@assets/img/logoTV.svg';
import { loginLink } from '@lib/auth/getAuthLinks';
import { deleteCookie } from '@lib/cookie';
import { COOKIE_NAMES, MAIN_DOMAIN, MY_FILES_LINK } from '@constants/config';
import { ROUTES } from '@constants/routes';

export const HeaderTV: React.FC<{ type?: string }> = () => {
  const userInfo = useSelector(getUserInfo);
  const isAuth = useSelector(getIsAuth);

  return (
    <header className="header-tv container">
      <ResponsiveContainer
        className="header-tv__container"
        sx={{ py: 3, mb: 5 }}
      >
        <Grid
          container
          columnSpacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs="auto" display={{ xs: 'block', md: 'none' }}>
            <div className="menu__trigger">
              <div className="menu__trigger-line" />
            </div>
          </Grid>
          <Grid
            className="header-tv__logo"
            item
            xs="auto"
            container
            sx={{ alignItems: 'center' }}
          >
            <Grid
              item
              container
              sx={{ alignItems: 'center' }}
              component={Link}
              to={ROUTES.main}
            >
              <Logo />
            </Grid>
          </Grid>
          <Grid item xs="auto">
            <Grid item container columnSpacing={3}>
              <Grid
                item
                component="a"
                href={`${MY_FILES_LINK}Products/Files/`}
                className="header-tv__nav-link"
              >
                Мои файлы
              </Grid>
              <Grid
                item
                component={NavLink}
                activeClassName="active"
                exact
                to={ROUTES.smartLib}
                className="header-tv__nav-link"
              >
                Библиотека
              </Grid>
              <Grid
                item
                component={NavLink}
                activeClassName="active"
                exact
                to={ROUTES.smartEducation}
                className="header-tv__nav-link"
              >
                Мое просвещение
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs="auto" display={{ xs: 'none', md: 'block' }}>
            {!isAuth ? (
              <Button variant="first">
                <a
                  className="header-tv__login-link"
                  href={loginLink}
                  onClick={() =>
                    deleteCookie(COOKIE_NAMES.ACCESS_TOKEN, MAIN_DOMAIN)
                  }
                >
                  Войти
                </a>
              </Button>
            ) : (
              <Link tabIndex={-1} className="user" to={ROUTES.profile}>
                {/*<span className="user__name">{userName}</span>*/}
                {/*<img src={ava} className={'user__logo'} alt="" />*/}
                <Avatar
                  sx={{
                    bgcolor: '#1C3154',
                    color: '#FFFFFF',
                    fontSize: 15,
                    fontWeight: 500,
                  }}
                  variant="circular"
                >
                  {userInfo.initials}
                </Avatar>
              </Link>
            )}
          </Grid>
        </Grid>
      </ResponsiveContainer>
    </header>
  );
};

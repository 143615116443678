import { SEARCH_API_URL } from '@constants/config';
import axios from 'axios';
import {
  dataResponseInterceptor,
  authResponseErrorInterceptor,
  authRequestInterceptor,
} from '../interceptors';
import qs from 'query-string';

export const instance = axios.create({
  baseURL: `${SEARCH_API_URL}fts`,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'none' }),
});

instance.interceptors.request.use(authRequestInterceptor);

instance.interceptors.response.use(
  dataResponseInterceptor,
  authResponseErrorInterceptor(instance),
);

import { Grid, Typography } from '@mui/material';

import Button from '@components/common/Button/Button';
import { ROUTES } from '@constants/routes';

type Props = {
  link?: string;
};

export const MyDayBook = ({ link = ROUTES.notFound }: Props) => {
  return (
    <div className="profile-block__daybook">
      <Typography variant="h4" fontWeight="500" align="center">
        Подключаем дневник
      </Typography>
      <Grid container justifyContent="center" sx={{ marginTop: 2 }}>
        <div className="profile-block__description">
          Ожидаемое время подключения 1 декабря 2024 года
        </div>
      </Grid>

      <Grid container justifyContent="center">
        <Button
          externalLink={link}
          sx={{ marginTop: 4 }}
          size="large"
          variant="first"
        >
          Перейти в дневник
        </Button>
      </Grid>
    </div>
  );
};

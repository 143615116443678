import { FC, useCallback, useMemo, useState } from 'react';
import Button from '@components/common/Button/Button';
import {
  Box,
  Grid,
  Modal,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import StyledRadio from '@components/common/StyledRadio/StyledRadio';
import { ReactComponent as CloseIcon } from '@assets/icons/ico-close.svg';
import OrganizationSelect from '@components/common/Select/Organization';
import { profileRoles } from '@mock-data/profile';
import { ProfileCategory } from '@declarations/enum/profile';
import { Definitions, Entity, isOfType } from '@declarations/common';
import { StructureRole } from '@declarations/enum/structureRole';
import { EntityTypes } from '@mock-data/entities';
import { UserRoleStatus } from '@redux/user/types';
import { PupilRole } from '@declarations/role';
import { userApi } from '@api/user';
import { AuthAccount } from '@declarations/profile';
import EduGroupSelect from '@components/common/Select/EduGroup';
import { EduGroup } from '@declarations/eduGroup';
import { ReactComponent as QuestionIcon } from '@assets/icons/ico-question.svg';
import { useSnackbar } from '@hooks/useSnackbar';

interface ProfileStudentChangeInfoProps {
  userBirthday?: string;
  userMail?: string;
  onReloadRoleList: () => void;
  authAccount?: AuthAccount;
}

interface PupilFormData {
  role: StructureRole;
  attendanceForm: Definitions.Coding;
  organizationId?: string;
  eduGroup?: Entity<EduGroup>;
}

const AttendanceForm = {
  [StructureRole.OO_STUDENT]: {
    code: 'очная',
    display: 'Очная',
  },
  [StructureRole.FAMILY_STUDENT]: {
    code: 'заочная',
    display: 'Заочная',
  },
  [StructureRole.SPO_STUDENT]: {
    code: 'очная',
    display: 'Очная',
  },
};

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 780,
  bgcolor: '#FFFFFF',
  borderRadius: '8px',
  boxShadow:
    '0px 1px 8px rgba(123, 135, 158, 0.12), 0px 8px 32px rgba(123, 135, 158, 0.25)',
  p: 4,
};

const STUDENT_WITH_PASSPORT = 14;

const ProfileStudentChangeInfo: FC<ProfileStudentChangeInfoProps> = ({
  userBirthday,
  onReloadRoleList,
  authAccount,
  // userMail,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [activeSave, setActiveSave] = useState<boolean>(false);
  const [formData, setFormData] = useState<PupilFormData>({
    role: StructureRole.OO_STUDENT,
    attendanceForm: AttendanceForm[StructureRole.OO_STUDENT],
  });
  const { addSnack } = useSnackbar();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const canChangeInfo = useMemo(() => {
    if (userBirthday) {
      const studentAge = Date.parse(userBirthday);
      const dateNow = Date.now();
      const diff = dateNow - studentAge;
      const diffYear: number = new Date(diff).getFullYear() - 1970;
      return diffYear >= STUDENT_WITH_PASSPORT;
    }
  }, [userBirthday]);

  const handleChangeFormData = useCallback(
    (newData: { [key: string]: any }) =>
      setFormData((prevState: any) => ({
        ...prevState,
        ...newData,
      })),
    [],
  );

  const pupilProfile = useMemo(() => {
    if (authAccount?.pupilProfile) {
      return authAccount.pupilProfile;
    }
  }, [authAccount?.pupilProfile]);

  const attendanceForm = useMemo(() => {
    switch (formData.role) {
      case StructureRole.OO_STUDENT:
        return AttendanceForm[StructureRole.OO_STUDENT];
      case StructureRole.FAMILY_STUDENT:
        return AttendanceForm[StructureRole.FAMILY_STUDENT];
      case StructureRole.SPO_STUDENT:
        return AttendanceForm[StructureRole.SPO_STUDENT];
    }
  }, [formData.role]);

  // const pupilRoleId = useMemo(() => {
  //   if (userRoleList?.profileRoles?.roleListAll?.length) {
  //     const roleId: Entity<PupilRole> =
  //       userRoleList?.profileRoles?.roleListAll[0];
  //     return roleId.resource.id;
  //   }
  // }, [userRoleList?.profileRoles?.roleListAll]);

  const handleChangePersonalInfo = useCallback(
    async (formData: PupilFormData) => {
      setActiveSave(true);
      const data = {
        resourceType: EntityTypes.PUPIL_ROLE,
        role: {
          code: formData.role,
        },
        pupilProfile,
        period: {
          start: new Date().toISOString(),
        },
        attendanceForm,
        organization: {
          reference:
            formData.organizationId &&
            `${EntityTypes.ORGANIZATION}/${formData.organizationId}`,
        },
        eduGroup: {
          reference:
            formData.eduGroup &&
            formData.eduGroup.resource &&
            `${EntityTypes.EDU_GROUP}/${formData.eduGroup.resource.id}`,
        },
        status: UserRoleStatus.CREATED_BY_SELF,
      };
      try {
        // if (pupilRoleId) {
        const res = await userApi.addPupilRole(data as PupilRole);
        if (!res) {
          throw new Error('Ошибка при изменении данных');
        }
        setTimeout(() => onReloadRoleList(), 3000);
        handleChangeFormData({
          organizationId: undefined,
          eduGroup: undefined,
        });
        setActiveSave(false);
        handleClose();
        // }
      } catch (err) {
        console.error(err);
        setActiveSave(false);
        addSnack('Произошла ошибка', 'error');
      }
    },
    [
      attendanceForm,
      handleChangeFormData,
      addSnack,
      onReloadRoleList,
      pupilProfile,
    ],
  );

  const eduGroupFilters = useMemo(
    () => ({
      organizationId: formData.organizationId,
    }),
    [formData.organizationId],
  );

  return (
    <Grid container>
      <Grid item>
        {canChangeInfo && (
          <Button variant="fourth" onClick={handleOpen}>
            Изменить
          </Button>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid container flexDirection="column" sx={style}>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={0}
            >
              <Grid item>
                <Typography id="modal-modal-title" variant="h3" component="h2">
                  Персональная информация
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  sx={{ width: '36px', height: '36px', p: 0, minWidth: 0 }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </Button>
              </Grid>
            </Grid>
            <Grid item container spacing={3} flexDirection="column">
              <Grid
                item
                container
                spacing={3}
                sx={{
                  alignItems: 'flex-start',
                  flexWrap: 'nowrap',
                }}
              >
                <Grid item md={3} flexShrink={0}>
                  <Typography
                    variant={'additionally' as 'h1'}
                    sx={{ display: 'flex', alignItems: 'center', height: 36 }}
                  >
                    Место обучения
                  </Typography>
                </Grid>
                <Grid item container flexDirection="column">
                  {profileRoles[ProfileCategory.STUDENT].map((role) => (
                    <Grid item key={role.code}>
                      <StyledRadio
                        name="role"
                        value={role.code}
                        checked={formData.role === role.code}
                        label={role.display}
                        onChange={() =>
                          handleChangeFormData({
                            role: role.code,
                          })
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item container spacing={3} sx={{ alignItems: 'center' }}>
                <Grid item md={3}>
                  <Typography variant={'additionally' as 'h1'}>
                    Образовательная организация
                  </Typography>
                </Grid>
                <Grid item flexGrow={1}>
                  <Box display={'flex'}>
                    <Box flexGrow={1} maxWidth="416px">
                      <OrganizationSelect
                        name="organization"
                        id="organization"
                        // isDisabled={!formData.region}
                        placeholder="выбрать"
                        value={formData.organizationId}
                        // placeholder={
                        //   formData.region
                        //     ? 'выбрать'
                        //     : 'сначала выберите Населенный пункт'
                        // }
                        //selected={formData.organization}
                        // filters={{
                        //   regionCode: formData.region?.id,
                        // }}
                        onChange={(organizationId) => {
                          if (
                            isOfType<string>(
                              organizationId,
                              'toLocaleLowerCase',
                            )
                          ) {
                            handleChangeFormData({
                              organizationId,
                              eduGroup: undefined,
                            });
                          } else {
                            console.error('Organization cannot be multiple');
                          }
                        }}
                      />
                    </Box>
                    <Box>
                      <Tooltip title="Свою образовательную организацию вы можете так же найти, указав ИНН">
                        <IconButton disableRipple>
                          <QuestionIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid item container spacing={3} sx={{ alignItems: 'center' }}>
                <Grid item md={3}>
                  <Typography variant={'additionally' as 'h1'}>
                    Класс (или другая группировка обучающихся)
                  </Typography>
                </Grid>
                <Grid item flexGrow={1}>
                  <Box maxWidth="416px">
                    <EduGroupSelect
                      name="eduGroup"
                      isDisabled={!formData.organizationId}
                      placeholder={
                        formData.organizationId
                          ? 'выбрать'
                          : 'сначала выберите Образовательную организацию'
                      }
                      selected={formData.eduGroup}
                      filters={eduGroupFilters}
                      onChange={(eduGroup: Entity<EduGroup>) =>
                        handleChangeFormData({
                          eduGroup,
                        })
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item container spacing={3} sx={{ flexWrap: 'nowrap' }}>
                <Grid item md={3} flexShrink={0} />
                <Grid item container columnSpacing={1}>
                  <Grid item flexGrow={1} md={7}>
                    <Button
                      variant="first"
                      fullWidth
                      onClick={() => handleChangePersonalInfo(formData)}
                      disabled={
                        !(
                          formData.role &&
                          formData.organizationId &&
                          formData.eduGroup
                        ) || activeSave
                      }
                    >
                      Сохранить
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="fourth" onClick={handleClose}>
                      Отмена
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default ProfileStudentChangeInfo;

import { ReactComponent as Logo } from '@assets/img/logo-black.svg';
import './Sidebar.scss';
import { NavLink, Link } from 'react-router-dom';
import {
  Divider,
  Grid,
  MenuItem,
  MenuList,
  Popover,
  styled,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { getUserInfo, isExternalISAdmin } from '@redux/user';
import { ReactComponent as MoreIcon } from '@assets/icons/ico-more.svg';
import { useState } from 'react';
import Button from '@components/common/Button/Button';
import { logoutLink } from '@lib/auth/getAuthLinks';
import { deleteCookie } from '@lib/cookie';
import { COOKIE_NAMES, MAIN_DOMAIN } from '@constants/config';
import { ROUTES } from '@constants/routes';
import { useContentCredentials } from '@hooks/useContentCredentials';

const NavButton = styled(Button)({
  fontFamily: 'inherit',
  padding: 0,
  width: '100%',
  textAlign: 'left',
  justifyContent: 'left',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '& a': {
    padding: '12px 0',
    width: '100%',
    textTransform: 'none',
    textDecoration: 'none',
    transition: 'all 0.3s ease',
    [`&:hover, &.active`]: {
      color: '#0278FF',
    },
  },
});

export const Sidebar: React.FC = () => {
  const userInfo = useSelector(getUserInfo);
  const isAdminIS = useSelector(isExternalISAdmin);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const credentials = useContentCredentials();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const clazz = open ? 'active' : '';

  const handlerLogout = () => {
    deleteCookie(COOKIE_NAMES.ACCESS_TOKEN, MAIN_DOMAIN);
    deleteCookie(COOKIE_NAMES.REFRESH_TOKEN, MAIN_DOMAIN);
  };

  return (
    <aside className="sidebar">
      <Grid container flexDirection="column" sx={{ p: 3 }}>
        <Grid item xs="auto">
          <div className="manager-logo">
            <Logo />
            <div className="manager-logo__text">Управление контентом</div>
            <Link className="manager-logo__link" to="/" />
          </div>
        </Grid>
      </Grid>
      <Divider />
      <Grid
        component="nav"
        flexDirection="column"
        sx={{ p: 3 }}
        className="manager-navigation"
      >
        {credentials.news && (
          <Grid item>
            <NavButton>
              <NavLink
                to={ROUTES.managerNewsList}
                activeClassName="active"
                className="manager-navigation__link"
              >
                Новости
              </NavLink>
            </NavButton>
          </Grid>
        )}
        {credentials.faq && (
          <Grid item>
            <NavButton>
              <NavLink
                to={ROUTES.managerFaqList}
                activeClassName="active"
                className="manager-navigation__link"
              >
                Вопросы и ответы
              </NavLink>
            </NavButton>
          </Grid>
        )}
        {credentials.links && (
          <Grid item>
            <NavButton>
              <NavLink
                to={ROUTES.managerLinkList}
                activeClassName="active"
                className="manager-navigation__link"
              >
                Полезные ссылки
              </NavLink>
            </NavButton>
          </Grid>
        )}
        {credentials.quiz && (
          <Grid item>
            <NavButton>
              <NavLink
                to={ROUTES.managerQuizList}
                activeClassName="active"
                className="manager-navigation__link"
              >
                Опросы
              </NavLink>
            </NavButton>
          </Grid>
        )}
        {isAdminIS && (
          <Grid item>
            <NavButton>
              <NavLink
                to={ROUTES.managerAlertList}
                activeClassName="active"
                className="manager-navigation__link"
              >
                Оповещения
              </NavLink>
            </NavButton>
          </Grid>
        )}
        {credentials.journal && (
          <Grid item>
            <NavButton>
              <NavLink
                to={ROUTES.managerJournalList}
                activeClassName="active"
                className="manager-navigation__link"
              >
                Управление журналами
              </NavLink>
            </NavButton>
          </Grid>
        )}
        {credentials.diary && (
          <Grid item>
            <NavButton>
              <NavLink
                to={ROUTES.managerDiaryList}
                activeClassName="active"
                className="manager-navigation__link"
              >
                Управление дневниками
              </NavLink>
            </NavButton>
          </Grid>
        )}
      </Grid>
      <Divider sx={{ mt: 'auto' }} />
      <Grid
        component={NavButton}
        container
        className={clazz}
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 3,
          borderRadius: 0,
          ['&:hover, &.active']: {
            backgroundColor: '#F2F4F7',
            boxShadow: '1px 0px 0px #E5E5EB',
          },
        }}
        onClick={handleClick}
      >
        <Grid item xs="auto">
          {userInfo.fullName}
        </Grid>
        <Grid item xs="auto">
          <MoreIcon />
        </Grid>
      </Grid>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: -128,
          horizontal: 'left',
        }}
      >
        <MenuList sx={{ m: 0 }}>
          <MenuItem className="profile-menu__item">
            <Link to={ROUTES.profile} style={{ textDecoration: 'none' }}>
              Личный кабинет
            </Link>
          </MenuItem>
          <Divider />
          <MenuItem className="profile-menu__item">
            <a
              href={logoutLink}
              onClick={handlerLogout}
              style={{ textDecoration: 'none' }}
            >
              Выйти
            </a>
          </MenuItem>
        </MenuList>
      </Popover>
    </aside>
  );
};

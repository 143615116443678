export const parseReference = (reference: string) => {
  const [resourceType, id] = reference.split('/');
  return { resourceType, id };
};

export function createReference(data: { resourceType: string; id?: string }) {
  return `${data.resourceType}/${data.id}`;
}

export const getNewItemName = (
  currentItemsName: string[],
  name: string | undefined,
  index = 0,
): string => {
  if (!name) {
    return '';
  }
  const newName = `${name}${index ? ` (${index})` : ''}`;
  if (currentItemsName.includes(newName)) {
    return getNewItemName(currentItemsName, name, index + 1);
  }
  return newName;
};

export const isEmpty = (data: any) => {
  if (!data) {
    return;
  }

  if (typeof data === 'string' || data instanceof String) {
    return !data.trim();
  }

  if (Array.isArray(data)) {
    return data.length === 0;
  }

  if (typeof data === 'object') {
    return Object.keys(data).length === 0;
  }
};

/**
 * Генерация ссылки исходя из указанного шаблона
 * @param template - шаблон ссылки
 * @param params - параметры, которые необходимо вставить в шаблон
 */
export const generateLink = (
  template: string,
  params: { [key: string]: string | number },
) => {
  if (!params) {
    return template;
  }
  if (
    Object.keys(params).some(
      (key) =>
        !params[key] || !['string', 'number'].includes(typeof params[key]),
    )
  ) {
    throw new Error('Unsupported parameter type');
  }
  let formattedString = template;
  const matches = formattedString.match(/:[a-zA-Z0-9]*/g);
  if (matches) {
    matches.forEach((i: string) => {
      const variable = i.replace(/:/g, '');
      const matchData = params[variable];
      if (matchData) {
        formattedString = formattedString.replace(i, matchData?.toString());
      }
    });
  }
  return formattedString;
};

export const downloadFile = (file: File, name?: string) => {
  const url = window.URL.createObjectURL(file);
  const a = document.createElement('a');
  a.download = name || file.name;
  a.href = url;
  a.click();
};

/**
 * Функция берет массив числовых значений и возвращает массив с соответствующими
 * округленными процентами. Сумма возвращаемых значений всегда будет равна 100.
 * Решение взято отсюда https://github.com/super-ienien/percent-round/blob/master/src/index.js
 * @param ipt - Массив чисел, которые будут преобразованы в проценты.
 * @param precision - Количество цифр после запятой.
 */
export function percentRound(ipt: number[], precision?: number) {
  if (!precision) {
    precision = 0;
  }
  if (!Array.isArray(ipt)) {
    throw new Error('percentRound input should be an Array');
  }
  const iptPercents = ipt.slice();
  const length = ipt.length;
  const out = new Array(length);

  let total = 0;
  for (let i = length - 1; i >= 0; i--) {
    if (typeof iptPercents[i] === 'string') {
      //@ts-ignore
      iptPercents[i] = Number.parseFloat(iptPercents[i]);
    }
    total += iptPercents[i] * 1;
  }
  if (isNaN(total)) {
    throw new Error('percentRound invalid input');
  }

  if (total === 0) {
    out.fill(0);
  } else {
    const powPrecision = Math.pow(10, precision);
    const pow100 = 100 * powPrecision;
    let check100 = 0;
    for (let i = length - 1; i >= 0; i--) {
      iptPercents[i] = (100 * iptPercents[i]) / total;
      check100 += out[i] = Math.round(iptPercents[i] * powPrecision);
    }

    if (check100 !== pow100) {
      const totalDiff = check100 - pow100;
      const roundGrain = 1;
      let grainCount = Math.abs(totalDiff);
      const diffs = new Array(length);

      for (let i = 0; i < length; i++) {
        diffs[i] = Math.abs(out[i] - iptPercents[i] * powPrecision);
      }

      while (grainCount > 0) {
        let idx = 0;
        let maxDiff = diffs[0];
        for (let i = 1; i < length; i++) {
          if (maxDiff < diffs[i]) {
            idx = i;
            maxDiff = diffs[i];
          }
        }
        if (check100 > pow100) {
          out[idx] -= roundGrain;
        } else {
          out[idx] += roundGrain;
        }
        diffs[idx] -= roundGrain;
        grainCount--;
      }
    }

    if (powPrecision > 1) {
      for (let i = 0; i < length; i++) {
        out[i] = out[i] / powPrecision;
      }
    }
  }

  return out;
}

import {
  selectOptionsTime,
  selectSortCategoryOptions,
  selectSortOptions,
  systemOptions,
} from '@pages/NewsListPage/constants';
import { Filter } from '@declarations/common';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterOptions, Filters, FiltersState } from './types';

const initialState: FiltersState = {
  filters: {
    grade: [],
    time: selectOptionsTime,
    sort: selectSortOptions,
    sortCategory: selectSortCategoryOptions,
    system: systemOptions,
    query: '',
  },
  activeFilterQuery: '',
  options: {
    audience: [],
    eduLevels: [],
    regions: [],
    parallels: [],
    linkCategories: [],
    journalLinks: [],
  },
  values: {
    search: '',
    period: [undefined, undefined],
  },
  userFilters: null,
  isTargetedSearch: true,
};

const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilterOptions: (
      state: FiltersState,
      action: PayloadAction<Partial<Filters>>,
    ) => ({
      ...state,
      filters: {
        ...state.filters,
        ...action.payload,
      },
    }),
    setActiveFilterQuery: (
      state: FiltersState,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      activeFilterQuery: action.payload,
    }),
    setOptions: (
      state: FiltersState,
      action: PayloadAction<Partial<FilterOptions>>,
    ) => ({
      ...state,
      options: {
        ...state.options,
        ...action.payload,
      },
    }),
    addFilterValues: (
      state: FiltersState,
      action: PayloadAction<Partial<Filter>>,
    ) => ({
      ...state,
      values: {
        ...state.values,
        ...action.payload,
      },
    }),
    clearFilterValues: (
      state: FiltersState,
      action: PayloadAction<Partial<Filter> | undefined>,
    ) => ({
      ...state,
      values: {
        ...initialState.values,
        time: undefined,
        ...action.payload,
      },
    }),
    setUserFilter: (
      state: FiltersState,
      action: PayloadAction<Partial<Filter>>,
    ) => ({
      ...state,
      userFilters: action.payload,
    }),
    setTargetedSearch: (state, action: PayloadAction<boolean>) => {
      state.isTargetedSearch = action.payload;
    },
  },
});

export const {
  setFilterOptions,
  setActiveFilterQuery,
  setOptions,
  addFilterValues,
  clearFilterValues,
  setUserFilter,
  setTargetedSearch,
} = slice.actions;

export * from './thunks';
export * from './selectors';

export default slice.reducer;

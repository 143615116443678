import { News, NewsStatus } from '@declarations/news';
import { FileDto, Filter } from '@declarations/common';
import { getSearchQueryParams } from '@api/utils';
import { mainAxios } from './utils/axios';
import { ResponseList } from './types/common';

const SERVICE_NAME = 'api-news/api/v1';

export const newsAPI = {
  getEduLevels: () =>
    mainAxios.get<Option[], Option[]>(`${SERVICE_NAME}/edu-levels/list`),
  getAudienceList: () =>
    mainAxios.get<Option[], Option[]>(`${SERVICE_NAME}/target-audience/list`),
  getParallels: () =>
    mainAxios.get<Option[], Option[]>(`${SERVICE_NAME}/parallel/list`),
  getSubjects: () =>
    mainAxios.get<Option[], Option[]>(`${SERVICE_NAME}/regions/list`),
  search: (
    pagination: { currentPage: number; pageSize: number },
    filters: Partial<Filter>,
    isTargetedSearch?: boolean,
  ) =>
    mainAxios.get<ResponseList<News>, ResponseList<News>>(
      `${SERVICE_NAME}/search`,
      {
        params: {
          ...getSearchQueryParams(pagination, filters),
          isProtected: true,
          targeting: isTargetedSearch ? 'on' : 'off',
        },
      },
    ),
  add: (data: News) =>
    mainAxios.post<News, News>(`${SERVICE_NAME}/`, data, {
      params: { isProtected: true },
    }),
  update: (data: News, id: string) =>
    mainAxios.put<News, News>(
      `${SERVICE_NAME}/${id}`,
      {
        ...data,
        files: data.files.map(({ id }) => id),
      },
      {
        params: { isProtected: true },
      },
    ),
  addFiles: (files: File[]) => {
    const formData = new FormData();
    files?.forEach((fileItem) => {
      formData.append('files', fileItem);
    });

    return mainAxios.post<FileDto[], FileDto[]>(
      `${SERVICE_NAME}/files`,
      formData,
      {
        params: { isProtected: true },
      },
    );
  },
  changeStatus: (id: number, status: NewsStatus) =>
    mainAxios.put<unknown, unknown>(
      `${SERVICE_NAME}/status/${id}`,
      { status },
      {
        params: { isProtected: true },
      },
    ),
  remove: (id: number | number[]) =>
    mainAxios.delete<unknown, unknown>(
      `${SERVICE_NAME}/${Array.isArray(id) ? id.join(',') : id}`,
      {
        params: { isProtected: true },
      },
    ),
  get: (id: number, isManage = false) =>
    mainAxios.get<News, News>(`${SERVICE_NAME}/${id}`, {
      params: { isManage },
    }),
};

export type Option = {
  id: number;
  name: string;
  description?: string | null;
  eduOrgGroupId?: number;
};

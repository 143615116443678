import { UserRoleList } from '@redux/user/types';

export const getUserMobile = (req: UserRoleList) => {
  if (req) {
    const userResponse = req?.entry;
    const userPerson = userResponse && userResponse[1];
    return userPerson?.resource.telecom.find((item: any) => {
      return item.system.code.toLowerCase() === 'телефон';
    })?.value;
  } else {
    return '-';
  }
};

export default getUserMobile;

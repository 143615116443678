import { useState, useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { Link, Typography, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { ReactComponent as ArrowLeft } from '@assets/icons/back-icon.svg';
import {
  fetchNotificationList,
  fetchUnreadNotifications,
  getUnreadNotifications,
  setReadNotificationsByIds,
} from '@redux/notifications';
import { Notification } from '@redux/notifications/types';
import { RootState } from '@redux/store';
import { ROUTES } from '@constants/routes';

const NotificationDetailPage = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const dispatch = useDispatch();
  const [message, setMessage] = useState<Notification>();
  const [loading, setLoading] = useState(true);
  const [fetched, setFetched] = useState(false);

  const notifications = useSelector((state: RootState) => state.notifications);

  const unreadNotifications = useSelector(getUnreadNotifications);

  const { messageId, messageIsRead } = useMemo(
    () => ({ messageId: message?.id, messageIsRead: message?.isRead }),
    [message],
  );

  useEffect(() => {
    //TODO-nafis: Попросить доработать API, чтобы можно было получать сообщения по id и после переделать этот компонент
    const init = async () => {
      const { id } = params;
      const message =
        notifications.notifications.data.find((item) => item.id === +id) ||
        unreadNotifications?.find((item) => item.id === +id);
      if (!message) {
        setLoading(true);
        if (!fetched) {
          await Promise.allSettled([
            dispatch(fetchNotificationList()),
            dispatch(fetchUnreadNotifications()),
          ]);
        }
      } else {
        setMessage(message);
      }
      setLoading(false);
      setFetched(true);
    };
    init();
  }, [params, notifications, dispatch, fetched, unreadNotifications]);

  useEffect(() => {
    if (messageId && !messageIsRead) {
      dispatch(setReadNotificationsByIds([messageId]));
    }
  }, [messageId, messageIsRead, dispatch]);

  if (loading) {
    return (
      <div className="notification-detail-loader">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="notification-detail">
      <Link
        className="notification-detail__link"
        href={ROUTES.notificationList}
      >
        <span className="notification-detail__link-icon">
          <ArrowLeft />
        </span>
        Уведомления
      </Link>

      <div className="notification-detail__card">
        {message ? (
          <>
            <Typography variant="h3">{message.title}</Typography>
            <div className="notification-detail__push-top" />
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: message.value,
              }}
            />
            <div className="notification-detail__card-footer">
              <div className="notification-detail__chip">{message.system}</div>
              <div className="notification-detail__chip notification-detail__chip--transparent">
                {dayjs(message.date).format('HH:mm, D MMMM YYYY')}
              </div>
            </div>
          </>
        ) : (
          <Typography variant="h5">Уведомление не найдено</Typography>
        )}
      </div>
    </div>
  );
};

export default NotificationDetailPage;

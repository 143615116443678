import { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { SingleValue } from 'react-select';

import SingleAsyncSelect from '@components/common/Select/SingleAsyncSelect';
import { Option } from '@components/common/Select/types';
import { EduGroup, EduGroupFilters } from '@declarations/eduGroup';
import { eduGroupApi } from '@api/eduGroup';
import { Entity } from '@declarations/common';

type Props = {
  name: string;
  placeholder: string;
  id?: string;
  onChange?: (eduGroup: Entity<EduGroup>) => void;
  filters?: EduGroupFilters;
  isDisabled?: boolean;
  selected?: Entity<EduGroup>;
};

const EduGroupSelect = ({
  onChange,
  filters,
  selected,
  ...otherProps
}: Props) => {
  const [eduGroups, setEduGroups] = useState<Entity<EduGroup>[]>([]);
  const [defaultOptions, setDefaultOptions] = useState<Option[]>([]);

  const value = useMemo(
    () =>
      selected
        ? {
            value: selected.resource.id,
            label: selected.resource.name,
          }
        : null,
    [selected],
  );

  const loadData = useCallback(
    async (inputValue: string, cb: (options: Option[]) => void) => {
      let options: Option[] = [];
      try {
        const data = await loadEduGroups({
          ...filters,
          name: inputValue,
        });
        setEduGroups(data);
        options = data.map((item) => ({
          label: item.resource.name,
          value: item.resource.id,
        }));
      } catch (err) {
        console.error('Error on load edu groups');
      }
      cb(options);
    },
    [filters],
  );

  const handleChange = useCallback(
    (option: SingleValue<Option>) => {
      const exists = eduGroups?.find(
        (item) => option && item.resource.id === option.value,
      );
      if (exists && onChange) {
        onChange(exists);
      }
    },
    [onChange, eduGroups],
  );
  const loadOptions = debounce(loadData, 1000);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!otherProps.isDisabled) {
        loadData('', (items) => mounted && setDefaultOptions(items));
      }
      return () => {
        mounted = false;
      };
    }
  }, [loadData, otherProps.isDisabled]);

  return (
    <SingleAsyncSelect
      {...otherProps}
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      onChange={handleChange}
      value={value}
    />
  );
};

export default EduGroupSelect;

const loadEduGroups = async (filters: EduGroupFilters) => {
  const params: EduGroupFilters = {
    name: filters.name,
    status: 'active',
    organizationId: filters.organizationId,
  };

  const response = await eduGroupApi.search(params);
  if (response) {
    return response.entry;
  }

  return [];
};

export enum DIRECTORY_NAME {
  MY_DOCUMENTS = 'мои документы',
  AVAILABLE_TO_ME = 'доступно для меня',
  SHARING_ACCESS = 'совместный доступ',
  FAVOURITES = 'избранное',
  BASKET = 'корзина',
  LATEST_DOCUMENTS = 'последние документы',
}

export enum LINK_LIFE_TIME_TYPE {
  SINGLE_CALL,
  FIVE_MINUTES,
  ONE_HOUR,
}

export enum DOCUMENT_TYPE {
  DOCUMENT = 'Document',
}

export enum DOCUMENT_ACCESS_TYPE {
  READ = 0,
  WRITE = 4,
}

export enum DOCUMENT_MIME_TYPE {
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
}

export enum FILE_TYPE {
  DOCX = 'docx',
  XLSX = 'xlsx',
  PPTX = 'pptx',
}

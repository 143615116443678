import { useEffect, useCallback, useState, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Select,
  MenuItem,
  Box,
} from '@mui/material';
import SingleSelect from '@components/common/Select/SingleSelect';
import './NotifiacationsPage.scss';
import { selectOptionsSystem } from '@pages/NewsListPage/constants';
import { StyledInput } from '@components/styledComponents/StyledInput';
import NotificationSubscribe from '@pages/NotifactionsPage/NotificationSubscribe/NotificationSubscribe';
import StyledCheckbox from '@components/common/StyledCheckbox/StyledCheckbox';
import {
  fetchNotificationList,
  getNotificationList,
  setDeletedNotificationsByIds,
  setPage,
  setReadNotificationsByIds,
  setSize,
} from '@redux/notifications';
import DropDown from '@components/common/Dropdown/Dropdown';
import { ReactComponent as IconArrowDown } from '@assets/icons/arrow-down.svg';
import { ReactComponent as IconCheck } from '@assets/icons/check.svg';
import { ReactComponent as IconTrash } from '@assets/icons/trash.svg';
import { ReactComponent as IconCaretDown } from '@assets/icons/ico-caret-down.svg';
import { useSnackbar } from '@hooks/useSnackbar';
import { generateLink } from '@lib/common';
import { ROUTES } from '@constants/routes';
import { Option } from '@components/common/Select/types';
import { Pagination } from '@components/common/Pagination';
import { DatePicker } from '@components/common/DatePicker/DatePicker';
import useMediaQuery from '@mui/material/useMediaQuery';

const CHECKBOX = 'checkbox';
const DEL_BTN = 'delete_btn';

export const NotificationsPage = () => {
  const dispatch = useDispatch();
  const isPhoneWidth = useMediaQuery('(max-width: 768px)');
  const isTabletWidth = useMediaQuery('(max-width: 1024px)');
  const history = useHistory();
  const { addSnack } = useSnackbar();
  const [selectedSystem, setSelectedSystem] = useState<Option | undefined>(
    selectOptionsSystem[0],
  );

  const { data, total, page, size } = useSelector(getNotificationList);
  const [checkedItemsId, setCheckedItemsId] = useState<Array<number>>([]);
  const [filter, setFilter] = useState<{ search: string; date?: Date }>({
    search: '',
  });

  const pageCount = Math.ceil(total / size);

  const dropDownItems = useMemo(
    () => [
      {
        label: 'Все',
        action: () => setCheckedItemsId(data.map((item) => item.id)),
      },
      {
        label: 'Непрочитанные',
        action: () =>
          setCheckedItemsId(
            data?.reduce(
              (acc: number[], item) => (item.isRead ? acc : [...acc, item.id]),
              [],
            ),
          ),
      },
      {
        label: 'Прочитанные',
        action: () =>
          setCheckedItemsId(
            data?.reduce(
              (acc: number[], item) => (item.isRead ? [...acc, item.id] : acc),
              [],
            ),
          ),
      },
    ],
    [data],
  );

  const debouncedSearch = useMemo(
    () =>
      debounce((search: string, date?: Date) => {
        dispatch(fetchNotificationList({ search, date }));
      }, 500),
    [dispatch],
  );

  const onClickRow = useCallback(
    (event, id) => {
      if ([CHECKBOX, DEL_BTN].includes(event.target.name)) {
        return;
      }
      history.push(
        generateLink(ROUTES.notificationItem, {
          id,
        }),
      );
    },
    [history],
  );

  const onCheck = useCallback((id: number, checked: boolean) => {
    setCheckedItemsId((prev) =>
      checked ? [...prev, id] : prev?.filter((item) => item !== id),
    );
  }, []);

  const deleteById = useCallback(
    async (id: number) => {
      await dispatch(setDeletedNotificationsByIds([id]));
      dispatch(fetchNotificationList(filter));
      addSnack('Уведомление удалено', 'success');
    },
    [dispatch, filter, addSnack],
  );

  const deletedSelected = useCallback(async () => {
    await dispatch(setDeletedNotificationsByIds(checkedItemsId));
    dispatch(fetchNotificationList(filter));
    setCheckedItemsId([]);
    addSnack('Уведомления удалены', 'success');
  }, [checkedItemsId, dispatch, filter, addSnack]);

  const readSelected = useCallback(async () => {
    await dispatch(setReadNotificationsByIds(checkedItemsId));
    setCheckedItemsId([]);
  }, [checkedItemsId, dispatch]);

  const setPagination = useCallback(
    async (page: number, size?: number) => {
      await dispatch(setPage({ page }));
      if (size) {
        await dispatch(setSize({ size }));
      }
      debouncedSearch(filter.search, filter.date);
    },
    [debouncedSearch, filter, dispatch],
  );

  useEffect(() => {
    debouncedSearch(filter.search, filter.date);
  }, [filter, debouncedSearch]);

  return (
    <div className="notifications">
      <Typography
        sx={{
          marginTop: '50px',
          marginBottom: '24px',
        }}
        variant="h3"
      >
        Уведомления
      </Typography>

      <div className="notifications__filters">
        <Grid
          container
          sx={{ flexGrow: 1 }}
          spacing={1}
          flexWrap="nowrap"
          flexDirection={{
            xs: 'column',
            sm: 'column',
            md: 'row',
          }}
        >
          <Grid item xs={2} flexShrink={0} className="filter-wrapper">
            <SingleSelect
              options={selectOptionsSystem}
              name="select"
              placeholder="Все"
              id="select"
              value={selectedSystem}
              onChange={(option) => setSelectedSystem(option || undefined)}
              styleExtra={{
                valueContainer: (base: any) => ({
                  ...base,
                  height: 42,
                }),
              }}
            />
          </Grid>
          <Grid item xs={8} flexShrink={0} className="filter-wrapper">
            <StyledInput
              className="notifications__search-field"
              placeholder="Поиск"
              onChange={({ target }) =>
                setFilter((prev) => ({ ...prev, search: target.value }))
              }
              value={filter.search}
            />
          </Grid>
          <Grid item xs={2} flexShrink={0} className="filter-wrapper">
            <DatePicker
              isMinWidth={true}
              value={filter.date}
              onChange={(date) =>
                setFilter((prev) => ({ ...prev, date: date || undefined }))
              }
            />
          </Grid>
        </Grid>
        <div className="notifications__filters-subscribe">
          <NotificationSubscribe />
        </div>
      </div>
      <Table id="notifications-table" className="notifications__table">
        <TableHead>
          <TableRow>
            <TableCell
              className={`${
                isPhoneWidth && checkedItemsId.length ? 'pr-10' : ''
              } notifications__table-cell notifications__table-cell--header notifications__table-checkbox`}
            >
              <DropDown
                MyButton={() => (
                  <div className="notifications__dropdown-checkbox">
                    <StyledCheckbox checked={!!checkedItemsId.length} />
                    <span className="notifications__dropdown-checkbox-icon">
                      <IconArrowDown />
                    </span>
                  </div>
                )}
              >
                {(setShow) => (
                  <DropDownInner
                    items={dropDownItems}
                    controlItems={[
                      {
                        label: 'сбросить',
                        action: () => setCheckedItemsId([]),
                      },
                    ]}
                    setShow={setShow}
                  />
                )}
              </DropDown>
            </TableCell>
            <TableCell
              className={`${
                isPhoneWidth && checkedItemsId.length ? 'pl-0' : ''
              } notifications__table-cell notifications__table-cell--header notifications__table-name`}
              sx={{ padding: '8px 16px' }}
              colSpan={isTabletWidth && checkedItemsId.length ? 3 : 1}
            >
              {checkedItemsId.length ? (
                <div className={'notifications__checked-items-actions'}>
                  <Button
                    variant="first"
                    size="small"
                    startIcon={<IconCheck />}
                    onClick={readSelected}
                  >
                    Отметить как прочитанное
                  </Button>
                  <Button
                    variant="fourth"
                    size="small"
                    className="notifications__table-name-trash-btn"
                    startIcon={<IconTrash />}
                    onClick={deletedSelected}
                  >
                    Удалить
                  </Button>
                  <Button
                    size="small"
                    className="notifications__table-name-cancel-btn"
                    onClick={() => setCheckedItemsId([])}
                  >
                    Отмена
                  </Button>
                </div>
              ) : (
                'Название'
              )}
            </TableCell>
            {!(isPhoneWidth || (isTabletWidth && checkedItemsId.length)) && (
              <>
                <TableCell
                  align="right"
                  className="notifications__table-cell notifications__table-cell--header notifications__table-system"
                >
                  Система
                </TableCell>
                <TableCell
                  align="right"
                  className="notifications__table-cell notifications__table-cell--header notifications__table-date"
                >
                  Дата
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                '&:hover p': {
                  color: '#0C1524 !important',
                },
              }}
              className="notifications__table-row"
              onClick={(e) => onClickRow(e, row.id)}
            >
              <TableCell className="notifications__table-checkbox">
                <StyledCheckbox
                  name={CHECKBOX}
                  size="small"
                  checked={checkedItemsId.includes(row.id)}
                  onChange={({ target }) => onCheck(row.id, target.checked)}
                />
              </TableCell>
              <TableCell align="left">
                <Box position="relative">
                  {!row.isRead && (
                    <Box
                      width="8px"
                      height="8px"
                      borderRadius="50%"
                      bgcolor="#FA5C41"
                      position="absolute"
                      left="-16px"
                      top="50%"
                      sx={{
                        transform: 'translateY(-50%)',
                      }}
                    />
                  )}
                  <Typography color={row.isRead ? '#818999' : '#0C1524'}>
                    {row.title}
                  </Typography>
                </Box>
              </TableCell>
              {!isPhoneWidth && (
                <>
                  <TableCell align="right">
                    <Typography
                      fontSize="14px"
                      lineHeight="20px"
                      color="#2C3038"
                    >
                      {row.system}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                    className="notifications__table-row-date"
                  >
                    <div className="notifications__table-row-hide-on-hover">
                      <Typography
                        fontSize="14px"
                        lineHeight="20px"
                        color="#2C3038"
                      >
                        {dayjs(row.date).format('DD MMMM')}
                      </Typography>
                    </div>
                    <div className="notifications__table-row-show-on-hover">
                      <Button
                        variant="fourth"
                        size="small"
                        className="notifications__table-name-trash-btn"
                        name={DEL_BTN}
                        startIcon={<IconTrash />}
                        onClick={() => deleteById(row.id)}
                      >
                        Удалить
                      </Button>
                    </div>
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {total > 0 ? (
        <div className="notifications__footer">
          <div className="notifications__page-size">
            <Select
              labelId="select-label"
              id="simple-select"
              value={size}
              onChange={({ target }) => setPagination(0, +target.value)}
              size="small"
              sx={{
                borderRadius: '6px',
              }}
              IconComponent={IconCaretDown}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
            </Select>
            <span className="notifications__page-size-desc">
              Всего: {total}
            </span>
          </div>
          <div className="notifications__pagination">
            {pageCount > 1 && (
              <Pagination
                siblingCount={isPhoneWidth ? -1 : 0}
                count={pageCount}
                page={page + 1}
                hidePrevButton={page === 0}
                hideNextButton={page === pageCount - 1}
                onChange={(val, page) => setPagination(page - 1)}
              />
            )}
          </div>
        </div>
      ) : (
        <Typography color="#818999" textAlign="center" mt={16}>
          У вас нет ни одного уведомления
        </Typography>
      )}
    </div>
  );
};

type DropDownInnerProps = {
  items: {
    label: string;
    action: () => void;
  }[];
  controlItems: {
    label: string;
    action: () => void;
  }[];
  setShow: (show: boolean) => void;
};

const DropDownInner = ({
  items,
  controlItems,
  setShow,
}: DropDownInnerProps) => {
  return (
    <div className="notifications__dropdown">
      <div className="notifications__dropdown-title">Выбрать</div>
      <Box px="4px">
        {items.map((item) => (
          <button
            key={item.label}
            className="notifications__dropdown-item"
            onClick={() => {
              item.action();
              setShow(false);
            }}
          >
            {item.label}
          </button>
        ))}
        <div className="notifications__dropdown-divider" />
        {controlItems.map((item) => (
          <button
            key={item.label}
            className="notifications__dropdown-item notifications__dropdown-item--primary"
            onClick={() => {
              item.action();
              setShow(false);
            }}
          >
            {item.label}
          </button>
        ))}
      </Box>
    </div>
  );
};

import { useEffect, useMemo } from 'react';
// @ts-ignore
import { Slider } from 'react-slider-overflow-x';
import 'react-slider-overflow-x/dist/style.css';
import 'swiper/swiper.scss';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Title from '../../../common/Title/Title';
import { getScoreList } from './utils/getScoreList';
import './styles.scss';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import { scheduleAPI } from '@api/schedule';
import { mapResponse } from '../utils/mapResponse';
import { Box } from '@mui/system';
import classNames from 'classnames';
import { JournalError } from '@api/types/gounn';
import { isOfType } from '@declarations/common';
import { useSnackbar } from '@hooks/useSnackbar';
import { DIARY_SNACK_ID } from '../constants';

type Props = {
  regionCode: string;
  esiaId: string;
  journalUrl: string | undefined;
};

export const Grade = ({ regionCode, esiaId, journalUrl }: Props) => {
  const { addSnack } = useSnackbar();
  const week = useMemo(
    () => [dayjs().startOf('week').toDate(), dayjs().endOf('week').toDate()],
    [],
  );
  const {
    data: schedule,
    isError,
    error,
    isLoading,
  } = useQuery(['diary-widget', regionCode, week[0], week[1]], async () => {
    const response = await scheduleAPI.getJournal(
      week[0],
      week[1],
      esiaId,
      regionCode,
    );
    if (response) {
      if (isOfType<JournalError>(response, 'issue')) {
        const errorList = response.issue?.reduce((list, issue) => {
          if (issue.severity === 'error') {
            list.push(issue.details);
          }
          return list;
        }, [] as string[]);
        throw Error(errorList.join(', '));
      }
      return mapResponse(response);
    }
  });

  const scoreList = useMemo(() => getScoreList(schedule), [schedule]);
  const scheduleLink = journalUrl ? journalUrl : '#';

  useEffect(() => {
    if (error) {
      const message =
        // @ts-ignore
        (typeof error === 'object' && error.message) ||
        'Ошибка получения данных';
      addSnack(message, 'error', DIARY_SNACK_ID);
    }
  }, [addSnack, error]);

  if (isError || isLoading) {
    return null;
  }

  return (
    <div className="grade-widget">
      <Box
        display="flex"
        alignItems="center"
        mb="32px"
        onClick={() => {
          window.location.href = scheduleLink;
        }}
        sx={{
          cursor: 'pointer',
        }}
      >
        <Title className="grade-widget__title" sx={{ fontSize: '24px' }}>
          Оценки
        </Title>
        <Box ml={1} alignItems="center" />
        <ChevronRightIcon />
      </Box>
      <div className="grade-widget__slider">
        {scoreList.length === 0 ? (
          <Box color="#818999" textAlign="center" width="100%">
            Уроков нет
          </Box>
        ) : (
          <Slider>
            {scoreList.map((item, index) => (
              <div key={index} className="grade-widget__item">
                <div className="grade-widget__item-title">{item.lesson}</div>
                <div
                  className={classNames(
                    'grade-widget__item-value',
                    item.value < 3 && 'grade-widget__item-value--bad',
                  )}
                >
                  {item.value}
                </div>
                <div className="grade-widget__item-date">{item.date}</div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

import * as React from 'react';
import './ProfileBlock.scss';
import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { fetchUserRole, getUserPupilInfo } from '@redux/user';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { UserRoleStatus } from '@redux/user/types';
import {
  mapPupilData,
  PupilEntity,
} from '@components/Profile/ProfileParentRoleList/ProfileParentRoleList';
import ProfileParentPupil from '@pages/MainPage/ProfileBlock/ProfileParentPupil';
import { Diary } from '@components/Profile/Diary';
import { AuthAccountStatus } from '@declarations/enum/account';

export const ProfileParentBlock: React.FC = () => {
  const [tabIndex, setTabIndex] = useState<number>(0);

  const onSelect = useCallback((index) => {
    setTabIndex(index);
  }, []);

  const pupilInfo = useSelector(getUserPupilInfo);

  const pupilList = useMemo(() => {
    const result: {
      active: PupilEntity[];
      archive: PupilEntity[];
    } = {
      active: [],
      archive: [],
    };
    mapPupilData(pupilInfo)?.forEach((pupil) => {
      // if (pupil.nextOfKinRole) {
      pupil.pupilRole.status === UserRoleStatus.RETIRED ||
      pupil.nextOfKinRole?.status === UserRoleStatus.RETIRED ||
      !pupil.pupilAuthAccount ||
      pupil.pupilAuthAccount?.status === AuthAccountStatus.BLOCKED
        ? result.archive.push(pupil)
        : result.active.push(pupil);
      // }
    });

    return result;
  }, [pupilInfo]);

  useLayoutEffect(() => {
    if (!pupilInfo) {
      fetchUserRole();
    }
  }, [pupilInfo]);

  return (
    <>
      {!pupilInfo && (
        <Grid
          container
          sx={{ py: 3, justifyContent: 'center', alignItems: 'center' }}
        >
          <Grid item>
            <Typography variant="h4" sx={{ fontWeight: 500 }}>
              Пока нет добавленных ролей
            </Typography>
          </Grid>
        </Grid>
      )}
      {pupilList.active.length > 0 && (
        <Tabs selectedIndex={tabIndex} onSelect={onSelect}>
          <TabList className="profile-block__child-list">
            {pupilList.active.map(
              ({
                pupilRole,
                eduGroup,
                nextOfKinRole,
                organization,
                pupilPerson,
              }) => (
                <Tab
                  key={pupilRole.id}
                  width={`calc(100%/${pupilList.active.length})`}
                >
                  <ProfileParentPupil
                    pupilRole={pupilRole}
                    eduGroup={eduGroup}
                    nextOfKinRole={nextOfKinRole}
                    organization={organization}
                    person={pupilPerson}
                  />
                </Tab>
              ),
            )}
          </TabList>
          <div className="profile-block">
            {pupilList.active.map(({ pupilRole, journalData }) => (
              <TabPanel key={pupilRole.id}>
                <Diary
                  esiaId={journalData?.id}
                  regionCode={journalData?.code}
                />
              </TabPanel>
            ))}
          </div>
        </Tabs>
      )}
    </>
  );
};

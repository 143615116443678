import {
  EduProviderStatusReason,
  NextOfKinStatusReason,
  PupilStatusReason,
} from '@declarations/enum/retireRoleStatusReason';

export const eduProviderStatusReasonLocale = {
  [EduProviderStatusReason.MISTAKE]: 'Создана по ошибке',
  [EduProviderStatusReason.INVALID_DATA]: 'Недостоверные данные',
  [EduProviderStatusReason.DUTY_TERMINATION]:
    'Прекращение выполнения обязанностей',
  [EduProviderStatusReason.PROFILE_DELETED]: 'Прекращение профиля',
} as const;

export const pupilStatusReasonLocale = {
  [PupilStatusReason.MISTAKE]: 'Создана по ошибке',
  [PupilStatusReason.INVALID_DATA]: 'Недостоверные данные',
  [PupilStatusReason.EARLY_EDUCATION_TERMINATION]: 'Прекращение обучения',
  [PupilStatusReason.EDUCATION_COMPLETED]: 'Завершение обучения',
  [PupilStatusReason.PROFILE_DELETED]: 'Прекращение профиля',
} as const;

export const nextOfKinStatusReasonLocale = {
  [NextOfKinStatusReason.MISTAKE]: 'Создана по ошибке',
  [NextOfKinStatusReason.INVALID_DATA]: 'Недостоверные данные',
  [NextOfKinStatusReason.ADULTHOOD]: 'Совершеннолетие обучающегося',
  [NextOfKinStatusReason.RELATIONSHIP_BREAKDOWN]: 'Прекращение отношения',
  [NextOfKinStatusReason.PROFILE_DELETED]: 'Прекращение профиля',
} as const;

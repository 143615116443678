import * as React from 'react';
import './ProfileBlock.scss';
import { FilesBlock } from '@pages/MainPage/ProfileBlock/FilesBlock/FilesBlock';
import { MyDayBook } from '@pages/MainPage/ProfileBlock/DaybookBlock';
import { ScheduleWidget } from '@pages/MainPage/ScheduleWidget/ScheduleWidget';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import { useEffect, useMemo } from 'react';
import { getUserRegion } from '@lib/userInfo/getUserRegion';
import { fetchSchedule } from '@redux/schedule/thunks';
import { Grid } from '@mui/material';
import { Loader } from '@components/common/Loader/Loader';
// import { JournalWidget } from '@pages/MainPage/JournalWidget/JournalWidget';
// import { ScheduleWidget } from '@pages/MainPage/ScheduleWidget/ScheduleWidget';
// import { FileWidget } from '@pages/MainPage/FileWidget/FileWidget';

export const ProfileTeacherBlock: React.FC = () => {
  const dispatch = useDispatch();
  const { locations, loading, schedule, startDate, endDate } = useSelector(
    (state: RootState) => ({
      locations: state.user?.locations,
      loading: state.schedule.loading,
      schedule: state.schedule.schedule,
      startDate: state.schedule.startDate,
      endDate: state.schedule.endDate,
    }),
  );
  const journalLinks = useSelector(
    (state: RootState) => state.filters.options.journalLinks,
  );
  const regionCode = useMemo(() => getUserRegion(locations), [locations]);
  const journalUrl = React.useMemo(() => {
    return journalLinks.find((j) => j.value === regionCode)?.label;
  }, [regionCode, journalLinks]);

  useEffect(() => {
    dispatch(fetchSchedule());
  }, [dispatch, startDate, endDate, regionCode]);

  if (loading) {
    return (
      <Grid
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: 500 }}
      >
        <Loader />
      </Grid>
    );
  }

  return (
    <div>
      {schedule && <ScheduleWidget />}
      <div className="profile-block">
        {!schedule && <MyDayBook link={journalUrl} />}
        <FilesBlock />
      </div>
    </div>
  );
};

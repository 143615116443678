import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
} from '@mui/material';
import { DayData } from '../types';

type Props = {
  shedule: {
    [time: string]: DayData;
  };
};

export const TabContent = ({ shedule }: Props) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: 'none', padding: 0, marginBottom: '32px' }}
    >
      <Table sx={{ minWidth: 650 }}>
        <TableBody>
          {Object.keys(shedule).map((time, index) => {
            const data: DayData = shedule[time];
            return (
              <TableRow
                key={time}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  scope="row"
                  valign="top"
                  sx={{
                    minWidth: 16,
                    padding: '16px 0',
                    verticalAlign: 'top',
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  scope="row"
                  sx={{ minWidth: 350, verticalAlign: 'top', fontSize: 16 }}
                >
                  <Box mb={1}>{data.lesson}</Box>
                  <Box color="#626b7a" fontSize={14}>
                    {time} каб. {data.classroom}
                  </Box>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ verticalAlign: 'top', fontSize: 16 }}
                >
                  {data.homework}
                  {/* <div dangerouslySetInnerHTML={{ __html: data.text }} /> */}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ verticalAlign: 'top', minWidth: '150px' }}
                >
                  <div className="diary-item__grade-wrap">
                    {data.score.map((grade, i) =>
                      grade ? (
                        <div key={i} className="diary-item__grade">
                          {grade.score}
                        </div>
                      ) : null,
                    )}
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

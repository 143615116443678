import axios from 'axios';
import { KEYCLOAK_SECRET, LOGIN_API_URL } from '@constants/config';

const instanceApi = axios.create({
  withCredentials: false,
  baseURL: LOGIN_API_URL,
});

const grantType = 'authorization_code';
const refreshTokenGrantToken = 'refresh_token';
const clientSecret = KEYCLOAK_SECRET;
const redirectUri: string = window.location.origin;
// const accessToken: string | null = getCookie(COOKIE_NAMES.ACCESS_TOKEN);

export const authApi = {
  getToken(code: string | string[]) {
    return instanceApi.post(
      `/auth/realms/etd-realm/protocol/openid-connect/token`,
      `grant_type=${grantType}&code=${code}&client_id=${process.env.REACT_APP_LOGIN_CLIENT_ID}&client_secret=${clientSecret}&redirect_uri=${redirectUri}`,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
  },
  refreshToken(refreshToken: string) {
    return instanceApi.post(
      `/auth/realms/etd-realm/protocol/openid-connect/token`,
      `grant_type=${refreshTokenGrantToken}&client_id=${process.env.REACT_APP_LOGIN_CLIENT_ID}&client_secret=${clientSecret}&refresh_token=${refreshToken}`,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
  },
};

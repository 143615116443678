import { FC, useCallback } from 'react';
import { AsyncProps } from 'react-select/async';
import { MultiValue, SingleValue } from 'react-select';

import SingleAsyncSelect from '@components/common/Select/SingleAsyncSelect';
import { Option } from '@components/common/Select/types';
import { OrganizationFilters } from '@declarations/organization';
import MultiAsyncSelect from '@components/common/Select/MultiAsyncSelect';
import { useCachedOrganisationsStateManager } from '@components/common/Select/hooks/useCachedOrganisationsStateManager';
import { EduOrganizationGroup } from '@declarations/eduOrganizationGroup';
import MultiSelect from '@components/common/Select/MultiSelect';

type OrganizationSelectProps = Omit<
  AsyncProps<Option, any, any>,
  'onChange' | 'value'
> & {
  name: string;
  placeholder: string;
  id: string;
  onChange?: (organization: string | string[]) => void;
  filters?: OrganizationFilters;
  allowedIds?: string[];
  value?: string | string[];
  isDisabled?: boolean;
  showFullName?: boolean;
  hardInstalledOrganizations?: EduOrganizationGroup[];
};

const OrganizationSelect: FC<OrganizationSelectProps> = ({
  onChange,
  filters,
  isMulti,
  allowedIds,
  value,
  showFullName,
  hardInstalledOrganizations,
  ...otherProps
}) => {
  const {
    displayedOrganizations,
    selectedOptions,
    loadOptions,
    defaultOptions,
    assembleOrganizations,
  } = useCachedOrganisationsStateManager({
    filters,
    value,
    showFullName,
    allowedIds,
    hardInstalledOrganizations,
  });

  const handleChange = useCallback(
    (option: SingleValue<Option>) => {
      const exists = displayedOrganizations?.find(
        (item) => option && item.id === option.value,
      );
      if (exists && exists.id && onChange) {
        onChange(exists.id);
      }
    },
    [onChange, displayedOrganizations],
  );

  const handleMultiChange = useCallback(
    (optionList: MultiValue<Option>) => {
      const ids = optionList.map((option) => option.value);
      const exists = displayedOrganizations?.filter(
        (item) => item.id && ids.includes(item.id),
      );

      if (exists && onChange) {
        onChange(
          exists?.reduce(
            (ids: string[], item) => (item.id ? [...ids, item.id] : ids),
            [],
          ),
        );
      }
    },
    [onChange, displayedOrganizations],
  );

  if (hardInstalledOrganizations) {
    return (
      <MultiSelect
        {...otherProps}
        options={defaultOptions}
        value={selectedOptions as Option[]}
        onChange={handleMultiChange}
        onBlur={assembleOrganizations}
      />
    );
  }

  if (isMulti) {
    return (
      <MultiAsyncSelect
        {...otherProps}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        onChange={handleMultiChange}
        value={selectedOptions}
        onBlur={assembleOrganizations}
      />
    );
  }
  return (
    <SingleAsyncSelect
      {...otherProps}
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      onChange={handleChange}
      value={selectedOptions}
      onBlur={assembleOrganizations}
    />
  );
};

export default OrganizationSelect;

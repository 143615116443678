import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from '@hooks/useSnackbar';
import { Grid, Typography } from '@mui/material';
import { Diary } from '@declarations/diary';
import { UI_MESSAGE } from '@declarations/enum/uiMessage';
import { ROUTES } from '@constants/routes';
import { diaryAPI } from '@api/diary';
import { ErrorMessage } from '@pages/FeedbackForm/ErrorMessage';
import { StyledInput } from '@components/styledComponents/StyledInput';
import Button from '@components/common/Button/Button';
import { Loader } from '@components/common/Loader/Loader';
import EmptyData from '@components/common/EmptyData/EmptyData';
import RegionSelect from '@components/common/Select/Region';

type DiaryForm = {
  id?: number;
  regionCode: string | number;
  url: string;
};

type ErrorMessages = {
  [Property in keyof Partial<DiaryForm>]: string;
};

export const DiaryForm = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const history = useHistory();
  const { addSnack } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<DiaryForm>({
    regionCode: '',
    url: '',
  });
  const [isLoadDiary, setIsLoadDiary] = useState(!!id);
  const [errLoadDiary, setErrLoadDiary] = useState(false);
  const [errors, setErrors] = useState<ErrorMessages>({});

  const handleChangeFormData = useCallback(
    (newData: { [key: string]: any }) =>
      setFormData((prevState: any) => ({
        ...prevState,
        ...newData,
      })),
    [],
  );

  const disableCheckBtn = !formData.regionCode || !formData.url;

  const disableSave = useMemo(() => {
    return disableCheckBtn;
  }, [disableCheckBtn]);

  const onSubmit = useCallback(async () => {
    const formErrors = validationForm(formData);
    setErrors(formErrors);
    if (isEmpty(formErrors)) {
      try {
        setLoading(true);
        const diaryItem = mapToDiary(formData);
        if (id) {
          await diaryAPI.update(diaryItem);
        } else {
          await diaryAPI.add(diaryItem);
        }
        addSnack(
          id
            ? 'Данные для получения дневников обновлены'
            : 'Данные для получения дневников созданы',
          'success',
        );
        history.push(ROUTES.managerDiaryList);
      } catch (err: any) {
        console.error(err);
        if (err.response?.data?.message) {
          addSnack(err.response?.data?.message, 'error');
        } else {
          addSnack(
            id
              ? 'Ошибка при обновлении данных для получения журнала'
              : 'Ошибка при создании данных для получения журнала',
            'error',
          );
        }
      } finally {
        setLoading(false);
      }
    }
  }, [formData, id, addSnack, history]);

  const handleSaveLink = useCallback(() => onSubmit(), [onSubmit]);

  useEffect(() => {
    const loadData = async (regionCode: string) => {
      try {
        const diaryItem = await diaryAPI.getByRegionCode(regionCode);
        if (diaryItem) {
          setFormData(mapDiaryToFormData(diaryItem));
        }
      } catch (err) {
        setErrLoadDiary(true);
        addSnack(UI_MESSAGE.DIARY_NOT_AVAILABLE, 'error');
      } finally {
        setIsLoadDiary(false);
      }
    };
    if (id && +id) {
      loadData(id);
      return;
    }
    if (id && id !== 'add') {
      setIsLoadDiary(false);
      setErrLoadDiary(true);
      addSnack(UI_MESSAGE.DIARY_NOT_AVAILABLE, 'error');
    }
  }, [addSnack, id]);

  if (isLoadDiary) {
    return <Loader />;
  }

  if (errLoadDiary) {
    return <EmptyData />;
  }

  return (
    <div>
      <Grid container sx={{ marginBottom: '32px' }}>
        <Grid item xs alignItems="center">
          <Typography variant="h2" sx={{ fontWeight: '500', fontSize: '18px' }}>
            {!id ? 'Новый дневник' : 'Редактирование дневника'}
          </Typography>
        </Grid>
        <Grid item />
      </Grid>
      <Grid container sx={{ maxWidth: '768px' }}>
        <Grid item xs={3}>
          Название региона
          <span className="mandatory-field">*</span>
        </Grid>
        <Grid item xs={9} pb={2}>
          <RegionSelect
            name="selectRegInDiary"
            id="selectRegInDiary"
            placeholder="Выберите регион РФ"
            isDisabled={!!id}
            showRegionCode
            onChange={(selectedOptions: any) =>
              handleChangeFormData({ regionCode: selectedOptions.value })
            }
            value={formData.regionCode ? +formData.regionCode : 0}
          />
        </Grid>
        <Grid item xs={3}>
          Базовая ссылка
          <span className="mandatory-field">*</span>
        </Grid>
        <Grid item xs={9}>
          <div style={{ marginBottom: '16px' }}>
            <StyledInput
              required
              size={'medium'}
              fullWidth
              value={formData.url}
              onChange={({ target }) =>
                handleChangeFormData({
                  url: target.value,
                })
              }
            />
            {errors.url && <ErrorMessage>{errors.url}</ErrorMessage>}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container columnSpacing={2}>
            <Grid item xs />
            <Grid item xs="auto">
              <Button
                variant="third"
                disabled={loading || disableSave}
                onClick={handleSaveLink}
              >
                Сохранить
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapToDiary = (form: DiaryForm): Diary => {
  return {
    id: form.id,
    regionCode: `0${form.regionCode}`.slice(-2),
    url: form.url,
  };
};

const mapDiaryToFormData = (diaryItem: Diary): DiaryForm => {
  return {
    id: diaryItem.id,
    regionCode: diaryItem.regionCode,
    url: diaryItem.url,
  };
};

const validationForm = (value: DiaryForm) => {
  const isRequiredMessage = 'Обязатально для заполнения';
  const newErrors: {
    [Property in keyof Partial<ErrorMessages>]: string;
  } = {};
  if (!value.regionCode) {
    newErrors.regionCode = isRequiredMessage;
  }
  if (!value.url || value.url.trim().length === 0) {
    newErrors.url = isRequiredMessage;
  }
  if (value.url) {
    const urlRE = /^(https?|ftp):\/\/.+\w$/;
    const validDataBaseUrl = urlRE.test(value.url.trim().toLowerCase());
    if (!validDataBaseUrl) {
      newErrors.url = 'Невалидный адрес (URL) для ссылки';
    }
  }
  return newErrors;
};

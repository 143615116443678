import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { IMask, useIMask } from 'react-imask';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { DatePickerProps } from '@mui/lab';
import MuiDatePicker from '@mui/lab/DatePicker';
import { Grid, TextField } from '@mui/material';
import { TextFieldProps as MuiTextFieldPropsType } from '@mui/material/TextField/TextField';

const timeReg = /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/;

type Props = Omit<DatePickerProps, 'renderInput' | 'value' | 'onChange'> & {
  withTime?: boolean;
  value?: Date | null;
  onChange: (date: Date | null) => void;
  renderInput?: (props: MuiTextFieldPropsType) => ReactElement;
  isMinWidth?: true;
};

const WITH_TIME_STYLES = {
  width: 172,
  [`& fieldset`]: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  ['& input']: {
    padding: '6px 10px',
  },
};

const TIME_FIELD_STYLES = {
  width: 72,
  [`& fieldset`]: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  ['& input']: {
    padding: '6px 10px',
  },
};

const TIME_MASK_OPTIONS = {
  overwrite: true,
  autofix: true,
  mask: 'HH:MM',
  blocks: {
    HH: {
      mask: IMask.MaskedRange,
      placeholderChar: 'HH',
      from: 0,
      to: 23,
      maxLength: 2,
    },
    MM: {
      mask: IMask.MaskedRange,
      placeholderChar: 'MM',
      from: 0,
      to: 59,
      maxLength: 2,
    },
  },
};

export const DatePicker = ({
  withTime = false,
  onChange,
  isMinWidth,
  value = null,
  ...props
}: Props) => {
  const {
    ref,
    setValue: setTimeValue,
    value: timeValue,
  } = useIMask(TIME_MASK_OPTIONS);

  const timeIsValid = useMemo(
    () => withTime && timeReg.test(timeValue),
    [timeValue, withTime],
  );

  const dateTime = useMemo(() => {
    if (value) {
      const dateTime = new Date(value);
      return {
        hours: dateTime.getHours(),
        minutes: dateTime.getMinutes(),
      };
    }
    return null;
  }, [value]);

  const setTime = useCallback(() => {
    if (withTime) {
      if (dateTime) {
        setTimeValue(`${addZero(dateTime.hours)}:${addZero(dateTime.minutes)}`);
      } else {
        setTimeValue('');
      }
    }
  }, [dateTime, setTimeValue, withTime]);

  const onChangeDate = useCallback(
    (date: any) => {
      if (date) {
        const newDate = dayjs(date);
        const isValid = newDate.isValid();
        const updatedDate = newDate.toDate();
        onChange(isValid ? updatedDate : null);
      } else {
        onChange(null);
      }
    },
    [onChange],
  );

  const onChangeTime = useCallback(
    (time: string) => {
      if (timeReg.test(time)) {
        const [hours, minutes] = time.split(':').map(Number);
        if (value) {
          const newDate = new Date(value);
          newDate.setHours(hours, minutes);

          if (!dayjs(value).isSame(newDate, 'minutes')) {
            onChange(newDate);
          }
        }
      }
    },
    [onChange, value],
  );

  useEffect(setTime, [setTime]);

  return (
    <Grid
      display="flex"
      minWidth={isMinWidth ? { xs: '100%', sm: '100%', md: '100%' } : {}}
    >
      {withTime && (
        <Grid item>
          <TextField
            inputRef={ref}
            name="time"
            size="small"
            sx={TIME_FIELD_STYLES}
            color={timeIsValid ? undefined : 'error'}
            placeholder="00:00"
            onBlur={setTime}
            onChange={({ target }) => onChangeTime(target.value)}
          />
        </Grid>
      )}
      <Grid
        item
        minWidth={isMinWidth ? { xs: '100%', sm: '100%', md: '100%' } : {}}
        className={'date-picker__wrapper'}
      >
        <MuiDatePicker
          {...props}
          className={classNames(props.className, 'my-date-picker')}
          value={value}
          onChange={(value) => onChangeDate(value)}
          inputFormat="DD.MM.YYYY"
          mask="__.__.____"
          renderInput={
            props.renderInput
              ? props.renderInput
              : (params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={
                      withTime
                        ? WITH_TIME_STYLES
                        : {
                            width: 172,
                            [`& fieldset`]: {
                              border: '1px solid #D8D8DD',
                              borderRadius: '6px',
                            },
                            ['& input']: {
                              minHeight: '27px',
                            },
                          }
                    }
                  />
                )
          }
        />
      </Grid>
    </Grid>
  );
};

const addZero = (item: string | number) =>
  Number(item) < 10 ? `0${item}` : item;

const getRole = (user: any) => {
  const access = user?.realm_access;
  const role = access?.roles;

  for (const item of role || []) {
    switch (item) {
      case 'AdminProfile':
        return 'Администратор';
      case 'EduProviderProfile':
        return 'Педагогический работник';
      case 'NextOfKinProfile':
        return 'Законный представитель обучающегося';
      case 'PupilProfile':
        return 'Обучающийся';
      case 'ContributorProfile':
        return 'Участник ЦОС';
    }
  }
  return 'отсутствует';
};

export default getRole;

import { Quiz } from '@api/types/quiz';
import { ROUTES } from '@constants/routes';
import { DEMO_QUIZ_STORAGE } from '@constants/config';

export const openPreviewWindow = (quiz: Quiz, route = ROUTES.quizDemo) => {
  localStorage.setItem(DEMO_QUIZ_STORAGE, JSON.stringify(quiz));
  const newWindow = window.open(route, 'example', 'width=1000,height=800');
  if (newWindow) {
    newWindow.onload = function () {
      newWindow.document.body.style.padding = '24px';
    };
  }
};

import { FC, useEffect, useMemo, useState } from 'react';
import { UserRoleList, UserRoleStatus } from '@redux/user/types';
import { Grid, Typography } from '@mui/material';
import { EduProviderRole } from '@declarations/role';
import { Entity } from '@declarations/common';
import { ReactComponent as ConfirmIcon } from '@assets/icons/confirm.svg';
import { splitReference } from '@lib/userInfo/getUserAllRoleList';
import { EduGroup } from '@declarations/eduGroup';

interface ProfileAdminRoleListProps {
  userRoleList: UserRoleList;
}

const ProfileAdminRoleList: FC<ProfileAdminRoleListProps> = ({
  userRoleList,
}) => {
  const [roleList, setRoleList] = useState<UserRoleList | null>(null);
  useEffect(() => {
    if (
      !userRoleList?.profileRoles?.roleListAll?.length ||
      !roleList?.profileRoles?.roleListAll?.length
    ) {
      setRoleList(userRoleList);
    }
  }, [roleList, userRoleList]);

  const adminRoleList = useMemo(() => {
    let result: Entity<EduProviderRole>[] = [];
    if (roleList?.profileRoles?.roleListAll?.length) {
      const grouped = (
        roleList?.profileRoles?.roleListAll as Entity<EduProviderRole>[]
      )?.reduce(
        (acc, value) => {
          switch (value.resource.status) {
            case UserRoleStatus.RETIRED:
              acc.retired.push(value);
              break;
            case UserRoleStatus.CONFIRMED:
              acc.confirmed.push(value);
              break;
            case UserRoleStatus.CREATED_BY_ADMIN:
              acc.adminCreate.push(value);
              break;
            case UserRoleStatus.CREATED_BY_SELF:
              acc.selfCreate.push(value);
              break;
          }
          return acc;
        },
        { retired: [], confirmed: [], adminCreate: [], selfCreate: [] } as {
          retired: Entity<EduProviderRole>[];
          confirmed: Entity<EduProviderRole>[];
          adminCreate: Entity<EduProviderRole>[];
          selfCreate: Entity<EduProviderRole>[];
        },
      );

      result = [...grouped.confirmed];
    }
    return result;
  }, [roleList]);

  const adminOrgList = useMemo(() => {
    return adminRoleList
      .map((role) => {
        const org =
          role.resource.eduOrganizationGroup?.reference ||
          role.resource.organization?.reference;
        if (org) {
          const orgData = splitReference(org);
          if (roleList?.profileRoles?.userEduGroupListAll?.length) {
            return roleList?.profileRoles?.userEduGroupListAll?.filter(
              (element: Entity<EduGroup>) =>
                element.resource.id === orgData.id &&
                element.resource.resourceType === orgData.resourceType,
            );
          }
          return roleList?.profileRoles?.userOrgListAll?.filter(
            (element: Entity<EduGroup>) =>
              element.resource.id === orgData.id &&
              element.resource.resourceType === orgData.resourceType,
          );
        }
      })
      .flat(1);
  }, [
    adminRoleList,
    roleList?.profileRoles?.userEduGroupListAll,
    roleList?.profileRoles?.userOrgListAll,
  ]);

  return (
    <>
      <Typography variant="h4" sx={{ fontWeight: 500 }}>
        Роль
      </Typography>
      <ul className="profile__list">
        {!adminRoleList ||
          (!adminRoleList.length && (
            <li className="profile__list-item">
              <Typography variant="h5">Пока нет добавленных ролей</Typography>
            </li>
          ))}
        {adminRoleList.map((role, index: number) => {
          return (
            <li className="profile__list-item" key={role.resource.id}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography variant="h5">
                    {role.resource.role.display}
                  </Typography>
                </Grid>
                <Grid item>
                  <ConfirmIcon />
                </Grid>
              </Grid>
              {adminOrgList[index] && (
                <div className="profile__text">
                  {adminOrgList[index]?.resource?.name}
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default ProfileAdminRoleList;

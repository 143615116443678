import {
  ReactChild,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

type Props = {
  show?: boolean;
  handleShow?: (show: boolean) => void;
  disabled?: boolean;
  MyButton: () => ReactNode;
  children: ((setShow: (show: boolean) => void) => ReactChild) | ReactChild;
};

const id = Date.now();

const elementName = 'drop-down';

const DropDown = ({
  handleShow,
  disabled,
  MyButton,
  children,
  show: visible,
}: Props) => {
  const [show, setShow] = useState(false);

  const active = useMemo(
    () => (visible !== undefined ? visible : show),
    [visible, show],
  );

  const hide = useCallback(
    ({ target }: any) => {
      if (show && !target.closest(`[data-name="${elementName}${id}"]`)) {
        setShow(false);
        handleShow && handleShow(false);
      }
    },
    [handleShow, show],
  );

  const changeShow = useCallback(() => {
    setShow((prev) => !prev);
    handleShow && handleShow(!show);
  }, [show, handleShow]);

  useEffect(() => {
    document.addEventListener('click', hide, true);

    return () => {
      document.removeEventListener('click', hide, true);
    };
  }, [hide]);

  useEffect(() => {
    if (visible !== undefined && show !== visible) {
      setShow(visible);
    }
  }, [visible, show]);

  return (
    <div
      data-name={elementName + id}
      className={`drop-down-brand ${active ? 'active' : ''}`}
    >
      <div
        role="button"
        className={`drop-down-brand__button ${disabled ? 'disabled' : ''}`}
        onClick={changeShow}
      >
        {MyButton()}
      </div>

      <div className="drop-down-brand__body">
        {typeof children === 'function' ? children(setShow) : children}
      </div>
    </div>
  );
};

export default DropDown;

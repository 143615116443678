import { HeaderTV } from '@pages/SmartTVPages/Header/HeaderTV';
import { ResponsiveContainer } from '@components/common/ResponsiveContainer/ResponsiveContainer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import educationApi from '@api/smart/education';
import {
  EducationContent,
  EducationData,
  EducationSectionData,
  EducationSections,
  EducationSectionsContent,
  SmartPageParams,
} from '@api/types/smart/education';
import { setLoading } from '@redux/schedule';
import { MY_LIBRARY_LINK } from '@constants/config';
import '../SmartTVMyEducation/SmartEducation.scss';
import './SmartSection.scss';
import myEduLogo from '@assets/smartTV/play_perspective.png';
import btnBack from '@assets/smartTV/ico-smart_btnBack.svg';
const SECTION_DESCRIPTION = 'Описание';
const SERVICE_NAME = `${MY_LIBRARY_LINK}attaches/`;
const MATERIALS_URL = `${MY_LIBRARY_LINK}player/`;

export const SmartSection = () => {
  const { id } = useParams<SmartPageParams>();
  const [data, setData] = useState<EducationData<EducationContent>>({
    content: [],
    total: 0,
  });
  const history = useHistory();
  const [sections, setSections] = useState<
    EducationSections<EducationSectionsContent>
  >({ content: [], totalPages: 0 });
  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const sections = await educationApi.getSectionList();
      if (sections) {
        setSections(sections);
      }
      const data = await educationApi.getAllContent();
      if (data) {
        setData(data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);
  const sortData = useMemo(() => {
    if (data?.content?.length) {
      const res: EducationSectionData[] = [];
      data.content.forEach((item, id) => {
        return item.sections.forEach((section: number) => {
          const sectionItem: EducationSectionsContent | undefined =
            sections.content.find((el) => el.id === section);
          if (sectionItem) {
            const { value, property } = sectionItem;
            const sectionDescription = property?.find(
              (sectionElement) => sectionElement.code === SECTION_DESCRIPTION,
            )?.value;
            const isSectionAdded = res?.find(
              (sectionElement) => sectionElement.section === value,
            );
            if (isSectionAdded) {
              const index = res.findIndex((i) => i.section === value);
              res[index].content = [...res[index].content, item];
            } else {
              const arr: EducationSectionData = {
                section: '',
                description: '',
                content: [],
                id: id,
              };
              arr.id = id;
              arr.section = value;
              arr.content = [item];
              arr.description = sectionDescription || '';
              res.push(arr);
            }
          }
        });
      });
      return res;
    }
    return [];
  }, [data.content, sections.content]);
  const found = sortData.find((obj) => obj.id == id);
  useEffect(() => {
    loadData();
  }, [loadData]);
  return (
    <div className="smart">
      <HeaderTV />
      <ResponsiveContainer sx={{ flex: '2 0 auto', pb: 4 }}>
        <Grid container columnSpacing={0.5}>
          <Grid item>
            <Typography
              variant="h1"
              sx={{
                color: '#ffffff',
                fontWeight: 400,
                fontSize: '48px',
                letterSpacing: '-1px',
              }}
            >
              Моё просвещение
            </Typography>
          </Grid>
          <Grid item>
            <img src={myEduLogo} alt="Логотип" />
          </Grid>
        </Grid>

        <Box sx={{ minHeight: '50vh', mt: 12 }}>
          <Typography
            variant="h1"
            sx={{
              color: '#ffffff',
              fontWeight: 400,
              fontSize: '48px',
              letterSpacing: '-1px',
            }}
          >
            <Grid item>
              <button
                className="smart-btn_back"
                onClick={() => history.goBack()}
              >
                <img src={btnBack} alt="Кнопка назад" />
              </button>
            </Grid>
            {found && found.section}
          </Typography>
          {found && (
            <Typography
              variant="h6"
              sx={{
                letterSpacing: '-0.4px',
                color: '#ABABAD',
                display: 'inline-block',
                mt: 1,
                fontWeight: '400',
                fontSize: '14px',
              }}
            >
              {found.description}
            </Typography>
          )}
          <Grid
            container
            columnSpacing={2}
            tabIndex={1}
            className="smart__slider"
          >
            {found &&
              found.content.map((contentEl: EducationContent) => (
                <Grid item key={contentEl.id} className="smart__slider-slide">
                  <Grid
                    container
                    flexDirection="column"
                    rowSpacing={2}
                    component="a"
                    tabIndex={1}
                    href={`${MATERIALS_URL}${contentEl.id}#init`}
                    sx={{
                      outline: '0',
                      textDecoration: 'none',
                      color: 'inherit',
                    }}
                  >
                    <Grid item>
                      <div
                        className="smart__item-cover"
                        style={{ maxWidth: '172px' }}
                      >
                        {contentEl.cover ? (
                          <img
                            src={`${SERVICE_NAME}${contentEl.cover}`}
                            alt={contentEl.name}
                          />
                        ) : (
                          <div
                            className="smart__item-cover-color"
                            style={{
                              backgroundColor: `${contentEl.coverColor}`,
                            }}
                          >
                            <Typography
                              variant={'caption' as 'h1'}
                              sx={{
                                color: '#333333',
                                fontWeight: '700',
                                p: 2,
                                display: 'block',
                                wordBreak: 'break-word',
                              }}
                            >
                              {contentEl.name}
                            </Typography>
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item>
                      <Typography
                        style={{ color: '#fff', width: '172px' }}
                        variant="h6"
                        sx={{ wordBreak: 'break-word' }}
                      >
                        {contentEl.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Box>
      </ResponsiveContainer>
    </div>
  );
};

import { useState } from 'react';

import Button from '@components/common/Button/Button';
import { Box, ClickAwayListener, Popper } from '@mui/material';
import { ROUTES } from '@constants/routes';

const NotificationSubscribe = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleAwayClick = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <ClickAwayListener onClickAway={handleAwayClick}>
      <div>
        <Button
          sx={{ minWidth: 0, width: 36, height: 36, p: 0 }}
          onClick={handleClick}
          aria-describedby={id}
        >
          ···
        </Button>
        <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
          <Box
            sx={{
              p: '8px',
              backgroundColor: '#ffffff',
              boxShadow:
                '0px 1px 8px rgba(123, 135, 158, 0.12), 0px 8px 32px rgba(123, 135, 158, 0.25)',
              borderRadius: '8px',
            }}
          >
            <Button
              to={ROUTES.notificationSubscribe}
              sx={{ fontSize: 16, fontWeight: 400 }}
            >
              Управление подписками
            </Button>
          </Box>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default NotificationSubscribe;

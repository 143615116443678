import { RootState } from '../store';
import { Faq } from './types';

const faq = (state: RootState) => state.faq;

export const getFaqs = (state: RootState) => faq(state).faqs;
export const getFaqFilter = (state: RootState) => faq(state)?.filter;
export const getFaqById = (state: RootState, id: string) => {
  const faqList = getFaqs(state).data;
  const faqItem = faqList.find((faq: Faq) => faq.id === Number(id));
  return faqItem;
};

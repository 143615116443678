import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Menu, MenuItem, Typography } from '@mui/material';

import Button from '@components/common/Button/Button';
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';
import {
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
} from '@mui/x-data-grid';
import CommonFilter from '@pages/Manager/CommonFilter/CommonFilter';
import { ToggleGroup } from '@components/common/StyledToggleButtonGroup/StyledToggleButtonGroup';
import { quizAPI } from '@api/quiz';
import { Quiz } from '@api/types/quiz';
import { generateLink, getNewItemName } from '@lib/common';
import { useSnackbar } from '@hooks/useSnackbar';
import { Filter } from '@declarations/common';
import Table from '@components/common/Table/Table';
import { ROUTES } from '@constants/routes';
import { openPreviewWindow } from '@lib/quiz';

const PER_PAGE_OPTIONS = [20, 40];

const columns: GridColDef[] = [
  { field: 'title', headerName: 'Заголовок', flex: 1, minWidth: 300 },
  {
    field: 'target',
    headerName: 'Аудитория',
    minWidth: 200,
  },
  {
    field: 'startDate',
    headerName: 'Дата публикации',
    minWidth: 170,
    renderCell: (params: GridRenderCellParams<Quiz>) => {
      const status = params.row.status;
      return status === 'PUBLISHED' ? (
        new Date(params.row.startDate).toLocaleDateString('ru')
      ) : (
        <div className="quiz-list__status-label">
          {status === 'DRAFT'
            ? 'черновик'
            : `архив, ${new Date(params.row.startDate).toLocaleDateString(
                'ru',
              )}`}
        </div>
      );
    },
  },
  {
    field: 'region',
    headerName: 'Субъект РФ',
    minWidth: 150,
  },
  {
    field: 'eduLevels',
    headerName: 'Уровень образования',
  },
  {
    field: 'eduOrganizations',
    headerName: 'ОО',
  },
  {
    field: 'eduGroupOrganizations',
    headerName: 'Группы ОО',
  },
];

const QuizList = () => {
  const history = useHistory();
  const { addSnack } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState<Partial<Filter>>({
    // Намеренно сделано, т.к. в FAQ period = undefined
    period: [undefined, undefined],
  });
  const [quiz, setQuiz] = useState<{ total: number; data: Quiz[] }>({
    total: 0,
    data: [],
  });
  const [selectedQuizes, setSelectedQuizes] = useState<Quiz[]>([]);
  const [pagination, setPagination] = useState({
    pageSize: PER_PAGE_OPTIONS[0],
    currentPage: 1,
  });

  const rows = useMemo(
    () =>
      quiz.data.map((item) => ({
        ...item,
        target: item.targetAudience.map((region) => region.name).join(', '),
        region: item.regions.map((region) => region.name).join(', '),
        eduLevels: item.eduLevels.map((level) => level.name).join(', '),
        // TODO-achernyavets, поправить когда с бэка будут приходить данные
        eduOrganizations: '',
        // TODO-achernyavets, поправить когда с бэка будут приходить данные
        eduGroupOrganizations: '',
      })),
    [quiz.data],
  );

  const quizesNameList = useMemo(
    () => quiz.data.map((item) => item.title),
    [quiz.data],
  );

  const onContextMenu = (e: any): void => {
    if (e.type === 'contextmenu') {
      e.preventDefault();
      const closestParent = e?.target?.closest('div[data-id]');
      if (closestParent && selectedQuizes.length === 0) {
        const id = closestParent.dataset?.id;
        if (id) {
          const quizItem = quiz.data.find((item) => item.id == id);
          quizItem && setSelectedQuizes([quizItem]);
        }
      }
      handleContextMenu(e);
    }
  };

  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null,
    );
  };

  const handlePageChange = useCallback((page: number) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: page + 1,
    }));
  }, []);

  const handlePageSizeChange = useCallback((pageSize: number) => {
    setPagination((prev) => ({
      ...prev,
      pageSize,
    }));
  }, []);

  const handleClose = useCallback(() => {
    setSelectedQuizes([]);
    setContextMenu(null);
  }, []);

  const handleRowSelect = useCallback(
    (selectionModel: GridSelectionModel) => {
      setSelectedQuizes(
        quiz.data?.filter((item) => selectionModel.includes(item.id || 0)),
      );
    },
    [quiz.data],
  );

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const { content, totalElements } = await quizAPI.search(
        pagination,
        filter,
      );

      setQuiz({
        total: totalElements || 0,
        data: content || [],
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [filter, pagination]);

  const items = [
    { value: '', label: 'Все' },
    { value: 'PUBLISHED', label: 'Опубликованные' },
    { value: 'DRAFT', label: 'Черновики' },
    { value: 'ARCHIVED', label: 'Архив' },
  ];

  const handleUnpublish = useCallback(async () => {
    if (selectedQuizes.length) {
      try {
        const response = await Promise.allSettled(
          selectedQuizes.map((newsItem) =>
            quizAPI.changeStatus(newsItem.id || 0, 'ARCHIVED'),
          ),
        );
        if (response.some(({ status }) => status === 'rejected')) {
          if (response.every(({ status }) => status === 'rejected')) {
            throw new Error('Some news not updated');
          }
          addSnack('Опросы сняты с публикации частично', 'error');
        } else {
          let message = 'Опрос снята с публикации';
          if (selectedQuizes.length > 1) {
            message = 'Опросы сняты с публикации';
          }
          addSnack(message, 'success');
        }
        await loadData();
      } catch (error) {
        console.error(error);
        let message = 'Не удалось снять опрос с публикации';
        if (selectedQuizes.length > 1) {
          message = 'Не удалось снять опросы с публикации';
        }
        addSnack(message, 'error');
      }
    }
    handleClose();
  }, [selectedQuizes, handleClose, loadData, addSnack]);

  const handleView = useCallback(() => {
    if (selectedQuizes.length) {
      if (selectedQuizes.length > 1) {
        return addSnack('Массовое редактирование не поддерживается', 'error');
      }
      const quiz = selectedQuizes[0];
      if (quiz) {
        openPreviewWindow(quiz, ROUTES.quizPreview);
      }
    }
    handleClose();
  }, [handleClose, selectedQuizes, addSnack]);

  const handleCopy = useCallback(async () => {
    if (selectedQuizes.length) {
      try {
        const response = await Promise.allSettled(
          selectedQuizes.map((quiz) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { id, ...newQuiz } = quiz;
            newQuiz.title = getNewItemName(quizesNameList, newQuiz.title);
            newQuiz.status = 'DRAFT';
            return quizAPI.create(newQuiz);
          }),
        );
        if (response.some(({ status }) => status === 'rejected')) {
          if (response.every(({ status }) => status === 'rejected')) {
            throw new Error('Some news not copied');
          }
          addSnack('Опросы скопированы частично', 'error');
        } else {
          let message = 'Опрос скопирован';
          if (selectedQuizes.length > 1) {
            message = 'Опросы скопированы';
          }
          addSnack(message, 'success');
        }
        await loadData();
      } catch (error) {
        console.error(error);
        let message = 'Ошибка при копировании опроса';
        if (selectedQuizes.length > 1) {
          message = 'Ошибка при копировании опросов';
        }
        addSnack(message, 'error');
      }
      await loadData();
    }
    handleClose();
  }, [selectedQuizes, quizesNameList, handleClose, loadData, addSnack]);

  const handleRemove = useCallback(async () => {
    if (selectedQuizes) {
      try {
        await quizAPI.deleteQuiz(selectedQuizes.map((quiz) => quiz.id || 0));

        let message = 'Опрос удален';
        if (selectedQuizes.length > 1) {
          message = 'Опросы удалены';
        }
        addSnack(message, 'success');

        await loadData();
      } catch (error) {
        console.error(error);
        let message = 'Ошибка удаления опроса';
        if (selectedQuizes.length > 1) {
          message = 'Ошибка удаления опросов';
        }
        addSnack(message, 'error');
      }
    }
    handleClose();
  }, [selectedQuizes, handleClose, loadData, addSnack]);

  const handleEdit = useCallback(() => {
    if (selectedQuizes.length) {
      if (selectedQuizes.length > 1) {
        return addSnack('Массовое редактирование не поддерживается', 'error');
      }
      const selectedQuizesItem = selectedQuizes[0];
      if (selectedQuizesItem.id) {
        const link = generateLink(ROUTES.managerQuizItem, {
          id: selectedQuizesItem.id,
        });
        history.push(link);
      }
    } else {
      addSnack('Необходимо выбрать опрос для редактирования', 'error');
    }
  }, [history, selectedQuizes, addSnack]);

  const handleViewResult = useCallback(() => {
    if (selectedQuizes.length) {
      if (selectedQuizes.length > 1) {
        return addSnack(
          'Массовый просмотр результатов не поддерживается',
          'error',
        );
      }
      const quiz = selectedQuizes[0];
      if (quiz.id) {
        const link = generateLink(ROUTES.managerQuizResult, {
          id: quiz.id,
        });
        history.push(link);
      }
    }
  }, [selectedQuizes, addSnack, history]);

  const contextMenuItems = useMemo(() => {
    const groups = selectedQuizes?.reduce(
      (acc, quiz) => {
        switch (quiz.status) {
          case 'DRAFT':
            acc.draft++;
            break;
          case 'PUBLISHED':
            acc.published++;
            break;
          case 'ARCHIVED':
            acc.archived++;
            break;
        }
        if (quiz.hasPrivs) {
          acc.hasCredentials++;
        }
        return acc;
      },
      {
        draft: 0,
        published: 0,
        archived: 0,
        hasCredentials: 0,
      },
    );

    const hasCredentials = selectedQuizes.length === groups.hasCredentials;

    if (!groups.archived && !groups.draft && !groups.published) {
      return [];
    }

    const menuItems: JSX.Element[] = [];
    const isPublished = groups.archived === 0 && groups.draft === 0;
    if (isPublished) {
      menuItems.push(
        <MenuItem key="view" onClick={handleView}>
          Просмотр
        </MenuItem>,
      );
    }
    menuItems.push(
      <MenuItem key="copy" onClick={handleCopy}>
        Скопировать
      </MenuItem>,
    );

    if (groups.archived === 0 && groups.published === 0 && hasCredentials) {
      if (selectedQuizes.length === 1) {
        menuItems.push(
          <MenuItem key="edit" onClick={handleEdit}>
            Изменить
          </MenuItem>,
        );
      }
      menuItems.push(
        <MenuItem key="delete" onClick={handleRemove}>
          Удалить
        </MenuItem>,
      );
    }

    if (isPublished && hasCredentials) {
      menuItems.push(
        <MenuItem key="unpublish" onClick={handleUnpublish}>
          Снять с публикации
        </MenuItem>,
      );
    }

    if (groups.draft === 0) {
      menuItems.push(
        <MenuItem key="unpublish" onClick={handleViewResult}>
          Просмотр ответов
        </MenuItem>,
      );
    }

    return menuItems;
  }, [
    selectedQuizes,
    handleCopy,
    handleEdit,
    handleRemove,
    handleUnpublish,
    handleView,
    handleViewResult,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="quiz-list">
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {contextMenuItems}
        {/* {selectedItem?.status &&
          menuItemsByStatus[selectedItem.status].map(({ key, label }) => (
            <MenuItem key={key} onClick={() => handleContextItemClick(key)}>
              {label}
            </MenuItem>
          ))} */}
      </Menu>
      <Grid container>
        <Grid item xs alignItems="center">
          <Typography variant="h2" sx={{ fontWeight: '500', fontSize: '18px' }}>
            Опросы
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="first"
            to={ROUTES.managerQuizAdd}
            startIcon={<PlusIcon />}
          >
            Добавить
          </Button>
        </Grid>
      </Grid>
      <div style={{ margin: '20px 0' }}>
        <Grid container columnSpacing={2}>
          <Grid item xs="auto">
            <ToggleGroup
              items={items}
              value={filter.status || ''}
              handleChange={(event: any, value: string) => {
                setFilter((prev) => ({
                  ...prev,
                  status: value || undefined,
                }));
              }}
            />
          </Grid>
          <Grid item xs flexGrow={1}>
            <CommonFilter onChange={setFilter} />
          </Grid>
        </Grid>
      </div>
      <div
        style={{ height: 'auto', width: '100%' }}
        onContextMenu={onContextMenu}
      >
        <Table
          rows={rows}
          loading={loading}
          columns={columns}
          handleRowSelect={handleRowSelect}
          rowCount={quiz.total}
          page={pagination.currentPage - 1}
          pageSize={pagination.pageSize}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>
    </div>
  );
};

export default QuizList;

import { PUBLICATION_DATE } from '@pages/NewsListPage/enum';

export const selectOptionsTime = [
  {
    value: PUBLICATION_DATE.ALL_TIME,
    label: 'За все время',
  },
  {
    value: PUBLICATION_DATE.WEEK,
    label: 'За неделю',
  },
  {
    value: PUBLICATION_DATE.DAY,
    label: 'За сутки',
  },
];

export const selectSortOptions = [
  {
    value: 'DESC',
    label: 'Сначала новые',
  },
  {
    value: 'ASC',
    label: 'Сначала старые',
  },
];

export const selectSortCategoryOptions = [
  {
    value: 'news',
    label: 'Новости',
  },
  {
    value: 'links',
    label: 'Полезные ссылки',
  },
  {
    value: 'polls',
    label: 'Опросы',
  },
  {
    value: 'faq',
    label: 'Вопросы и ответы',
  },
  {
    value: 'books',
    label: 'Материалы библиотеки',
  },
];

export const selectOptionsEducation = [
  {
    value: '',
    label: 'Все',
  },
  {
    value: '0',
    label: 'Без образования',
  },
  {
    value: '1',
    label: 'Среднее',
  },
  {
    value: '2',
    label: 'Среднее специальное',
  },
  {
    value: '3',
    label: 'Высшее',
  },
  {
    value: '4',
    label: 'Два и более высших',
  },
];

export const selectOptionsSystem = [
  {
    value: '',
    label: 'Все',
  },
  {
    value: '1',
    label: 'Базовая платформа',
  },
];

export const selectOptionsTer = [
  {
    value: '',
    label: 'Все',
  },
  {
    value: '0',
    label: 'Центральный федеральный округ',
  },
  {
    value: '1',
    label: 'Северо-Западный федеральный округ',
  },
  {
    value: '2',
    label: 'Приволжский федеральный округ',
  },
];

export const selectOptionsSchool = [
  {
    value: '',
    label: 'Все',
  },
  {
    value: '0',
    label: 'Школа №12',
  },
  {
    value: '1',
    label: 'Школа №17',
  },
  {
    value: '2',
    label: 'Школа №89',
  },
];

export const systemOptions = [
  {
    value: 'BASE_SYSTEM',
    label: 'Базовая система',
  },
  {
    value: 'LIBRARY',
    label: 'Библиотека',
  },
];

import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import { Widget } from './Widget';
import { Grade } from './Grade';
import { HomeWork } from './HomeWork';
import { MyDayBook } from '@pages/MainPage/ProfileBlock/DaybookBlock';

type Props = Partial<{
  esiaId: string;
  regionCode: string;
}>;

export const Diary = ({ esiaId, regionCode }: Props) => {
  const journalLinks = useSelector(
    (state: RootState) => state.filters.options.journalLinks,
  );
  const journalUrl = React.useMemo(() => {
    return journalLinks.find((j) => j.value === regionCode)?.label;
  }, [regionCode, journalLinks]);

  if (!regionCode || !esiaId) {
    return <MyDayBook link={journalUrl} />;
  }

  return (
    <>
      <Widget regionCode={regionCode} esiaId={esiaId} journalUrl={journalUrl} />
      <Grade regionCode={regionCode} esiaId={esiaId} journalUrl={journalUrl} />
      <HomeWork
        regionCode={regionCode}
        esiaId={esiaId}
        journalUrl={journalUrl}
      />
    </>
  );
};

import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, FormControlLabel, Grid, Typography } from '@mui/material';
import { StyledInput } from '@components/styledComponents/StyledInput';
import { Editor } from '@tinymce/tinymce-react';
import Button from '@components/common/Button/Button';
import EmptyData from '@components/common/EmptyData/EmptyData';
import { Loader } from '@components/common/Loader/Loader';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { FaqStatus, Faq } from '@redux/faq/types';
import { faqApi } from '@api/faq';
import {
  SETTINGS_FORM_DEFAULT_VALUE_FOR_FAQ,
  SettingsForm,
  SettingsFormType,
} from '@pages/Manager/CommonForms/SettingsForm';
import { useSnackbar } from '@hooks/useSnackbar';
import dayjs from 'dayjs';
import StyledCheckbox from '@components/common/StyledCheckbox/StyledCheckbox';
import { ROUTES } from '@constants/routes';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import { useCheckForAdminStructuredRole } from '@hooks/useCheckForAdminStructuredRole';
import { StructureRole } from '@declarations/enum/structureRole';
import { UI_MESSAGE } from '@declarations/enum/uiMessage';

type FaqForm = {
  title: string;
  body: string;
  settings: SettingsFormType;
  isImportant: boolean;
};

export const FaqForm: React.FC = () => {
  const editorRef = useRef<any>(null);
  const { id } = useParams<{ id: string | undefined }>();
  const history = useHistory();
  const { addSnack } = useSnackbar();

  const functionalRoles = useSelector(
    (state: RootState) => state.user.functionalRoles,
  );

  const funcRoleId = useMemo(
    () => functionalRoles.find((item) => item.role === 'FaqCRUD')?.id,
    [functionalRoles],
  );

  const { isRole: isOOGroupsAdmin } = useCheckForAdminStructuredRole(
    StructureRole.OO_GROUP_ADMINISTRATOR,
  );

  const [formData, setFormData] = useState<FaqForm>({
    title: '',
    body: '',
    settings: SETTINGS_FORM_DEFAULT_VALUE_FOR_FAQ,
    isImportant: false,
  });
  const [isEditorContentEmpty, setIsEditorContentEmpty] = useState(
    !formData.body.length,
  );
  const [isLoadFaq, setIsLoadFaq] = useState(!!id);
  const [errLoadFaq, setErrLoadFaq] = useState(false);

  const handleChangeFormData = useCallback(
    (newData: { [key: string]: any }) =>
      setFormData((prevState: any) => ({
        ...prevState,
        ...newData,
      })),
    [],
  );

  const onSubmit = useCallback(
    async (status: FaqStatus) => {
      try {
        const bodyContent = editorRef.current?.getContent();
        const faqItem = await mapToFaq(formData, status, bodyContent);
        if (id) {
          await faqApi.updateFaq(faqItem, id);
        } else {
          await faqApi.createFaq(faqItem);
        }
        addSnack(id ? 'Вопрос обновлен' : 'Вопрос создан', 'success');
        history.push(ROUTES.managerFaqList);
      } catch (err) {
        console.error(err);
        addSnack(
          id ? 'Ошибка при обновлении вопроса' : 'Ошибка при создании вопроса',
          'error',
        );
      }
    },
    [addSnack, formData, history, id],
  );

  const handleSaveFaq = useCallback(
    () => onSubmit(FaqStatus.DRAFT),
    [onSubmit],
  );

  const handlePublishFaq = useCallback(
    () => onSubmit(FaqStatus.PUBLISHED),
    [onSubmit],
  );

  const disableSave =
    isEditorContentEmpty ||
    formData.title.length === 0 ||
    (!!isOOGroupsAdmin &&
      (!formData.settings.region.length || !formData.settings.ooGroup.length));

  const disabledPublishBtn = useMemo(() => {
    if (disableSave) {
      return true;
    }
    if (formData?.settings?.period?.length) {
      return (
        dayjs(formData.settings.period[0]).toISOString() > dayjs().toISOString()
      );
    }
  }, [formData.settings.period, disableSave]);

  useEffect(() => {
    const loadData = async (faqId: number | string) => {
      try {
        const faqItem = await faqApi.getFaqById(faqId, true);
        if (faqItem) {
          setFormData(mapFaqToFormData(faqItem));
        }
      } catch (err) {
        setErrLoadFaq(true);
        addSnack(UI_MESSAGE.FAQ_NOT_AVAILABLE, 'error');
      } finally {
        setIsLoadFaq(false);
      }
    };

    if (id) {
      loadData(id);
      return;
    }
    if (id && id !== 'add') {
      setIsLoadFaq(false);
      setErrLoadFaq(true);
      addSnack(UI_MESSAGE.FAQ_NOT_AVAILABLE, 'error');
    }
  }, [addSnack, id]);

  if (isLoadFaq) {
    return <Loader />;
  }

  if (errLoadFaq) {
    return <EmptyData />;
  }

  return (
    <div>
      <Grid container sx={{ marginBottom: '32px' }}>
        <Grid item xs alignItems="center">
          <Typography variant="h2" sx={{ fontWeight: '500', fontSize: '18px' }}>
            {!id ? 'Новый вопрос' : 'Редактирование'}
          </Typography>
        </Grid>
        <Grid item />
      </Grid>
      <Grid container sx={{ maxWidth: '768px' }}>
        <Grid item xs={3}>
          Вопрос
          <span className="mandatory-field">*</span>
        </Grid>
        <Grid item xs={9}>
          <div style={{ marginBottom: '16px' }}>
            <StyledInput
              className={'news__search'}
              size={'medium'}
              fullWidth
              value={formData.title}
              onChange={({ target }) =>
                handleChangeFormData({
                  title: target.value,
                })
              }
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          Ответ
          <span className="mandatory-field">*</span>
        </Grid>
        <Grid item xs={9}>
          <div style={{ marginBottom: '16px' }}>
            <Editor
              onInit={(evt, editor) => (editorRef.current = editor)}
              apiKey="kloj8h7ptguwelopnwfvh5w22t0wr6i5jg4ijc37gvuq40l6"
              initialValue={formData.body}
              onEditorChange={(text) =>
                setIsEditorContentEmpty(text.length === 0)
              }
              outputFormat="html"
              init={{
                language: 'ru',
                height: 500,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            />
          </div>
        </Grid>
        <Grid item xs={3} sx={{ mb: 2 }}>
          <Typography fontSize="18px" fontWeight="500" color="#2C3038">
            Настройки
          </Typography>
        </Grid>
        <SettingsForm
          forFaq
          value={formData.settings}
          funcRoleId={funcRoleId}
          onChange={(settings) => handleChangeFormData({ settings })}
        />
        <Grid item xs={3} />
        <Grid item xs={9}>
          <FormControlLabel
            label={<Box sx={{ ml: 1 }}>отметить вопрос как важный</Box>}
            control={
              <StyledCheckbox
                name="isImportant"
                size="small"
                checked={formData.isImportant}
                onChange={({ target }) =>
                  handleChangeFormData({
                    isImportant: target.checked,
                  })
                }
              />
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mt: 4, p: '24px 32px', backgroundColor: '#f2f4f7' }}
        >
          <Grid container columnSpacing={2} sx={{ justifyContent: 'flex-end' }}>
            <Grid item xs="auto">
              <Button
                variant="fourth"
                disabled={disableSave}
                sx={{ backgroundColor: '#fff' }}
                onClick={handleSaveFaq}
              >
                Сохранить
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                variant="first"
                disabled={disabledPublishBtn}
                onClick={handlePublishFaq}
              >
                Опубликовать
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapToFaq = async (
  form: FaqForm,
  status: FaqStatus,
  bodyContent: string,
): Promise<Faq> => {
  return {
    status,
    isImportant: form.isImportant,
    body: bodyContent,
    title: form.title,
    startDate: dayjs(form.settings.period[0]).toISOString(),
    endDate: form.settings.period[1]
      ? dayjs(form.settings.period[1]).toISOString()
      : null,
    eduLevels: form.settings.educationLevel.map((item) => ({ id: +item })),
    targetAudience: form.settings.audience.map((item) => ({ id: +item })),
    genders:
      form.settings.forWhom === 'all'
        ? ['FEMALE', 'MALE']
        : [form.settings.forWhom],
    parallels: form.settings.parallel ? [{ id: +form.settings.parallel }] : [],
    regions: form.settings.region.map(({ value, label }) => ({
      id: +value,
      name: label,
    })),
    eduOrganizations: form.settings.oo
      ? form.settings.oo.map((item) => +item)
      : [],
    eduGroupOrganizations: form.settings.ooGroup
      ? form.settings.ooGroup.map((item) => +item)
      : [],
  };
};

const mapFaqToFormData = (faq: Faq): FaqForm => {
  const {
    title,
    body,
    isImportant,
    startDate,
    endDate,
    eduLevels,
    targetAudience,
    genders,
    parallels = [],
    regions,
    eduOrganizations,
    eduGroupOrganizations,
  } = faq;

  return {
    title,
    body,
    isImportant,
    settings: {
      audience: targetAudience.map((item) => item.id),
      educationLevel: eduLevels.map((item) => item.id),
      parallel: parallels.map(({ id }) => id),
      period: [
        startDate ? new Date(startDate) : undefined,
        endDate ? new Date(endDate) : undefined,
      ],
      region: regions.map((item) => ({
        label: item.name || '-',
        value: item.id,
      })),
      oo: eduOrganizations.map((org) => org.toString()),
      ooGroup: eduGroupOrganizations.map((org) => org.toString()),
      forWhom:
        genders?.length === 2
          ? 'all'
          : (genders?.length && genders[0]) === 'MALE'
          ? 'MALE'
          : 'FEMALE',
    },
  };
};

import { ThunkAction } from 'redux-thunk';
import { RootState } from '../store';
import { AnyAction } from 'redux';
import { setNotificationList, setUnreadNotifications } from './index';
import { setFetched, setFetching, setFetchingError } from '../ui';
import { GetNotificationOptions, notifyApi } from '@api/notify';
import { NOTIFICATION_COUNT_TO_OUTPUT } from '@constants/constants';

export type VoidThunk = ThunkAction<void, RootState, undefined, AnyAction>;

export const fetchNotificationList =
  (options: GetNotificationOptions = {}): VoidThunk =>
  async (dispatch, getState) => {
    const requestName = 'fetchNotificationList';
    const state = getState();
    const { user, notifications } = state;

    if (!user?.user) {
      return;
    }
    dispatch(setFetching(requestName));
    try {
      const notificationList = await notifyApi.getAllUserNotifications(
        +user.user.etd_id,
        1,
        {
          size: notifications.notifications.size,
          page: notifications.notifications.page,
          ...options,
        },
      );
      dispatch(setNotificationList(notificationList));
      dispatch(setFetched(requestName));
    } catch (e: any) {
      console.error(e.message || e);
      dispatch(setFetchingError(requestName));
    }
  };

export const setReadNotificationsByIds =
  (ids: number[]): VoidThunk =>
  async (dispatch) => {
    const requestName = 'readNotificationsByIds';
    dispatch(setFetching(requestName));
    try {
      await Promise.all(ids.map((id) => notifyApi.readById(id)));
      dispatch(setFetched(requestName));
    } catch (error) {
      console.error(error);
      dispatch(setFetchingError(requestName));
    }
    dispatch(fetchUnreadNotifications());
  };

export const setDeletedNotificationsByIds =
  (ids: number[]): VoidThunk =>
  async (dispatch) => {
    const requestName = 'deletedNotificationsByIds';
    dispatch(setFetching(requestName));
    try {
      await Promise.all(ids.map((id) => notifyApi.removeById(id)));
      dispatch(setFetched(requestName));
    } catch (error) {
      console.error(error);
      dispatch(setFetchingError(requestName));
    }
    dispatch(fetchUnreadNotifications());
  };

export const fetchUnreadNotifications =
  (): VoidThunk => async (dispatch, getState) => {
    const requestName = 'fetchUnreadNotifications';
    const state = getState();
    const { user } = state;

    if (!user?.user) {
      return;
    }
    dispatch(setFetching(requestName));
    try {
      const { data, total } = await notifyApi.getAllUserNotifications(
        +user.user.etd_id,
        1,
        {
          size: NOTIFICATION_COUNT_TO_OUTPUT,
          isRead: false,
        },
      );
      dispatch(setUnreadNotifications({ data, total }));
    } catch (error) {
      console.error(error);
      dispatch(setFetchingError(requestName));
    }
  };

import uniq from 'lodash/uniq';
import { Entity, Filter } from '@declarations/common';
import { EduGroup } from '@declarations/eduGroup';
import { Organization } from '@declarations/organization';
import { PupilRole } from '@declarations/role';
import { StructureRole } from '@declarations/enum/structureRole';
import { EntityTypes } from '@mock-data/entities';
import { organizationApi } from '@api/organization';
import { eduGroupApi } from '@api/eduGroup';
import {
  AccountEntry,
  EUserRole,
  UserRole,
  UserRoleStatus,
} from '@redux/user/types';

export const getUserDefaultFilter = async (
  entries: AccountEntry[],
  roleListAll: [],
  userRole: UserRole,
) => {
  const roleCodeList: StructureRole[] = uniq(
    roleListAll
      .filter((item: any) => item.resource.status !== UserRoleStatus.RETIRED)
      .map((item: any) => item.resource.role.code) || [],
  );

  const initialFilters: Partial<Filter> = {};

  const eduGroups = entries
    ?.filter((item) => item.resource.resourceType === EntityTypes.EDU_GROUP)
    .map((item) => item.resource) as EduGroup[];

  const organizations = entries
    ?.filter((item) => item.resource.resourceType === EntityTypes.ORGANIZATION)
    .map((item) => item.resource) as Organization[];

  for (const role of roleCodeList) {
    if (
      [
        StructureRole.TEACHER,
        StructureRole.CLASS_TEACHER,
        StructureRole.OO_STUDENT,
        StructureRole.FAMILY_STUDENT,
        StructureRole.SPO_STUDENT,
      ].includes(role)
    ) {
      initialFilters.oo = (initialFilters.oo || []).concat(
        findOrganizationIds(organizations),
      );
      initialFilters.educationLevel = (
        initialFilters.educationLevel || []
      ).concat(findEduLevelsFromEduGroups(eduGroups));
      initialFilters.region = (initialFilters.region || []).concat(
        findRegionCodes(organizations),
      );
    }

    if (
      [
        StructureRole.PARENT,
        StructureRole.GUARDIAN,
        StructureRole.TRUSTEE,
      ].includes(role)
    ) {
      const pupilRoles = entries?.filter(
        (item) =>
          item.resource.resourceType === EntityTypes.PUPIL_ROLE &&
          item.resource.status !== UserRoleStatus.RETIRED,
      ) as Entity<PupilRole>[];
      if (pupilRoles.length) {
        const orgIds = pupilRoles?.reduce((result: string[], item) => {
          if (item.resource.organization) {
            const id = item.resource.organization.reference.split('/')[1];
            if (id && !result.includes(id)) {
              return [...result, id];
            }
          }
          return result;
        }, []);

        const eduGroupsIds = pupilRoles?.reduce((result: string[], item) => {
          if (item.resource.eduGroup) {
            const id = item.resource.eduGroup.reference.split('/')[1];
            if (id && !result.includes(id)) {
              return [...result, id];
            }
          }
          return result;
        }, []);

        const notExistOrgIds = orgIds.filter((orgId) =>
          organizations.every(({ id }) => id !== orgId),
        );

        const orgs = organizations?.filter(
          ({ id }) => id && orgIds.includes(id),
        );

        if (notExistOrgIds.length) {
          const notExistOrgs = await Promise.all(
            notExistOrgIds.map(organizationApi.get),
          );
          orgs.push(...notExistOrgs);
        }
        const notExistEduGroupIds = eduGroupsIds?.filter((eduGroupId) =>
          eduGroups.every(({ id }) => id !== eduGroupId),
        );

        const userEduGroups = eduGroups?.filter(
          ({ id }) => id && eduGroupsIds.includes(id),
        );
        if (notExistEduGroupIds.length) {
          const notExistEduGroups = await Promise.all(
            notExistEduGroupIds.map(eduGroupApi.get),
          );
          userEduGroups.push(...notExistEduGroups);
        }

        initialFilters.oo = (initialFilters.oo || []).concat(
          findOrganizationIds(orgs),
        );
        initialFilters.educationLevel = (
          initialFilters.educationLevel || []
        ).concat(findEduLevelsFromEduGroups(userEduGroups));
        initialFilters.region = (initialFilters.region || []).concat(
          findRegionCodes(orgs),
        );
      }
    }

    if (
      [
        StructureRole.OO_ADMINISTRATOR,
        StructureRole.OO_GROUP_ADMINISTRATOR,
      ].includes(role)
    ) {
      initialFilters.oo = (initialFilters.oo || []).concat(
        findOrganizationIds(organizations),
      );

      initialFilters.region = (initialFilters.region || []).concat(
        findRegionCodes(organizations),
      );
    }
  }

  const parallelEduGroups = eduGroups.filter(
    (group) => group.type === 'параллель',
  );

  if (
    parallelEduGroups.length &&
    [EUserRole.PARENT, EUserRole.STUDENT].includes(userRole)
  ) {
    const response = await Promise.allSettled(
      parallelEduGroups.map((eduGroup) =>
        organizationApi.getEduLevelByParallelName(eduGroup.name),
      ),
    );

    response.forEach((responseItem) => {
      if (responseItem.status === 'fulfilled' && responseItem.value?.id) {
        initialFilters.levelOfEducation = [
          ...(initialFilters.levelOfEducation
            ? initialFilters.levelOfEducation
            : []),
          responseItem.value.id,
        ];
      }
    });
  }

  return uniqValues(initialFilters);
};

const findRegionCodes = (entries: Organization[]) =>
  entries
    ?.reduce(
      (result: number[], item) =>
        item.address?.length
          ? [
              ...result,
              ...item.address?.map((address) => +(address.state?.code || 0)),
            ]
          : result,
      [],
    )
    .filter((regionCode) => !!regionCode);

const findOrganizationIds = (entries: Organization[]) =>
  entries?.reduce(
    (result: string[], item) => (item.id ? [...result, item.id] : result),
    [],
  );

const findEduLevelsFromEduGroups = (groups: EduGroup[]) => {
  const parallels = groups?.reduce((result: number[], group) => {
    const classNum = group.name.match(/\d+/g);
    if (classNum && ['класс', 'параллель'].includes(group.type)) {
      return [...result, +classNum[0]];
    }
    return result;
  }, []);

  return parallels.map((item) => {
    if (item >= 1 && item <= 4) {
      return 1;
    }
    if (item >= 5 && item <= 9) {
      return 2;
    }
    return 3;
  });
};

const uniqValues = (filter: Partial<Filter>): Partial<Filter> => {
  const result: { [k: string]: any } = {};

  Object.keys(filter)?.forEach((key: string) => {
    // @ts-ignore
    const value = filter[key];
    if (Array.isArray(value)) {
      result[key] = uniq(value);
    } else {
      result[key] = value;
    }
  });

  return result as Partial<Filter>;
};

import { AxiosResponse } from 'axios';
import { convertUTCtoLocale } from '@lib/date/date';

const DATE_REGEX = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;

export const dataResponseInterceptor = (response: AxiosResponse) =>
  response.data;

export const mapToLocaleDateInterceptor = (data: any) => {
  if (!data) {
    return data;
  }

  if (Array.isArray(data)) {
    const array: any = [];

    data?.forEach((item) => {
      array.push(mapToLocaleDateInterceptor(item));
    });

    return array;
  }

  if (isObject(data)) {
    const obj: { [key: string]: any } = {};

    Object.keys(data).forEach((item: string) => {
      // @ts-ignore
      obj[item] = mapToLocaleDateInterceptor(data[item]);
    });

    return obj;
  }

  if (DATE_REGEX.test(data)) {
    return convertUTCtoLocale(data);
  }
  return data;
};

const isObject = (data: any) =>
  Object.prototype.toString.call(data) === '[object Object]';

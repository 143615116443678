import dayjs from 'dayjs';
import { Schedule } from '../../types';

export const getScoreList = (schedule?: Schedule) => {
  const scoreList: {
    value: number;
    lesson: string;
    date: string;
  }[] = [];

  if (schedule) {
    Object.keys(schedule).forEach((key) => {
      const date = dayjs(key, 'DD.MM.YYYY');
      if (date.isValid()) {
        Object.values(schedule[key]).forEach((lesson) => {
          if (lesson.score && lesson.score.length > 0) {
            lesson.score.forEach((score) => {
              scoreList.push({
                date: date.format('DD MMM'),
                lesson: lesson.lesson,
                value: +score.score,
              });
            });
          }
        });
      }
    });
  }

  return scoreList;
};

import { Entity } from '@declarations/common';
import { AuthAccount } from '@declarations/profile';
import { EntityTypes } from '@mock-data/entities';
import { AuthAccountReponse } from '@redux/user/types';

const getWhoIsAuth = (req: AuthAccountReponse) => {
  if (req) {
    return req.entry?.find(
      (item) => item.resource.resourceType === EntityTypes.AUTH_ACCOUNT,
    ) as Entity<AuthAccount> | undefined;
  }
};

export default getWhoIsAuth;

import { useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar as useNotistackSnackbar } from 'notistack';
import { nanoid } from 'nanoid';

import { ReactComponent as ConfirmIcon } from '@assets/icons/confirm.svg';
import { ReactComponent as ErrorIcon } from '@assets/icons/alert/error.svg';
import { SnackContent } from '@components/common/SnackContent/SnackContent';

export const useSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useNotistackSnackbar();

  const addSnack = useCallback(
    (
      message: string,
      type: 'success' | 'error',
      snackKey?: string,
      persist?: boolean,
    ) => {
      const id = snackKey || nanoid();
      enqueueSnackbar(
        <SnackContent
          message={message}
          icon={
            type === 'success' ? (
              <ConfirmIcon width={24} height={24} />
            ) : (
              <ErrorIcon width={24} height={24} />
            )
          }
        />,
        {
          key: id,
          action: <CloseButton onButtonClick={() => closeSnackbar(id)} />,
          preventDuplicate: true,
          persist,
        },
      );
    },
    [enqueueSnackbar, closeSnackbar],
  );

  return {
    addSnack,
  };
};

type CloseButtonProps = {
  onButtonClick: () => void;
};

const CloseButton = ({ onButtonClick }: CloseButtonProps) => (
  <IconButton
    size="small"
    aria-label="close"
    color="inherit"
    onClick={onButtonClick}
  >
    <CloseIcon fontSize="small" />
  </IconButton>
);

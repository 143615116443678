import { TargetInfo } from '@declarations/user';
import { EntityTypes } from '@mock-data/entities';
import { AccountEntry } from '@redux/user/types';
import { StructureRole } from '@declarations/enum/structureRole';
import { AdminRole } from '@declarations/role';

export const getUserTargetInfo = (entries: AccountEntry[]) => {
  const response: TargetInfo = {
    organizations: {},
    eduOrganizationGroups: {},
    adminRoles: {},
  };

  entries?.forEach((entry) => {
    const resourceType = entry.resource.resourceType;
    if (entry.resource.id) {
      switch (resourceType) {
        case EntityTypes.ORGANIZATION:
          response.organizations[entry.resource.id] = entry.resource;
          break;
        case EntityTypes.EDU_ORGANIZATION_GROUP:
          response.eduOrganizationGroups[entry.resource.id] = entry.resource;
          break;
        case EntityTypes.ADMIN_ROLE:
          if (
            entry.resource.role.code === StructureRole.OO_GROUP_ADMINISTRATOR
          ) {
            response.adminRoles[entry.resource.role.code] = response.adminRoles[
              entry.resource.role.code
            ]
              ? [
                  ...(response.adminRoles[
                    entry.resource.role.code
                  ] as AdminRole[]),
                  entry.resource,
                ]
              : [entry.resource];
          } else {
            response.adminRoles[entry.resource.role.code] = entry.resource;
          }
          break;
      }
    }
  });
  return response;
};

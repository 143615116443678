import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { Search } from '@redux/search/types';
import { setPublishDate } from '@lib/date/date';
import isValidUrl from '@lib/url/isValidUrl';
import { IMAGE_BASE_URL, MY_LIBRARY_LINK } from '@constants/config';

import './SearchItem.scss';
import { regionApi } from '@api/region';

type Props = {
  item: Search;
};

const SearchItem = ({ item }: Props) => {
  const history = useHistory();
  const [regionName, setRegionName] = useState('');

  useEffect(() => {
    const loadRegions = async () => {
      try {
        const result = await regionApi.search({});
        const filteredRegions = result?.filter(
          (region) =>
            item?.content?.region && item?.content?.region.includes(region.id),
        );
        if (filteredRegions.length > 0) {
          setRegionName(
            filteredRegions.map((region) => region.name).join(', '),
          );
        }
      } catch (err) {
        console.error(err);
      }
    };
    if (item?.content?.region && item?.content?.region.length > 0) {
      loadRegions();
    }
  }, [item?.content?.region]);

  const clickHandler = useCallback(() => {
    const link = getEntityLink(item);
    if (link.isExternal) {
      window.open(link.url, '_blank');
    } else {
      history.push(link.url);
    }
  }, [history, item]);

  const hasImg = useMemo(() => !!item?.content?.img, [item?.content?.img]);

  const typeName = getTypeName(item);

  return (
    <div className="search-item">
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        onClick={clickHandler}
      >
        <Grid item xs>
          <div className="search__item-title">{item?.content?.title || ''}</div>
          {item.content?.body && (
            <Box>
              <div
                className="search-item__body"
                dangerouslySetInnerHTML={{
                  __html: item?.content?.body,
                }}
              />
            </Box>
          )}
          <Box mt={1}>
            <Typography variant="caption" color="#818999">
              {setPublishDate(item?.content?.status_date)}
            </Typography>
          </Box>
          <Grid item container columnSpacing={1}>
            <Grid item xs="auto">
              <Grid container flexDirection="row" columnSpacing="4px" mt={1}>
                <Grid item>
                  {typeName && (
                    <Box
                      bgcolor="#F2F4F7"
                      borderRadius="4px"
                      padding="2px 8px"
                      fontSize="12px"
                      lineHeight="20px"
                      height="24px"
                    >
                      {typeName}
                    </Box>
                  )}
                </Grid>
                {regionName && (
                  <Grid item>
                    <Box
                      borderRadius="4px"
                      padding="2px 8px"
                      fontSize="12px"
                      height="24px"
                      color="#006DE5"
                      border="1px solid #DEE8FA"
                    >
                      {regionName}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {hasImg && (
          <Grid item xs="auto">
            <div className="search__item-img">
              <img
                style={{ maxWidth: 100, maxHeight: 100, borderRadius: '4px' }}
                src={`${IMAGE_BASE_URL}${item?.content?.img}`}
                alt={item?.content?.title}
              />
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default SearchItem;

const getEntityLink = (item: Search) => {
  if (item.index === 'ms-library') {
    return {
      url: `${MY_LIBRARY_LINK}content/${item.content.uid}`,
      isExternal: true,
    };
  }
  if (item.content.type === 'links') {
    const url = getUrl(item.content.url);
    return {
      url,
      isExternal: true,
    };
  }
  return {
    url: `/${item.content.type}/${item.content.uid}`,
    isExternal: false,
  };
};

const getUrl = (url: string) =>
  isValidUrl(url) ? url.trim() : `http://${url.trim()}`;

const getTypeName = (item: Search) => {
  if (item.index === 'ms-library') {
    return 'Материалы библиотеки';
  }
  switch (item.content.type) {
    case 'links':
      return 'Ссылка';
    case 'news':
      return 'Новости';
    case 'faq':
      return 'Вопросы и ответы';
    case 'polls':
      return 'Опросы';
    default:
      return null;
  }
};

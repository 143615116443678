import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { StructureRole } from '@declarations/enum/structureRole';
import { RootState } from '@redux/store';
import { EduOrganizationGroup } from '@declarations/eduOrganizationGroup';
import { SettingsFormType } from '@pages/Manager/Links/constants';
import { TargetInfo } from '@declarations/user';
import { Option } from '@components/common/Select/types';
import { eduOrganizationGroupApi } from '@api/eduOrganizationGroup';
import { EduOrganizationGroupType } from '@declarations/enum/eduOrganizationGroup';
import { parseReference } from '@lib/common';
import { AdminRole } from '@declarations/role';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import { useCheckForAdminStructuredRole } from '@hooks/useCheckForAdminStructuredRole';
import { UserRoleStatus } from '@redux/user/types';

//TODO: need more deep refactoring logic of this hook

interface InputProps {
  setSelectedGroups: React.Dispatch<
    React.SetStateAction<{ [p: string]: EduOrganizationGroup }>
  >;
  value: SettingsFormType;
  onChange: (value: SettingsFormType) => void;
  selectedGroups: {
    [id: string]: EduOrganizationGroup;
  };
}

const useTargetingForOOGroupAdmin = (inputProps: InputProps) => {
  const { selectedGroups, setSelectedGroups, value, onChange } = inputProps;

  const targetInfo = useSelector((state: RootState) => state.user.targetInfo);

  const regionOptions = useSelector(
    (state: RootState) => state.filters.options.regions,
  );

  const [loadingStatusTargetingData, setLoadingStatusTargetingData] = useState<
    'notStarted' | 'inProcess' | 'completed' | 'loadingError'
  >('notStarted');
  const [disableAdminGroupsFields, setDisableAdminGroupsFields] =
    React.useState<{
      region: boolean;
      org: boolean;
      group: boolean;
    }>({ region: false, org: false, group: false });

  const [
    allowedOrganizationsIdsForAdminGroup,
    setAllowedOrganizationsIdsForAdminGroup,
  ] = React.useState<string[] | null>([]);

  const [
    allowedOrganizationsForAdminGroup,
    setAllowedOrganizationsForAdminGroup,
  ] = React.useState<EduOrganizationGroup[] | undefined>();

  const [allowedRegionsForAdminGroup, setAllowedRegionsForAdminGroup] =
    React.useState<number[] | null>([]);

  const [
    allowedOrganizationGroupsForAdminGroup,
    setAllowedOrganizationGroupsForAdminGroup,
  ] = React.useState<{ [p: string]: EduOrganizationGroup }>({});

  const { isRole: isOOGroupsAdmin } = useCheckForAdminStructuredRole(
    StructureRole.OO_GROUP_ADMINISTRATOR,
  );

  const isVisibleItem = useMemo(() => {
    return (
      // @ts-ignore
      isOOGroupsAdmin === false ||
      (isOOGroupsAdmin &&
        (loadingStatusTargetingData === 'completed' ||
          loadingStatusTargetingData === 'loadingError'))
    );
  }, [isOOGroupsAdmin, loadingStatusTargetingData]);

  const getTargetingDataForGroupsAdmin = useCallback(async () => {
    try {
      setLoadingStatusTargetingData('inProcess');
      const info = await findTargetingDataForGroupsAdmin(
        targetInfo,
        regionOptions,
      );
      setLoadingStatusTargetingData('completed');
      if (!info) return;

      setAllowedRegionsForAdminGroup(
        info.includedRegions.map((region) => +region.value),
      );
      setAllowedOrganizationsIdsForAdminGroup(info.includedOrganizationsIds);
      setAllowedOrganizationsForAdminGroup(info.includedOrganizations);

      const allowedGroups = info.assignedEduGroups.reduce((acc, group) => {
        if (group.id) acc[group.id] = group;
        return acc;
      }, {} as { [p: string]: EduOrganizationGroup });

      setAllowedOrganizationGroupsForAdminGroup(allowedGroups);

      setDefaultCheckedItems(info.includedRegions, allowedGroups);

      setDisableAdminGroupsFields({
        region: info.includedRegions.length === 1,
        org: !info.includedOrganizationsIds.length,
        group: Object.keys(allowedGroups).length === 1,
      });
    } catch (e) {
      console.log(e);
      setDisableAdminGroupsFields({
        region: true,
        org: true,
        group: true,
      });
      setLoadingStatusTargetingData('loadingError');
    }
    // eslint-disable-next-line
  }, [regionOptions, targetInfo]);

  const setDefaultCheckedItems = (
    includedRegions: Option[],
    allowedGroups: { [p: string]: EduOrganizationGroup },
  ) => {
    const updatedValue: {
      region?: Option[];
      ooGroup?: string[];
    } = {};

    if (includedRegions.length === 1) {
      updatedValue.region = includedRegions.map((region) => ({
        label: region.label,
        value: region.value,
      }));
    }

    if (Object.keys(allowedGroups).length === 1) {
      setSelectedGroups(allowedGroups);
      updatedValue.ooGroup = Object.keys(allowedGroups);
    }

    if (Object.keys(updatedValue).length) {
      onChange({
        ...value,
        ...updatedValue,
      });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => getTargetingDataForGroupsAdmin(), 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [getTargetingDataForGroupsAdmin]);

  const findTargetingDataForGroupsAdmin = async (
    targetInfo: TargetInfo | null,
    regionsList: Option[],
  ) => {
    if (
      !targetInfo ||
      !Array.isArray(
        targetInfo?.adminRoles[StructureRole.OO_GROUP_ADMINISTRATOR],
      )
    )
      return;

    const eduGroupRefs = (
      targetInfo?.adminRoles[
        StructureRole.OO_GROUP_ADMINISTRATOR
      ] as AdminRole[]
    )
      .filter((adminGroup) => adminGroup.status === UserRoleStatus.CONFIRMED)
      .map((adminGroup) => adminGroup.eduOrganizationGroup)
      .filter((orgGroup) => !!orgGroup);

    if (!eduGroupRefs.length) return;

    const assignedEduGroupsIds: string[] = eduGroupRefs.map(
      (eduGroupRef) => eduGroupRef!.reference.split('/')[1],
    );
    let assignedEduGroups = assignedEduGroupsIds.map(
      (id) => targetInfo.eduOrganizationGroups[id],
    );

    const assembledData = await Promise.allSettled(
      assignedEduGroupsIds.map((eduGroupId) =>
        findDataForEduGroup({ targetInfo, regionsList, eduGroupId }),
      ),
    );

    const includedRegions = uniqBy(
      assembledData.reduce((acc, item) => {
        return item.status === 'fulfilled'
          ? [...acc, ...item.value.regions]
          : acc;
      }, [] as Option[]),
      'value',
    );

    const includedOrganizationsIds = uniq(
      assembledData.reduce((acc, item) => {
        return item.status === 'fulfilled'
          ? [...acc, ...item.value.organizationIds]
          : acc;
      }, [] as string[]),
    );

    const includedOrganizations = uniqBy(
      assembledData.reduce((acc, item) => {
        return item.status === 'fulfilled'
          ? [...acc, ...item.value.organizations]
          : acc;
      }, [] as EduOrganizationGroup[]),
      'id',
    );

    const typeGChildrenForTargetGroup = await getTypeGChildren(
      assignedEduGroupsIds,
    );

    assignedEduGroups = uniqBy(
      [...assignedEduGroups, ...typeGChildrenForTargetGroup],
      'id',
    );

    return {
      assignedEduGroups,
      includedRegions,
      includedOrganizationsIds,
      includedOrganizations,
    };
  };

  const findDataForEduGroup = async (data: {
    targetInfo: TargetInfo;
    regionsList: Option[];
    eduGroupId: string;
  }) => {
    const { targetInfo, regionsList, eduGroupId } = data;

    let regions = findRegion(regionsList, +eduGroupId, targetInfo);

    const response = await eduOrganizationGroupApi.search({
      type: EduOrganizationGroupType.ORGANIZATION,
      id: eduGroupId,
    });
    const organizations = response.entry
      .filter((entry) => entry.resource.organization?.reference)
      .map((entity) => entity.resource);

    const organizationIds = organizations
      .map((org) => parseReference(org.organization!.reference)?.id)
      .filter((id) => !Number.isNaN(id) && id !== null);

    if (!regions) {
      regions = await findRegionsByRequest(eduGroupId, regionsList);
    }

    return { regions, organizationIds, organizations };
  };

  const findRegion = (
    regionsList: Option[],
    eduGroupId: number,
    targetInfo: TargetInfo,
  ): Option[] | null => {
    let searchedRegions: Option[] | null = null;

    const region =
      regionsList.find((region) => region.eduOrgGroupId === eduGroupId) ?? null;

    if (region) {
      searchedRegions = [region];
    }

    if (!searchedRegions) {
      const parentGroup = findParentGroup(
        +eduGroupId,
        targetInfo.eduOrganizationGroups,
      );

      if (parentGroup?.id) {
        searchedRegions = findRegion(regionsList, +parentGroup.id, targetInfo);
      }
    }

    return searchedRegions;
  };

  const findParentGroup = (
    childId: number,
    allGroups: { [p: string]: EduOrganizationGroup },
  ) => {
    const childGroup = allGroups[childId];
    if (!childGroup || !childGroup.partOf?.reference) return;
    const parentId = parseReference(childGroup.partOf.reference)?.id;
    return allGroups[parentId];
  };

  const findRegionsByRequest = async (
    eduGroupId: string,
    regionsList: Option[],
  ) => {
    const maxRecursionDepth = 2;
    let recursiveDepth = 0;
    let searchedIds: string[] = [eduGroupId];

    let result: Option[] = [];

    const findRegionsByRequestStep = async (parentGroupIds: string[]) => {
      const response = await Promise.allSettled(
        parentGroupIds.map((parentGroupId) =>
          eduOrganizationGroupApi.search({
            type: EduOrganizationGroupType.GROUP,
            id: parentGroupId,
          }),
        ),
      );
      const responseGroups = response.reduce((acc, responseItem) => {
        if (responseItem.status === 'fulfilled') {
          return [
            ...acc,
            ...responseItem.value.entry
              .map((entry) => entry.resource)
              .filter((eduOrgGroup) => !!eduOrgGroup.id),
          ];
        } else {
          return acc;
        }
      }, [] as EduOrganizationGroup[]);

      const foundRegions = regionsList.filter((region) =>
        responseGroups.some(
          (respGroup) => +respGroup.id! === region.eduOrgGroupId,
        ),
      );

      return { foundRegions, responseGroups };
    };

    while (!result.length && recursiveDepth < maxRecursionDepth) {
      const { foundRegions, responseGroups } = await findRegionsByRequestStep(
        searchedIds,
      );
      searchedIds = responseGroups
        .map((resGroup) => resGroup.id!)
        .filter((id) => !!id);
      result = foundRegions;
      recursiveDepth++;
    }

    return result;
  };

  const getTypeGChildren = async (eduGroupIds: string[]) => {
    const data = await Promise.allSettled(
      eduGroupIds.map((eduGroupId) =>
        eduOrganizationGroupApi.search({
          type: EduOrganizationGroupType.GROUP,
          id: eduGroupId,
        }),
      ),
    );

    return data.reduce((acc, responseItem) => {
      if (responseItem.status === 'fulfilled') {
        return [
          ...acc,
          ...responseItem.value.entry
            .map((entry) => entry.resource)
            .filter((eduOrgGroup) => !!eduOrgGroup.id),
        ];
      } else {
        return acc;
      }
    }, [] as EduOrganizationGroup[]);
  };

  const checkSelectedGroup = (hashTable: {
    [p: string]: EduOrganizationGroup;
  }) => {
    const removeParent = (
      hashTable: {
        [p: string]: EduOrganizationGroup;
      },
      newItem: EduOrganizationGroup,
    ) => {
      if (newItem.partOf?.reference) {
        const parentId = parseReference(newItem.partOf?.reference)?.id;
        if (parentId) {
          delete hashTable[parentId];
        }
      }
    };

    const removeChildren = (
      hashTable: {
        [p: string]: EduOrganizationGroup;
      },
      newItem: EduOrganizationGroup,
    ) => {
      Object.values(hashTable).forEach((group) => {
        if (group.partOf?.reference) {
          const parentId = parseReference(group.partOf?.reference)?.id;
          if (parentId === newItem.id && group.id) {
            delete hashTable[group.id];
          }
        }
      });
    };

    if (Object.keys(hashTable).length) {
      const newItem = Object.values(hashTable).find(
        (item) => item.id && !selectedGroups[item.id],
      );
      if (newItem) {
        removeParent(hashTable, newItem);
        removeChildren(hashTable, newItem);
      }
    }

    return hashTable;
  };

  return {
    isOOGroupsAdmin,
    disableAdminGroupsFields,
    allowedOrganizationsIdsForAdminGroup,
    allowedOrganizationsForAdminGroup,
    allowedRegionsForAdminGroup,
    allowedOrganizationGroupsForAdminGroup,
    checkSelectedGroup,
    loadingStatusTargetingData,
    isVisibleItem,
  };
};

export { useTargetingForOOGroupAdmin };

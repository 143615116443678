import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';

import Button from '@components/common/Button/Button';
import { ReactComponent as CloseIcon } from '@assets/icons/ico-close.svg';
import StyledRadio from '@components/common/StyledRadio/StyledRadio';
import PersonSelect from '../PersonSelect/PersonSelect';
import { Person } from '@declarations/person';
import { profileRoles } from '@mock-data/profile';
import { ProfileCategory } from '@declarations/enum/profile';
import OrganizationSelect from '@components/common/Select/Organization';
import { Region } from '@declarations/region';
import { Entity, isOfType } from '@declarations/common';
import { EduGroup } from '@declarations/eduGroup';
import EduGroupSelect from '@components/common/Select/EduGroup';
import { StructureRole } from '@declarations/enum/structureRole';
import { structureRoleLocale } from '@locales/structureRole';
import { EntityTypes } from '@mock-data/entities';
import { FormField } from '@components/common/FormField';
import { PupilUtils } from '@utils/pupilUtils';
import { useSelector } from 'react-redux';
import { getUserPupilInfo } from '@redux/user';
import { UserRoleStatus } from '@redux/user/types';
import { ReactComponent as QuestionIcon } from '@assets/icons/ico-question.svg';

export type FormData = Partial<{
  resourceType: string;
  role: string;
  parentRole: string;
  region: Region;
  organizationId: string;
  person: Person;
  eduGroup: Entity<EduGroup>;
}>;
interface ProfileParentAddChildProps {
  handleClose: () => void;
  parentChildPersonIds: string[];
  onSave: (data: FormData) => Promise<void>;
}

const ProfileParentAddChild: FC<ProfileParentAddChildProps> = ({
  handleClose,
  parentChildPersonIds,
  onSave,
}) => {
  const pupilInfo = useSelector(getUserPupilInfo);
  const [parentRoleDisabled, setParentRoleDisabled] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({
    resourceType: EntityTypes.PUPIL_ROLE,
    role: profileRoles[ProfileCategory.STUDENT][0].code,
    parentRole: profileRoles[ProfileCategory.LEGAL_REPRESENTATIVE][0].code,
  });
  const [loading, setLoading] = useState(false);

  // TODO: set correct logic if needed
  const disableSubmit = useMemo(
    () => !formData?.eduGroup || !formData.person || loading,
    [formData, loading],
  );

  const organizationFilters = useMemo(
    () => ({
      regionCode: formData.region && [formData.region.id],
    }),
    [formData.region],
  );

  const eduGroupFilters = useMemo(
    () => ({
      organizationId: formData.organizationId,
    }),
    [formData.organizationId],
  );

  const availableParentTypes = useMemo(() => {
    const age =
      formData.person?.birthDate &&
      dayjs().diff(dayjs(formData.person.birthDate), 'year');
    const result: { code: string; display: string; disabled: boolean }[] = [
      {
        code: StructureRole.PARENT,
        display: structureRoleLocale[StructureRole.PARENT],
        disabled: !formData.person,
      },
      {
        code: StructureRole.GUARDIAN,
        display: structureRoleLocale[StructureRole.GUARDIAN],
        disabled: !age || (!!age && age >= 14),
      },
      {
        code: StructureRole.TRUSTEE,
        display: structureRoleLocale[StructureRole.TRUSTEE],
        disabled: !age || (!!age && age < 14),
      },
    ];
    return result;
  }, [formData.person]);

  const onSubmit = useCallback(async () => {
    if (!disableSubmit) {
      setLoading(true);
      await onSave(formData);
      setLoading(false);
    }
  }, [formData, disableSubmit, onSave]);

  const handleChangeFormData = useCallback(
    (newData: { [key: string]: any }) =>
      setFormData((prevState: any) => ({
        ...prevState,
        ...newData,
      })),
    [],
  );

  useEffect(() => {
    const nextOfKinRoles =
      pupilInfo && formData.person?.pupilProfile
        ? PupilUtils.findAllNextOfKinRolesForPupil(
            pupilInfo.nextOfKinRole,
            formData.person?.pupilProfile,
          )
        : undefined;

    const nextOfKinRole = nextOfKinRoles?.length
      ? PupilUtils.findPriorityNextOfKinRole(nextOfKinRoles)
      : undefined;

    if (!!nextOfKinRole && nextOfKinRole.status !== UserRoleStatus.RETIRED) {
      setParentRoleDisabled(true);
      handleChangeFormData({
        parentRole: nextOfKinRole.role.code,
      });
    } else {
      setParentRoleDisabled(false);
      handleChangeFormData({
        parentRole: profileRoles[ProfileCategory.LEGAL_REPRESENTATIVE][0].code,
      });
    }
  }, [formData.person, handleChangeFormData, pupilInfo]);

  return (
    <Grid container flexDirection="column" spacing={3}>
      <Grid item container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h5">Новая запись</Typography>
        </Grid>
        <Grid item>
          <Button
            sx={{ width: '36px', height: '36px', p: 0, minWidth: 0 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <FormField label="Обучающийся">
          <PersonSelect
            name="person"
            id="person"
            placeholder="выбрать"
            personIds={parentChildPersonIds}
            selected={formData.person}
            onChange={(person) =>
              handleChangeFormData({
                person,
                organization: undefined,
                eduGroup: undefined,
              })
            }
          />
        </FormField>
      </Grid>
      <Grid item>
        <FormField label="Место обучения">
          {profileRoles[ProfileCategory.STUDENT].map((role) => (
            <Grid item key={role.code}>
              <StyledRadio
                name="role"
                value={role.code}
                checked={formData.role === role.code}
                label={role.display}
                onChange={() =>
                  handleChangeFormData({
                    role: role.code,
                  })
                }
              />
            </Grid>
          ))}
        </FormField>
      </Grid>
      <Grid item>
        <FormField label="Образовательная организация">
          <Box display={'flex'}>
            <Box flexGrow={1}>
              <OrganizationSelect
                name="organization"
                id="organization"
                // isDisabled={!formData.region}
                placeholder="выбрать"
                // placeholder={
                //   formData.region
                //     ? 'выбрать'
                //     : 'сначала выберите Населенный пункт'
                // }
                value={formData.organizationId}
                filters={organizationFilters}
                onChange={(organizationId) => {
                  if (isOfType<string>(organizationId, 'toLocaleLowerCase')) {
                    handleChangeFormData({
                      organizationId,
                      eduGroup: undefined,
                    });
                  } else {
                    console.error('Organization cannot be multiple');
                  }
                }}
              />
            </Box>
            <Box>
              <Tooltip title="Свою образовательную организацию вы можете так же найти, указав ИНН">
                <IconButton disableRipple>
                  <QuestionIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </FormField>
      </Grid>
      <Grid item>
        <FormField label="Класс (или другая группировка обучающихся)">
          <EduGroupSelect
            name="eduGroup"
            isDisabled={!formData.organizationId}
            placeholder={
              formData.organizationId
                ? 'выбрать'
                : 'сначала выберите Образовательную организацию'
            }
            selected={formData.eduGroup}
            filters={eduGroupFilters}
            onChange={(eduGroup: Entity<EduGroup>) =>
              handleChangeFormData({
                eduGroup,
              })
            }
          />
        </FormField>
      </Grid>
      <Grid item>
        <FormField label="Кем приходитесь">
          {availableParentTypes.map((parentRole) => (
            <Grid item key={parentRole.code}>
              <StyledRadio
                value={parentRole.code}
                name="parentRole"
                checked={formData.parentRole === parentRole.code}
                onChange={() =>
                  handleChangeFormData({
                    parentRole: parentRole.code,
                  })
                }
                label={parentRole.display}
                disabled={parentRoleDisabled || parentRole.disabled}
              />
            </Grid>
          ))}
        </FormField>
      </Grid>
      <Grid item>
        <FormField label="">
          <Grid container columnSpacing={1}>
            <Grid item xs={5}>
              <Button
                fullWidth
                variant="first"
                disabled={disableSubmit}
                onClick={onSubmit}
              >
                Сохранить
              </Button>
            </Grid>
            <Grid item>
              <Button variant="fourth" onClick={handleClose}>
                Отмена
              </Button>
            </Grid>
          </Grid>
        </FormField>
      </Grid>
    </Grid>
  );
};

export default ProfileParentAddChild;

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button as MuiButton, MenuList } from '@mui/material';
import {
  ClickAwayListener,
  MenuItem,
  Popper,
  styled,
  Typography,
} from '@mui/material';
import './HeaderNotificationWidget.scss';
import { ReactComponent as NotificationIcon } from '@assets/icons/notification.svg';
import Button from '@components/common/Button/Button';
import {
  fetchUnreadNotifications,
  getTotalUnreadNotifications,
  getUnreadNotifications,
  setReadNotificationsByIds,
} from '@redux/notifications';
import CircleCounter from '@components/Header/HeaderNotificationWidget/CircleCounter';
import { ROUTES } from '@constants/routes';
import { generateLink } from '@lib/common';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';

const IcoButton = styled(MuiButton)({
  fontFamily: 'inherit',
  color: '#151b24',
  height: '44px',
  width: '44px',
  minWidth: 'auto',
  padding: '8px',
  '&:hover, &.active': {
    backgroundColor: '#F2F4F7',
  },
});

export const HeaderNotificationWidget = () => {
  const iconRef = useRef(null);
  const dispatch = useDispatch();

  const [isOpened, setIsOpened] = useState(false);

  const notifications = useSelector(getUnreadNotifications);
  const totalNotifications = useSelector(getTotalUnreadNotifications) || 0;

  const data = useMemo(() => {
    const items = cloneDeep(notifications);
    items?.sort((a, b) => b.date - a.date);
    return items;
  }, [notifications]);

  const isNotEmpty = totalNotifications > 0;

  const handleAwayClick = () => {
    setIsOpened(false);
  };

  const clear = useCallback(async () => {
    await dispatch(
      setReadNotificationsByIds(notifications?.map((item) => item.id) || []),
    );
    dispatch(fetchUnreadNotifications());
  }, [dispatch, notifications]);

  useEffect(() => {
    dispatch(fetchUnreadNotifications());
  }, [dispatch]);

  return (
    <ClickAwayListener onClickAway={handleAwayClick}>
      <IcoButton
        className={classNames({
          active: open,
        })}
        sx={{ display: { xs: 'none', md: 'block' } }}
        onClick={() => !isOpened && setIsOpened(true)}
        ref={iconRef}
      >
        <NotificationIcon />
        {isNotEmpty && <CircleCounter count={totalNotifications} />}
        <Popper
          id={isOpened ? 'notification-popper' : undefined}
          open={isOpened}
          anchorEl={iconRef.current}
          placement="bottom-end"
          className="notification-menu__popper"
        >
          <div className="notification-menu">
            <Box
              padding="20px 16px 12px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography fontSize="14px" lineHeight="20px" color="#818999">
                Уведомления
              </Typography>
              {isNotEmpty && (
                <Box
                  color="#007AFF"
                  sx={{
                    cursor: 'pointer',
                    fontSize: '14px',
                    lineHeight: '20px',
                  }}
                  onClick={clear}
                >
                  очистить
                </Box>
              )}
            </Box>
            <MenuList sx={{ py: 0, maxHeight: '600px', overflow: 'auto' }}>
              <div className="notification-menu__items-wrapper">
                {isNotEmpty ? (
                  data?.map((item) => (
                    <MenuItem
                      key={item.id}
                      className="notification-menu__item"
                      sx={{
                        flexDirection: 'column',
                        position: 'relative',
                        padding: '16px 48px 16px 24px',
                        boxShadow: '0px 1px 0px #DCE2EB',
                        mb: '1px',
                        ':last-child': {
                          boxShadow: 'none',
                          mb: '0',
                        },
                        ':hover': {
                          backgroundColor: '#F2F4F7',
                        },
                      }}
                    >
                      <Box width="100%">
                        <Box display="flex" alignItems="flex-start">
                          <Box
                            width="8px"
                            height="8px"
                            color="#FA5C41"
                            borderRadius="50%"
                            bgcolor="#FA5C41"
                            visibility={item.isRead ? 'hidden' : 'visible'}
                            mt="6px"
                            mr={1}
                          />
                          <div className="notification-menu__notification">
                            <Typography sx={{ fontSize: 14 }}>
                              {item.title}
                            </Typography>
                            {item.system && (
                              <Box
                                mt={1}
                                bgcolor="#F2F4F7"
                                borderRadius="4px"
                                fontSize="12px"
                                lineHeight="20px"
                                color="#818999"
                                width="fit-content"
                                padding="2px 8px"
                              >
                                {item.system}
                              </Box>
                            )}
                          </div>
                        </Box>
                      </Box>
                      <Link
                        className="notification-menu__link"
                        to={generateLink(ROUTES.notificationItem, {
                          id: item.id,
                        })}
                      />
                    </MenuItem>
                  ))
                ) : (
                  <Box
                    width="100%"
                    height="144px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    color="#818999"
                  >
                    Новых уведомлений нет
                  </Box>
                )}
              </div>
            </MenuList>
            {isNotEmpty && (
              <Box sx={{ p: '16px 24px' }}>
                <Button
                  to={ROUTES.notificationList}
                  variant="first"
                  sx={{
                    backgroundColor: '#007AFF',
                    width: '100%',
                  }}
                  onClick={handleAwayClick}
                >
                  Все уведомления
                </Button>
              </Box>
            )}
          </div>
        </Popper>
      </IcoButton>
    </ClickAwayListener>
  );
};

import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Grid, Menu, MenuItem, Typography } from '@mui/material';

import Button from '@components/common/Button/Button';
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';
import {
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
} from '@mui/x-data-grid';
import { RootState } from '@redux/store';
import { getActiveFilters } from '@redux/filters';
import { getFaqs } from '@redux/faq';
import { getFetchStatus } from '@redux/ui';
import { connect } from 'react-redux';
import CommonFilter from '@pages/Manager/CommonFilter/CommonFilter';
import { ToggleGroup } from '@components/common/StyledToggleButtonGroup/StyledToggleButtonGroup';
import { faqApi } from '@api/faq';
import { setPublishDate } from '@lib/date/date';
import { FaqStatus, Faq } from '@redux/faq/types';
import { useSnackbar } from '@hooks/useSnackbar';
import { Filter } from '@declarations/common';
import Table from '@components/common/Table/Table';
import { generateLink, getNewItemName } from '@lib/common';
import { ROUTES } from '@constants/routes';

const mapStateToProps = (state: RootState) => ({
  activeFilterQuery: getActiveFilters(state),
  faqs: getFaqs(state),
  faqFetchingStatus: getFetchStatus(state, 'fetchFaqs'),
});

const columns: GridColDef[] = [
  { field: 'title', headerName: 'Заголовок', flex: 2, minWidth: 300 },
  { field: 'target', flex: 1, headerName: 'Аудитория', minWidth: 200 },
  {
    field: 'date',
    headerName: 'Дата публикации',
    flex: 1,
    renderCell: (params: GridRenderCellParams<Faq>) => {
      const status = params.row.status;
      const date = params.row.date && setPublishDate(params.row.date);
      return status === FaqStatus.PUBLISHED ? (
        date
      ) : (
        <div className="news-list__status-label">
          {status === FaqStatus.DRAFT
            ? 'черновик'
            : `архив${date ? `, ${date}` : ''}`}
        </div>
      );
    },
  },
  {
    field: 'region',
    flex: 1,
    headerName: 'Субъект РФ',
  },
  {
    field: 'eduLevels',
    headerName: 'Уровень образования',
  },
  {
    field: 'eduOrganizations',
    headerName: 'ОО',
  },
  {
    field: 'eduGroupOrganizations',
    headerName: 'Группы ОО',
  },
];

const PER_PAGE_OPTIONS = [20, 40];

const FaqList: React.FC = () => {
  const [faqList, setFaqList] = useState<{ total: number; data: Faq[] }>({
    total: 0,
    data: [],
  });
  const [pagination, setPagination] = useState({
    pageSize: PER_PAGE_OPTIONS[0],
    currentPage: 1,
  });
  const [filters, setFilters] = useState<Partial<Filter>>({
    period: [undefined, undefined],
  });
  const [loading, setLoading] = useState(false);
  const [selectedFaqItem, setSelectedFaqItem] = useState<Faq[]>([]);

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const history = useHistory();
  const { addSnack } = useSnackbar();

  const rows = useMemo(
    () =>
      faqList.data.map((item) => {
        return {
          ...item,
          date: item.startDate,
          region: item.regions?.map((region) => region.name).join(', '),
          target: item.targetAudience?.map((target) => target.name).join(','),
          eduLevels: item.eduLevels?.map((level) => level.name).join(', '),
          // TODO-achernyavets, поправить когда с бэка будут приходить данные
          eduOrganizations: '',
          // TODO-achernyavets, поправить когда с бэка будут приходить данные
          eduGroupOrganizations: '',
        };
      }),
    [faqList.data],
  );

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const faqs = await faqApi.searchFaqList(pagination, filters);
      setFaqList({
        total: faqs?.totalElements || 0,
        data: faqs?.content || [],
      });
    } catch (err) {
      console.error(err);
      addSnack('Ошибка загрузки данных', 'error');
    } finally {
      setLoading(false);
    }
  }, [addSnack, filters, pagination]);

  const handlePageChange = useCallback((page: number) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: page + 1,
    }));
  }, []);

  const handlePageSizeChange = useCallback((pageSize: number) => {
    setPagination((prev) => ({
      ...prev,
      pageSize,
    }));
  }, []);

  const handleContextMenu = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX - 2,
              mouseY: event.clientY - 4,
            }
          : null,
      );
    },
    [contextMenu],
  );

  const onContextMenu = useCallback(
    (e: any): void => {
      if (e.type === 'contextmenu') {
        e.preventDefault();
        const closestParent = e?.target?.closest('div[data-id]');
        if (closestParent && selectedFaqItem.length === 0) {
          const id = closestParent.dataset?.id;
          if (id) {
            const faqItem = faqList.data.find((item) => item.id == id);
            if (faqItem) {
              setSelectedFaqItem([faqItem]);
            }
          }
        }
        handleContextMenu(e);
      }
    },
    [faqList.data, handleContextMenu, selectedFaqItem.length],
  );

  const handleClose = useCallback(() => {
    setSelectedFaqItem([]);
    setContextMenu(null);
  }, []);

  const handleUnpublishedFaq = useCallback(async () => {
    if (selectedFaqItem.length) {
      try {
        const response = await Promise.allSettled(
          selectedFaqItem.map((faqItem) =>
            faqApi.changeFaqStatus(faqItem.id || 0, FaqStatus.DRAFT),
          ),
        );
        if (response.some(({ status }) => status === 'rejected')) {
          if (response.every(({ status }) => status === 'rejected')) {
            throw new Error('Some faq not updated');
          }
          addSnack('Выбранные вопросы сняты с публикации частично', 'error');
        } else {
          const message =
            selectedFaqItem.length > 1
              ? 'Вопросы сняты с публикации'
              : 'Вопрос снят с публикации';
          addSnack(message, 'success');
        }
        await loadData();
      } catch (err) {
        console.error(err);
        const message =
          selectedFaqItem.length > 1
            ? 'Не удалось снять вопросы с публикации'
            : 'Не удалось снять вопрос с публикации';
        addSnack(message, 'error');
      }
      handleClose();
    }
  }, [addSnack, handleClose, loadData, selectedFaqItem]);

  const handleRemoveFaq = useCallback(async () => {
    if (selectedFaqItem.length) {
      try {
        await faqApi.removeFaq(
          selectedFaqItem.map((faqItem) => faqItem.id || 0),
        );

        const message =
          selectedFaqItem.length > 1 ? 'Вопросы удалены' : 'Вопрос удален';
        addSnack(message, 'success');

        await loadData();
      } catch (err) {
        console.error(err);
        const message =
          selectedFaqItem.length > 1
            ? 'Ошибка удаления вопросов'
            : 'Ошибка удаления вопроса';
        addSnack(message, 'error');
      }
    }
    handleClose();
  }, [addSnack, handleClose, loadData, selectedFaqItem]);

  const handleEditFaq = useCallback(() => {
    if (selectedFaqItem.length) {
      if (selectedFaqItem.length > 1) {
        return addSnack('Массовое редактирование не поддерживается', 'error');
      }
      const selectedFaqItemElement = selectedFaqItem[0];
      if (selectedFaqItemElement.id) {
        const link = generateLink(ROUTES.managerFaqItem, {
          id: selectedFaqItemElement.id,
        });
        history.push(link);
      }
    } else {
      addSnack('Необходимо выбрать вопрос для редактирования', 'error');
    }
  }, [addSnack, history, selectedFaqItem]);

  const faqNameList = useMemo(
    () => faqList.data.map((faq) => faq.title),
    [faqList.data],
  );

  const handleView = useCallback(() => {
    if (selectedFaqItem.length) {
      selectedFaqItem.forEach((item) => {
        if (item.id) {
          const link = generateLink(ROUTES.faqItem, { id: item.id });
          window.open(link);
        }
      });
    }
    handleClose();
  }, [handleClose, selectedFaqItem]);

  const handleCopyFaq = useCallback(async () => {
    if (selectedFaqItem.length) {
      try {
        const response = await Promise.allSettled(
          selectedFaqItem.map((faqItem) => {
            faqItem.title = getNewItemName(faqNameList, faqItem.title);
            faqItem.status = FaqStatus.DRAFT;
            return faqApi.createFaq(faqItem);
          }),
        );
        if (response.some(({ status }) => status === 'rejected')) {
          if (response.every(({ status }) => status === 'rejected')) {
            throw new Error('Some faq not copied');
          }
          addSnack('Выбранные вопросы скопированы частично', 'error');
        } else {
          const message =
            selectedFaqItem.length > 1
              ? 'Вопросы скопированы'
              : 'Вопрос скопирован';
          addSnack(message, 'success');
        }
        await loadData();
      } catch (err) {
        console.error(err);
        const message =
          selectedFaqItem.length > 1
            ? 'Ошибка при копировании вопросов'
            : 'Ошибка при копировании вопроса';
        addSnack(message, 'error');
      }
      await loadData();
    }
    handleClose();
  }, [addSnack, faqNameList, handleClose, loadData, selectedFaqItem]);

  const handleRowSelect = useCallback(
    (selectionModel: GridSelectionModel) => {
      setSelectedFaqItem(
        faqList.data?.filter((faqItem) =>
          selectionModel.includes(faqItem.id || 0),
        ),
      );
    },
    [faqList.data],
  );

  const contextMenuItems = useMemo(() => {
    const groups = selectedFaqItem?.reduce(
      (acc, faqItem) => {
        switch (faqItem.status) {
          case FaqStatus.DRAFT:
            acc.draft++;
            break;
          case FaqStatus.PUBLISHED:
            acc.published++;
            break;
          case FaqStatus.ARCHIVED:
            acc.archived++;
            break;
        }
        if (faqItem.hasPrivs) {
          acc.hasCredentials++;
        }
        return acc;
      },
      {
        draft: 0,
        published: 0,
        archived: 0,
        hasCredentials: 0,
      },
    );

    const hasCredentials = selectedFaqItem.length === groups.hasCredentials;

    if (!groups.archived && !groups.draft && !groups.published) {
      return [];
    }

    const menuItems: JSX.Element[] = [];
    const isPublished = groups.archived === 0 && groups.draft === 0;
    if (isPublished) {
      menuItems.push(
        <MenuItem key="view" onClick={handleView}>
          Просмотр
        </MenuItem>,
      );
    }
    menuItems.push(
      <MenuItem key="copy" onClick={handleCopyFaq}>
        Скопировать
      </MenuItem>,
    );

    if (groups.archived === 0 && groups.published === 0 && hasCredentials) {
      menuItems.push(
        ...[
          <MenuItem key="edit" onClick={handleEditFaq}>
            Изменить
          </MenuItem>,
          <MenuItem key="delete" onClick={handleRemoveFaq}>
            Удалить
          </MenuItem>,
        ],
      );
    }

    if (isPublished && hasCredentials) {
      menuItems.push(
        <MenuItem key="unpublish" onClick={handleUnpublishedFaq}>
          Снять с публикации
        </MenuItem>,
      );
    }

    return menuItems;
  }, [
    selectedFaqItem,
    handleCopyFaq,
    handleEditFaq,
    handleRemoveFaq,
    handleUnpublishedFaq,
    handleView,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const items = [
    { value: '', label: 'Все' },
    { value: FaqStatus.PUBLISHED, label: 'Опубликованные' },
    { value: FaqStatus.DRAFT, label: 'Черновики' },
  ];

  return (
    <div>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {contextMenuItems}
      </Menu>
      <Grid container>
        <Grid item xs alignItems="center">
          <Typography variant="h2" sx={{ fontWeight: '500', fontSize: '18px' }}>
            Вопросы и ответы
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="first"
            to={ROUTES.managerFaqAdd}
            startIcon={<PlusIcon />}
          >
            Добавить
          </Button>
        </Grid>
      </Grid>
      <div style={{ margin: '20px 0' }}>
        <Grid container columnSpacing={2}>
          <Grid item xs="auto">
            <ToggleGroup
              items={items}
              value={filters.status || ''}
              handleChange={(e: any, value: string) => {
                setFilters((prevState) => ({
                  ...prevState,
                  status: value || undefined,
                }));
              }}
            />
          </Grid>
          <Grid item xs flexGrow={1}>
            <CommonFilter
              onChange={setFilters}
              showActivityPeriodSelector
              showActivityPeriod
            />
          </Grid>
        </Grid>
      </div>
      <div
        style={{ height: 'auto', width: '100%' }}
        onContextMenu={onContextMenu}
      >
        <Table
          rows={rows}
          columns={columns}
          handleRowSelect={handleRowSelect}
          rowCount={faqList.total}
          page={pagination.currentPage - 1}
          pageSize={pagination.pageSize}
          loading={loading}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(FaqList);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationList, NotificationState } from './types';

const initialState: NotificationState = {
  notifications: {
    data: [],
    total: 0,
    page: 0,
    size: 10,
  },
  unreadNotifications: {
    data: [],
    total: 0,
  },
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotificationList: (
      state: NotificationState,
      action: PayloadAction<NotificationList>,
    ) => ({
      ...state,
      notifications: {
        ...state.notifications,
        data: action.payload.data ? [...action.payload.data] : [],
        total: action.payload.total,
      },
    }),
    setPage: (
      state: NotificationState,
      action: PayloadAction<{ page: number }>,
    ) => ({
      ...state,
      notifications: {
        ...state.notifications,
        page: action.payload.page,
      },
    }),
    setSize: (
      state: NotificationState,
      action: PayloadAction<{ size: number }>,
    ) => ({
      ...state,
      notifications: {
        ...state.notifications,
        size: action.payload.size,
      },
    }),
    setUnreadNotifications: (
      state: NotificationState,
      action: PayloadAction<Partial<NotificationList>>,
    ) => ({
      ...state,
      unreadNotifications: {
        data: action.payload.data,
        total: action.payload.total,
      },
    }),
  },
});

export const { setNotificationList, setPage, setSize, setUnreadNotifications } =
  slice.actions;

export * from './thunks';
export * from './selectors';

export default slice.reducer;

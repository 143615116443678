import dayjs from 'dayjs';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import { Notification } from '@redux/notifications/types';
import { BaseSystems } from '@components/../pages/NewsListPage/enum';
import { NotificationDTO, PagedData } from './types/notify';
import { notifyAxios } from './utils/axios';

const SERVICE_NAME = 'api-ntfc-pull/api/v1';

export type GetNotificationOptions = Partial<{
  search: string;
  date: Date;
  page: number;
  size: number;
  isRead: boolean;
}>;

export const notifyApi = {
  getAllUserNotifications: (
    userId: number,
    subType: number,
    options: GetNotificationOptions = {},
  ) =>
    notifyAxios
      .get<PagedData<NotificationDTO>, any>(
        `${SERVICE_NAME}/search/${userId}/${subType}`,
        {
          params: {
            ...omitBy(
              {
                page: options.page,
                size: options.size,
                'message.title.contains': options.search,
                'sentDate.greaterThan':
                  options.date &&
                  dayjs(options.date)
                    .startOf('day')
                    .format('YYYY-MM-DDTHH:mm:ss'),
                'sentDate.lessThan':
                  options.date &&
                  dayjs(options.date)
                    .endOf('day')
                    .format('YYYY-MM-DDTHH:mm:ss'),
                'readDate.specified': options.isRead,
              },
              isUndefined,
            ),
            isProtected: true,
          },
        },
      )
      .then((data) => ({
        data: data.content.map(mapFromServer),
        total: data.totalElements,
        page: data.pageable.pageNumber,
        size: data.pageable.pageSize,
      })),
  removeById: (id: number) =>
    notifyAxios.delete<unknown, unknown>(`${SERVICE_NAME}/del/${id}`, {
      params: { isProtected: true },
    }),
  readById: (id: number) =>
    notifyAxios.patch<unknown, unknown>(`${SERVICE_NAME}/read/${id}`, null, {
      params: { isProtected: true },
    }),
};

const mapFromServer = (item: NotificationDTO): Notification => ({
  id: item.notificationId,
  value: item.content,
  system: BaseSystems[item.eventSource] || BaseSystems[1],
  date: new Date(item.sentDate).getTime(),
  isRead: !!item.readDate,
  title: item.title,
});

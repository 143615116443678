import { DOCUMENT_MIME_TYPE, FILE_TYPE } from '@declarations/enum/documents';
import React, { useCallback } from 'react';
import { MY_FILES_LINK } from '@constants/config';
import { documentsAPI } from '@api/documents';
import { AxiosError } from 'axios';
import { useSnackbar } from '@hooks/useSnackbar';
import {
  Card,
  CardActions,
  CardContent,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import Button from '@components/common/Button/Button';
import styles from './CreateNewDocumentModal.module.scss';

interface ThisProps {
  documentTypeForNewDocument: FILE_TYPE | 'directory';
  handleClose: () => void;
  mainFolderId: number | null;
  successCallback: () => void;
}

const DEFAULT_HEADER_NAME = 'Создать документ';
const DEFAULT_PLACEHOLDER_NAME = 'Новый документ';

const CreateNewDocumentModal: React.FC<ThisProps> = (props) => {
  const { addSnack } = useSnackbar();
  const [newDocumentName, setNewDocumentName] = React.useState<string>('');

  React.useEffect(() => {
    if (!props.mainFolderId && MY_FILES_LINK) {
      props.handleClose();
      window.location.href = MY_FILES_LINK;
    }
  }, [props]);

  const createDocument = () => {
    if (props.documentTypeForNewDocument === 'directory') {
      onCreateFolder().then(() => {
        props.successCallback();
        props.handleClose();
      });
    } else {
      const mimeType = getDocumentMimeType(
        props.documentTypeForNewDocument as FILE_TYPE,
      );
      if (!mimeType) return;

      onCreateFile(
        props.documentTypeForNewDocument as FILE_TYPE,
        mimeType,
      ).then(() => {
        props.successCallback();
        props.handleClose();
      });
    }
  };

  const onCreateFile = useCallback(
    async (fileType: FILE_TYPE, documentMimeType: DOCUMENT_MIME_TYPE) => {
      const fileName = `${newDocumentName}.${fileType}`;
      try {
        if (!props.mainFolderId) {
          if (MY_FILES_LINK) {
            window.location.href = MY_FILES_LINK;
          }
          return;
        }
        const newFileData = await documentsAPI
          .createDocument({
            folderId: props.mainFolderId,
            documentMimeType,
            fileName,
          })
          .then((arr) => arr[0]);
        if (!newFileData) return;
        const newFileLinkData = await documentsAPI.generateLinkToFile(
          newFileData.id,
        );
        if (newFileLinkData) {
          window.open(`${MY_FILES_LINK}link/${newFileLinkData.uid}`, '_blank');
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response?.status === 409) {
            addSnack(`Файл ${fileName} уже существует!`, 'error');
            throw error;
          }
        }
        addSnack('Произошла ошибка при создании документа', 'error');
      }
    },
    [addSnack, newDocumentName, props.mainFolderId],
  );

  const onCreateFolder = useCallback(async () => {
    try {
      if (!props.mainFolderId) {
        if (MY_FILES_LINK) {
          window.location.href = MY_FILES_LINK;
        }
        return;
      }
      const newFolderData = await documentsAPI.createDirectory({
        parentId: props.mainFolderId,
        directoryName: newDocumentName,
      });
      window.open(getFolderLink(newFolderData.id), '_blank');
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 409) {
          addSnack(`Папка ${newDocumentName} уже существует!`, 'error');
          throw error;
        }
      }
      addSnack('Произошла ошибка при создании папки', 'error');
    }
  }, [addSnack, newDocumentName, props.mainFolderId]);

  const getFolderLink = (id: number) => `${MY_FILES_LINK}docs/${id}`;

  const getDocumentMimeType = (fileType: FILE_TYPE) => {
    switch (fileType) {
      case FILE_TYPE.DOCX:
        return DOCUMENT_MIME_TYPE.DOCX;
      case FILE_TYPE.XLSX:
        return DOCUMENT_MIME_TYPE.XLSX;
      case FILE_TYPE.PPTX:
        return DOCUMENT_MIME_TYPE.PPTX;
      default:
        return null;
    }
  };

  const modalText = {
    [FILE_TYPE.DOCX]: {
      header: 'Создать документ',
      placeholder: 'Новый документ',
    },
    [FILE_TYPE.XLSX]: {
      header: 'Создать таблицу',
      placeholder: 'Новая таблица',
    },
    [FILE_TYPE.PPTX]: {
      header: 'Создать презентацию',
      placeholder: 'Новая презентация',
    },
    ['directory']: {
      header: 'Создать папку',
      placeholder: 'Новая папка',
    },
  };

  return (
    <Modal
      open={true}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card className={styles.content_wrapper}>
        <CardContent className={styles.content}>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            style={{ fontWeight: '500' }}
          >
            {modalText[props.documentTypeForNewDocument]?.header ??
              DEFAULT_HEADER_NAME}
          </Typography>
          <TextField
            style={{ width: '80%' }}
            label={
              modalText[props.documentTypeForNewDocument]?.placeholder ??
              DEFAULT_PLACEHOLDER_NAME
            }
            value={newDocumentName}
            onChange={(e) => setNewDocumentName(e.target.value)}
          />
        </CardContent>
        <CardActions>
          <Button
            className={styles.content__button}
            onClick={props.handleClose}
          >
            Отмена
          </Button>
          <Button
            className={styles.content__button}
            disabled={!newDocumentName.length}
            onClick={createDocument}
          >
            Создать
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export { CreateNewDocumentModal };

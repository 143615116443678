import * as React from 'react';
import { ReactComponent as IconFolder } from '@assets/icons/file-types/folder.svg';
import { ReactComponent as IconDown } from '@assets/icons/arrow-down.svg';
import { MY_FILES_LINK } from '@constants/config';
import { DirectoryEntityResponse } from '@api/types/documents';

type Props = {
  folder: DirectoryEntityResponse;
};

export const Folder = ({ folder }: Props) => {
  return (
    <div className="file">
      <div className="file__header" />
      <div className="file__body">
        <IconFolder />
      </div>
      <div className="file__footer">
        <div className="file__footer-file-icon">
          <IconFolder />
        </div>
        <div className="file__footer-label">{folder.name}</div>
        <div className="file__footer-down-icon">
          <IconDown />
        </div>
        <a
          href={getFolderLink(folder.id)}
          className="file__footer-btn"
          target={'_blank'}
          rel="noreferrer"
        />
      </div>
    </div>
  );
};

const getFolderLink = (id: number) => `${MY_FILES_LINK}docs/${id}`;

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';

import Button from '@components/common/Button/Button';
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';
import { UserRoleStatus } from '@redux/user/types';
import { NextOfKinRole, PupilRole } from '@declarations/role';
import { Organization } from '@declarations/organization';
import { EduGroup } from '@declarations/eduGroup';
import { Person } from '@declarations/person';
import { PupilInfo } from '@declarations/pupil';
import { userApi } from '@api/user';
import { createReference, parseReference } from '@lib/common';

import ProfileParentAddChild, {
  FormData,
} from '../ProfileParentAddChild/ProfileParentAddChild';
import { PupilCard } from './PupilCard';
import getWhoIsAuth from '@lib/userInfo/getWhoIsAuth';
import { Definitions, Entity } from '@declarations/common';
import {
  AuthAccount,
  NextOfKinProfile,
  PupilProfile,
} from '@declarations/profile';
import { EntityTypes } from '@mock-data/entities';
import {
  AuthAccountStatus,
  ConsentStatus,
  JuvenileStatus,
} from '@declarations/enum/account';
import { UserProfileStatus } from '@declarations/enum/profile';
import { useSnackbar } from '@hooks/useSnackbar';
import { useSelector } from 'react-redux';
import { getUserRoleList } from '@redux/user';
import { PupilUtils } from '@utils/pupilUtils';
import { Loader } from '@components/common/Loader/Loader';

export type PupilEntity = {
  pupilRole: PupilRole;
  pupilAuthAccount?: AuthAccount;
  organization?: Organization;
  pupilPerson?: Person;
  nextOfKinRole?: NextOfKinRole;
  eduGroup?: EduGroup;
  journalData?: {
    id?: string;
    code?: string;
  };
};

type Props = {
  pupilInfo: PupilInfo | null;
  onReloadRoleList: () => void;
  onChangeIndicatorStatusAboutUnconfirmedRole: (status: boolean) => void;
};

const ProfileParentRoleList = ({
  pupilInfo,
  onReloadRoleList,
  onChangeIndicatorStatusAboutUnconfirmedRole,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const roleListAll = useSelector(getUserRoleList)?.profileRoles?.roleListAll;
  const [openAddChild, setOpenAddChild] = useState<boolean>(false);
  const { addSnack } = useSnackbar();
  const pupilList = useMemo(() => {
    const result: {
      active: PupilEntity[];
      archive: PupilEntity[];
    } = {
      active: [],
      archive: [],
    };
    mapPupilData(pupilInfo).forEach((pupil) => {
      // if (pupil.nextOfKinRole) {
      // pupil.pupilAuthAccount?.status === AuthAccountStatus.BLOCKED
      pupil.pupilRole.status === UserRoleStatus.RETIRED ||
      pupil.nextOfKinRole?.status === UserRoleStatus.RETIRED ||
      !pupil.pupilAuthAccount
        ? result.archive.push(pupil)
        : result.active.push(pupil);
      // }
    });

    return result;
  }, [pupilInfo]);

  useEffect(() => {
    onChangeIndicatorStatusAboutUnconfirmedRole(
      pupilList.active.some(
        (pupil) =>
          pupil.nextOfKinRole?.status === UserRoleStatus.CREATED_BY_ADMIN ||
          pupil.nextOfKinRole?.status === UserRoleStatus.CREATED_BY_SELF ||
          pupil.pupilRole?.status === UserRoleStatus.CREATED_BY_ADMIN ||
          pupil.pupilRole?.status === UserRoleStatus.CREATED_BY_SELF ||
          !pupil.nextOfKinRole,
      ),
    );
  }, [onChangeIndicatorStatusAboutUnconfirmedRole, pupilList]);

  const parentChildPersonIds = useMemo(() => {
    if (pupilInfo?.parentPerson) {
      const existsPupil = Object.values(pupilInfo.person)
        .filter((person) => {
          // проверка на то, что хотя бы одна роль отличная от RETIRED
          return (
            person.pupilProfile?.reference &&
            Object.values(pupilInfo.pupilRole).some(
              (pupilRole) =>
                pupilRole.pupilProfile?.reference ===
                  person.pupilProfile?.reference &&
                pupilRole.status !== UserRoleStatus.RETIRED,
            )
          );
        })
        .map((person) => person.id || '0');

      const blockedChildrenForAdding = Object.values(pupilInfo.person)
        .filter((person) => {
          const nextOfKinRoles =
            pupilInfo && person.pupilProfile
              ? PupilUtils.findAllNextOfKinRolesForPupil(
                  pupilInfo.nextOfKinRole,
                  person.pupilProfile,
                )
              : undefined;

          const nextOfKinRole = nextOfKinRoles?.length
            ? PupilUtils.findPriorityNextOfKinRole(nextOfKinRoles)
            : undefined;

          const personAuthAccount = pupilInfo.pupilAuthAccounts.find(
            (acc) =>
              acc.pupilProfile?.reference === person.pupilProfile?.reference,
          );

          return (
            (nextOfKinRole &&
              nextOfKinRole.status === UserRoleStatus.RETIRED) ||
            (personAuthAccount &&
              personAuthAccount.status === AuthAccountStatus.BLOCKED)
          );
        })
        .map((person) => person.id || '0');

      const result = pupilInfo.parentPerson.related
        .filter((related) => {
          const { id } = parseReference(related.related.reference);
          return (
            !existsPupil.includes(id) && !blockedChildrenForAdding.includes(id)
          );
        })
        .map((related) => {
          const { id } = parseReference(related.related.reference);
          return id;
        });

      const unrelatedChildrenPersons = Object.values(pupilInfo.person).filter(
        (person) =>
          !pupilInfo.parentPerson?.related.find(
            (relatedPerson) =>
              parseReference(relatedPerson.related.reference)?.id === person.id,
          ),
      );

      if (unrelatedChildrenPersons.length) {
        unrelatedChildrenPersons.forEach((person) => {
          const nextOfKinRoles =
            pupilInfo && person.pupilProfile
              ? PupilUtils.findAllNextOfKinRolesForPupil(
                  pupilInfo.nextOfKinRole,
                  person.pupilProfile,
                )
              : undefined;

          const nextOfKinRole = nextOfKinRoles?.length
            ? PupilUtils.findPriorityNextOfKinRole(nextOfKinRoles)
            : undefined;

          if (
            nextOfKinRole?.status === UserRoleStatus.CONFIRMED &&
            person.id &&
            !existsPupil.includes(person.id) &&
            !blockedChildrenForAdding.includes(person.id)
          ) {
            result.push(person.id);
          }
        });
      }

      return result;
    }
    return [];
  }, [pupilInfo]);

  const addChild = useCallback(() => {
    if (!pupilInfo?.parentPerson?.related?.length) {
      addSnack(
        'Невозможно добавить роль, т.к. не добавлены дети в ЕСИА',
        'error',
        undefined,
        true,
      );
    } else if (!parentChildPersonIds.length) {
      addSnack(
        'Невозможно добавить роль, т.к. для всех детей уже созданы роли',
        'error',
        undefined,
        true,
      );
    } else {
      setOpenAddChild(true);
    }
  }, [
    addSnack,
    parentChildPersonIds.length,
    pupilInfo?.parentPerson?.related?.length,
  ]);

  const handleClose = useCallback(() => {
    setOpenAddChild(false);
  }, []);

  const handleAddChild = useCallback(
    async (formData: FormData) => {
      setIsLoading(true);
      try {
        if (pupilInfo?.nextOfKinProfile) {
          await createPupilRole(
            formData,
            pupilInfo.nextOfKinProfile,
            roleListAll,
          );
          addSnack('Ребенок добавлен', 'success');
          // TODO: find another way for reload data, maybe set data to redux store
          setTimeout(() => onReloadRoleList(), 3000);
          handleClose();
        } else {
          throw new Error('Отсутствует профиль законного представителя');
        }
      } catch (err) {
        console.error(err);
        addSnack('Произошла ошибка при добавлении ребенка', 'error');
      } finally {
        setIsLoading(false);
      }
    },
    [
      addSnack,
      pupilInfo?.nextOfKinProfile,
      onReloadRoleList,
      handleClose,
      roleListAll,
    ],
  );

  return (
    <>
      <Typography variant="h4" sx={{ fontWeight: 500 }}>
        Дети
      </Typography>
      <ul className="profile__list">
        {pupilList.active.length === 0 && pupilList.archive.length === 0 ? (
          <li className="profile__list-item">
            <Typography variant="h5">Пока нет добавленных ролей</Typography>
          </li>
        ) : (
          pupilList.active.map(
            ({
              pupilRole,
              eduGroup,
              nextOfKinRole,
              organization,
              pupilPerson,
            }) => {
              return (
                <li className="profile__list-item" key={pupilRole.id}>
                  <PupilCard
                    pupilRole={pupilRole}
                    eduGroup={eduGroup}
                    nextOfKinRole={nextOfKinRole}
                    organization={organization}
                    person={pupilPerson}
                  />
                </li>
              );
            },
          )
        )}
      </ul>
      {openAddChild && (
        <Box
          sx={{
            mt: 2,
            border: '1px solid #CAD3E0',
            borderRadius: '8px',
            p: '24px 24px 24px 40px',
          }}
          style={{ position: 'relative' }}
        >
          <ProfileParentAddChild
            handleClose={handleClose}
            parentChildPersonIds={parentChildPersonIds}
            onSave={handleAddChild}
          />
          {isLoading && (
            <div className={'stub-element'}>
              <Loader />
            </div>
          )}
        </Box>
      )}
      <Button
        variant="text"
        startIcon={<PlusIcon />}
        sx={{ mt: 2 }}
        onClick={addChild}
      >
        Добавить
      </Button>
    </>
  );
};

export default ProfileParentRoleList;

export const mapPupilData = (pupilInfo: PupilInfo | null): PupilEntity[] => {
  if (pupilInfo) {
    return Object.values(pupilInfo.pupilRole).map((pupilRole: PupilRole) => {
      const organization: Organization | undefined =
        pupilInfo?.organization[pupilRole.organization?.reference || ''];

      const eduGroup: EduGroup | undefined =
        pupilInfo?.eduGroup[pupilRole.eduGroup?.reference || ''];

      const nextOfKinRoles =
        pupilInfo && pupilRole.pupilProfile
          ? PupilUtils.findAllNextOfKinRolesForPupil(
              pupilInfo.nextOfKinRole,
              pupilRole.pupilProfile,
            )
          : undefined;

      const nextOfKinRole = nextOfKinRoles?.length
        ? PupilUtils.findPriorityNextOfKinRole(nextOfKinRoles)
        : undefined;

      const pupilPerson =
        pupilInfo &&
        (Object.values(pupilInfo.person)?.find(
          (profile: Person) =>
            profile.pupilProfile?.reference ===
            pupilRole.pupilProfile?.reference,
        ) as Person | undefined);

      const pupilAuthAccount = pupilInfo.pupilAuthAccounts.find(
        (acc) =>
          acc.pupilProfile?.reference === pupilRole.pupilProfile?.reference,
      ) as AuthAccount | undefined;

      // eslint-disable-next-line
      // debugger;
      const esiaIdKey =
        pupilPerson && pupilPerson.id
          ? `${EntityTypes.PERSON}/${pupilPerson.id}`
          : undefined;

      const journalData = esiaIdKey
        ? pupilInfo.pupilJournalData[esiaIdKey]
        : undefined;

      return {
        pupilRole,
        pupilAuthAccount,
        organization,
        pupilPerson,
        nextOfKinRole,
        eduGroup,
        journalData,
      };
    });
  }
  return [];
};

async function createPupilRole(
  formData: FormData,
  nextOfKinProfile: NextOfKinProfile,
  roleListAll: any,
) {
  if (formData.person && formData.role && formData.parentRole) {
    const period: Definitions.Period = { start: new Date().toISOString() };

    const req = await userApi.getUserInfo({
      principal: formData.person.id,
    });
    let authAccount: Entity<AuthAccount> | undefined = undefined;
    if (req) {
      authAccount = getWhoIsAuth(req);
    }
    if (!authAccount) {
      const authData: AuthAccount = {
        resourceType: EntityTypes.AUTH_ACCOUNT,
        period,
        principal: {
          reference: `${formData.person.resourceType}/${formData.person.id}`,
        },
        status: AuthAccountStatus.PENDING,
        juvenileStatus: JuvenileStatus.CHILD,
        consentStatus: ConsentStatus.PENDING,
      };

      const authAccountResponse = await userApi.addAuthAccount(authData);

      if (authAccountResponse?.id) {
        authAccount = {
          fullUrl: `https://${authAccountResponse.resourceType}/${authAccountResponse.id}`,
          resource: authAccountResponse,
        };
      }
    }

    if (!authAccount) {
      throw new Error('Не создан аккаунт ребенка');
    }

    let pupilProfileReference = authAccount.resource.pupilProfile?.reference;

    if (!authAccount.resource.pupilProfile?.reference) {
      const pupilProfile: PupilProfile = {
        resourceType: EntityTypes.PUPIL_PROFILE,
        roleCategory: '3',
        status: UserProfileStatus.CREATED_BY_SELF,
        authAccount: {
          reference: `${authAccount.resource.resourceType}/${authAccount.resource.id}`,
        },
      };

      const pupilProfileResponse = await userApi.addPupilProfile(pupilProfile);

      if (!pupilProfileResponse?.id) {
        throw new Error('Не создан профиль ребенка');
      }

      pupilProfileReference = createReference(pupilProfileResponse);
    }

    const pupilRole: PupilRole = {
      resourceType: EntityTypes.PUPIL_ROLE,
      // @ts-ignore
      role: {
        code: formData.role,
      },
      period,
      status: UserRoleStatus.CREATED_BY_SELF,
      eduGroup: formData.eduGroup && {
        reference: `${formData.eduGroup.resource.resourceType}/${formData.eduGroup.resource.id}`,
      },
      pupilProfile: {
        reference: pupilProfileReference!,
      },
      // @ts-ignore
      attendanceForm: {
        // TODO: set correct type
        code: 'очная',
      },
      organization: formData.organizationId
        ? {
            reference: `${EntityTypes.ORGANIZATION}/${formData.organizationId}`,
          }
        : undefined,
    };

    const pupilRoleResponse = await userApi.addPupilRole(pupilRole);

    if (!pupilRoleResponse?.id) {
      throw new Error('Не создана роль ребенка');
    }

    if (
      !roleListAll?.some(
        (listItem: any) =>
          listItem.resource.pupilProfile.reference === pupilProfileReference &&
          listItem.resource.status !== UserRoleStatus.RETIRED,
      )
    ) {
      const nextOfKinRole: NextOfKinRole = {
        resourceType: EntityTypes.NEXT_OF_KIN_ROLE,
        // @ts-ignore
        role: {
          code: formData.parentRole,
        },
        period,
        status: UserRoleStatus.CREATED_BY_SELF,
        nextOfKinProfile: {
          reference: createReference(nextOfKinProfile),
        },
        pupilProfile: {
          reference: pupilProfileReference!,
        },
      };

      const nextOfKinRoleResponse = await userApi.addNextOfKinRole(
        nextOfKinRole,
      );

      if (!nextOfKinRoleResponse?.id) {
        throw new Error('Не удалось создать роль родителя');
      }
    }
  }
}

import { Box, Grid, Typography } from '@mui/material';

import { Person } from '@declarations/person';
import { ReactComponent as ConfirmIcon } from '@assets/icons/confirm.svg';
import { NextOfKinRole, PupilRole } from '@declarations/role';
import { EduGroup } from '@declarations/eduGroup';
import { Organization } from '@declarations/organization';
import { UserRoleStatus } from '@redux/user/types';
import StyledRadio from '@components/common/StyledRadio/StyledRadio';
import { useCallback, useMemo, useState } from 'react';
import { StructureRole } from '@declarations/enum/structureRole';
import { structureRoleLocale } from '@locales/structureRole';
import dayjs from 'dayjs';
import Button from '@components/common/Button/Button';
import { Definitions } from '@declarations/common';
import { EntityTypes } from '@mock-data/entities';
import { createReference } from '@lib/common';
import { userApi } from '@api/user';
import { useSnackbar } from '@hooks/useSnackbar';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserRole, getUserPupilInfo } from '@redux/user';
import { Loader } from '@components/common/Loader/Loader';

type Props = {
  pupilRole: PupilRole;
  person?: Person;
  eduGroup?: EduGroup;
  organization?: Organization;
  nextOfKinRole?: NextOfKinRole;
};

export const PupilCard = ({
  person,
  pupilRole,
  eduGroup,
  organization,
  nextOfKinRole,
}: Props) => {
  const dispatch = useDispatch();
  const { addSnack } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const pupilInfo = useSelector(getUserPupilInfo);
  const [selectedParentRole, setSelectedParentRole] = useState<string>(
    StructureRole.PARENT,
  );
  const availableParentTypes = useMemo(() => {
    const age =
      person?.birthDate && dayjs().diff(dayjs(person.birthDate), 'year');
    const result: { code: string; display: string; disabled: boolean }[] = [
      {
        code: StructureRole.PARENT,
        display: structureRoleLocale[StructureRole.PARENT],
        disabled: !person,
      },
      {
        code: StructureRole.GUARDIAN,
        display: structureRoleLocale[StructureRole.GUARDIAN],
        disabled: !age || (!!age && age >= 14),
      },
      {
        code: StructureRole.TRUSTEE,
        display: structureRoleLocale[StructureRole.TRUSTEE],
        disabled: !age || (!!age && age < 14),
      },
    ];
    return result;
  }, [person]);

  const handleChangeRolesStatuses = useCallback(
    async (data: {
      nextOfKinRole?: NextOfKinRole;
      pupilRole?: PupilRole;
      eventType: 'confirm' | 'reject';
    }) => {
      setIsLoading(true);
      try {
        if (!data.nextOfKinRole) {
          if (!pupilInfo || !data.pupilRole?.pupilProfile?.reference) return;
          const period: Definitions.Period = {
            start: new Date().toISOString(),
          };
          const nextOfKinRole: NextOfKinRole = {
            resourceType: EntityTypes.NEXT_OF_KIN_ROLE,
            // @ts-ignore
            role: {
              code: selectedParentRole,
            },
            period,
            status: UserRoleStatus.CONFIRMED,
            nextOfKinProfile: {
              reference: createReference(pupilInfo.nextOfKinProfile),
            },
            pupilProfile: {
              reference: data.pupilRole.pupilProfile.reference,
            },
          };

          const nextOfKinRoleResponse = await userApi.addNextOfKinRole(
            nextOfKinRole,
          );

          if (!nextOfKinRoleResponse?.id) {
            throw new Error('Не удалось создать роль законного представителя');
          }

          //ТО, что прокомментировано ниже убрано после обсуждения, статус остается CREATED_BY_SELF пока администратор его не изменит
          /* После создания роли - она имеет статус CREATED_BY_SELF, даже если мы передаем CONFIRMED в POST запрос выше
          т.о. после создания роли делается запрос на изменения её статуса на CONFIRMED
           */
          // if (nextOfKinRoleResponse.status === UserRoleStatus.CREATED_BY_SELF) {
          //   await userApi.updateNextOfKinRoleStatus(
          //     nextOfKinRoleResponse,
          //     UserRoleStatus.CONFIRMED,
          //   );
          // }
        } else if (
          data.nextOfKinRole?.status === UserRoleStatus.CREATED_BY_ADMIN &&
          !!data.nextOfKinRole?.id
        ) {
          /* Роль ЗП переводиться в статус CONFIRMED всегда (при подтверждении и при отклонении)
            для того чтобы иметь возможность изменить роль обучающемуся
             */
          await userApi.updateNextOfKinRoleStatus(
            data.nextOfKinRole,
            UserRoleStatus.CONFIRMED,
          );
          await changePupilRole();
        } else if (data.nextOfKinRole?.status === UserRoleStatus.CONFIRMED) {
          await changePupilRole();
        }
      } catch (error) {
        addSnack(
          'Произошла ошибка при смене статуса роли законного представителя',
          'error',
        );
      } finally {
        setIsLoading(false);
      }

      async function changePupilRole() {
        try {
          if (
            data.pupilRole?.status === UserRoleStatus.CREATED_BY_ADMIN &&
            !!data.pupilRole?.id
          ) {
            await userApi.updatePupilRoleStatus(
              data.pupilRole,
              data.eventType === 'confirm'
                ? UserRoleStatus.CONFIRMED
                : UserRoleStatus.RETIRED,
            );
          }

          addSnack(
            data.eventType === 'confirm'
              ? 'Роль подтверждена'
              : 'Роль отклонена',
            'success',
          );
        } catch (error) {
          addSnack(
            'Произошла ошибка при смене статуса роли обучающегося',
            'error',
          );
        }
      }

      dispatch(fetchUserRole());
    },
    [addSnack, dispatch, pupilInfo, selectedParentRole],
  );

  return (
    <div style={{ position: 'relative' }}>
      <Grid container flexDirection="column">
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ pb: 2 }}
        >
          <Grid item>
            <Typography variant="h5">
              {person?.name.family} {person?.name.given} {person?.name.middle}
            </Typography>
          </Grid>
          <Grid item>
            <ConfirmIcon />
          </Grid>
        </Grid>
        <Grid item container flexDirection="column" spacing={1}>
          <Grid item>
            <Typography variant="caption">
              <Box sx={{ display: 'inline-block', color: '#626B7A' }}>
                Место обучения:
              </Box>{' '}
              {pupilRole.attendanceForm?.display || '-'}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">
              <Box sx={{ display: 'inline-block', color: '#626B7A' }}>
                Класс:
              </Box>{' '}
              {eduGroup?.name || '-'}
            </Typography>
          </Grid>
          {organization && (
            <Grid item>
              <Typography variant="caption">
                <Box sx={{ display: 'inline-block', color: '#626B7A' }}>
                  Школа:
                </Box>{' '}
                {organization.name}
              </Typography>
            </Grid>
          )}
          {person && (
            <>
              {person.identifier?.map(
                ({ type, value, assignerDisplay, assignerIdentifierValue }) => (
                  <Grid item key={`${type}-${value}`}>
                    <Typography variant="caption">
                      <Box
                        sx={{
                          display: 'inline-block',
                          color: '#626B7A',
                        }}
                      >
                        {type}:
                      </Box>{' '}
                      {value} {assignerDisplay} {assignerIdentifierValue}
                    </Typography>
                  </Grid>
                ),
              )}
              {person.telecom?.map(({ system, value }) => (
                <Grid item key={`${system.code}-${value}`}>
                  <Typography variant="caption">
                    <Box
                      sx={{
                        display: 'inline-block',
                        color: '#626B7A',
                      }}
                    >
                      {system.display}:
                    </Box>{' '}
                    {value ?? '-'}
                  </Typography>
                </Grid>
              ))}
            </>
          )}
          {organization && (
            <Grid item>
              <Typography variant="caption">
                <Box sx={{ display: 'inline-block', color: '#626B7A' }}>
                  Субъект РФ:
                </Box>{' '}
                {organization.address?.length
                  ? organization.address
                      .map((address) => address.state?.display ?? '')
                      .join(', ')
                  : '-'}
              </Typography>
            </Grid>
          )}

          <Grid item>
            <Typography variant="caption">
              <Box sx={{ display: 'inline-block', color: '#626B7A' }}>
                Статус законного представителя:
              </Box>{' '}
              {nextOfKinRole?.role.display}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {(pupilRole.status === UserRoleStatus.CREATED_BY_SELF ||
        nextOfKinRole?.status === UserRoleStatus.CREATED_BY_SELF) && (
        <Box sx={{ mt: 2 }}>
          <div className="profile__notification">на рассмотрении</div>
        </Box>
      )}
      {(((nextOfKinRole?.status === UserRoleStatus.CREATED_BY_ADMIN ||
        nextOfKinRole?.status === UserRoleStatus.CONFIRMED) &&
        (pupilRole.status === UserRoleStatus.CREATED_BY_ADMIN ||
          pupilRole.status === UserRoleStatus.CONFIRMED) &&
        !(
          nextOfKinRole?.status === UserRoleStatus.CONFIRMED &&
          pupilRole.status === UserRoleStatus.CONFIRMED
        )) ||
        (pupilRole.status !== UserRoleStatus.RETIRED && !nextOfKinRole)) && (
        <Grid
          marginTop={'20px'}
          container
          alignItems="center"
          justifyContent="flex-start"
          spacing={3}
          columnSpacing={1}
        >
          {!nextOfKinRole &&
            availableParentTypes.map((parentRole) => (
              <Grid item key={parentRole.code}>
                <StyledRadio
                  value={parentRole.code}
                  name="parentRole"
                  checked={selectedParentRole === parentRole.code}
                  onChange={() => setSelectedParentRole(parentRole.code)}
                  label={parentRole.display}
                  disabled={parentRole.disabled}
                />
              </Grid>
            ))}
          <Grid item>
            <Button
              size="small"
              variant="first"
              onClick={() => {
                handleChangeRolesStatuses({
                  nextOfKinRole,
                  pupilRole,
                  eventType: 'confirm',
                });
              }}
            >
              Подтвердить роль
            </Button>
          </Grid>
          {/*Если статус роли обучающегося CONFIRMED, то скрываем кнопку Отклонить*/}
          {pupilRole.status === UserRoleStatus.CREATED_BY_ADMIN &&
            !!nextOfKinRole && (
              <Grid item>
                <Button
                  size="small"
                  variant="fourth"
                  onClick={() => {
                    handleChangeRolesStatuses({
                      nextOfKinRole,
                      pupilRole,
                      eventType: 'reject',
                    });
                  }}
                >
                  Отклонить
                </Button>
              </Grid>
            )}
        </Grid>
      )}
      {isLoading && (
        <div className={'stub-element'}>
          <Loader />
        </div>
      )}
    </div>
  );
};

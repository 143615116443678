import { useEffect, useRef, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Popper,
  styled,
  ClickAwayListener,
  Box,
  Button,
  List,
  ListItem,
  Divider,
} from '@mui/material';
import qs from 'query-string';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as SearchIcon } from '@assets/icons/search.svg';

import { ROUTES } from '@constants/routes';
import SingleSelect from '@components/common/Select/SingleSelect';

import './HeaderSearchWidget.scss';
import { MY_LIBRARY_LINK } from '@constants/config';
import { SearchSource } from '@declarations/enum/search';
import { SEARCH_SOURCE_OPTIONS } from '@constants/constants';

import { addHistoryItem, clearHistory } from '@redux/search';
import { RootState } from '@redux/store';

const SearchButton = styled(Button)({
  fontFamily: 'inherit',
  color: '#151b24',
  height: '76px',
  minWidth: 'auto',
  padding: '8px',
  justifyContent: 'space-between',
  '&:hover': {
    backgroundColor: 'transparent',
    color: '#0278ff',
  },
});

const SELECT_WIDTH = 150;

export const HeaderSearchWidget = () => {
  const dispatch = useDispatch();
  const buttonRef = useRef(null);
  const inputRef = useRef(null);

  const [expanded, setExpanded] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [source, setSource] = useState(SearchSource.ALL);
  const [selectPosition, setSelectPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const [searchString, setSearchString] = useState('');
  const searchHistory = useSelector((state: RootState) => state.search.history);

  const textInput = useRef<HTMLInputElement>(null);

  const handleSearchClick = () => {
    setExpanded(true);
    setTimeout(() => {
      textInput.current?.focus();
    }, 100);
  };

  const handleSearchAwayClick = () => {
    if (!showHistory || !searchString) {
      setExpanded(false);
    }
  };

  const history = useHistory();

  const search = useCallback(() => {
    if (searchString) {
      dispatch(addHistoryItem(searchString));
    }
    if (source === SearchSource.LIBRARY) {
      const link = qs.stringifyUrl({
        url: `${MY_LIBRARY_LINK}market`,
        query: {
          filters: `"commonSearch":"${searchString}"`,
        },
      });
      window.location.href = link;
    } else {
      history.push(
        qs.stringifyUrl({
          url: ROUTES.search,
          query: {
            q: searchString,
            source,
          },
        }),
      );
      setExpanded(false);
    }
  }, [dispatch, history, source, searchString]);

  const handleClearSearchHistory = () => {
    dispatch(clearHistory());
  };

  const idSearch = expanded ? 'simple-popper' : undefined;

  useEffect(() => {
    if (expanded) {
      // К сожалению, чтобы обратить к DOM-элементу и взять его координаты нужно время
      // поэтому приходится городить костыли с setTimeout 0.
      setTimeout(() => {
        const data = textInput.current?.getBoundingClientRect();
        if (data) {
          setSelectPosition({
            top: data.top,
            left: data.left - SELECT_WIDTH,
          });
        }
      }, 0);
    } else {
      setSelectPosition(null);
    }
  }, [textInput, expanded]);

  useEffect(() => {
    setShowHistory(!searchString);
  }, [searchString]);

  return (
    <>
      <ClickAwayListener onClickAway={handleSearchAwayClick}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width={{
            xs: '44px',
            md: '120px',
            lg: '44px',
            xl: '120px',
          }}
        >
          <SearchButton
            onClick={handleSearchClick}
            ref={buttonRef}
            sx={{
              pr: { xs: '0', xl: '24px' },
              pl: { xs: '0', xl: '19px' },
            }}
          >
            <SearchIcon />
            <Box
              display={{ xs: 'none', md: 'block', lg: 'none', xl: 'block' }}
              ml={1}
            >
              Поиск
            </Box>
          </SearchButton>
          <Popper
            id={idSearch}
            open={expanded}
            anchorEl={buttonRef.current}
            placement="left"
          >
            <div className="search-widget" ref={inputRef}>
              <input
                type="text"
                ref={textInput}
                className="search-widget__input"
                placeholder="Поиск"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') search();
                }}
                onFocus={() => {
                  if (!searchString) {
                    setShowHistory(true);
                  }
                }}
              />
              <Button
                sx={{
                  backgroundColor: '#F3F3F4',
                  position: 'absolute',
                  top: '2px',
                  right: '2px',
                  height: '40px',
                }}
                onClick={search}
              >
                Найти
              </Button>
            </div>
          </Popper>
          <Box
            position="absolute"
            {...selectPosition}
            width={SELECT_WIDTH}
            display={selectPosition ? 'block' : 'none'}
          >
            <SingleSelect
              options={SEARCH_SOURCE_OPTIONS}
              name="selectSort"
              placeholder="Сортировка"
              id="selectSort"
              className="search-widget__source-select"
              onChange={(selectedOption) =>
                selectedOption &&
                setSource(selectedOption.value as SearchSource)
              }
              value={SEARCH_SOURCE_OPTIONS?.find(
                (item) => item.value === source,
              )}
            />
          </Box>
        </Box>
      </ClickAwayListener>
      {searchHistory.length > 0 && (
        <Popper
          open={showHistory && expanded}
          anchorEl={inputRef.current}
          placement="bottom-end"
        >
          <Box
            borderRadius="8px"
            boxShadow="0px 1px 8px rgba(123, 135, 158, 0.12), 0px 8px 32px rgba(123, 135, 158, 0.25)"
            bgcolor="#FFF"
            // @ts-ignore
            width={inputRef.current?.clientWidth + SELECT_WIDTH}
          >
            <List>
              {searchHistory.map((item) => (
                <ListItem
                  key={item}
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => setSearchString(item)}
                >
                  {item}
                </ListItem>
              ))}
            </List>
            <Divider />
            <Box
              color="#007AFF"
              padding="14px 16px 22px"
              sx={{
                cursor: 'pointer',
              }}
              onClick={handleClearSearchHistory}
            >
              очистить
            </Box>
          </Box>
        </Popper>
      )}
    </>
  );
};

import { Region } from '@declarations/region';
import { notifyAxios } from './utils/axios';
import { PagedData } from './types/notify';

// TODO-achernyavetsздесь было withCredentials: true, нужно проверить
const SERVICE_NAME = 'api-ntfc-pull/api/v1';

// TODO: refactor this, temporary logic
let cachedRegionsReponse: {
  content: Region[];
} = { content: [] };

export const regionApi = {
  async search(
    searchParams: Partial<{
      name: string;
    }>,
  ) {
    const data = cachedRegionsReponse.content.length
      ? null
      : await notifyAxios.get<PagedData<Region>, PagedData<Region>>(
          `${SERVICE_NAME}/regions/search?size=200`,
          {
            params: {
              isProtected: true,
            },
          },
        );

    if (data || cachedRegionsReponse.content.length) {
      if (data) {
        cachedRegionsReponse = data;
      }
      return cachedRegionsReponse.content?.filter((region) => {
        if (searchParams.name) {
          return (
            region.name
              .toLowerCase()
              .includes(searchParams.name.toLowerCase()) ||
            region.id.toString() === searchParams.name
          );
        }
        return true;
      });
    }

    return [];
  },
};

export const getRegionRequest = async (
  searchString: string,
): Promise<Region[]> => {
  const response = await regionApi.search({
    name: searchString,
  });
  if (response) {
    return response;
  }
  return [];
};

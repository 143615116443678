import { TextField, TextFieldProps } from '@mui/material';
import { FontStyles } from '../../muiStyles/FontStyles';
import variables from '../../muiStyles/variables';

declare module '@mui/material/TextField' {
  // eslint-disable-next-line no-unused-vars
  interface TextFieldPropsSizeOverrides {
    large: true;
  }
}

type PropsType =
  | TextFieldProps
  | {
      size?: 'medium' | 'large' | 'small';
      name: string;
      id: string;
      sx: any;
    };

export const StyledInput = ({ size = 'medium', sx, ...rest }: PropsType) => {
  const rootElement = 'MuiTextField-root';

  let cssInput = {
    height: '24px',
    padding: '0',
  };

  const cssPlaceholder = {
    ...FontStyles['text/p_small'],
    opacity: 1,
    color: variables['$color-minor-text'],
  };

  const cssFieldset = {
    border: '1px solid #D8D8DD',
    borderRadius: '6px',
    borderColor: 'none',
  };
  const cssFieldsetHover = {
    borderColor: '#0278FF',
  };
  const cssFieldsetFocus = {
    boxShadow:
      '-3px -3px 0px #C6DDFF, -3px 3px 0px #C6DDFF, 3px 3px 0px #C6DDFF, 3px -3px 0px #C6DDFF',
    border: '1px solid #D8D8DD',
    borderColor: '#0278FF',
  };

  switch (size) {
    case 'medium':
      cssInput.padding = '10px';
      cssInput = { ...cssInput, ...FontStyles['text/p_small'] };
      break;
    case 'small':
      cssInput.padding = '6px';
      break;
    case 'large':
      cssInput.padding = '15px';
      break;
  }

  const styles = {
    [`&.${rootElement} label`]: {
      backgroundColor: '#fff',
      outline: '5px solid #fff',
    },
    [`&.${rootElement} input`]: cssInput,
    [`&.${rootElement} input::placeholder`]: cssPlaceholder,
    [`&.${rootElement} fieldset`]: cssFieldset,
    [`&.${rootElement}:hover fieldset`]: cssFieldsetHover,
    [`&.${rootElement} input:focus ~ fieldset`]: cssFieldsetFocus,
  };

  return (
    <TextField
      size={size === 'medium' ? 'small' : undefined}
      sx={Object.assign({}, styles, sx)}
      {...rest}
    />
  );
};

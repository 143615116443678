import { FaqStatus, Faq } from '@redux/faq/types';
import { getSearchQueryParams } from '@api/utils';
import { Filter } from '@declarations/common';
import { mainAxios } from './utils/axios';
import { ResponseList } from './types/common';

const SERVICE_NAME = 'api-faq/api/v1';

export const faqApi = {
  createFaq(faq: Faq) {
    return mainAxios.post<Faq, Faq>(`${SERVICE_NAME}/`, faq, {
      params: { isProtected: true },
    });
  },
  searchFaqList(
    pagination: { currentPage: number; pageSize: number },
    filters: Partial<Filter>,
    isTargetedSearch?: boolean,
  ) {
    return mainAxios.get<ResponseList<Faq>, ResponseList<Faq>>(
      `${SERVICE_NAME}/search`,
      {
        params: {
          ...getSearchQueryParams(pagination, filters),
          isProtected: true,
          targeting: isTargetedSearch ? 'on' : 'off',
        },
      },
    );
  },
  changeFaqStatus(id: string | number, status: FaqStatus) {
    return mainAxios.put(
      `${SERVICE_NAME}/status/${id}`,
      {
        status,
      },
      {
        params: { isProtected: true },
      },
    );
  },
  removeFaq(id: (string | number) | (string | number)[]) {
    return mainAxios.delete(
      `${SERVICE_NAME}/${Array.isArray(id) ? id.join(',') : id}`,
      {
        params: { isProtected: true },
      },
    );
  },
  updateFaq(faq: Faq, id: number | string) {
    return mainAxios.put<Faq, Faq>(`${SERVICE_NAME}/${id}`, faq, {
      params: { isProtected: true },
    });
  },
  getFaqList() {
    return mainAxios.get<Faq[], Faq[]>(`${SERVICE_NAME}/list`);
  },
  getFaqById(id: number | string, isManage = false) {
    return mainAxios.get<Faq, Faq>(`${SERVICE_NAME}/${id}`, {
      params: { isManage },
    });
  },
};

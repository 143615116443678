import { Quiz, Question, Variant } from '@declarations/quiz';
import { sortFunc } from '@pages/Manager/Quiz/utils';
import { Quiz as QuizEntity } from '@api/types/quiz';

export const mapData = (item: QuizEntity): Quiz => {
  const data = [...item.questions];
  data.sort(sortFunc);
  const questions: Question[] = data.map((question) => {
    let variants: Variant[] = [];

    if (question.answers?.length) {
      const answers = [...question.answers];
      answers.sort(sortFunc);
      variants = answers.map((item, i) => ({
        id: item.id || i,
        value: item.title,
        fullBody: item.body || '',
      }));
    }

    return {
      id: question.id || 0,
      type: question.type,
      fullBody: question.title || '',
      minAnswers: question.minAnswerCount || 1,
      maxAnswers: question.maxAnswerCount || 1,
      customVariantSupported: question.type === 'CUSTOM_ANSWER',
      variants,
    };
  });

  return {
    title: item.title,
    publishDate: item.startDate,
    id: item.id || 0,
    shortBody: item.body || '',
    fullBody: item.body || '',
    status: item.status,
    questions,
    endDate: item.endDate,
  };
};

import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import './LibraryWidget.scss';
import Title from '../../../components/common/Title/Title';
import SwiperCore, { Navigation } from 'swiper';
import { useRef, useState } from 'react';
import ArrowRight from '../../../assets/icons/slider-arrow-right.svg';
import { EUserRole, UserRole } from '@redux/user/types';
import { useSelector } from 'react-redux';
import { getUserRole } from '@redux/user';
import { MATERIALS_LIB_LINK, MY_LIBRARY_LINK } from '@constants/config';

interface LibraryItem {
  lesson: string;
  image: string;
  color: string;
  title: string;
  url: string;
  tags: string[];
  target: UserRole[];
}

const items: Array<LibraryItem> = [
  {
    lesson: 'Каталог',
    image: '/img/mainpage/library/ico-catalog.png',
    color: '#299EF2',
    title: 'Каталог цифрового образовательного контента',
    url: `${MATERIALS_LIB_LINK}my-school/catalog?backUrl=https://myschool.edu.ru/&token=00d3c187bded0129a346af21affaa6a9e375b1c38cf3d61632acf9035c32a694`,
    tags: [],
    target: [
      EUserRole.NO_AUTH,
      EUserRole.STUDENT,
      EUserRole.ADMIN,
      EUserRole.PARENT,
      EUserRole.TEACHER,
    ],
  },
  {
    lesson: 'Подборка',
    image: '/img/mainpage/library/ico-it.svg',
    color: '#299EF2',
    title:
      'Актуальная тематическая подборка цифрового образовательного контента',
    url: `${MATERIALS_LIB_LINK}my-school/schedule?backUrl=https://myschool.edu.ru/&token=00d3c187bded0129a346af21affaa6a9e375b1c38cf3d61632acf9035c32a694`,
    tags: [],
    target: [
      EUserRole.NO_AUTH,
      EUserRole.STUDENT,
      EUserRole.ADMIN,
      EUserRole.PARENT,
      EUserRole.TEACHER,
    ],
  },
  {
    lesson: 'Урок',
    image: '/img/mainpage/library/ico-language.svg',
    color: '#6D55CC',
    title: 'Досуг и увлечения',
    url: `${MATERIALS_LIB_LINK}my-school/lesson/4b77263d-86ea-441d-8bd8-5d77b3400c7c?backUrl=https%3A%2F%2Fmyschool.edu.ru&token=00d3c187bded0129a346af21affaa6a9e375b1c38cf3d61632acf9035c32a694`,
    tags: ['8кл', 'Английский язык'],
    target: [
      EUserRole.NO_AUTH,
      EUserRole.STUDENT,
      EUserRole.ADMIN,
      EUserRole.PARENT,
    ],
  },
  {
    lesson: 'Урок',
    image: '/img/mainpage/library/ico-physics.svg',
    color: '#22AC62',
    title: 'Колебательное движение',
    url: `${MATERIALS_LIB_LINK}my-school/lesson/1b737581-2a8a-4622-b862-77de56a52f7c?backUrl=https%3A%2F%2Fmyschool.edu.ru&token=00d3c187bded0129a346af21affaa6a9e375b1c38cf3d61632acf9035c32a694`,
    tags: ['9 кл', 'Физика'],
    target: [
      EUserRole.NO_AUTH,
      EUserRole.STUDENT,
      EUserRole.ADMIN,
      EUserRole.PARENT,
    ],
  },
  {
    lesson: 'Урок',
    image: '/img/mainpage/library/ico-maths.svg',
    color: '#299EF2',
    title: 'Симметрия. Осевая симметрия',
    url: `${MATERIALS_LIB_LINK}my-school/lesson/93163539-642c-4f76-ba47-6c9b8d031bce?backUrl=https%3A%2F%2Fmyschool.edu.ru&token=00d3c187bded0129a346af21affaa6a9e375b1c38cf3d61632acf9035c32a694`,
    tags: ['6 кл', 'Математика'],
    target: [
      EUserRole.NO_AUTH,
      EUserRole.STUDENT,
      EUserRole.ADMIN,
      EUserRole.PARENT,
    ],
  },
  {
    lesson: 'Урок',
    image: '/img/mainpage/library/ico-chemistry.svg',
    color: '#22AC62',
    title:
      'Закон сохранения массы веществ. М.В. Ломоносов — учёный-энциклопедист',
    url: `${MATERIALS_LIB_LINK}my-school/lesson/e093dccf-40f3-4242-b78a-9f426bbd9d5f?backUrl=https://myschool.edu.ru/&token=00d3c187bded0129a346af21affaa6a9e375b1c38cf3d61632acf9035c32a694`,
    tags: ['8 кл', 'Химия'],
    target: [EUserRole.TEACHER],
  },
  {
    lesson: 'Урок',
    image: '/img/mainpage/library/ico-language.svg',
    color: '#6D55CC',
    title: 'Эпитет',
    url: `${MATERIALS_LIB_LINK}my-school/lesson/db8551dc-eb9c-4edc-8673-db4faa7c1ea3?backUrl=https://myschool.edu.ru/&token=00d3c187bded0129a346af21affaa6a9e375b1c38cf3d61632acf9035c32a694`,
    tags: ['6 кл', 'Русский язык'],
    target: [EUserRole.TEACHER],
  },
  {
    lesson: 'Урок',
    image: '/img/mainpage/library/ico-maths.svg',
    color: '#299EF2',
    title: 'Симметрия. Осевая симметрия',
    url: `${MATERIALS_LIB_LINK}my-school/lesson/93163539-642c-4f76-ba47-6c9b8d031bce?backUrl=https://myschool.edu.ru/&token=00d3c187bded0129a346af21affaa6a9e375b1c38cf3d61632acf9035c32a694`,
    tags: ['6 кл', 'Математика'],
    target: [EUserRole.TEACHER],
  },
  {
    lesson: 'Урок',
    image: '/img/mainpage/library/ico-bio.svg',
    color: '#22AC62',
    title:
      'Размножение хвойных, цикл развития на примере сосны. Значение хвойных растений в природе и жизни человека',
    url: `${MATERIALS_LIB_LINK}my-school/lesson/1f7cf86d-9359-4b8d-9715-767070e85c8f?backUrl=https://myschool.edu.ru/&token=00d3c187bded0129a346af21affaa6a9e375b1c38cf3d61632acf9035c32a694`,
    tags: ['7 кл', 'Биология'],
    target: [EUserRole.TEACHER],
  },
  {
    lesson: 'Урок',
    image: '/img/mainpage/library/ico-bio.svg',
    color: '#22AC62',
    title:
      'Одноклеточные и многоклеточные организмы. Клетки, ткани, органы, системы органов.',
    url: `${MATERIALS_LIB_LINK}my-school/lesson/d6e87a17-821e-485e-9a43-b8e1e0840f31?backUrl=https://myschool.edu.ru/&token=00d3c187bded0129a346af21affaa6a9e375b1c38cf3d61632acf9035c32a694`,
    tags: ['5 кл', 'Биология'],
    target: [EUserRole.TEACHER],
  },
];

SwiperCore.use([Navigation]);

export const LibraryWidget: React.FC = () => {
  const prevRef = useRef<HTMLInputElement>(null);
  const nextRef = useRef<HTMLInputElement>(null);
  const [isEnd, setIsEnd] = useState(false);
  const [isBegin, setIsBegin] = useState(false);

  const userRole = useSelector(getUserRole);
  const filteredItems = items?.filter((item) => item.target.includes(userRole));

  return (
    <div className="library-widget">
      <Title
        sx={{ fontSize: '24px', marginBottom: '40px' }}
        href="https://lib.myschool.edu.ru/market"
      >
        Материалы библиотеки
      </Title>
      <div className="library-widget__slider">
        <Swiper
          navigation={{
            prevEl: prevRef.current!,
            nextEl: nextRef.current!,
          }}
          onInit={(swiper) => {
            // @ts-ignore
            swiper.params.navigation.prevEl = prevRef.current;
            // @ts-ignore
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.update();
          }}
          spaceBetween={32}
          slidesPerView={1}
          onSlideChange={(swiper) => {
            setIsEnd(swiper.isEnd);
            setIsBegin(swiper.isBeginning);
          }}
          onSwiper={(swiper) => {
            setIsEnd(swiper.isEnd);
            setIsBegin(swiper.isBeginning);
          }}
          breakpoints={{
            758: {
              slidesPerView: 5,
            },
          }}
        >
          {filteredItems.map((item, index) => (
            <SwiperSlide key={index}>
              <a tabIndex={1} href={item.url} className="library-widget__item">
                <div
                  className="library-widget__item-img"
                  style={{
                    backgroundColor: item.color,
                  }}
                >
                  <div className="library-widget__item-lesson">
                    {item.lesson}
                  </div>
                  <div className="library-widget__item-title">{item.title}</div>
                  <div className="library-widget__item-ico">
                    <img src={item.image} alt="" style={{ height: 69 }} />
                  </div>
                </div>
                <div>
                  {item.tags.map((tag, index) => (
                    <div key={index} className="library-widget__item-tag">
                      {tag}
                    </div>
                  ))}
                </div>
              </a>
            </SwiperSlide>
          ))}
          <SwiperSlide>
            <a
              tabIndex={1}
              href={`${MY_LIBRARY_LINK}market`}
              className="library-widget__item"
            >
              <span className="library-widget__item-img library-widget__item-img--more">
                <span className="library-widget__item-text">
                  Посмотреть все материалы
                </span>
              </span>
            </a>
          </SwiperSlide>
          <div ref={prevRef} />
          <div ref={nextRef} />
        </Swiper>
        <div
          onClick={() => prevRef?.current?.click()}
          className={`library-widget__arrow library-widget__arrow--prev ${
            isBegin ? 'library-widget__arrow--disable' : ''
          }`}
          style={{ backgroundImage: `url(${ArrowRight})` }}
        />
        <div
          onClick={() => nextRef?.current?.click()}
          className={`library-widget__arrow library-widget__arrow--next ${
            isEnd ? 'library-widget__arrow--disable' : ''
          }`}
          style={{ backgroundImage: `url(${ArrowRight})` }}
        />
      </div>
    </div>
  );
};

import * as React from 'react';
import { useEffect, useState } from 'react';
import LinksItem from '@pages/Links/LinksItem/LinksItem';
import { useParams } from 'react-router';
import { Link } from '@declarations/links';
import linksApi from '@api/links';
import EmptyData from '@components/common/EmptyData/EmptyData';
import { Grid } from '@mui/material';
import { Loader } from '@components/common/Loader/Loader';
import { useSnackbar } from '@hooks/useSnackbar';
import { UI_MESSAGE } from '@declarations/enum/uiMessage';

const LinkDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [link, setLink] = useState<Link>();
  const [isLoadLink, setIsLoadLink] = useState(!!id);
  const [errLoadLink, setErrLoadLink] = useState(false);
  const { addSnack } = useSnackbar();

  useEffect(() => {
    const loadData = async () => {
      try {
        if (id) {
          const data = await linksApi.getLinkById(id);
          if (data) {
            setLink(data);
          }
        }
      } catch (err) {
        setErrLoadLink(true);
        addSnack(UI_MESSAGE.LINK_NOT_AVAILABLE, 'error');
      } finally {
        setIsLoadLink(false);
      }
    };
    loadData();
  }, [addSnack, id]);

  if (!link) {
    return <EmptyData />;
  }

  if (isLoadLink) {
    return <Loader />;
  }

  if (errLoadLink) {
    return <EmptyData />;
  }
  return (
    <Grid
      container
      flexDirection="column"
      sx={{ mt: 6, maxWidth: { xl: 952, lg: 894, md: 852, sm: '100%' } }}
    >
      <Grid item>
        <LinksItem link={link} />
      </Grid>
    </Grid>
  );
};

export default LinkDetail;

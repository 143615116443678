import { Definitions } from '@declarations/common';
import { ProfileCategory } from '@declarations/enum/profile';

export const getProfileList = (user: any) => {
  const access = user?.realm_access;
  const roles: string[] = access?.roles || [];
  const profileList: Definitions.Coding[] = [];
  Object.values(ProfileCategory)
    .filter((x) => typeof x === 'string')
    .forEach((value) => {
      if (roles.includes(value)) {
        switch (value) {
          case ProfileCategory.ADMINISTRATOR:
            profileList.push({
              code: ProfileCategory.ADMINISTRATOR,
              display: 'Администратор',
            });
            break;
          case ProfileCategory.TEACHER_EDUCATOR:
            profileList.push({
              code: ProfileCategory.TEACHER_EDUCATOR,
              display: 'Педагогический работник',
            });
            break;
          case ProfileCategory.LEGAL_REPRESENTATIVE:
            profileList.push({
              code: ProfileCategory.LEGAL_REPRESENTATIVE,
              display: 'Законный представитель обучающегося',
            });
            break;
          case ProfileCategory.STUDENT:
            profileList.push({
              code: ProfileCategory.STUDENT,
              display: 'Обучающийся',
            });
            break;
          default:
            profileList.push({
              code: '',
              display: 'отсутствует',
            });
        }
      }
    });

  return profileList;
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestStatuses, UiState } from './types';

const initialState: UiState = {
  requestStatuses: {},
};

const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setFetching: (state: UiState, action: PayloadAction<string>) => ({
      ...state,
      requestStatuses: {
        ...state.requestStatuses,
        ...{ [action.payload]: 'fetching' as RequestStatuses },
      },
    }),
    setFetched: (state: UiState, action: PayloadAction<string>) => ({
      ...state,
      requestStatuses: {
        ...state.requestStatuses,
        ...{ [action.payload]: 'fetched' as RequestStatuses },
      },
    }),
    setFetchingError: (state: UiState, action: PayloadAction<string>) => ({
      ...state,
      requestStatuses: {
        ...state.requestStatuses,
        ...{ [action.payload]: 'error' as RequestStatuses },
      },
    }),
  },
});

export const { setFetching, setFetched, setFetchingError } = slice.actions;

export * from './selectors';

export default slice.reducer;

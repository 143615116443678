export enum ProfileCategory {
  ADMINISTRATOR = '1',
  TEACHER_EDUCATOR = '2',
  STUDENT = '3',
  LEGAL_REPRESENTATIVE = '4',
}

export enum UserProfileStatus {
  CREATED_BY_ADMIN = 'created-by-admin',
  CREATED_BY_SELF = 'created-by-self',
  CONFIRMED = 'confirmed',
  CONFIRMED_PARTIALLY = 'confirmed-partially',
  RETIRED = 'retired',
}

export enum AdminProfileStatus {
  CREATED_BY_ADMIN = 'created-by-admin',
  CONFIRMED = 'confirmed',
  CONFIRMED_PARTIALLY = 'confirmed-partially',
  RETIRED = 'retired',
}

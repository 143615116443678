export const getCookie = (name: string) => {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`,
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (
  name: string,
  value: any,
  duration: number | null,
  domain?: string,
) => {
  let expires = '';
  const domainName = domain ? ` domain=${domain};` : '';
  if (duration) {
    const date = new Date();
    date.setTime(date.getTime() + duration * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value}${expires}; path=/;${domainName}`;
};

export function deleteCookie(name: string, domain?: string) {
  setCookie(name, '', -1, domain);
}

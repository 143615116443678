import * as React from 'react';
import { StructureRole } from '@declarations/enum/structureRole';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';

const useCheckForAdminStructuredRole = (adminRoleForCheck: StructureRole) => {
  const targetInfo = useSelector((state: RootState) => state.user.targetInfo);

  const isRole = React.useMemo(() => {
    return !targetInfo ? null : !!targetInfo.adminRoles[adminRoleForCheck];
  }, [adminRoleForCheck, targetInfo]);

  return { isRole };
};

export { useCheckForAdminStructuredRole };

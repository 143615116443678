import { Entity } from '@declarations/common';
import { EduGroup } from '@declarations/eduGroup';
import { eduGroupApi } from '@api/eduGroup';
import { UserRoleList } from '@redux/user/types';

const getUserAllRoleList = async (
  req: UserRoleList,
  whoIs: string,
  whoIsRole: string,
) => {
  if (req) {
    const profileList =
      req.entry &&
      req.entry?.filter((item: any) => {
        return item.resource.resourceType === whoIs;
      });
    // TODO: need to check typings
    // @ts-ignore
    const roleList = profileList && profileList[0]?.resource[`${whoIsRole}`];
    const roleListArr = roleList?.map((item: any) => {
      const arrItem = item.reference;
      const separatorIndex = arrItem.indexOf('/');
      const resourceType = arrItem.slice(0, separatorIndex);
      const id = arrItem.slice(separatorIndex + 1);
      return {
        resourceType,
        id,
      };
    });

    const roleListAll = (roleListArr || [])
      .map((item: any) => {
        const { resourceType, id } = item;
        return req.entry?.filter((element: any) => {
          return (
            element.resource.id === id &&
            element.resource.resourceType === resourceType
          );
        });
      })
      .flat(1);

    const metaDefaultValue: {
      userOrgListArr: any[];
      userEduGroupArr: any[];
    } = {
      userOrgListArr: [],
      userEduGroupArr: [],
    };

    const { userOrgListArr, userEduGroupArr } = (roleListAll as any[])?.reduce(
      (acc, record) => {
        if (record.resource.organization) {
          acc.userOrgListArr.push(
            splitReference(record.resource.organization.reference),
          );
        }

        if (record.resource.eduGroup) {
          acc.userEduGroupArr.push(
            splitReference(record.resource.eduGroup.reference),
          );
        }

        if (record.resource.eduOrganizationGroup) {
          acc.userEduGroupArr.push(
            splitReference(record.resource.eduOrganizationGroup.reference),
          );
        }

        return acc;
      },
      metaDefaultValue,
    );

    const userOrgListAll = (userOrgListArr || [])
      .map((item: any) => {
        const { resourceType, id } = item;
        return req.entry?.filter((element: any) => {
          return (
            element.resource.id === id &&
            element.resource.resourceType === resourceType
          );
        });
      })
      .flat(1);

    const userEduGroupListAll: Entity<EduGroup>[] = [];
    for (let i = 0; i < userEduGroupArr?.length; i++) {
      const { resourceType, id } = userEduGroupArr[i];
      const entity = req.entry.find((item: any) => {
        return (
          item.resource.id === id && item.resource.resourceType === resourceType
        );
      }) as Entity<EduGroup> | undefined;
      if (entity) {
        userEduGroupListAll.push(entity);
      } else {
        // TODO: temporary logic, because api not send edugroup entry
        try {
          const eduGroup = await eduGroupApi.get(id);
          if (eduGroup) {
            userEduGroupListAll.push({
              // TODO: delete this hack
              fullUrl: `https:///${eduGroup.resourceType}/${eduGroup.id}`,
              resource: eduGroup,
            });
          }
        } catch (err) {
          console.error(err);
        }
      }
    }

    const profileRoles = {
      roleListAll,
      userOrgListAll,
      userEduGroupListAll,
    };

    return profileRoles;
  }
};

export default getUserAllRoleList;

export const splitReference = (reference: string) => {
  const separatorIndex = reference.indexOf('/');
  const resourceType = reference.slice(0, separatorIndex);
  const id = reference.slice(separatorIndex + 1);
  return {
    resourceType,
    id,
  };
};

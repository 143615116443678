import { useEffect, useMemo, useState } from 'react';
import { useCallback } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';

import { NewsStatus, NewsViewType } from '@declarations/news';
import { NewsListPageHeader } from '@pages/NewsListPage/NewsListPageHeader/NewsListPageHeader';
import { useSnackbar } from '@hooks/useSnackbar';
import { newsAPI } from '@api/news';
import { NewsCard } from './NewsItem/NewsCard';
import { RootState } from '@redux/store';
import { useInfiniteQuery } from 'react-query';
import { DEFAULT_PAGINATION } from '@constants/constants';
import { Loader } from '@components/common/Loader/Loader';
import { IS_IMPORTANT_CONTENT } from '@constants/constants';
import { getUser } from '@redux/user';
import { useQueryDelay } from '@hooks/useQueryDelay';

export const NewsListPage = () => {
  const { addSnack } = useSnackbar();
  const [view, setView] = useState<NewsViewType>('card');
  const filterValues = useSelector((state: RootState) => state.filters.values);
  const user = useSelector(getUser);
  const isTargetedSearch = useSelector(
    (state: RootState) => state.filters.isTargetedSearch,
  );
  const { requestAreAllowed } = useQueryDelay();

  const loadData = useCallback(
    async ({ pageParam = DEFAULT_PAGINATION.currentPage }) => {
      const response = await newsAPI.search(
        { currentPage: pageParam, pageSize: DEFAULT_PAGINATION.pageSize },
        {
          status: NewsStatus.PUBLISHED,
          isImportant: IS_IMPORTANT_CONTENT,
          ...filterValues,
        },
        user ? isTargetedSearch : false,
      );
      if (!response) {
        throw Error('Не удалось загрузить данные');
      }
      return response;
    },
    [filterValues, isTargetedSearch, user],
  );

  const {
    data,
    error,
    isSuccess,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['news-list', ...Object.values(filterValues)],
    loadData,
    {
      getNextPageParam: (lastPage, pages) =>
        !lastPage || lastPage.last ? undefined : pages.length + 1,
      enabled: requestAreAllowed,
    },
  );

  const totalCount = useMemo(
    () => data?.pages?.[0]?.totalElements,
    [data?.pages],
  );

  useEffect(() => {
    if (error) {
      addSnack('Ошибка загрузки данных', 'error');
    }
  }, [error, addSnack]);

  return (
    <section className={'news container'}>
      <NewsListPageHeader
        view={view}
        setView={setView}
        totalCount={totalCount}
      />

      {isLoading ? (
        <Box mt={3} width="100%">
          <Loader />
        </Box>
      ) : (
        totalCount === 0 && (
          <Grid
            container
            flexDirection="column"
            sx={{
              maxWidth: '368px',
              mx: 'auto',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Grid item>
              <Typography variant="h4">Мы ничего не нашли</Typography>
            </Grid>
            <Grid item sx={{ mt: 2 }}>
              <Typography
                variant={'additionally' as 'h1'}
                sx={{ color: '#75747B' }}
              >
                Попробуйте проверить написание или изменить значения фильтров
              </Typography>
            </Grid>
          </Grid>
        )
      )}
      {isSuccess && (
        <>
          {view === 'card' && (
            <Grid
              container
              sx={{
                width: '100%',
              }}
              rowSpacing={{
                xs: 5,
                md: 8,
              }}
              columnSpacing={{
                sm: 0,
                md: 2,
              }}
            >
              {data?.pages.map((item) => {
                return item.content.map((newsItem) => (
                  <Grid item key={newsItem.title} xs={12} md={6} lg={4} xl={3}>
                    <NewsCard data={newsItem} view={view} />
                  </Grid>
                ));
              })}
            </Grid>
          )}
          {view === 'list' && (
            <Grid
              container
              sx={{
                width: '100%',
                maxWidth: 894,
              }}
              rowSpacing={2}
            >
              {data?.pages.map((item) => {
                return item.content.map((newsItem) => (
                  <Grid item key={newsItem.title} xs={12}>
                    <NewsCard data={newsItem} view={view} />
                  </Grid>
                ));
              })}
            </Grid>
          )}
          {hasNextPage && (
            <Button
              variant="third"
              fullWidth
              onClick={() => fetchNextPage()}
              sx={{ mt: 8 }}
              disabled={isFetchingNextPage}
            >
              {isFetchingNextPage ? 'Загружаем' : 'Показать еще'}
            </Button>
          )}
        </>
      )}
    </section>
  );
};

import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import dayjs from 'dayjs';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import DateAdapter from '@mui/lab/AdapterDayjs';
import { LocalizationProvider } from '@mui/lab';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/ru';
import { QueryClient, QueryClientProvider } from 'react-query';

import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FontStyles } from './muiStyles/FontStyles';
import store from './redux/store';
import { deleteCookie } from '@lib/cookie';
import { COOKIE_NAMES } from '@constants/config';

dayjs.locale('ru');
dayjs.extend(utc);
dayjs.extend(timezone);

/*
 * Размеры токенов, которые храняться в куках огроммные,
 * и из-за этого бывают проблемы, что некоторые запросы,
 * в которых передаем куки, падают.
 * Поэтому было принятно решение удалять ненужные куки
 *  */
deleteCookie(COOKIE_NAMES.SMART_TV.ACCESS_TOKEN);
deleteCookie(COOKIE_NAMES.SMART_TV.REFRESH_TOKEN);

declare module '@mui/material/Button' {
  // eslint-disable-next-line no-unused-vars
  interface ButtonPropsVariantOverrides {
    first: true;
    second: true;
    third: true;
    fourth: true;
    text: true;
    delete: true;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 375,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  palette: {
    text: {
      primary: '#151B24',
    },
  },
  typography: {
    fontFamily: '"Golos","Roboto","Helvetica","Arial", sans-serif',
    fontWeightRegular: 400,
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h1' },
          style: FontStyles.h1,
        },
        {
          props: { variant: 'h2' },
          style: FontStyles.h2,
        },
        {
          props: { variant: 'h3' },
          style: FontStyles.h3,
        },
        {
          props: { variant: 'h4' },
          style: FontStyles.h4,
        },
        {
          props: { variant: 'h5' },
          style: FontStyles.h5,
        },
        {
          props: { variant: 'h6' },
          style: FontStyles.h6,
        },
        {
          props: { variant: 'body' as 'h1' },
          style: FontStyles.body,
        },
        {
          props: { variant: 'additionally' as 'h1' },
          style: FontStyles.additionally,
        },
        {
          props: { variant: 'caption' },
          style: FontStyles.caption,
        },
        {
          props: { variant: 'title-caption' as 'h1' },
          style: FontStyles.titleCaption,
        },
        {
          props: { variant: 'button' },
          style: FontStyles.button,
        },
      ],
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          lineHeight: 1.35,
          ':hover': {
            backgroundColor: 'transparent',
          },
          ':disabled': {
            backgroundColor: '#F2F4F7',
            color: '#626B7A',
          },
        },
      },
      variants: [
        {
          props: { variant: 'first' },
          style: {
            color: '#FFFFFF',
            backgroundColor: '#007AFF',
            border: '1px solid transparent',
            ':hover': {
              backgroundColor: '#006DE5',
            },
          },
        },
        {
          props: { variant: 'second' },
          style: {
            color: '#007AFF',
            border: '1px solid #007AFF',
            ':hover': {
              color: '#FFFFFF',
              backgroundColor: '#007AFF',
            },
          },
        },
        {
          props: { variant: 'third' },
          style: {
            color: '#262626',
            backgroundColor: '#F3F3F4',
            border: '1px solid transparent',
            ':hover': {
              backgroundColor: '#E5E5EB',
            },
          },
        },
        {
          props: { variant: 'fourth' },
          style: {
            color: '#262626',
            border: '1px solid #D8D8DD',
            ':hover': {
              borderColor: '#75747B',
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            color: '#262626',
            border: '1px solid transparent',
            ':hover': {
              backgroundColor: '#F3F3F4',
            },
          },
        },
        {
          props: { variant: 'delete' },
          style: {
            color: '#FA5332',
            border: '1px solid transparent',
            ':hover': {
              backgroundColor: '#FFEAE6',
            },
          },
        },
        {
          props: { size: 'small' },
          style: {
            padding: '8px 20px',
            fontSize: 14,
            borderRadius: 4,
          },
        },
        {
          props: { size: 'medium' },
          style: {
            padding: '12px 24px',
            fontSize: 15,
            borderRadius: 6,
          },
        },
        {
          props: { size: 'large' },
          style: {
            padding: '17px 32px',
            fontSize: 15,
            borderRadius: 8,
          },
        },
      ],
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
      styleOverrides: {
        root: {
          transition: 'all 0.2s ease',
          ':hover': {
            color: '#0260CC',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl',
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
          minHeight: 0,
        },
        content: {
          marginTop: 0,
          marginBottom: 0,
          paddingTop: 24,
          paddingBottom: 24,
          ['&.Mui-expanded']: {
            marginTop: 0,
            marginBottom: 0,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingTop: 0,
          paddingBottom: 64,
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderBottom: '1px solid #E8E8ED',
          ['&.Mui-expanded']: {
            marginTop: 0,
            marginBottom: 0,
          },
          [':before']: {
            content: 'none',
          },
        },
      },
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <Provider store={store}>
            <SnackbarProvider
              maxSnack={5}
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
            >
              <QueryClientProvider client={queryClient}>
                <CssBaseline />
                <App />
              </QueryClientProvider>
            </SnackbarProvider>
          </Provider>
        </LocalizationProvider>
      </BrowserRouter>
    </ThemeProvider>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

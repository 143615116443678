import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Box, Grid, Skeleton } from '@mui/material';
import { MultiValue } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

import { Toggles } from '@components/common/Toggles/Toggles';
import { ButtonGroupToggle } from '@components/common/ButtonGroupToggle/ButtonGroupToggle';
import OrganizationSelect from '@components/common/Select/Organization';
import { organizationApi } from '@api/organization';
import { Organization } from '@declarations/organization';
import { RootState } from '@redux/store';
import { fetchFilterOptions } from '@redux/filters';
import RegionSelect from '@components/common/Select/Region';
import { Option } from '@components/common/Select/types';
import EduOrganizationGroupSelect from '@components/common/Select/EduOrganizationGroup';
import { EduOrganizationGroup } from '@declarations/eduOrganizationGroup';
import { eduOrganizationGroupApi } from '@api/eduOrganizationGroup';
import MultiSelect from '@components/common/Select/MultiSelect';
import { DatePicker } from '@components/common/DatePicker/DatePicker';
import dayjs from 'dayjs';
import { StructureRole } from '@declarations/enum/structureRole';
import { TargetInfo } from '@declarations/user';
import { ContentAccesses, UserLocation } from '@redux/user/types';
import uniq from 'lodash/uniq';
import { useTargetingForOOGroupAdmin } from '@pages/Manager/CommonForms/hooks/useTargetingForOOGroupAdmin';
import { AdminRole } from '@declarations/role';
import { AUDIENCE_ID } from '@declarations/enum/audience';
import { EduOrganizationGroupType } from '@declarations/enum/eduOrganizationGroup';
import { useCheckForAdminStructuredRole } from '@hooks/useCheckForAdminStructuredRole';

export const SETTINGS_FORM_DEFAULT_VALUE: SettingsFormType = {
  audience: [],
  educationLevel: [],
  region: [],
  oo: [],
  ooGroup: [],
  parallel: [],
  forWhom: 'all',
  period: [new Date(), new Date()],
};

export const SETTINGS_FORM_DEFAULT_VALUE_FOR_FAQ: SettingsFormType = {
  audience: [],
  educationLevel: [],
  region: [],
  oo: [],
  ooGroup: [],
  parallel: [],
  forWhom: 'all',
  period: [new Date(), undefined],
};

type GENDERS = 'all' | 'FEMALE' | 'MALE';

export type SettingsFormType = {
  audience: (string | number)[];
  educationLevel: (number | string)[];
  region: Option[];
  oo: string[];
  ooGroup: string[];
  parallel: (string | number)[];
  forWhom: GENDERS;
  period: [Date | undefined, Date | undefined];
};

type Props = {
  value: SettingsFormType;
  onChange: (value: SettingsFormType) => void;
  forFaq?: boolean;
  funcRoleId?: string;
};

const forWhomItems = [
  {
    label: 'для всех',
    value: 'all',
  },
  {
    label: 'мужчинам',
    value: 'MALE',
  },
  {
    label: 'женщинам',
    value: 'FEMALE',
  },
];

const actualPeriodItems = [
  {
    label: 'актуально всегда',
    value: '',
  },
  {
    label: 'указать месяцы',
    value: 'PERIOD',
  },
];

export const SettingsForm = ({
  value,
  onChange,
  forFaq,
  funcRoleId,
}: Props) => {
  const audienceOptions = useSelector(
    (state: RootState) => state.filters.options.audience,
  );
  const regionOptions = useSelector(
    (state: RootState) => state.filters.options.regions,
  );
  const parallelOptions = useSelector(
    (state: RootState) => state.filters.options.parallels,
  );
  const eduLevelOptions = useSelector(
    (state: RootState) => state.filters.options.eduLevels,
  );

  const contentAccesses = useSelector(
    (state: RootState) => state.user.contentAccesses,
  );

  const targetInfo = useSelector((state: RootState) => state.user.targetInfo);
  const locations = useSelector((state: RootState) => state.user.locations);

  const dispatch = useDispatch();

  const [disableAdminFields, setDisableAdminFields] = useState(false);

  const [selectedGroups, setSelectedGroups] = useState<{
    [id: string]: EduOrganizationGroup;
  }>({});
  const [selectedOrganizations, setSelectedOrganizations] = useState<{
    [id: string]: Organization;
  }>({});

  const {
    isOOGroupsAdmin,
    allowedOrganizationsIdsForAdminGroup,
    allowedOrganizationsForAdminGroup,
    disableAdminGroupsFields,
    allowedRegionsForAdminGroup,
    allowedOrganizationGroupsForAdminGroup,
    checkSelectedGroup,
    isVisibleItem,
  } = useTargetingForOOGroupAdmin({
    setSelectedGroups,
    value,
    onChange,
    selectedGroups,
  });

  const { isRole: isOOAdmin } = useCheckForAdminStructuredRole(
    StructureRole.OO_ADMINISTRATOR,
  );

  const {
    availableOrganizations,
    availableOrganizationGroupsKeys,
    availableOrganizationGroups,
    isChangeRegionEnabled = true,
    availableSubjects,
  } = useMemo(
    () =>
      getAvailableEntityIds({
        targetInfo,
        locations,
        accesses: contentAccesses,
        funcRoleId,
        allowedOrganizationsIdsForAdminGroup,
        allowedRegionsForAdminGroup,
      }),
    [
      locations,
      targetInfo,
      contentAccesses,
      funcRoleId,
      allowedOrganizationsIdsForAdminGroup,
      allowedRegionsForAdminGroup,
    ],
  );

  useEffect(() => {
    if (!!isOOAdmin && availableOrganizationGroups?.length === 1) {
      const eduGroup = availableOrganizationGroups[0];

      if (eduGroup && !!eduGroup.id) {
        onChange({ ...value, ooGroup: [eduGroup.id] });
        setSelectedGroups({ [eduGroup.id]: eduGroup });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOOAdmin, availableOrganizationGroups]);

  const organizationFilters = useMemo(
    () => ({
      regionCode: value.region.map((data) => +data.value),
    }),
    [value],
  );

  const eduOrganizationGroupFilters = useMemo(
    () => ({
      organizations: !isOOAdmin ? value.oo || [] : [],
    }),
    [isOOAdmin, value.oo],
  );

  const isAlwaysActual = useMemo(() => {
    return !value.period[1];
  }, [value.period]);

  useLayoutEffect(() => {
    if (
      regionOptions.length === 0 ||
      eduLevelOptions.length === 0 ||
      audienceOptions.length === 0 ||
      parallelOptions.length === 0
    ) {
      dispatch(fetchFilterOptions());
    }
  }, [
    dispatch,
    regionOptions.length,
    eduLevelOptions.length,
    audienceOptions.length,
    parallelOptions.length,
  ]);

  useEffect(() => {
    if (isOOGroupsAdmin) return;

    const loadOrganizations = async (orgIds: string[]) => {
      try {
        const { newIds, existsOrganizations } = orgIds?.reduce(
          (acc, id) => {
            if (selectedOrganizations[id]) {
              acc.existsOrganizations[id] = selectedOrganizations[id];
            } else {
              acc.newIds.push(id);
            }
            return acc;
          },
          { newIds: [], existsOrganizations: {} } as {
            newIds: string[];
            existsOrganizations: { [id: string]: Organization };
          },
        );
        const newOrganizationsResponse = await Promise.allSettled(
          newIds.map((orgId) => organizationApi.get(orgId)),
        );
        const organizations: { [id: string]: Organization } = {
          ...existsOrganizations,
        };
        newOrganizationsResponse.map((response) => {
          if (response.status === 'fulfilled' && response.value.id) {
            organizations[response.value.id] = response.value;
          }
        });
        // Если есть новые организации, только в этом случае обновляем state
        if (newIds.length) {
          setSelectedOrganizations(organizations);
        }
      } catch (err) {
        console.error(err);
      }
    };
    if (value.oo.length) {
      loadOrganizations(value.oo);
    }
  }, [value.oo, selectedOrganizations, isOOGroupsAdmin]);

  useEffect(() => {
    if (isOOGroupsAdmin) return;

    const loadEduOrganizationGroup = async (groupIds: string[]) => {
      try {
        const { newIds, existsGroups } = groupIds?.reduce(
          (acc, id) => {
            if (selectedGroups[id]) {
              acc.existsGroups[id] = selectedGroups[id];
            } else {
              acc.newIds.push(id);
            }
            return acc;
          },
          { newIds: [], existsGroups: {} } as {
            newIds: string[];
            existsGroups: { [id: string]: EduOrganizationGroup };
          },
        );
        const newGroupsResponse = await Promise.allSettled(
          newIds.map((orgId) => eduOrganizationGroupApi.get(orgId)),
        );
        const groups: { [id: string]: EduOrganizationGroup } = {
          ...existsGroups,
        };
        newGroupsResponse.map((response) => {
          if (response.status === 'fulfilled' && response.value.id) {
            groups[response.value.id] = response.value;
          }
        });
        // Если есть новые организации, только в этом случае обновляем state
        if (newIds.length) {
          setSelectedGroups(groups);
        }
      } catch (err) {
        console.error(err);
      }
    };
    if (value.ooGroup.length) {
      loadEduOrganizationGroup(value.ooGroup);
    }
  }, [value.ooGroup, selectedGroups, isOOGroupsAdmin]);

  useEffect(() => {
    const orgInfo = findAdminOrgInfo(targetInfo);

    if (orgInfo) {
      const { orgId, region } = orgInfo;
      if (region) {
        onChange({
          ...value,
          region: [{ label: region.display, value: region.code }],
          oo: [orgId],
        });
        setDisableAdminFields(true);
      }
    }
    // eslint-disable-next-line
  }, [targetInfo]);

  // useEffect(() => {
  //   const loadEduGroup = async (eduGroupId: string) => {
  //     try {
  //       const eduGroup = await eduGroupApi.get(eduGroupId);
  //       setGroup({
  //         fullUrl: `https:///${eduGroup.resourceType}/${eduGroup.id}`,
  //         resource: eduGroup,
  //       });
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   };
  //   if (value.ooGroup && group?.resource.id != value.ooGroup) {
  //     loadEduGroup(value.ooGroup);
  //   }
  // }, [value.ooGroup, group]);

  const handleChangeAudience = (item: Option, selected: boolean) => {
    const changedValues =
      item.value === AUDIENCE_ID.OO_ADMIN ||
      item.value === AUDIENCE_ID.OO_GROUPS_ADMIN
        ? [AUDIENCE_ID.OO_ADMIN, AUDIENCE_ID.OO_GROUPS_ADMIN]
        : [item.value];

    const audience = selected
      ? value.audience.filter(
          (val) => !changedValues.some((changedValue) => changedValue === val),
        )
      : [...value.audience, ...changedValues];

    const parallel = audience.some((item) =>
      [AUDIENCE_ID.STUDENT, AUDIENCE_ID.LEGAL_REPRESENTATIVE].includes(+item),
    )
      ? value.parallel
      : [];

    onChange({ ...value, audience, parallel });
  };

  return (
    <Grid container>
      <Grid item xs={3}>
        Аудитория
      </Grid>
      <Grid item xs={9}>
        <Box
          sx={{
            mb: 2,
          }}
        >
          <Toggles
            value={value.audience}
            items={audienceOptions}
            customHandleClick={handleChangeAudience}
          />
        </Box>
      </Grid>
      <Grid item xs={3}>
        Уровень общего образования
      </Grid>
      <Grid item xs={9}>
        <Box
          sx={{
            mb: 2,
          }}
        >
          <Toggles
            value={value.educationLevel}
            items={eduLevelOptions}
            onChange={(educationLevel) =>
              onChange({ ...value, educationLevel })
            }
          />
        </Box>
      </Grid>
      <Grid item xs={3}>
        Субъект РФ
        {(!!isOOGroupsAdmin || !!isOOAdmin) && (
          <span className="mandatory-field">*</span>
        )}
      </Grid>
      <Grid item xs={9}>
        <Box
          sx={{
            mb: 2,
          }}
        >
          {isVisibleItem ? (
            <RegionSelect
              isMulti
              isDisabled={
                !isChangeRegionEnabled ||
                disableAdminFields ||
                disableAdminGroupsFields.region
              }
              allowIds={availableSubjects}
              id="subjects"
              placeholder="Выбрать"
              name="Субъект РФ"
              value={value.region?.map((option) => +option.value)}
              onChange={(optionList: MultiValue<Option>) => {
                onChange({
                  ...value,
                  region: optionList.map((option) => option),
                  oo: isOOGroupsAdmin ? value.oo : [],
                  ooGroup: isOOGroupsAdmin ? value.ooGroup : [],
                });
                setSelectedOrganizations({});
              }}
            />
          ) : (
            <Skeleton variant="rectangular" width={'100%'} height={44} />
          )}
        </Box>
      </Grid>
      <Grid item xs={3}>
        ОО
        {!!isOOAdmin && !isOOGroupsAdmin && (
          <span className="mandatory-field">*</span>
        )}
      </Grid>
      <Grid item xs={9}>
        <Box
          sx={{
            mb: 2,
          }}
        >
          {isVisibleItem ? (
            <OrganizationSelect
              isMulti
              name="organization"
              id="organization"
              placeholder="OO"
              isDisabled={
                (!isOOGroupsAdmin && value.region.length === 0) ||
                disableAdminFields ||
                disableAdminGroupsFields.org
              }
              allowedIds={availableOrganizations}
              hardInstalledOrganizations={
                isOOGroupsAdmin
                  ? allowedOrganizationsForAdminGroup ?? []
                  : undefined
              }
              onChange={(organizationIds) => {
                if (Array.isArray(organizationIds)) {
                  onChange({
                    ...value,
                    oo: organizationIds,
                  });
                } else {
                  onChange({ ...value, oo: [organizationIds] });
                }
              }}
              filters={organizationFilters}
              value={value.oo}
            />
          ) : (
            <Skeleton variant="rectangular" width={'100%'} height={44} />
          )}
        </Box>
      </Grid>
      <Grid item xs={3}>
        Группа ОО
        {(!!isOOGroupsAdmin || !!isOOAdmin) && (
          <span className="mandatory-field">*</span>
        )}
      </Grid>
      <Grid item xs={9}>
        <Box
          sx={{
            mb: 2,
          }}
        >
          {isVisibleItem ? (
            <EduOrganizationGroupSelect
              id={'eduOrganizationGroup'}
              isMulti
              name="eduGroup"
              placeholder="Группа ОО"
              filters={eduOrganizationGroupFilters}
              selected={Object.values(selectedGroups)}
              allowedIds={availableOrganizationGroupsKeys}
              isDisabled={
                disableAdminGroupsFields.group ||
                (!!isOOAdmin && availableOrganizationGroups?.length === 1)
              }
              hardInstalledGroups={
                isOOGroupsAdmin
                  ? Object.values(allowedOrganizationGroupsForAdminGroup)
                  : isOOAdmin
                  ? availableOrganizationGroups
                  : undefined
              }
              onChange={(eduOrganizationGroups) => {
                if (Array.isArray(eduOrganizationGroups)) {
                  onChange({
                    ...value,
                    ooGroup: eduOrganizationGroups.map((org) => org.id || '0'),
                  });
                  const hashTable = eduOrganizationGroups?.reduce(
                    (hashTable, eduOrganizationGroup) => {
                      if (eduOrganizationGroup.id) {
                        hashTable[eduOrganizationGroup.id] =
                          eduOrganizationGroup;
                      }
                      return hashTable;
                    },
                    {} as { [id: string]: EduOrganizationGroup },
                  );

                  if (isOOGroupsAdmin) {
                    checkSelectedGroup(hashTable);
                  }

                  setSelectedGroups(hashTable);
                } else {
                  eduOrganizationGroups.id &&
                    onChange({ ...value, ooGroup: [eduOrganizationGroups.id] });

                  setSelectedGroups(
                    eduOrganizationGroups.id
                      ? {
                          [eduOrganizationGroups.id]: eduOrganizationGroups,
                        }
                      : {},
                  );
                }
              }}
            />
          ) : (
            <Skeleton variant="rectangular" width={'100%'} height={44} />
          )}
        </Box>
      </Grid>
      {value.audience.includes(AUDIENCE_ID.STUDENT) ? (
        <>
          <Grid item xs={3}>
            Параллель
          </Grid>
          <Grid item xs={9}>
            <Box
              sx={{
                mb: 2,
              }}
            >
              <MultiSelect
                options={parallelOptions}
                id="parallel"
                placeholder="Выбрать"
                name="Параллель"
                isDisabled={
                  value.audience.length === 0 ||
                  value.audience.every(
                    (item) =>
                      ![
                        AUDIENCE_ID.STUDENT,
                        AUDIENCE_ID.LEGAL_REPRESENTATIVE,
                      ].includes(+item),
                  )
                }
                value={parallelOptions?.filter((item) =>
                  value.parallel.includes(item.value),
                )}
                onChange={(items) =>
                  onChange({
                    ...value,
                    parallel: items.map(({ value }) => value),
                  })
                }
              />
            </Box>
          </Grid>
          <Grid item xs={3}>
            Для кого
          </Grid>
          <Grid item xs={9}>
            <Box
              sx={{
                mb: 2,
              }}
            >
              <ButtonGroupToggle
                value={value.forWhom}
                items={forWhomItems}
                onChange={(forWhom) =>
                  onChange({ ...value, forWhom: forWhom as GENDERS })
                }
              />
            </Box>
          </Grid>
        </>
      ) : null}
      {forFaq ? (
        <>
          <Grid item xs={3}>
            Период актуальности
          </Grid>
          <Grid item xs={9} mb={2}>
            <Box>
              <ButtonGroupToggle
                value={!isAlwaysActual ? 'PERIOD' : ''}
                items={actualPeriodItems}
                onChange={(period) => {
                  onChange({
                    ...value,
                    period: [
                      value.period[0],
                      period ? dayjs().add(1, 'year').toDate() : undefined,
                    ],
                  });
                }}
              />
            </Box>
            {!isAlwaysActual && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                mt={1}
              >
                {/*<DatePicker*/}
                {/*  value={value.period[0]}*/}
                {/*  onChange={(date) =>*/}
                {/*    onChange({*/}
                {/*      ...value,*/}
                {/*      period: [date || undefined, value.period[1]],*/}
                {/*    })*/}
                {/*  }*/}
                {/*/>*/}
                {/*&nbsp; &#8212; &nbsp;*/}
                <DatePicker
                  value={value.period[1]}
                  onChange={(date) =>
                    onChange({
                      ...value,
                      period: [value.period[0], date || undefined],
                    })
                  }
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={3} mb={2}>
            Время и дата публикации
          </Grid>
          <Grid item xs={9} mb={2}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <DatePicker
                withTime
                value={value.period[0]}
                onChange={(date) =>
                  onChange({
                    ...value,
                    period: [date || undefined, value.period[1] || undefined],
                  })
                }
              />
            </Box>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={3} mb={2}>
            Период публикации
          </Grid>
          <Grid item xs={9} mb={2}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <DatePicker
                withTime
                value={value.period[0]}
                onChange={(date) =>
                  onChange({
                    ...value,
                    period: [date || undefined, value.period[1]],
                  })
                }
              />
              &nbsp; &#8212; &nbsp;
              <DatePicker
                withTime
                value={value.period[1]}
                onChange={(date) =>
                  onChange({
                    ...value,
                    period: [value.period[0], date || undefined],
                  })
                }
              />
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};

/**
 * Получение данных на основе таргетирования
 * @param targetInfo - информация по таргетированию
 * @param locations - список доступных субъектов РФ
 * @returns объект с доступными значениями для выбора ОО и групп ОО
 */
function getAvailableEntityIds(data: {
  targetInfo: TargetInfo | null;
  locations: UserLocation[] | null;
  accesses: ContentAccesses | null;
  funcRoleId?: string;
  allowedOrganizationsIdsForAdminGroup: string[] | null;
  allowedRegionsForAdminGroup: number[] | null;
}) {
  const {
    targetInfo,
    locations,
    accesses,
    funcRoleId,
    allowedOrganizationsIdsForAdminGroup,
    allowedRegionsForAdminGroup,
  } = data;

  const result: {
    availableOrganizations?: string[];
    availableOrganizationGroupsKeys?: string[];
    availableOrganizationGroups?: EduOrganizationGroup[];
    isChangeRegionEnabled?: boolean;
    availableSubjects?: number[];
  } = {};

  // Если структурная роль "Админ групп ОО"
  if (
    targetInfo &&
    targetInfo.adminRoles[StructureRole.OO_GROUP_ADMINISTRATOR]
  ) {
    if (allowedOrganizationsIdsForAdminGroup) {
      result.availableOrganizations = uniq([
        ...(result.availableOrganizations ?? []),
        ...allowedOrganizationsIdsForAdminGroup,
      ]);
    }

    result.availableOrganizations = result.availableOrganizations?.length
      ? result.availableOrganizations
      : undefined;

    if (allowedRegionsForAdminGroup) {
      result.availableSubjects = uniq([
        ...(result.availableSubjects ?? []),
        ...allowedRegionsForAdminGroup,
      ]);
    }

    result.availableSubjects = result.availableSubjects?.length
      ? result.availableSubjects
      : undefined;

    return result;
  }

  // Если структурная роль "Администратор ЕТД", то ограничений не навешиваем
  if (
    targetInfo &&
    !targetInfo.adminRoles[StructureRole.ETD_ADMINISTRATOR] &&
    ((accesses && funcRoleId && accesses[funcRoleId] === 'onlyOwnArea') ||
      targetInfo.adminRoles[StructureRole.OO_ADMINISTRATOR])
  ) {
    result.availableOrganizations = Object.values(targetInfo.organizations).map(
      (org) => org.id || '',
    );

    if (locations?.length) {
      result.availableSubjects = Object.values(locations).map(
        (subject) => +subject.code,
      );
    }

    result.availableOrganizationGroups = Object.values(
      targetInfo.eduOrganizationGroups,
    ).filter((eduOrgGroup) =>
      targetInfo.adminRoles[StructureRole.OO_ADMINISTRATOR]
        ? eduOrgGroup.type === EduOrganizationGroupType.GROUP
        : true,
    );

    result.availableOrganizationGroupsKeys =
      result.availableOrganizationGroups?.map((group) => group.id || '');

    if (
      !targetInfo.adminRoles[StructureRole.OO_ADMINISTRATOR] &&
      !targetInfo.adminRoles[StructureRole.EXTERNAL_IS_ADMINISTRATOR]
    ) {
      result.isChangeRegionEnabled = true;
    }
  }

  return result;
}

const findAdminOrgInfo = (targetInfo: TargetInfo | null) => {
  if (!targetInfo) {
    return null;
  }

  if (Array.isArray(targetInfo.adminRoles[StructureRole.OO_ADMINISTRATOR]))
    return;

  const eduGroupId = (
    targetInfo.adminRoles[StructureRole.OO_ADMINISTRATOR] as AdminRole
  )?.eduOrganizationGroup?.reference.split('/')[1];
  if (!eduGroupId) {
    return null;
  }
  const orgId =
    targetInfo.eduOrganizationGroups[eduGroupId]?.organization?.reference.split(
      '/',
    )[1];
  if (!orgId) {
    return null;
  }
  const org = targetInfo.organizations[orgId];
  const address = org.address?.find((item) => !!item.state?.code);
  const region = address?.state;
  return { orgId, region };
};

import Button from '@components/common/Button/Button';

type TogglesProps = {
  value: string;
  items: {
    label: string;
    value: string;
  }[];
  onChange: (value: string) => void;
};

export const ButtonGroupToggle = ({ items, value, onChange }: TogglesProps) => {
  return (
    <div className="btn-group-toggle">
      {items.map((item) => (
        <Button
          className="btn-group-toggle__item"
          key={item.value}
          size="small"
          variant={value === item.value ? 'first' : 'fourth'}
          sx={value !== item.value ? { backgroundColor: '#fff' } : undefined}
          onClick={() => onChange(item.value)}
        >
          {item.label}
        </Button>
      ))}
    </div>
  );
};

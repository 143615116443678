import { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';

import { Header } from '@components/Header/Header';
import { LinksPage } from '@pages/Links/Links';
import { QuizListPage } from '@pages/Quiz/QuizList';
import { QuizDetailPage } from '@pages/Quiz/QuizDetail';
import { SearchPage } from '@pages/Search/Search';
import Footer from '@components/Footer/Footer';
import FaqListPageContainer from '@pages/FaqListPage/FaqListPageContainer';
import FaqItemPageContainer from '@pages/FaqItemPage/FaqItemPageContainer';
import { NewsDetailPage } from '@pages/NewsDetailPage/NewsDetailPage';
import { NewsListPage } from '@pages/NewsListPage/NewsListPage';
import { authApi } from '@api/api';
import { ResponsiveContainer } from '@components/common/ResponsiveContainer/ResponsiveContainer';
import { MainPage } from '@pages/MainPage/MainPage';
import { parseJwt } from '@lib/parseJwt/parseJwt';
import { fetchUserRole, setUser } from '@redux/user';
import { SmartTVMain } from '@pages/SmartTVPages/SmartTVMain';
import { SmartSection } from '@pages/SmartTVPages/SmartSection/SmartSection';
import { NotificationsPage } from '@pages/NotifactionsPage/NotificationsPage';
import NotificationSubscribePage from '@pages/NotificationSubscribePage/NotificationSubscribePage';
import { ManagerContainer } from '@pages/Manager/ManagerContainer';
import ProfileContainer from '@components/Profile/ProfileContainer';
import NotificationDetailPage from '@pages/NotificationDetailPage/NotificationDetailPage';
import { getCookie, setCookie } from '@lib/cookie';
import { PrivateRoute } from '@components/common/PrivateRoute/PrivateRoute';
import LinkDetail from '@pages/Links/LinkDetail';
import { COOKIE_NAMES, MAIN_DOMAIN } from '@constants/config';
import { NotFound } from '@pages/NotFound/NotFound';
import { ROUTES } from './constants';
import { QuizResult } from '@pages/Quiz/QuizResult';
import SmartEducation from '@pages/SmartTVPages/SmartTVMyEducation/SmartEducation';
import { useSnackbar } from '@hooks/useSnackbar';
// import { FeedbackForm } from '@pages/FeedbackForm';
import { SmartTVLibrary } from '@pages/SmartTVLibrary/SmartTVLibrary';
import { fetchRegionFilterOptions } from '@redux/filters';

function App() {
  const queryParams = queryString.parse(location.search);
  const { addSnack } = useSnackbar();

  const dispatch = useDispatch();

  useEffect(() => {
    if (queryParams.code && !getCookie(COOKIE_NAMES.ACCESS_TOKEN)) {
      authApi.getToken(queryParams.code).then(
        (res) => {
          const data: any = res.data;
          const accessToken = data.access_token;
          setCookie(
            COOKIE_NAMES.ACCESS_TOKEN,
            data.access_token,
            null,
            MAIN_DOMAIN,
          );
          setCookie(
            COOKIE_NAMES.REFRESH_TOKEN,
            data.refresh_token,
            null,
            MAIN_DOMAIN,
          );
          const decodedToken = parseJwt(accessToken);
          dispatch(setUser(decodedToken));
          dispatch(fetchUserRole());
        },
        (err) => {
          console.error(err);
        },
      );
    }
  }, [queryParams.code, dispatch]);

  useEffect(() => {
    const exp = localStorage.getItem('refreshTokenStatus') || '';
    if (exp) {
      addSnack(
        'Ваша авторизационная сессия истекла. Для продолжения работы требуется повторная авторизация',
        'error',
      );
      localStorage.removeItem('refreshTokenStatus');
    }
  }, [addSnack]);

  // for TV-devices
  useEffect(() => {
    const token = getCookie(COOKIE_NAMES.SMART_TV.ACCESS_TOKEN);
    if (token) {
      const decodedToken = parseJwt(token);
      dispatch(setUser(decodedToken));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUserRole());
    dispatch(fetchRegionFilterOptions());
  }, [dispatch]);

  const browser = () => (
    <>
      <Header />
      <ResponsiveContainer
        sx={{
          flex: '2 0 auto',
        }}
      >
        <Switch>
          <Route exact path="/" component={MainPage} />
          <Route exact path={ROUTES.newsList} component={NewsListPage} />
          <Route path={ROUTES.newsItem} component={NewsDetailPage} />
          <Route exact path={ROUTES.faqList} component={FaqListPageContainer} />
          <Route path={ROUTES.faqItem} component={FaqItemPageContainer} />
          <Route exact path={ROUTES.linkList} component={LinksPage} />
          <Route path={ROUTES.linkItem} component={LinkDetail} />
          <Route exact path={ROUTES.quizList} component={QuizListPage} />
          <Redirect from={ROUTES.feedback} to={ROUTES.notFound} />
          <PrivateRoute
            exact
            path={ROUTES.quizItem}
            component={QuizDetailPage}
          />
          <Route exact path={ROUTES.quizResult} component={QuizResult} />
          <Route path={ROUTES.profile} component={ProfileContainer} />
          <Route path={ROUTES.search} component={SearchPage} />
          <PrivateRoute
            exact
            path={ROUTES.notificationList}
            component={NotificationsPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.notificationItem}
            component={NotificationDetailPage}
          />
          <Route
            path={ROUTES.notificationSubscribe}
            component={NotificationSubscribePage}
          />
          <Route path={ROUTES.notFound} component={NotFound} />
        </Switch>
      </ResponsiveContainer>
      <Footer />
    </>
  );
  ``;

  return (
    <>
      <Switch>
        <Route exact path="/smart" component={SmartTVMain} />
        <Route exact path={ROUTES.smartEducation} component={SmartEducation} />
        <Route exact path={ROUTES.smartLib} component={SmartTVLibrary} />
        <Route exact path={ROUTES.smartSection} component={SmartSection} />
        <Route exact path={ROUTES.quizDemo} component={QuizDetailPage} />
        <Route
          exact
          path={ROUTES.quizPreview}
          component={() => <QuizDetailPage isPreview />}
        />
        <PrivateRoute path="/manager" component={ManagerContainer} />
        <Route path="/" component={browser} />
      </Switch>
    </>
  );
}

export default App;

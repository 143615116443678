import * as React from 'react';
import {
  CSSProperties,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, Grid, Menu, MenuItem, Typography } from '@mui/material';
import Button from '@components/common/Button/Button';
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';
import {
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
} from '@mui/x-data-grid';

import CommonFilter from '@pages/Manager/CommonFilter/CommonFilter';
import { ToggleGroup } from '@components/common/StyledToggleButtonGroup/StyledToggleButtonGroup';
import linksApi from '@api/links';
import { Link, LinkStatus } from '@declarations/links';
import { FileDto, Filter } from '@declarations/common';
import { IMAGE_BASE_URL } from '@constants/config';
import { useHistory } from 'react-router';
import { useSnackbar } from '@hooks/useSnackbar';
import { setPublishDate } from '@lib/date/date';
import Table from '@components/common/Table/Table';
import { generateLink, getNewItemName } from '@lib/common';
import { ROUTES } from '@constants/routes';

const columns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Заголовок',
    flex: 2,
    minWidth: 300,
    renderCell: (params: GridRenderCellParams<Link>) => {
      let imageStyles: CSSProperties | undefined = undefined;
      const image = params?.row?.files?.find(
        (file: FileDto) => file.id === params.row.img,
      );
      if (image) {
        imageStyles = {
          backgroundImage: `url(${IMAGE_BASE_URL}${image?.path})`,
          backgroundColor: 'inherit',
        };
      }

      return (
        <div className="news-list__title">
          <Box sx={{ mr: 2 }}>
            <div className="news-list__title-object" style={imageStyles} />
          </Box>
          <div>{params.row.title}</div>
        </div>
      );
    },
  },
  { field: 'target', headerName: 'Аудитория', flex: 1, minWidth: 200 },
  {
    field: 'date',
    headerName: 'Дата публикации',
    flex: 1,
    renderCell: (params: GridRenderCellParams<Link>) => {
      const status = params.row.status;
      const date = params.row.date && setPublishDate(params.row.date);
      return status === LinkStatus.PUBLISHED ? (
        date
      ) : (
        <div className="news-list__status-label">
          {status === LinkStatus.DRAFT
            ? 'черновик'
            : `архив${date ? `, ${date}` : ''}`}
        </div>
      );
    },
  },
  {
    field: 'region',
    headerName: 'Субъект РФ',
    flex: 1,
  },
  {
    field: 'eduLevels',
    headerName: 'Уровень образования',
  },
  {
    field: 'eduOrganizations',
    headerName: 'ОО',
  },
  {
    field: 'eduGroupOrganizations',
    headerName: 'Группы ОО',
  },
];

const PER_PAGE_OPTIONS = [20, 40];

const LinksList: React.FC = () => {
  const [linkList, setLinkList] = useState<{ total: number; data: Link[] }>({
    total: 0,
    data: [],
  });
  const [pagination, setPagination] = useState({
    pageSize: PER_PAGE_OPTIONS[0],
    currentPage: 1,
  });
  const [filters, setFilters] = useState<Partial<Filter>>({
    // Намеренно сделано, т.к. в FAQ period = undefined
    period: [undefined, undefined],
  });
  const [loading, setLoading] = useState(false);
  const [selectedLinkItem, setSelectedLinkItem] = useState<Link[]>([]);

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const history = useHistory();
  const { addSnack } = useSnackbar();

  const rows = useMemo(
    () =>
      linkList.data.map((item) => {
        return {
          ...item,
          date: item.startDate,
          region: item.regions?.map((region) => region.name).join(', '),
          target: item.targetAudience?.map((target) => target.name).join(','),
          eduLevels: item.eduLevels?.map((level) => level.name).join(', '),
          // TODO-achernyavets, поправить когда с бэка будут приходить данные
          eduOrganizations: '',
          // TODO-achernyavets, поправить когда с бэка будут приходить данные
          eduGroupOrganizations: '',
        };
      }),
    [linkList.data],
  );

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const links = await linksApi.searchLinks(pagination, filters);
      setLinkList({
        total: links?.totalElements || 0,
        data: links?.content || [],
      });
    } catch (err) {
      console.log(err);
      addSnack('Ошибка загрузки данных', 'error');
    } finally {
      setLoading(false);
    }
  }, [addSnack, filters, pagination]);

  const handlePageChange = useCallback((page: number) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: page + 1,
    }));
  }, []);

  const handlePageSizeChange = useCallback((pageSize: number) => {
    setPagination((prev) => ({
      ...prev,
      pageSize,
    }));
  }, []);

  const handleContextMenu = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX - 2,
              mouseY: event.clientY - 4,
            }
          : null,
      );
    },
    [contextMenu],
  );

  const onContextMenu = useCallback(
    (e: any): void => {
      if (e.type === 'contextmenu') {
        e.preventDefault();
        const closestParent = e?.target?.closest('div[data-id]');
        if (closestParent && selectedLinkItem.length === 0) {
          const id = closestParent.dataset?.id;
          if (id) {
            const linkItem = linkList.data.find((item) => item.id == id);
            if (linkItem) {
              setSelectedLinkItem([linkItem]);
            }
          }
        }
        handleContextMenu(e);
      }
    },
    [selectedLinkItem.length, handleContextMenu, linkList.data],
  );

  const handleClose = useCallback(() => {
    setSelectedLinkItem([]);
    setContextMenu(null);
  }, []);

  const handleView = useCallback(() => {
    if (selectedLinkItem.length) {
      selectedLinkItem.forEach((item) => {
        if (item.id) {
          const link = generateLink(ROUTES.linkItem, { id: item.id });
          window.open(link);
        }
      });
    }
    handleClose();
  }, [handleClose, selectedLinkItem]);

  const handleUnpublish = useCallback(async () => {
    if (selectedLinkItem.length) {
      try {
        const response = await Promise.allSettled(
          selectedLinkItem.map((linkItem) =>
            linksApi.changeLinkStatus(linkItem.id || 0, LinkStatus.DRAFT),
          ),
        );
        if (response.some(({ status }) => status === 'rejected')) {
          if (response.every(({ status }) => status === 'rejected')) {
            throw new Error('Some link not updated');
          }
          addSnack('Выбранные ссылки сняты с публикации частично', 'error');
        } else {
          const message =
            selectedLinkItem.length > 1
              ? 'Ссылки сняты с публикации'
              : 'Ссылка снята с публикации';
          addSnack(message, 'success');
        }
        await loadData();
      } catch (err) {
        console.error(err);
        const message =
          selectedLinkItem.length > 1
            ? 'Не удалось снять ссылки с публикации'
            : 'Не удалось снять ссылку с публикации';
        addSnack(message, 'error');
      }
      handleClose();
    }
  }, [selectedLinkItem, handleClose, loadData, addSnack]);

  const handleRemoveLink = useCallback(async () => {
    if (selectedLinkItem.length) {
      try {
        await linksApi.removeLink(
          selectedLinkItem.map((linkItem) => linkItem.id || 0),
        );

        const message =
          selectedLinkItem.length > 1 ? 'Ссылки удалены' : 'Ссылка удалена';
        addSnack(message, 'success');
        await loadData();
      } catch (err) {
        console.error(err);
        const message =
          selectedLinkItem.length > 1
            ? 'Ошибка удаления ссылок'
            : 'Ошибка удаления ссылки';
        addSnack(message, 'error');
      }
    }
    handleClose();
  }, [addSnack, handleClose, loadData, selectedLinkItem]);

  const handleEditLink = useCallback(() => {
    if (selectedLinkItem.length) {
      if (selectedLinkItem.length > 1) {
        return addSnack('Массовое редактирование не поддерживается', 'error');
      }
      const selectedLinkItemElement = selectedLinkItem[0];
      if (selectedLinkItemElement.id) {
        const link = generateLink(ROUTES.managerLinkItem, {
          id: selectedLinkItemElement.id,
        });
        history.push(link);
      }
    } else {
      addSnack('Необходимо выбрать вопрос для редактирования', 'error');
    }
  }, [addSnack, history, selectedLinkItem]);

  const linkNameList = useMemo(
    () => linkList.data.map((link) => link.title),
    [linkList.data],
  );

  const handleCopyLink = useCallback(async () => {
    if (selectedLinkItem.length) {
      try {
        const response = await Promise.allSettled(
          selectedLinkItem.map((linkItem) => {
            linkItem.title = getNewItemName(linkNameList, linkItem.title);
            linkItem.status = LinkStatus.DRAFT;
            return linksApi.createLink(linkItem);
          }),
        );
        if (response.some(({ status }) => status === 'rejected')) {
          if (response.every(({ status }) => status === 'rejected')) {
            throw new Error('Some link not copied');
          }
          addSnack('Выбранные ссылки скопированы частично', 'error');
        } else {
          const message =
            selectedLinkItem.length > 1
              ? 'Ссылки скопированы'
              : 'Ссылка скопирована';
          addSnack(message, 'success');
        }
        await loadData();
      } catch (err) {
        console.error(err);
        const message =
          selectedLinkItem.length > 1
            ? 'Ошибка при копировании ссылок'
            : 'Ошибка при копировании ссылки';
        addSnack(message, 'error');
      }
      await loadData();
    }
    handleClose();
  }, [addSnack, handleClose, linkNameList, loadData, selectedLinkItem]);

  const handleRowSelect = useCallback(
    (selectionModel: GridSelectionModel) => {
      setSelectedLinkItem(
        linkList.data?.filter((linkItem) =>
          selectionModel.includes(linkItem.id || 0),
        ),
      );
    },
    [linkList.data],
  );

  const contextMenuItems = useMemo(() => {
    const groups = selectedLinkItem?.reduce(
      (acc, linkItem) => {
        switch (linkItem.status) {
          case LinkStatus.DRAFT:
            acc.draft++;
            break;
          case LinkStatus.PUBLISHED:
            acc.published++;
            break;
        }
        if (linkItem.hasPrivs) {
          acc.hasCredentials++;
        }
        return acc;
      },
      {
        draft: 0,
        published: 0,
        hasCredentials: 0,
      },
    );

    const hasCredentials = selectedLinkItem.length === groups.hasCredentials;

    if (!groups.draft && !groups.published) {
      return [];
    }

    const menuItems: JSX.Element[] = [];
    const isPublished = groups.draft === 0;
    if (isPublished) {
      menuItems.push(
        <MenuItem key="view" onClick={handleView}>
          Просмотр
        </MenuItem>,
      );
    }
    menuItems.push(
      <MenuItem key="copy" onClick={handleCopyLink}>
        Скопировать
      </MenuItem>,
    );

    if (groups.published === 0 && hasCredentials) {
      menuItems.push(
        ...[
          <MenuItem key="edit" onClick={handleEditLink}>
            Изменить
          </MenuItem>,
          <MenuItem key="delete" onClick={handleRemoveLink}>
            Удалить
          </MenuItem>,
        ],
      );
    }

    if (isPublished && hasCredentials) {
      menuItems.push(
        <MenuItem key="unpublish" onClick={handleUnpublish}>
          Снять с публикации
        </MenuItem>,
      );
    }

    return menuItems;
  }, [
    selectedLinkItem,
    handleCopyLink,
    handleEditLink,
    handleRemoveLink,
    handleUnpublish,
    handleView,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const items = [
    { value: '', label: 'Все' },
    { value: LinkStatus.PUBLISHED, label: 'Опубликованные' },
    { value: LinkStatus.DRAFT, label: 'Черновики' },
  ];

  return (
    <div>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {contextMenuItems}
      </Menu>
      <Grid container>
        <Grid item xs alignItems="center">
          <Typography variant="h2" sx={{ fontWeight: '500', fontSize: '18px' }}>
            Полезные ссылки
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="first"
            to={ROUTES.managerLinkAdd}
            startIcon={<PlusIcon />}
          >
            Добавить
          </Button>
        </Grid>
      </Grid>
      <div style={{ margin: '20px 0' }}>
        <Grid container columnSpacing={2}>
          <Grid item xs="auto">
            <ToggleGroup
              items={items}
              value={filters.status || ''}
              handleChange={(e: any, value: string) => {
                setFilters((prevState) => ({
                  ...prevState,
                  status: value || undefined,
                }));
              }}
            />
          </Grid>
          <Grid item xs flexGrow={1}>
            <CommonFilter onChange={setFilters} />
          </Grid>
        </Grid>
      </div>
      <div
        style={{ height: 'auto', width: '100%' }}
        onContextMenu={onContextMenu}
      >
        <Table
          rows={rows}
          columns={columns}
          handleRowSelect={handleRowSelect}
          rowCount={linkList.total}
          page={pagination.currentPage - 1}
          pageSize={pagination.pageSize}
          loading={loading}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>
    </div>
  );
};

export default LinksList;

import { Checkbox, CheckboxProps, styled } from '@mui/material';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  border: '1px solid #CAD3E0',
  width: 20,
  height: 20,
  backgroundColor: '#FFFFFF',
  '.Mui-focusVisible &': {
    outline: '0',
    outlineOffset: 0,
    boxShadow:
      '-3px -3px 0px #C6DDFF, -3px 3px 0px #C6DDFF, 3px 3px 0px #C6DDFF, 3px -3px 0px #C6DDFF',
  },
  'input:hover ~ &': {
    borderColor: '#075FF7',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    backgroundColor: '#F7F7F7',
  },
  '.Mui-checked input:disabled ~ &': {
    backgroundColor: '#075FF7',
    opacity: '0.5',
  },
  [theme.breakpoints.up('md')]: {
    // width: 28,
    // height: 28,
    // borderRadius: 8,
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: '#075FF7',
  borderColor: '#075FF7',
  '&:before': {
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M16.875 5.6254L8.125 14.375L3.75 10.0004' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/></svg>\")",
    content: '""',
    [theme.breakpoints.up('md')]: {
      // backgroundImage:
      //   "url(\"data:image/svg+xml;charset=utf-8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M20.25 6.75046L9.75 17.25L4.5 12.0005' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/></svg>\")",
    },
  },
  'input:hover ~ &': {
    backgroundColor: '#006DE5',
    borderColor: '#006DE5',
  },
}));

const StyledCheckbox = (props: CheckboxProps) => {
  return (
    <Checkbox
      sx={{ p: 0 }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'checkbox' }}
      {...props}
    />
  );
};

export default StyledCheckbox;

import { MY_LIBRARY_LINK } from '@constants/config';
import axios from 'axios';
import {
  dataResponseInterceptor,
  authResponseErrorInterceptor,
  authRequestInterceptor,
  mapToLocaleDateInterceptor,
} from '../interceptors';

export const instance = axios.create({
  baseURL: `${MY_LIBRARY_LINK}node-backend`,
  withCredentials: false,
});

instance.interceptors.request.use(authRequestInterceptor);

instance.interceptors.response.use((response) => {
  const data = dataResponseInterceptor(response);
  return mapToLocaleDateInterceptor(data);
}, authResponseErrorInterceptor(instance));

import { Grid, Typography } from '@mui/material';

export const NotFound = () => (
  <Grid
    sx={{ minHeight: '50vh' }}
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
  >
    <Typography variant="h1" fontSize={60}>
      404
    </Typography>
    <Typography sx={{ mt: 4 }} variant="h3">
      Страница не найдена
    </Typography>
  </Grid>
);

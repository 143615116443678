import { AccountEntry, ContentAccesses } from '@redux/user/types';
import { EntityTypes } from '@mock-data/entities';
import { AccessRights, ContextType } from '@declarations/role';
import { Entity } from '@declarations/common';

export const getAccesses = (
  entries: AccountEntry[],
): ContentAccesses | null => {
  const accesses =
    entries &&
    (entries?.filter(
      (item) => item.resource.resourceType === EntityTypes.ACCESS_RIGHTS,
    ) as Entity<AccessRights>[]);
  if (!accesses?.length) {
    return null;
  }
  const contexts =
    entries &&
    (entries?.filter(
      (item) => item.resource.resourceType === EntityTypes.CONTEXT_TYPE,
    ) as Entity<ContextType>[]);
  if (!contexts?.length) {
    return null;
  }

  const contextAccesses = contexts?.reduce((result, item) => {
    return {
      ...result,
      [item.resource.id]: item.resource.code === 'AC' ? 'all' : 'onlyOwnArea',
    };
  }, {} as { [key: string]: string });

  return accesses?.reduce((result, item) => {
    const funcRoleId = item.resource.functionalRole.reference?.split('/')[1];
    const contentTypId = item.resource.contextType.reference?.split('/')[1];

    return {
      ...result,
      [funcRoleId]: contextAccesses[contentTypId],
    };
  }, {});
};

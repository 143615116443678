import {
  AuthAccountReponse,
  AccountEntry,
  UserRoleStatus,
} from '@redux/user/types';
import { EntityTypes } from '@mock-data/entities';

export const clearRetiredRoles = (req: AuthAccountReponse) => {
  const entries: AccountEntry[] = req?.entry;
  const res: AccountEntry[] = [];

  entries?.forEach((entry) => {
    const resourceType = entry.resource.resourceType;
    if (entry.resource.id) {
      switch (resourceType) {
        case EntityTypes.NEXT_OF_KIN_ROLE:
          if (entry.resource.status !== UserRoleStatus.RETIRED) {
            res.push(entry);
          }
          break;
        case EntityTypes.PUPIL_ROLE:
          if (entry.resource.status !== UserRoleStatus.RETIRED) {
            res.push(entry);
          }
          break;
        case EntityTypes.EDU_PROVIDER_ROLE:
          if (entry.resource.status !== UserRoleStatus.RETIRED) {
            res.push(entry);
          }
          break;
        case EntityTypes.CONTRIBUTOR_ROLE:
          if (entry.resource.status !== UserRoleStatus.RETIRED) {
            res.push(entry);
          }
          break;
        case EntityTypes.ADMIN_ROLE:
          if (entry.resource.status !== UserRoleStatus.RETIRED) {
            res.push(entry);
          }
          break;
        default:
          res.push(entry);
      }
    }
  });
  return { ...req, entry: res };
};

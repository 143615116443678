import { GounnResponse } from '@api/types/gounn';
import dayjs from 'dayjs';
import { Schedule } from '../types';
import objectFieldsToLowerCase from '@utils/objectFormat';

/**
 * Нам нужно показывать время и игнорировать таймзону (чтобы не было привидения к текущей)
 * Поэтому в явном виде должны передать формат для парсинга даты
 *  */
const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const mapResponse = (data: GounnResponse) => {
  const result: Schedule = {};

  const parsedResponse = objectFieldsToLowerCase(data);

  parsedResponse?.school?.forEach((school) => {
    school?.class?.forEach((cl) => {
      cl?.lesson?.forEach((lesson) => {
        const { start, end } = lesson.period;
        const dateKey = dayjs(start, DATE_FORMAT).format('DD.MM.YYYY');
        const timeKey = [
          dayjs(start, DATE_FORMAT).format('H:mm'),
          dayjs(end, DATE_FORMAT).format('H:mm'),
        ].join('—');
        result[dateKey] = result[dateKey] || {};
        result[dateKey][timeKey] = {
          lesson: lesson.subject,
          classroom: lesson.classroom || lesson.classRoom || 'не указан',
          topic: lesson.topic,
          homework: lesson.homework,
          score: lesson.score || [],
        };
      });
    });
  });

  return result;
};

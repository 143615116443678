import { Box } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  value: string;
  isActive: boolean;
};

export const TabPanel = ({ children, value, isActive }: Props) => (
  <div
    hidden={!isActive}
    id={`simple-tabpanel-${value}`}
    aria-labelledby={`simple-tab-${value}`}
  >
    {/* mb={2.5} */}
    <Box borderTop={1} borderColor="#DCE2EB" pt={3}>
      {children}
    </Box>
  </div>
);
